import useRouter from 'found/useRouter';
import { useMemo, useRef } from 'react';

import { TenantRoutes, useTenantRoutes } from './tenantRoutes';

type Route<T extends Obj> = (params: T) => string;

/*
  We need to useRef here -- when client-side routing, the new page
  renders before the component unmounts, causing the projectHandle
  to not exist. We use a ref to differentiate if this is the case,
  or if the consumer is just trying to use the hook where it isn't
  intended to be used (in which case, we throw an error).
*/

function useProjectHandle() {
  const { match } = useRouter();
  const ref = useRef<string>();

  return useMemo(() => {
    const { projectHandle } = match.params;

    if (!projectHandle && !ref.current) {
      throw new Error(
        'cannot call useProjectHandle outside of project routes',
      );
    }

    if (projectHandle) {
      ref.current = projectHandle;
    }

    return ref.current;
  }, [match.params]);
}

type OmitProjectFromRoute<T extends Route<any>> = (
  params: Omit<Parameters<T>[0], 'project'>,
) => string;

// check tenantRoutes.ts for a detailed explanation of
export type ProjectRoutes = {
  [idx in keyof TenantRoutes]: Parameters<TenantRoutes[idx]>[0] extends {
    project: any;
  }
    ? keyof Omit<Parameters<TenantRoutes[idx]>[0], 'project'> extends never
      ? () => string
      : OmitProjectFromRoute<TenantRoutes[idx]>
    : TenantRoutes[idx];
};

export function useProjectRoutes() {
  const projectHandle = useProjectHandle();
  const tenantRoutes = useTenantRoutes();
  const project = { handle: projectHandle };

  const projectRoutes: ProjectRoutes = {} as any;

  Object.entries(tenantRoutes).forEach(([k, v]) => {
    (projectRoutes as any)[k] = (params: any) => v({ project, ...params });
  });

  return projectRoutes;
}
