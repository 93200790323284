import { HttpError, Match } from 'found';
import RedirectException from 'found/RedirectException';
import { GraphQLTaggedNode } from 'react-relay';

import * as routes from 'utils/routes';

function getQuery(match: Match): GraphQLTaggedNode {
  const {
    params: { tenantSlug, projectHandle },
    location,
  } = match;
  if (tenantSlug && projectHandle) {
    throw new RedirectException({
      pathname: routes.runPlanNew({
        project: { handle: projectHandle },
        tenant: { slug: tenantSlug },
      }),
      query: location.query,
    });
  }

  throw new HttpError(404);
}

function newRunPlanRedirect() {
  // We issued the redirect in getQuery
  throw new HttpError(404);
}

newRunPlanRedirect.getQuery = getQuery;

export default newRunPlanRedirect;
