import { HttpError } from 'found';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { RelayRenderArgs } from 'components/Route';
import * as routes from 'utils/routes';

import type {
  analysisRedirectQuery$variables as AnalysisResponse,
  analysisRedirectQuery$data as Props,
} from './__generated__/analysisRedirectQuery.graphql';
import type { tenantRedirectQuery$variables as RootResponse } from './__generated__/tenantRedirectQuery.graphql';

type Variables = RootResponse & AnalysisResponse;

function analysisRedirect({
  props,
  variables,
}: RelayRenderArgs<DeepNonNull<Props>>) {
  if (!props) return;

  const { analysis } = props;
  const { tenantSlug } = variables as Variables;
  if (!analysis) throw new HttpError(404);

  const { project } = props.analysis;
  throw new RedirectException({
    pathname: routes.analysis({
      project,
      analysis,
      tenant: { slug: tenantSlug },
    }),
  });
}

const query = graphql`
  query analysisRedirectQuery($analysisHandle: String!) {
    analysis(handle: $analysisHandle) {
      handle
      project {
        handle
      }
    }
  }
`;

analysisRedirect.query = query;

export default analysisRedirect;
