import Link from 'found/Link';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { graphql } from 'react-relay';
import { object, string } from 'yup';

import FieldGroup from 'components/FieldGroup';
import MultiTextInputGroup from 'components/MultiTextInputGroup';

import AuthForm from './AuthForm';
import BlankPage from './BlankPage';
import TitleMessage from './TitleMessage';
import type { ForgotPasswordPageMutation as Mutation } from './__generated__/ForgotPasswordPageMutation.graphql';

const messages = defineMessages({
  email: {
    id: 'forgotPassword.email',
    defaultMessage: 'Email',
  },
});

const schema = object({
  email: string()
    .email({
      id: 'forgotPassword.emailInvalid',
      defaultMessage: 'Email is invalid',
    })
    .required({
      id: 'forgotPassword.emailRequired',
      defaultMessage: 'Please enter an email',
    })
    .default(''),
});

const defaultValue = schema.getDefault();

const mutation = graphql`
  mutation ForgotPasswordPageMutation($input: ResetPasswordInput!) {
    resetPasswordOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

export default function ForgotPasswordPage() {
  const [finished, setFinished] = useState(false);
  const intl = useIntl();

  return (
    <BlankPage data-testid="ForgotPasswordPage">
      <TitleMessage>
        <FormattedMessage
          id="forgotPassword.title"
          defaultMessage="Reset Password"
        />
      </TitleMessage>
      <AuthForm<Mutation, typeof schema>
        schema={schema}
        mutation={mutation}
        defaultValue={defaultValue}
        onCompleted={() => {
          setFinished(true);
          toast.success(
            <FormattedMessage
              id="forgotPassword.success"
              defaultMessage="If a matching account was found an email was sent to reset your password."
            />,
          );
        }}
        onChange={() => {
          if (finished) setFinished(false);
        }}
        getInput={({ email }) => ({ email })}
      >
        <MultiTextInputGroup>
          <FieldGroup
            name="email"
            placeholder={intl.formatMessage({
              id: 'forgotPassword.email',
              defaultMessage: 'Email',
            })}
            labelSrOnly
            hideError
            label={<FormattedMessage {...messages.email} />}
          />
        </MultiTextInputGroup>
        <AuthForm.Submit finished={finished}>
          {finished ? (
            <FormattedMessage
              id="forgotPassword.emailSent"
              defaultMessage="Email Sent"
            />
          ) : (
            <FormattedMessage
              id="forgotPassword.submit"
              defaultMessage="Send Password Reset Link"
            />
          )}
        </AuthForm.Submit>
      </AuthForm>
      <Link to="/">
        <FormattedMessage id="forgotPassword.login" defaultMessage="Log In" />
      </Link>
    </BlankPage>
  );
}
