/**
 * @generated SignedSource<<bd3dd134639995ba036d96fd3ec449e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceProgress_subscriptionPayload$data = {
  readonly error: {
    readonly details: string | null;
    readonly label: string | null;
  } | null;
  readonly state: {
    readonly code: string | null;
    readonly label: string | null;
    readonly progress: number | null;
    readonly timeRemainingSeconds: number | null;
  } | null;
  readonly " $fragmentType": "DeviceProgress_subscriptionPayload";
};
export type DeviceProgress_subscriptionPayload$key = {
  readonly " $data"?: DeviceProgress_subscriptionPayload$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceProgress_subscriptionPayload">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceProgress_subscriptionPayload",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceError",
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "details",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceState",
      "kind": "LinkedField",
      "name": "state",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeRemainingSeconds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "e61b5aa0a4e8cde47e8535096716670b";

export default node;
