/**
 * @generated SignedSource<<8e7a5a291d978ed88a5cb3a090d837e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Tags_TagPageQuery$variables = {
  tagHandle: string;
};
export type Tags_TagPageQuery$data = {
  readonly tag: {
    readonly " $fragmentSpreads": FragmentRefs<"TagPage_tag">;
  } | null;
};
export type Tags_TagPageQuery = {
  response: Tags_TagPageQuery$data;
  variables: Tags_TagPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tagHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "tagHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Tags_TagPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tag",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TagPage_tag"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Tags_TagPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tag",
        "kind": "LinkedField",
        "name": "tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numLibraries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numProjects",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numRuns",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numSamples",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a5552c58e497995d8d8d2906e2945eb",
    "id": null,
    "metadata": {},
    "name": "Tags_TagPageQuery",
    "operationKind": "query",
    "text": "query Tags_TagPageQuery(\n  $tagHandle: String!\n) {\n  tag(handle: $tagHandle) {\n    ...TagPage_tag\n    id\n  }\n}\n\nfragment DeleteTagControl_tag on Tag {\n  id\n  tenant {\n    id\n  }\n}\n\nfragment EditTagControl_tag on Tag {\n  handle\n}\n\nfragment TagPageHeader_tag on Tag {\n  handle\n  name\n  ...EditTagControl_tag\n  ...DeleteTagControl_tag\n}\n\nfragment TagPageTabs_tag on Tag {\n  handle\n  numAnalyses\n  numLibraries\n  numProjects\n  numRuns\n  numSamples\n}\n\nfragment TagPage_tag on Tag {\n  ...TagPageHeader_tag\n  ...TagPageTabs_tag\n}\n"
  }
};
})();

(node as any).hash = "e07847310032a0310aa4dea46c8ad23f";

export default node;
