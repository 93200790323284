import type { Match, RouterState } from 'found';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { getSessionStore } from 'utils/SessionStore';
import { getNodes } from 'utils/graphql';
import * as routes from 'utils/routes';

import type { rootRedirectTenantQuery as TenantQuery } from './__generated__/rootRedirectTenantQuery.graphql';

function getQuery(match: Match) {
  const { location } = match;
  const session = getSessionStore(match);
  const { tenant } = session.getLastVisitedTenantAndProject();

  if (tenant) {
    throw new RedirectException({
      pathname: routes.dashboard({
        tenant: { slug: tenant.slug },
      }),
      state: {
        isRootRedirect: true,
        prevLocation: location,
      },
    });
  }

  return graphql`
    query rootRedirectTenantQuery {
      viewer {
        tenantConnection(first: 1) {
          edges {
            node {
              slug
            }
          }
        }
      }
    }
  `;
}

type Query = DeepNonNull<TenantQuery['response']>;

function rootRedirect({ props }: { props?: Query & RouterState }) {
  if (!props) {
    return;
  }

  const { match } = props;

  if (!props.viewer) {
    // This shouldn't be possible; we shouldn't be here if we're not logged in.
    throw new HttpError(500);
  }

  const tenants = getNodes(props.viewer.tenantConnection);

  if (tenants.length === 0) {
    throw new RedirectException({
      pathname: '/-/profile',
      state: {
        isRootRedirect: true,
        prevLocation: match.location,
      },
    });
  }

  const { slug } = tenants[0];

  throw new RedirectException({
    pathname: routes.dashboard({ tenant: { slug } }),
  });
}

rootRedirect.getQuery = getQuery;

export default rootRedirect;
