import { HttpError, Match, RedirectException } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getEntitySearchVariables,
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import { getSessionStore } from 'utils/SessionStore';
import applyFunctions from 'utils/applyFunctions';
import checkTenantItem from 'utils/checkTenantItem';

import analysesRoute from '../Analyses';
import librariesRoute from '../Libraries';
import runsRoute from '../Runs';
import samplesRoute from '../Samples';

export default (
  <Route path="projects">
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "projects" */ './components/ProjectsPage'
        ).then((p) => p.default)
      }
      query={graphql`
        query Project_projectsPageQuery(
          $tenantSlug: String!
          $count: Int!
          $cursor: String
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...ProjectsPage_tenant
          }
        }
      `}
      prepareVariables={getInfiniteListVariables}
    />
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "projects" */ './components/NewProjectPage'
        ).then((p) => p.default)
      }
      query={graphql`
        query Project_newProjectQuery($tenantSlug: String!) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NewProjectPage_tenant
          }
        }
      `}
    />
    <Route
      query={graphql`
        query Project_projectQuery(
          $tenantSlug: String!
          $projectHandle: String!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: project(handle: $projectHandle) {
            tenant {
              id
            }
          }
        }
      `}
      path=":projectHandle"
      prerender={({ props, match }) => {
        if (!props) {
          return;
        }

        const session = getSessionStore(match);
        const { tenantSlug, projectHandle } = match.params;
        try {
          checkTenantItem({ props } as any);
        } catch {
          if (props.item) {
            // Root redirects don't check for valid targets to avoid an extra round
            // trip. If it turns out we redirected to somewhere invalid, clear the
            // cache and actually ask the server where to go.
            const { state } = match.location;
            if (state && (state.isRootRedirect || state.isTenantRedirect)) {
              session.removeProjectFromSession(tenantSlug, projectHandle);
              throw new RedirectException(state.prevLocation);
            }
          }

          throw new HttpError(404);
        }

        // Don't save the project id until navigation completes, in case
        // something fails.
        session.setProjectAsVisited(tenantSlug, projectHandle);
      }}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "projects" */ './components/ProjectPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Project_projectPageQuery($projectHandle: String!) {
            project(handle: $projectHandle) {
              ...ProjectPage_project
            }
          }
        `}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectSummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Project_projectSummarySectionQuery($projectHandle: String!) {
              project(handle: $projectHandle) {
                ...ProjectSummarySection_project
              }
            }
          `}
        />
        <Route
          path="specimens"
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectSamplesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Project_samplesSectionQuery(
              $projectHandle: String!
              $count: Int!
              $cursor: String
              $sort: [SampleSorting!]
              $search: String
              $tagIdSearchFilters: [IdOrHandle!]
              $selectedTagHandle: String
              $hasTag: Boolean!
              $userProfileIdSearchFilters: [IdOrHandle!]
              $selectedUserProfileHandle: String
              $hasUserProfile: Boolean!
            ) {
              project(handle: $projectHandle) {
                ...ProjectSamplesSection_project
              }
              userProfile(handle: $selectedUserProfileHandle)
                @include(if: $hasUserProfile) {
                ...ProjectSamplesSection_userProfile
              }
              tag(handle: $selectedTagHandle) @include(if: $hasTag) {
                ...ProjectSamplesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
            getEntitySearchVariables,
          )}
        />
        <Route
          path="libraries"
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectLibrariesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Project_librariesSectionQuery(
              $projectHandle: String!
              $count: Int!
              $cursor: String
              $sort: [LibrarySorting!]
              $search: String
              $status: [LibraryStatus!]
              $tagIdSearchFilters: [IdOrHandle!]
              $selectedTagHandle: String
              $hasTag: Boolean!
              $userProfileIdSearchFilters: [IdOrHandle!]
              $selectedUserProfileHandle: String
              $hasUserProfile: Boolean!
            ) {
              project(handle: $projectHandle) {
                ...ProjectLibrariesSection_project
              }
              userProfile(handle: $selectedUserProfileHandle)
                @include(if: $hasUserProfile) {
                ...ProjectLibrariesSection_userProfile
              }
              tag(handle: $selectedTagHandle) @include(if: $hasTag) {
                ...ProjectLibrariesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
            getEntitySearchVariables,
          )}
        />
        <Route
          path="runs"
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectRunsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Project_runsSectionQuery(
              $projectHandle: String!
              $count: Int!
              $cursor: String
              $sort: [RunSorting!]
              $status: [RunStatus!]
              $search: String
              $tagIdSearchFilters: [IdOrHandle!]
              $selectedTagHandle: String
              $hasTag: Boolean!
              $userProfileIdSearchFilters: [IdOrHandle!]
              $selectedUserProfileHandle: String
              $hasUserProfile: Boolean!
            ) {
              project(handle: $projectHandle) {
                ...ProjectRunsSection_project
              }
              userProfile(handle: $selectedUserProfileHandle)
                @include(if: $hasUserProfile) {
                ...ProjectRunsSection_userProfile
              }
              tag(handle: $selectedTagHandle) @include(if: $hasTag) {
                ...ProjectRunsSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
            getEntitySearchVariables,
          )}
        />
        <Route
          path="analyses"
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectAnalysesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Project_analysesSectionQuery(
              $projectHandle: String!
              $count: Int!
              $cursor: String
              $sort: [AnalysisSorting!]
              $status: [AnalysisStatus!]
              $search: String
              $tagIdSearchFilters: [IdOrHandle!]
              $selectedTagHandle: String
              $hasTag: Boolean!
              $userProfileIdSearchFilters: [IdOrHandle!]
              $selectedUserProfileHandle: String
              $hasUserProfile: Boolean!
            ) {
              project(handle: $projectHandle) {
                ...ProjectAnalysesSection_project
              }
              userProfile(handle: $selectedUserProfileHandle)
                @include(if: $hasUserProfile) {
                ...ProjectAnalysesSection_userProfile
              }
              tag(handle: $selectedTagHandle) @include(if: $hasTag) {
                ...ProjectAnalysesSection_tag
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
            getEntitySearchVariables,
          )}
        />
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "projects" */ './components/ProjectNotesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Projects_notesSectionQuery(
              $projectHandle: String!
              $count: Int!
              $cursor: String
            ) {
              project(handle: $projectHandle) {
                ...ProjectNotesSection_project
              }
            }
          `}
          path="notes"
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "projects" */ './components/EditProjectPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Project_editProjectPageQuery($projectHandle: String!) {
            project(handle: $projectHandle) {
              ...EditProjectPage_project
            }
          }
        `}
      />

      {samplesRoute}
      {librariesRoute}
      {runsRoute}
      {analysesRoute}
    </Route>
  </Route>
);
