import classNames from 'classnames';
import React from 'react';

import './Section.scss';

interface ActionBarWrapperProps {
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  centerText?: boolean;
  className?: string;
  children: React.ReactNode;
  actionBar?: React.ReactNode;
  disablePadding?: boolean;
  isFormPage?: boolean;
}

const rootClass = 'Section';

function Section({
  size = 'xlarge',
  centerText,
  actionBar,
  disablePadding,
  isFormPage,
  className,
  children,
  ...props
}: ActionBarWrapperProps) {
  return (
    <div
      style={disablePadding ? { padding: 0 } : undefined}
      className={classNames([
        rootClass,
        className,
        `${rootClass}--${size}`,
        isFormPage && `${rootClass}--edit-page`,
        actionBar && `${rootClass}--has-action-bar`,
        centerText && `${rootClass}--center-text`,
      ])}
      {...props}
    >
      {actionBar}
      {children}
    </div>
  );
}

export default Section;
