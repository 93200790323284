export function toTimeParts(seconds: number) {
  const totalMinutes = Math.floor(seconds / 60);

  return {
    hours: Math.floor(totalMinutes / 60),
    minutes: totalMinutes % 60,
    seconds: seconds % 60,
  };
}

export function fromTimeParts({
  hours,
  minutes,
}: {
  hours: string;
  minutes: string;
}) {
  const hoursFloat = parseFloat(hours);
  const minutesFloat = parseFloat(minutes);

  const hasHours = !Number.isNaN(hoursFloat);
  const hasMinutes = !Number.isNaN(minutesFloat);

  if (!hasHours && !hasMinutes) return null;

  const hoursSafe = hasHours ? hoursFloat : 0;
  const minutesSafe = hasMinutes ? minutesFloat : 0;
  const totalMinutes = 60 * hoursSafe + minutesSafe;

  return Math.max(60 * totalMinutes, 0);
}
