import Layout from '@4c/layout';
import classNames from 'classnames';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Text from 'components/Text';

import Avatar from './Avatar';
import type { UserProfile_userProfile$data as UserProfileProp } from './__generated__/UserProfile_userProfile.graphql';

import './UserProfile.scss';

const AVATAR_WIDTH_PX = 28;

interface Props {
  userProfile: UserProfileProp;
  width?: number;
  showName?: boolean;
  className?: string;
}

const rootClass = 'UserProfile';

function UserProfile({
  userProfile,
  width = AVATAR_WIDTH_PX,
  showName = false,
  className,
}: Props) {
  return (
    <Layout
      className={classNames([rootClass, className])}
      direction="row"
      align="center"
    >
      <Avatar userProfile={userProfile} width={width} />
      {showName && (
        <Text className={classNames(`${rootClass}__name`)}>
          {userProfile.displayName}
        </Text>
      )}
    </Layout>
  );
}

export default createFragmentContainer(UserProfile, {
  userProfile: graphql`
    fragment UserProfile_userProfile on UserProfile {
      displayName

      ...Avatar_userProfile
    }
  `,
});
