import mapContextToProps from '@restart/context/mapContextToProps';
import React, { useContext } from 'react';

export interface AuthInfo {
  accessToken: string;
  expiresAt: number;
  impersonation?: boolean;
}

export interface AuthContextValue {
  setAccessToken: (authInfo: AuthInfo) => void;
  clearAccessToken: () => void;
  isAuthenticated: () => boolean;
  viewerLocalId: string | undefined;
}

const AuthContext = React.createContext<AuthContextValue>(null as any);
export default AuthContext;

export const useAuth = () => useContext(AuthContext);
export const withAuth = (Component: React.ElementType) =>
  mapContextToProps(AuthContext, (auth) => ({ auth }), Component);
