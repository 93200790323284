/**
 * @generated SignedSource<<a89edebd132d7c7860708ad795210162>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceFamily = "ATTO" | "CARBON" | "NANO" | "NICKEL" | "PLATINUM" | "VIRTUAL";
import { FragmentRefs } from "relay-runtime";
export type devicesMessages_device$data = {
  readonly family: DeviceFamily | null;
  readonly " $fragmentType": "devicesMessages_device";
};
export type devicesMessages_device$key = {
  readonly " $data"?: devicesMessages_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"devicesMessages_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "devicesMessages_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "6530990566050f7555404f8e408465f3";

export default node;
