/**
 * @generated SignedSource<<c98cf4a23223068a2aa932df3e2e43f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type References_ReferenceAnnotationQuery$variables = {
  referenceAnnotationHandle: string;
};
export type References_ReferenceAnnotationQuery$data = {
  readonly referenceAnnotation: {
    readonly __typename: "ReferenceAnnotation";
  } | null;
};
export type References_ReferenceAnnotationQuery = {
  response: References_ReferenceAnnotationQuery$data;
  variables: References_ReferenceAnnotationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceAnnotationHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceAnnotationHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferenceAnnotationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_ReferenceAnnotationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5065dbb9e67a2fce2ff6962ebf11ea73",
    "id": null,
    "metadata": {},
    "name": "References_ReferenceAnnotationQuery",
    "operationKind": "query",
    "text": "query References_ReferenceAnnotationQuery(\n  $referenceAnnotationHandle: String!\n) {\n  referenceAnnotation(handle: $referenceAnnotationHandle) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bf25faaa71e226b747ed81acf9925d35";

export default node;
