import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { Status } from 'config/runStatus';
import runMessages from 'messages/runs';

import DeviceProgress from './DeviceProgress';
import type { RunProgress_run$data as Run } from './__generated__/RunProgress_run.graphql';

interface Props {
  run: DeepNonNull<Run>;
  className?: string;
}

function RunProgress({ run, className }: Props) {
  if (run.status === Status.RUNNING && run.device?.state?.label) {
    return <DeviceProgress device={run.device!} className={className} />;
  }

  return <FormattedMessage {...runMessages[run.status]} />;
}

export default createFragmentContainer(RunProgress, {
  run: graphql`
    fragment RunProgress_run on Run {
      status

      device {
        state {
          label
        }
        ...DeviceProgress_device
      }
    }
  `,
});
