export const ChipSplitType = {
  WHOLE_CHIP: 'whole_chip',
  SPLIT_CHIP: 'split_chip',
  QUAD_CHIP: 'quad_chip',
};

export const ChipSliptConfigs = (hasQuad = false as boolean) => {
  return [
    { type: ChipSplitType.WHOLE_CHIP, numSplits: 0 },
    { type: ChipSplitType.SPLIT_CHIP, numSplits: 1 },
    ...(hasQuad ? [{ type: ChipSplitType.QUAD_CHIP, numSplits: 3 }] : []),
  ];
};
