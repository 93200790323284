/**
 * @generated SignedSource<<0d927b56e9c22ad74a9a523f97461717>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AllSearchList_tenant$data = {
  readonly analysisConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"AnalysisList_analyses">;
      } | null;
    } | null> | null;
  } | null;
  readonly libraryConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"LibraryList_libraries">;
      } | null;
    } | null> | null;
  } | null;
  readonly name: string | null;
  readonly runConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"RunList_runs">;
      } | null;
    } | null> | null;
  } | null;
  readonly sampleConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SampleList_samples">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AllSearchList_tenant";
};
export type AllSearchList_tenant$key = {
  readonly " $data"?: AllSearchList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllSearchList_tenant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "createdBy",
  "variableName": "userProfileHandles"
},
v1 = {
  "kind": "Variable",
  "name": "project",
  "variableName": "projectHandles"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "Variable",
  "name": "tag",
  "variableName": "tagHandles"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "analysisSort"
    },
    {
      "kind": "RootArgument",
      "name": "librarySort"
    },
    {
      "kind": "RootArgument",
      "name": "projectHandles"
    },
    {
      "kind": "RootArgument",
      "name": "runSort"
    },
    {
      "kind": "RootArgument",
      "name": "sampleSort"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "tagHandles"
    },
    {
      "kind": "RootArgument",
      "name": "userProfileHandles"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "analysisConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "sampleConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "runConnection"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "libraryConnection"
        ]
      }
    ]
  },
  "name": "AllSearchList_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "analysisConnection",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "analysisSort"
        },
        (v3/*: any*/)
      ],
      "concreteType": "AnalysisConnection",
      "kind": "LinkedField",
      "name": "__AllSearchList_analysisConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalysisEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Analysis",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AnalysisList_analyses"
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "sampleConnection",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sampleSort"
        },
        (v3/*: any*/)
      ],
      "concreteType": "SampleConnection",
      "kind": "LinkedField",
      "name": "__AllSearchList_sampleConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SampleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Sample",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SampleList_samples"
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "runConnection",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "runSort"
        },
        (v3/*: any*/)
      ],
      "concreteType": "RunConnection",
      "kind": "LinkedField",
      "name": "__AllSearchList_runConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RunEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Run",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RunList_runs"
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "libraryConnection",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "librarySort"
        },
        (v3/*: any*/)
      ],
      "concreteType": "LibraryConnection",
      "kind": "LinkedField",
      "name": "__AllSearchList_libraryConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LibraryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Library",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LibraryList_libraries"
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();

(node as any).hash = "efc203e063e62bf839d68589f9b9c952";

export default node;
