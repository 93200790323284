export const Status = {
  PLANNED: 'planned',
  CHIP_CHECK: 'chip_check',
  FINISHED_CHIP_CHECK: 'finished_chip_check',
  RUNNING: 'running',
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  FAILED: 'failed',
  ABORTED: 'aborted',
  RESTARTING: 'restarting',
};

export const StatusCategory = {
  PLANNED: Status.PLANNED,
  ACTIVE: 'active',
  FINISHED: Status.FINISHED,
  FAILED: Status.FAILED,
};

export const STATUSES_BY_CATEGORY = {
  [StatusCategory.PLANNED]: [Status.PLANNED],
  [StatusCategory.ACTIVE]: [
    Status.RUNNING,
    Status.CHIP_CHECK,
    Status.UPLOADING,
    Status.PROCESSING,
    Status.RESTARTING,
  ],
  [StatusCategory.FINISHED]: [Status.FINISHED, Status.FINISHED_CHIP_CHECK],
  [StatusCategory.FAILED]: [Status.FAILED, Status.ABORTED],
};

export const CATEGORY_BY_STATUS = {};
Object.keys(STATUSES_BY_CATEGORY).forEach((category) => {
  STATUSES_BY_CATEGORY[category].forEach((status) => {
    (CATEGORY_BY_STATUS as any)[status] = category;
  });
});
