import React, {
  ProviderProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import { RelayOperationWithError } from './RelayForm';
import type { useTenantUserSettingsMutation as Mutation } from './__generated__/useTenantUserSettingsMutation.graphql';
import type {
  useTenantUserSettingsQuery as Query,
  TenantUserSettingType,
} from './__generated__/useTenantUserSettingsQuery.graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment useTenantUserSettings_settings on TenantUser {
    settings {
      settingType
      ... on ShowHiddenAnalysisParamsSetting {
        data {
          enabled
        }
      }
    }
  }
`;

export type BooleanSettingData = {
  enabled: boolean;
};

type TenantUserSettingBase<T, P extends TenantUserSettingType> = {
  settingType: P;
  data: T;
};

export type ShowHiddenAnalysisParamsSetting = TenantUserSettingBase<
  BooleanSettingData,
  'SHOW_HIDDEN_ANALYSIS_PARAMS'
>;

type TenantUserSettingsValues = {
  SHOW_HIDDEN_ANALYSIS_PARAMS: ShowHiddenAnalysisParamsSetting | null;
};

export const useTenantUserSettingsQuery = graphql`
  query useTenantUserSettingsQuery($tenantSlug: String!) {
    tenant: tenantBySlug(slug: $tenantSlug) {
      id
      handle
      currentUser {
        userProfile {
          id
        }
        ...useTenantUserSettings_settings @relay(mask: false)
      }
    }
  }
`;

const mutation = graphql`
  mutation useTenantUserSettingsMutation(
    $input: SetShowHiddenAnalysisParamsSettingInput!
  ) {
    setShowHiddenAnalysisParamsSettingOrError(input: $input) {
      ... on SetShowHiddenAnalysisParamsSettingPayload {
        user {
          ...useTenantUserSettings_settings @relay(mask: false)
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

type NonNullRelayOperation<T extends RelayOperationWithError<any>> = {
  variables: T['variables'];
  response: DeepNonNull<T['response']>;
};

type Tenant = DeepNonNull<Query['response']['tenant']>;

export interface TenantUserSettingsContextValue {
  updateShowHiddenAnalysisParamsSetting: (enabled: boolean) => Promise<void>;
  settings: TenantUserSettingsValues;
}

const TenantUserSettingsContext =
  React.createContext<TenantUserSettingsContextValue | null>(null);
export default TenantUserSettingsContext;

export const useTenantUserSettings = () =>
  useContext(TenantUserSettingsContext);

export function useTenantUserSetting<
  TKey extends keyof TenantUserSettingsValues,
>(key: TKey): TenantUserSettingsValues[TKey] {
  const tenantUserSettings = useTenantUserSettings();
  return tenantUserSettings?.settings[key] ?? null;
}

interface Props
  extends Omit<ProviderProps<TenantUserSettingsContextValue>, 'value'> {
  tenant: Tenant;
}

export function TenantUserSettingProvider({ tenant, ...rest }: Props) {
  const [settings, setSettings] = useState(tenant.currentUser.settings);

  const [mutate] = useMutation<NonNullRelayOperation<Mutation>>(mutation);

  const updateSetting = useCallback(
    async (hiddenEnabled: boolean) => {
      const resp = await mutate({
        variables: {
          input: {
            tenantId: tenant.id,
            userId: tenant.currentUser.userProfile.id!,
            data: {
              enabled: hiddenEnabled,
            },
          },
        },
      });

      const newSettings =
        resp?.setShowHiddenAnalysisParamsSettingOrError?.user?.settings;
      setSettings(newSettings);
    },
    [mutate, tenant],
  );

  const settingsMap = useMemo(() => {
    const map: TenantUserSettingsValues = {
      SHOW_HIDDEN_ANALYSIS_PARAMS: null,
    };
    settings.forEach((setting) => {
      if (setting.settingType === 'SHOW_HIDDEN_ANALYSIS_PARAMS') {
        map.SHOW_HIDDEN_ANALYSIS_PARAMS =
          setting as ShowHiddenAnalysisParamsSetting;
      }
    });
    return map;
  }, [settings]);

  const tenantUserSettingsContext: TenantUserSettingsContextValue = useMemo(
    () => ({
      updateShowHiddenAnalysisParamsSetting: (enabled: boolean) => {
        return updateSetting(enabled);
      },
      getSettings: () => settings,
      settings: settingsMap,
    }),
    [settings, settingsMap, updateSetting],
  );

  return (
    <TenantUserSettingsContext.Provider
      value={tenantUserSettingsContext}
      {...rest}
    />
  );
}
