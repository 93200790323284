/**
 * @generated SignedSource<<7fa6fd56948b0fa2a2273182cc05a735>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sharedRoutes_analysisQuery$variables = {
  analysisHandle: string;
};
export type sharedRoutes_analysisQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedAnalysisPage_analysis">;
  } | null;
};
export type sharedRoutes_analysisQuery = {
  response: sharedRoutes_analysisQuery$data;
  variables: sharedRoutes_analysisQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hidden",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "level",
      "value": [
        "error",
        "warning"
      ]
    }
  ],
  "kind": "ScalarField",
  "name": "numLogLines",
  "storageKey": "numLogLines(level:[\"error\",\"warning\"])"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedRoutes_analysisQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SharedAnalysisPage_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sharedRoutes_analysisQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisReport",
                "kind": "LinkedField",
                "name": "report",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisMetric",
                    "kind": "LinkedField",
                    "name": "additionalMetrics",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisFile",
                    "kind": "LinkedField",
                    "name": "files",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "showTabbed",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisImage",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisJob",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisJobStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dbff43b421025dc789e0c868c7c6a7b",
    "id": null,
    "metadata": {},
    "name": "sharedRoutes_analysisQuery",
    "operationKind": "query",
    "text": "query sharedRoutes_analysisQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...SharedAnalysisPage_analysis\n    id\n  }\n}\n\nfragment AnalysisPageTabs_analysis on Analysis {\n  id\n  handle\n  numNotes\n  data {\n    report {\n      additionalMetrics {\n        __typename\n        hidden\n      }\n      files {\n        __typename\n        handle\n        title\n        showTabbed\n        hidden\n        id\n      }\n      images {\n        __typename\n        hidden\n        id\n      }\n    }\n    id\n  }\n  job {\n    __typename\n    numLogLines(level: [error, warning])\n    steps {\n      __typename\n      numLogLines(level: [error, warning])\n      id\n    }\n    id\n  }\n}\n\nfragment SharedAnalysisPage_analysis on Analysis {\n  handle\n  ...AnalysisPageTabs_analysis\n}\n"
  }
};
})();

(node as any).hash = "6a63e5a5d9c9b7a8dbbf0c11d44a91cd";

export default node;
