/**
 * @generated SignedSource<<dc8a5ae369cf92b272b82614b3bcb65a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_ReferencePageQuery$variables = {
  referenceHandle: string;
};
export type References_ReferencePageQuery$data = {
  readonly reference: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferencePage_reference">;
  } | null;
};
export type References_ReferencePageQuery = {
  response: References_ReferencePageQuery$data;
  variables: References_ReferencePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferencePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferencePage_reference"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_ReferencePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7bdb03a62ddc1088a16c048f86cd4c4a",
    "id": null,
    "metadata": {},
    "name": "References_ReferencePageQuery",
    "operationKind": "query",
    "text": "query References_ReferencePageQuery(\n  $referenceHandle: String!\n) {\n  reference(handle: $referenceHandle) {\n    ...ReferencePage_reference\n    id\n  }\n}\n\nfragment DeleteReferenceControl_reference on Reference {\n  id\n  tenant {\n    id\n  }\n}\n\nfragment EditReferenceControl_reference on Reference {\n  handle\n  tenant {\n    id\n  }\n}\n\nfragment ReferencePageHeader_reference on Reference {\n  handle\n  name\n  tenant {\n    handle\n    id\n  }\n  ...EditReferenceControl_reference\n  ...DeleteReferenceControl_reference\n}\n\nfragment ReferencePageTabs_reference on Reference {\n  handle\n}\n\nfragment ReferencePage_reference on Reference {\n  ...ReferencePageHeader_reference\n  ...ReferencePageTabs_reference\n}\n"
  }
};
})();

(node as any).hash = "585e12addcbef32318e9b0be5e6ac7c8";

export default node;
