import React from 'react';
import { FormattedDate } from 'react-intl';

import Text from 'components/Text';

export default function Date({
  value,
  imprecise,
  className,
}: {
  value: string | null;
  imprecise?: boolean;
  className?: string;
}) {
  const emDash = '\u2014';

  return (
    <Text className={className}>
      {value ? (
        <FormattedDate
          value={value}
          dateStyle={imprecise ? 'short' : 'long'}
          timeStyle="short"
        />
      ) : (
        emDash
      )}
    </Text>
  );
}
