/**
 * @generated SignedSource<<cd91a5fe617b807a16eb96a60b29d65c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TenantUserSettingType = "SHOW_HIDDEN_ANALYSIS_PARAMS";
export type SetShowHiddenAnalysisParamsSettingInput = {
  clientMutationId?: string | null;
  data: BooleanSettingInput;
  tenantId: String;
  userId: String;
};
export type BooleanSettingInput = {
  enabled: boolean;
};
export type useTenantUserSettingsMutation$variables = {
  input: SetShowHiddenAnalysisParamsSettingInput;
};
export type useTenantUserSettingsMutation$data = {
  readonly setShowHiddenAnalysisParamsSettingOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
    readonly user?: {
      readonly settings: ReadonlyArray<{
        readonly data?: {
          readonly enabled: boolean | null;
        } | null;
        readonly settingType: TenantUserSettingType | null;
      } | null> | null;
    } | null;
  } | null;
};
export type useTenantUserSettingsMutation = {
  response: useTenantUserSettingsMutation$data;
  variables: useTenantUserSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settingType",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BooleanSetting",
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ShowHiddenAnalysisParamsSetting",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useTenantUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setShowHiddenAnalysisParamsSettingOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "SetShowHiddenAnalysisParamsSettingPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useTenantUserSettingsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "setShowHiddenAnalysisParamsSettingOrError",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUser",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "SetShowHiddenAnalysisParamsSettingPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "204919b79fd22dcb431f61814b52b785",
    "id": null,
    "metadata": {},
    "name": "useTenantUserSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation useTenantUserSettingsMutation(\n  $input: SetShowHiddenAnalysisParamsSettingInput!\n) {\n  setShowHiddenAnalysisParamsSettingOrError(input: $input) {\n    __typename\n    ... on SetShowHiddenAnalysisParamsSettingPayload {\n      user {\n        settings {\n          __typename\n          settingType\n          ... on ShowHiddenAnalysisParamsSetting {\n            data {\n              enabled\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f106664329e31e4f701d6a42fea56c93";

export default node;
