import FarceActions from 'farce/Actions';
import store from 'store/dist/store.modern';

import { TokenState } from './AuthProvider';

const STORAGE_TOKEN_KEY = 'qsi:auth';

export default class WebTokenStorage {
  farceStore: any;

  constructor(farceStore: any) {
    this.farceStore = farceStore;
  }

  private loadFromQuerystring(): TokenState | null {
    if (!this.farceStore) return null;

    const foundState = this.farceStore.getState().found;
    if (!foundState || !foundState.match) {
      return null;
    }
    const { location } = foundState.match;
    const { token, ...query } = location.query as Obj<string>;

    if (!token) {
      return null;
    }

    this.farceStore.dispatch(FarceActions.replace({ ...location, query }));

    return {
      accessToken: token,
      // XXX: we don't know token expiration time
      expiresAt: new Date(Date.now() + 60 * 60 * 1000).getTime(),
      email: 'anonymous',
      localId: 'anonymous',
    };
  }

  private loadFromLocalStorage(): TokenState | null {
    return store.get(STORAGE_TOKEN_KEY);
  }

  load(): TokenState | null {
    const token = this.loadFromQuerystring() || this.loadFromLocalStorage();
    if (typeof token?.expiresAt !== 'number') {
      return null;
    }
    return token;
  }

  save(value: TokenState) {
    store.set(STORAGE_TOKEN_KEY, value);
  }

  clear() {
    store.remove(STORAGE_TOKEN_KEY);
  }
}
