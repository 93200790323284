/**
 * @generated SignedSource<<31bb148a53777e4904060f3fae461c95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EulaPage_viewer$data = {
  readonly latestCloudEula: {
    readonly id: string;
    readonly textHtml: string | null;
    readonly version: string | null;
  } | null;
  readonly latestDeviceEula: {
    readonly id: string;
    readonly textHtml: string | null;
    readonly version: string | null;
  } | null;
  readonly user: {
    readonly hasAcceptedLatestCloudEula: boolean | null;
    readonly hasAcceptedLatestDeviceEula: boolean | null;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "EulaPage_viewer";
};
export type EulaPage_viewer$key = {
  readonly " $data"?: EulaPage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EulaPage_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "textHtml",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EulaPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Eula",
      "kind": "LinkedField",
      "name": "latestCloudEula",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Eula",
      "kind": "LinkedField",
      "name": "latestDeviceEula",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAcceptedLatestCloudEula",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAcceptedLatestDeviceEula",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "398c43e0f92c83333e617004978fa046";

export default node;
