import classNames from 'classnames';
import React from 'react';
import './SidePanel.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const rootClass = 'SidePanel';

const SidePanel = ({ className, children }: Props) => {
  return (
    <div className={classNames(rootClass, className, 'scrollable')}>
      {children}
    </div>
  );
};

export default SidePanel;
