export function relayResponseHasError(data: any) {
  const payload = data && Object.values(data)[0];

  /* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
  if (!payload || payload.__typename.endsWith('Payload')) {
    // There was no error.
    return null;
  }

  return payload;
}
