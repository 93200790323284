/**
 * @generated SignedSource<<73a64cfafef39548a406f5ccd67945af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantSidePanelItem_recentProjects$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "TenantSidePanelItem_recentProjects";
}>;
export type TenantSidePanelItem_recentProjects$key = ReadonlyArray<{
  readonly " $data"?: TenantSidePanelItem_recentProjects$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TenantSidePanelItem_recentProjects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "d5b8ed0db66a34de7c2304751d551234";

export default node;
