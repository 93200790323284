import Layout from '@4c/layout';
import classNames from 'classnames';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Date from './Date';
import UserProfile from './UserProfile';
import type { CreatedByListItem_userProfile$data as UserProfileFragment } from './__generated__/CreatedByListItem_userProfile.graphql';

import './CreatedByListItem.scss';

interface Props {
  userProfile: UserProfileFragment | null;
  createdAt: string | null;
}

const rootClass = 'CreatedByListItem';

function CreatedByListItem({ userProfile, createdAt }: Props) {
  if (!userProfile) return null;

  return (
    <Layout align="center" className={rootClass}>
      <UserProfile userProfile={userProfile} />
      {createdAt && (
        <Date
          value={createdAt}
          imprecise
          className={classNames(`${rootClass}__date`)}
        />
      )}
    </Layout>
  );
}

export default createFragmentContainer(CreatedByListItem, {
  userProfile: graphql`
    fragment CreatedByListItem_userProfile on UserProfile {
      ...UserProfile_userProfile
    }
  `,
});
