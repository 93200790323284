/**
 * @generated SignedSource<<50d691c046ba74399ef735cc58e9e7df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apps_Developer_EditWorkflowPageQuery$variables = {
  workflowHandle: string;
};
export type Apps_Developer_EditWorkflowPageQuery$data = {
  readonly workflow: {
    readonly " $fragmentSpreads": FragmentRefs<"EditWorkflowPage_workflow">;
  } | null;
};
export type Apps_Developer_EditWorkflowPageQuery = {
  response: Apps_Developer_EditWorkflowPageQuery$data;
  variables: Apps_Developer_EditWorkflowPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workflowHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "workflowHandle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_Developer_EditWorkflowPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditWorkflowPage_workflow"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Apps_Developer_EditWorkflowPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "public",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequenceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowXlargeResources",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c6895bc10dcc8d7ffa66d64296cfddef",
    "id": null,
    "metadata": {},
    "name": "Apps_Developer_EditWorkflowPageQuery",
    "operationKind": "query",
    "text": "query Apps_Developer_EditWorkflowPageQuery(\n  $workflowHandle: String!\n) {\n  workflow(handle: $workflowHandle) {\n    ...EditWorkflowPage_workflow\n    id\n  }\n}\n\nfragment EditWorkflowPage_workflow on Workflow {\n  description\n  handle\n  id\n  name\n  public\n  sequenceType\n  allowXlargeResources\n}\n"
  }
};
})();

(node as any).hash = "b24494a8eeb7e8f33e2834784e391015";

export default node;
