import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import ItemGrid, { TableColumns } from 'components/ItemGrid';
import messages from 'messages/libraries';
import { useTenantRoutes } from 'utils/tenantRoutes';

import CreatedByListItem from './CreatedByListItem';
import LibraryProgress from './LibraryProgress';
import NoItemFound from './NoItemFound';
import type { LibraryListSubscription } from './__generated__/LibraryListSubscription.graphql';
import type { LibraryList_libraries$data as Libraries } from './__generated__/LibraryList_libraries.graphql';

const subscription = graphql`
  subscription LibraryListSubscription(
    $input: UpdateLibrarySubscriptionInput!
  ) {
    updateLibrary(input: $input) {
      library {
        ...LibraryList_libraries
      }
    }
  }
`;

function LibraryListSubscriber({ library }: { library: Libraries[number] }) {
  useSubscription<LibraryListSubscription>({
    subscription,
    variables: {
      input: { libraryId: library.id },
    },
  });
  return null;
}

interface Props {
  libraries: Libraries;
  showProject?: boolean;
}

function LibraryList({ libraries, showProject }: Props) {
  const routes = useTenantRoutes();
  const columns = React.useMemo<TableColumns<Libraries[number]>>(
    () => [
      {
        Header: (
          <FormattedMessage id="libraryList.name" defaultMessage="Library" />
        ),
        accessor: 'name',
      },
      {
        Header: (
          <FormattedMessage id="libraryList.origin" defaultMessage="Loading" />
        ),
        accessor: 'origin',
        Cell: ({ value }) => <FormattedMessage {...messages[value!]} />,
      },
      {
        Header: (
          <FormattedMessage id="libraryList.status" defaultMessage="Status" />
        ),
        accessor: 'status',
        Cell: ({ row }) => <LibraryProgress library={row.original} />,
      },
      {
        Header: (
          <FormattedMessage
            id="libraryList.project"
            defaultMessage="Project"
          />
        ),
        accessor: (row) => row.project?.name,
        id: 'project',
      },
      {
        Header: (
          <FormattedMessage id="libraryList.sample" defaultMessage="Sample" />
        ),
        accessor: (row) => row.sample?.name,
        id: 'sample',
      },
      {
        Header: (
          <FormattedMessage
            id="libraryList.created"
            defaultMessage="Created"
          />
        ),
        accessor: 'createdAt',
        sort: 'CREATED_AT',
        Cell: ({ row }) => (
          <CreatedByListItem
            userProfile={row.original.createdBy?.userProfile ?? null}
            createdAt={row.original.createdAt}
          />
        ),
      },
    ],
    [],
  );

  if (libraries.length === 0) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage
            id="libraryList.itemType"
            defaultMessage="libraries"
          />
        }
      />
    );
  }

  return (
    <ItemGrid
      data={libraries}
      columns={columns}
      initialState={{
        hiddenColumns: [!showProject && 'project'].filter(Boolean) as string[],
      }}
      linkTo={(library: DeepNonNull<Libraries[number]>) =>
        routes.library({ project: library.project, library })
      }
      subscriberComponent={(library) => (
        <LibraryListSubscriber library={library} />
      )}
      data-testid="LibraryList"
    />
  );
}

export default createFragmentContainer(LibraryList, {
  libraries: graphql`
    fragment LibraryList_libraries on Library @relay(plural: true) {
      id
      handle
      name
      origin
      project {
        handle
        name
      }
      sample {
        name
      }
      status
      ...LibraryProgress_library
      createdAt
      createdBy {
        userProfile {
          ...CreatedByListItem_userProfile
        }
      }
    }
  `,
});
