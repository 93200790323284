import React from 'react';
import { graphql } from 'react-relay';

import SampleList from 'components/SampleList';
import createInfiniteList from 'components/utils/createInfiniteList';

import SearchList from './SearchList';
import type { SampleSearchList_tenant$data as Tenant } from './__generated__/SampleSearchList_tenant.graphql';

interface Props {
  tenant: DeepNonNull<Tenant>;
}

export const samplesSearchListQuery = graphql`
  query SampleSearchListQuery(
    $tenantSlug: String!
    $count: Int!
    $cursor: String
    $search: String
    $sort: [SampleSorting!]
    $userProfileHandles: [IdOrHandle!]
    $tagHandles: [IdOrHandle!]
    $projectHandles: [IdOrHandle!]
  ) {
    tenant: tenantBySlug(slug: $tenantSlug) {
      ...SampleSearchList_tenant
    }
  }
`;

export default createInfiniteList(
  ({ tenant }: Props) => (
    <SearchList
      connection={tenant.sampleConnection}
      entityType="specimens"
      Component={SampleList}
    />
  ),
  {
    tenant: graphql`
      fragment SampleSearchList_tenant on Tenant {
        sampleConnection(
          search: $search
          sort: $sort
          createdBy: $userProfileHandles
          project: $projectHandles
          tag: $tagHandles
          first: $count
          after: $cursor
        ) @connection(key: "Tenant_sampleConnection") {
          edges {
            node {
              ...SampleList_samples
            }
          }
        }
      }
    `,
  },
  {
    query: samplesSearchListQuery,
  },
);
