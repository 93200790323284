/**
 * @generated SignedSource<<198adebcec5acecd5b8511d9e29f37de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Libraries_librarySummarySectionQuery$variables = {
  libraryHandle: string;
};
export type Libraries_librarySummarySectionQuery$data = {
  readonly library: {
    readonly " $fragmentSpreads": FragmentRefs<"LibrarySummarySection_library">;
  } | null;
};
export type Libraries_librarySummarySectionQuery = {
  response: Libraries_librarySummarySectionQuery$data;
  variables: Libraries_librarySummarySectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "libraryHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "libraryHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Libraries_librarySummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LibrarySummarySection_library"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Libraries_librarySummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "origin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "script",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceState",
                "kind": "LinkedField",
                "name": "state",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progress",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timeRemainingSeconds",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceError",
                "kind": "LinkedField",
                "name": "error",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "details",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Sample",
            "kind": "LinkedField",
            "name": "sample",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "userProfile",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LibraryKit",
            "kind": "LinkedField",
            "name": "libraryKit",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "AuditConnection",
                "kind": "LinkedField",
                "name": "auditConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Audit",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "data",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eventAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eventType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "auditConnection(first:100)"
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fa06b0a09b89c2ee334f158ab36fda6",
    "id": null,
    "metadata": {},
    "name": "Libraries_librarySummarySectionQuery",
    "operationKind": "query",
    "text": "query Libraries_librarySummarySectionQuery(\n  $libraryHandle: String!\n) {\n  library(handle: $libraryHandle) {\n    ...LibrarySummarySection_library\n    id\n  }\n}\n\nfragment AuditLogItem_audit on Audit {\n  data\n  eventType\n  userProfile {\n    displayName\n    id\n  }\n}\n\nfragment AuditLog_entity on Entity {\n  __isEntity: __typename\n  auditConnection(first: 100) {\n    edges {\n      node {\n        id\n        data\n        eventAt\n        ...AuditLogItem_audit\n      }\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByKeyValue_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment LibraryProgress_library on Library {\n  origin\n  status\n  device {\n    state {\n      label\n    }\n    ...DeviceProgress_device\n    id\n  }\n}\n\nfragment LibrarySummarySection_library on Library {\n  createdAt\n  description\n  id\n  origin\n  script\n  ...LibraryProgress_library\n  sample {\n    handle\n    name\n    id\n  }\n  createdBy {\n    userProfile {\n      ...CreatedByKeyValue_userProfile\n      id\n    }\n    id\n  }\n  device {\n    handle\n    name\n    id\n  }\n  libraryKit {\n    name\n    id\n  }\n  tags {\n    ...TagsKeyValue_tags\n    id\n  }\n  ...AuditLog_entity\n}\n\nfragment Tag_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment TagsKeyValue_tags on Tag {\n  ...Tags_tags\n}\n\nfragment Tags_tags on Tag {\n  id\n  ...Tag_tag\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "0813b56e517df4bb36453390310d6190";

export default node;
