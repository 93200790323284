/**
 * @generated SignedSource<<a33a869f7f2168225f1a7b83921056aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LibrarySorting = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "ENDED_AT_ASC" | "ENDED_AT_DESC" | "NAME_ASC" | "NAME_DESC" | "NAME_LENGTH_ASC" | "NAME_LENGTH_DESC";
export type Devices_DeviceLibrariesSectionQuery$variables = {
  count: number;
  cursor?: string | null;
  deviceHandle: ReadonlyArray<any>;
  sort?: ReadonlyArray<LibrarySorting> | null;
  tenantSlug: string;
};
export type Devices_DeviceLibrariesSectionQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"DeviceLibrariesSection_device">;
  } | null;
};
export type Devices_DeviceLibrariesSectionQuery = {
  response: Devices_DeviceLibrariesSectionQuery$data;
  variables: Devices_DeviceLibrariesSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceHandle"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "deviceId",
    "variableName": "deviceHandle"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_DeviceLibrariesSectionQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v5/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviceLibrariesSection_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "Devices_DeviceLibrariesSectionQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v5/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "LibraryConnection",
            "kind": "LinkedField",
            "name": "libraryConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LibraryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Library",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "origin",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sample",
                        "kind": "LinkedField",
                        "name": "sample",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeviceState",
                            "kind": "LinkedField",
                            "name": "state",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "progress",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timeRemainingSeconds",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeviceError",
                            "kind": "LinkedField",
                            "name": "error",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "details",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pictureUrl",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "sort",
              "deviceId"
            ],
            "handle": "connection",
            "key": "Device_libraryConnection",
            "kind": "LinkedHandle",
            "name": "libraryConnection"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40863d308581add62818bc1ce6644fda",
    "id": null,
    "metadata": {},
    "name": "Devices_DeviceLibrariesSectionQuery",
    "operationKind": "query",
    "text": "query Devices_DeviceLibrariesSectionQuery(\n  $tenantSlug: String!\n  $deviceHandle: [IdOrHandle!]!\n  $count: Int!\n  $cursor: String\n  $sort: [LibrarySorting!]\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...DeviceLibrariesSection_device\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment DeviceLibrariesSection_device on Tenant {\n  libraryConnection(first: $count, after: $cursor, sort: $sort, deviceId: $deviceHandle) {\n    edges {\n      node {\n        ...LibraryList_libraries\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment LibraryList_libraries on Library {\n  id\n  handle\n  name\n  origin\n  project {\n    handle\n    name\n    id\n  }\n  sample {\n    name\n    id\n  }\n  status\n  ...LibraryProgress_library\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n}\n\nfragment LibraryProgress_library on Library {\n  origin\n  status\n  device {\n    state {\n      label\n    }\n    ...DeviceProgress_device\n    id\n  }\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "0cbc62284a0e9f876bffafc817a91c95";

export default node;
