import React from 'react';

import Breadcrumbs, { DevicesBreadcrumb } from 'components/Breadcrumbs';
import Header from 'components/Header';
import Section from 'components/Section';
import useTenant from 'utils/useTenant';

import RegisterDeviceControl from './controls/RegisterDeviceControl';

interface Props {
  children: React.ReactNode;
}

export default function DevicesPage({ children }: Props) {
  const { isAdmin: canRegisterDevices } = useTenant();

  return (
    <>
      <Header.PageHeader>
        <Breadcrumbs>
          <DevicesBreadcrumb />
        </Breadcrumbs>
        <Header.Actions>
          {canRegisterDevices && <RegisterDeviceControl as={Header.Action} />}
        </Header.Actions>
      </Header.PageHeader>

      <Section data-testid="DevicesPage">{children}</Section>
    </>
  );
}
