import { defineMessages } from 'react-intl';

export default defineMessages({
  libraries: {
    id: 'libraries.libraries',
    defaultMessage: 'Libraries',
  },

  PLANNED: {
    id: 'libraries.status.planned',
    defaultMessage: 'Planned',
  },
  IN_PROGRESS: {
    id: 'libraries.status.inProgress',
    defaultMessage: 'In progress',
  },
  READY: {
    id: 'libraries.status.ready',
    defaultMessage: 'Ready',
  },
  FAILED: {
    id: 'libraries.status.failed',
    defaultMessage: 'Failed',
  },
  ABORTED: {
    id: 'libraries.status.aborted',
    defaultMessage: 'Aborted',
  },

  CARBON: {
    id: 'libraries.origin.carbon',
    defaultMessage: 'Carbon',
  },
  MANUAL: {
    id: 'libraries.origin.manual',
    defaultMessage: 'Manual',
  },
});
