import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import libraryMessages from 'messages/libraries';

import DeviceProgress from './DeviceProgress';
import type { LibraryProgress_library$data as Library } from './__generated__/LibraryProgress_library.graphql';

interface Props {
  library: DeepNonNull<Library>;
  className?: string;
}

function LibraryProgress({ library, className }: Props) {
  if (
    library.status === 'IN_PROGRESS' &&
    library.origin === 'CARBON' &&
    library.device?.state?.label
  ) {
    return <DeviceProgress device={library.device!} className={className} />;
  }

  return <FormattedMessage {...libraryMessages[library.status]} />;
}

export default createFragmentContainer(LibraryProgress, {
  library: graphql`
    fragment LibraryProgress_library on Library {
      origin
      status
      device {
        state {
          label
        }
        ...DeviceProgress_device
      }
    }
  `,
});
