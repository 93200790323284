/**
 * @generated SignedSource<<b2a1172a9e47f559bb69e8720baa1223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Samples_sampleSummarySectionQuery$variables = {
  sampleHandle: string;
};
export type Samples_sampleSummarySectionQuery$data = {
  readonly sample: {
    readonly " $fragmentSpreads": FragmentRefs<"SampleSummarySection_sample">;
  } | null;
};
export type Samples_sampleSummarySectionQuery = {
  response: Samples_sampleSummarySectionQuery$data;
  variables: Samples_sampleSummarySectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sampleHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "sampleHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Samples_sampleSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sample",
        "kind": "LinkedField",
        "name": "sample",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SampleSummarySection_sample"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Samples_sampleSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sample",
        "kind": "LinkedField",
        "name": "sample",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "userProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "handle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "AuditConnection",
                "kind": "LinkedField",
                "name": "auditConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Audit",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "data",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eventAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "eventType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "auditConnection(first:100)"
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7714eca683cd8b0b3099a209a1ed93cf",
    "id": null,
    "metadata": {},
    "name": "Samples_sampleSummarySectionQuery",
    "operationKind": "query",
    "text": "query Samples_sampleSummarySectionQuery(\n  $sampleHandle: String!\n) {\n  sample(handle: $sampleHandle) {\n    ...SampleSummarySection_sample\n    id\n  }\n}\n\nfragment AuditLogItem_audit on Audit {\n  data\n  eventType\n  userProfile {\n    displayName\n    id\n  }\n}\n\nfragment AuditLog_entity on Entity {\n  __isEntity: __typename\n  auditConnection(first: 100) {\n    edges {\n      node {\n        id\n        data\n        eventAt\n        ...AuditLogItem_audit\n      }\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByKeyValue_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment SampleSummarySection_sample on Sample {\n  description\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByKeyValue_userProfile\n      id\n    }\n    id\n  }\n  tags {\n    ...TagsKeyValue_tags\n    id\n  }\n  ...AuditLog_entity\n}\n\nfragment Tag_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment TagsKeyValue_tags on Tag {\n  ...Tags_tags\n}\n\nfragment Tags_tags on Tag {\n  id\n  ...Tag_tag\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "9acd0fe4bb8b4e259f238a9c26946334";

export default node;
