import Layout from '@4c/layout';
import classNames from 'classnames';
import { Router } from 'found';
import useRouter from 'found/useRouter';
import React, { useContext } from 'react';
import { RiMenuFill as HamburgerMenu } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AuthContext, { AuthContextValue } from 'components/AuthContext';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Dropdown, {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from 'components/Dropdown';
import Text from 'components/Text';
import * as routes from 'utils/routes';
import { isAdmin } from 'utils/useTenant';

import TenantSwitchControl from './TenantSwitchControl';
import type { HeaderUserMenu_tenant$data as Tenant } from './__generated__/HeaderUserMenu_tenant.graphql';
import type { HeaderUserMenu_viewer$data as Viewer } from './__generated__/HeaderUserMenu_viewer.graphql';

import './HeaderUserMenu.scss';

const rootClass = 'HeaderUserMenu';

interface Props {
  viewer: DeepNonNull<Viewer>;
  tenant: DeepNonNull<Tenant> | null;
  dataTestId?: string;
}

function renderTenantSettings(
  router: Router,
  numTenants: number,
  tenant: Props['tenant'],
  dataTestId: string,
) {
  if (!tenant) {
    if (numTenants === 0) return null;

    return (
      <>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          nohover
          className={classNames(`${rootClass}__tenant-menu-item`)}
        >
          <Text variant="bold">—</Text>
          <TenantSwitchControl tenant={tenant} />
        </DropdownMenuItem>
      </>
    );
  }

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        nohover
        className={classNames(`${rootClass}__tenant-menu-item`)}
      >
        <span className={classNames(`${rootClass}__tenant-name`, 'ellipsis')}>
          {tenant.name}
        </span>
        {numTenants > 1 && <TenantSwitchControl tenant={tenant} />}
      </DropdownMenuItem>
      {isAdmin(tenant.currentUser) && (
        <DropdownMenuItem
          onClick={() => router.push(`/${tenant.slug}/settings`)}
          data-testid={`${dataTestId}-organizationSettings`}
        >
          <FormattedMessage
            id="headerMenu.tenantSettings"
            defaultMessage="Organization Settings"
          />
        </DropdownMenuItem>
      )}

      <DropdownMenuItem
        onClick={() => router.push(`/${tenant.slug}/users`)}
        data-testid={`${dataTestId}-members`}
      >
        <FormattedMessage id="headerMenu.members" defaultMessage="Members" />
      </DropdownMenuItem>

      <DropdownMenuItem
        onClick={() => router.push(`/${tenant.slug}/protocols`)}
        data-testid={`${dataTestId}-protocols`}
      >
        <FormattedMessage
          id="headerMenu.protocols"
          defaultMessage="Manuals and Protocols"
        />
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => router.push(`/-/shared/news`)}
        data-testid={`${dataTestId}-news`}
      >
        <FormattedMessage
          id="headerMenu.news"
          defaultMessage="Quantum-Si News"
        />
      </DropdownMenuItem>
    </>
  );
}

function HeaderUserMenu({ viewer, tenant, dataTestId = rootClass }: Props) {
  const { router } = useRouter();
  const auth = useContext(AuthContext) as AuthContextValue;

  const onClickLogOut = () => {
    auth.clearAccessToken();
    router.push(routes.root());
  };

  if (!auth.isAuthenticated()) {
    return (
      <Button
        variant="navbar"
        onClick={() => {
          router.push(routes.root());
        }}
      >
        <FormattedMessage id="headerMenu.logIn" defaultMessage="Log In" />
      </Button>
    );
  }

  return (
    <Dropdown
      dataTestId={`${dataTestId}-dropdown`}
      className={classNames(`${rootClass}`)}
      trigger={
        <Button variant="header-user" dataTestId={`${dataTestId}-userMenu`}>
          <Layout
            direction="column"
            align="flex-end"
            className={classNames(`${rootClass}__avatar`)}
          >
            <Text color="headline" className={`${rootClass}__headline`}>
              {viewer.user.profile.displayName}
            </Text>
            <Text
              color="headline"
              size="sm"
              className={classNames(`${rootClass}__tenant-name`)}
              data-testid="TenantName"
            >
              {tenant?.name}
            </Text>
          </Layout>
          <Avatar
            userProfile={viewer.user.profile}
            width={30}
            className={classNames(`${rootClass}__avatar`)}
          />
          <HamburgerMenu width={30} size="30px" color="var(--white)" />
        </Button>
      }
    >
      <DropdownMenuItem
        onClick={() => router.push(routes.profile())}
        data-testid={`${rootClass}-accountSettings`}
      >
        <Layout>
          <Avatar
            width={48}
            userProfile={viewer.user.profile}
            className={classNames(`${rootClass}__avatar`)}
          />
          <Layout direction="column" justify="center">
            <span>
              <FormattedMessage
                id="headerMenu.accountSettings"
                defaultMessage="Account Settings"
              />
            </span>
            <Text
              color="subtitle"
              className={classNames(`${rootClass}__menu-item-subtitle`)}
            >
              {viewer.user.profile.displayName}
            </Text>
          </Layout>
        </Layout>
      </DropdownMenuItem>

      {renderTenantSettings(router, viewer.numTenants, tenant, dataTestId)}
      <DropdownMenuSeparator />

      <DropdownMenuItem
        onClick={onClickLogOut}
        data-testid={`${dataTestId}-logout`}
      >
        <FormattedMessage id="headerMenu.logOut" defaultMessage="Log Out" />
      </DropdownMenuItem>
    </Dropdown>
  );
}

export default createFragmentContainer(HeaderUserMenu, {
  viewer: graphql`
    fragment HeaderUserMenu_viewer on Viewer {
      user {
        profile {
          displayName
          ...Avatar_userProfile
        }
      }
      numTenants
    }
  `,
  tenant: graphql`
    fragment HeaderUserMenu_tenant on Tenant {
      id
      name
      slug
      currentUser {
        role
      }
      ...TenantSwitchControl_tenant
    }
  `,
});
