/**
 * @generated SignedSource<<3183662183c0f593dbcfac527ad86b54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RegisterDeviceInput = {
  clientMutationId?: string | null;
  deviceId: String;
  tenantId: String;
  userPin: string;
};
export type RegisterDeviceModal_Mutation$variables = {
  input: RegisterDeviceInput;
};
export type RegisterDeviceModal_Mutation$data = {
  readonly registerDeviceOrError: {
    readonly __typename?: string;
    readonly device?: {
      readonly handle: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"DeviceList_devices">;
    } | null;
    readonly message?: string | null;
  } | null;
};
export type RegisterDeviceModal_Mutation = {
  response: RegisterDeviceModal_Mutation$data;
  variables: RegisterDeviceModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterDeviceModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "registerDeviceOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DeviceList_devices"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RegisterDevicePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegisterDeviceModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "registerDeviceOrError",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceError",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "details",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registered",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "family",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "softwareVersion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "presence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SoftwareVersion",
                    "kind": "LinkedField",
                    "name": "availableSoftwareUpgrades",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "progress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeRemainingSeconds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "RegisterDevicePayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b4097032a0435be5bceb0d1fdfe9969",
    "id": null,
    "metadata": {},
    "name": "RegisterDeviceModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation RegisterDeviceModal_Mutation(\n  $input: RegisterDeviceInput!\n) {\n  registerDeviceOrError(input: $input) {\n    __typename\n    ... on RegisterDevicePayload {\n      device {\n        handle\n        ...DeviceList_devices\n        id\n      }\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n\nfragment DeviceList_devices on Device {\n  id\n  error {\n    label\n  }\n  handle\n  name\n  registered\n  family\n  softwareVersion\n  ...DeviceStatus_device\n  ...RegisterDeviceModal_device\n  ...DeviceSoftwareVersion_device\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment DeviceSoftwareVersion_device on Device {\n  id\n  softwareVersion\n  state {\n    code\n    label\n    progress\n  }\n}\n\nfragment DeviceStatus_device on Device {\n  id\n  presence\n  availableSoftwareUpgrades {\n    __typename\n    id\n  }\n  error {\n    __typename\n  }\n  state {\n    __typename\n  }\n  ...DeviceProgress_device\n}\n\nfragment RegisterDeviceModal_device on Device {\n  id\n  handle\n}\n"
  }
};
})();

(node as any).hash = "e0ca076a24e801ca78bbeddd5f6f559c";

export default node;
