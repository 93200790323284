import { idRoute, join } from './routeUtils';

export const root = () => '';

type TenantStub = { tenant: { slug: string } };
export const tenant = join(root, ({ tenant: { slug } }: TenantStub) => slug);

export const profile = join(root, '-/profile');
export const profileNotifications = join(profile, 'notifications');

export const tenantUsers = join(tenant, 'users');
export const notifications = join(tenant, 'notifications');

export const dashboard = join(tenant, 'dashboard');
export const workflows = join(tenant, 'apps');

export const tags = join(tenant, 'tags');
export const tagNew = join(tags, '-/new');
export const tag = join(tags, idRoute('tag'));
export const tagEdit = join(tag, 'edit');
export const tagProjects = join(tag, 'projects');
export const tagSamples = join(tag, 'specimens');
export const tagLibraries = join(tag, 'libraries');
export const tagRuns = join(tag, 'runs');
export const tagAnalyses = join(tag, 'analyses');

export const references = join(tenant, 'references');
export const referencesPublic = join(references, 'public');
export const referenceNew = join(references, '-/new');
export const reference = join(references, idRoute('reference'));
export const referenceEdit = join(reference, 'edit');
export const referenceAnnotations = join(reference, 'annotations');
export const referenceAnnotationNew = join(referenceAnnotations, '-/new');
export const referenceAnnotation = join(
  referenceAnnotations,
  idRoute('referenceAnnotation'),
);
export const referenceAnnotationEdit = join(referenceAnnotation, 'edit');

export const projects = join(tenant, 'projects');
export const projectNew = join(projects, '-/new');
export const project = join(projects, idRoute('project'));
export const projectEdit = join(project, 'edit');
export const projectNotes = join(project, 'notes');

export const specimens = join(project, 'specimens');
export const specimenNew = join(specimens, '-/new');
export const specimen = join(specimens, idRoute('sample'));
export const specimenEdit = join(specimen, 'edit');
export const specimenLibraries = join(specimen, 'libraries');
export const specimenRuns = join(specimen, 'runs');
export const specimenAnalyses = join(specimen, 'analyses');
export const specimenNotes = join(specimen, 'notes');

export const libraries = join(project, 'libraries');
export const libraryNew = join(libraries, '-/new');
export const library = join(libraries, idRoute('library'));
export const libraryEdit = join(library, 'edit');
export const libraryRuns = join(library, 'runs');
export const libraryAnalyses = join(library, 'analyses');
export const libraryNotes = join(library, 'notes');

export const runs = join(project, 'runs');
export const runNew = join(runs, '-/new');
export const runImport = join(runs, '-/import');
export const run = join(runs, idRoute('run'));
export const runPlan = join(runs, 'plan');
export const runPlanNew = join(runPlan, '-/new');
export const runEdit = join(run, 'edit');
export const runPlots = join(run, 'plots');
export const runFiles = join(run, 'files');
export const runAnalyses = join(run, 'analyses');
export const runInsights = join(run, 'insights');
export const runSplits = join(run, 'splits');
export const runLibraryLoading = join(run, 'library-loading');
export const runNotes = join(run, 'notes');

export const analyses = join(project, 'analyses');
export const analysisNew = join(analyses, '-/new');
export const analysis = join(analyses, idRoute('analysis'));
export const analysisEdit = join(analysis, 'edit');
export const analysisMetrics = join(analysis, 'metrics');
export const analysisPlots = join(analysis, 'plots');
export const analysisPlotsGrouping = join(
  analysisPlots,
  ({ grouping }: { grouping: string }) => encodeURIComponent(grouping),
);
export const analysisFiles = join(analysis, 'files');
export const analysisNotes = join(analysis, 'notes');
export const analysisHtmlFile = join(analysisFiles, idRoute('file'));
export const analysisWorkflow = join(analysis, 'workflow');
export const analysisWorkflowStep = join(
  analysisWorkflow,
  ({ index }: { index: string }) => index,
);

export const manuals = join(tenant, 'manuals');
export const manual = join(manuals, idRoute('manual'));

export const news = join(root, '-/shared/news');
export const newsArticle = join(news, idRoute('news'));

export const protocols = join(tenant, 'protocols');
export const protocol = join(protocols, idRoute('protocol'));

export const search = join(tenant, 'search');
export const settings = join(tenant, 'settings');

export const users = join(tenant, 'users');

export const devices = join(tenant, 'devices');
export const device = join(devices, idRoute('device'));
export const deviceEdit = join(device, 'edit');
export const devicePlots = join(device, 'plots');
export const deviceRuns = join(device, 'runs');
export const deviceLibraries = join(device, 'libraries');
export const deviceLibraryLoadings = join(device, 'loading');

export const apps = join(tenant, 'apps');
export const app = join(apps, idRoute('app'));
export const installations = join(apps, '-/installations');
export const developers = join(tenant, 'developer');
export const developersArchived = join(developers, 'archived');
export const developersNewWorkflow = join(developers, '-/new');
export const developersSandbox = join(developers, '-/sandbox');
export const developersWorkflow = join(developers, idRoute('workflow'));
export const developersEditWorkflow = join(developersWorkflow, 'edit');
export const developersReleaseEdit = join(
  developersWorkflow,
  join((r: { version: number }) => r.version.toString(), 'edit'),
);
export const developersWorkflowNewRelease = join(
  developersWorkflow,
  'new-release',
);
