/**
 * @generated SignedSource<<669e42c57c353b2ad2db881b217ce444>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnalysisJobStatus = "ABORTED" | "COMPLETED" | "FAILED" | "QUEUED" | "RESTARTING" | "RUNNING" | "WAITING_FOR_INSTANCE";
export type AnalysisJobStepStatus = "ABORTED" | "COMPLETED" | "FAILED" | "PENDING" | "RESTARTING" | "RUNNING";
export type AnalysisStatus = "aborted" | "completed" | "failed" | "pending" | "restarting" | "running";
import { FragmentRefs } from "relay-runtime";
export type AnalysisProgress_analysis$data = {
  readonly job: {
    readonly id: string;
    readonly status: AnalysisJobStatus | null;
    readonly steps: ReadonlyArray<{
      readonly status: AnalysisJobStepStatus | null;
    } | null> | null;
  } | null;
  readonly numPendingRuns: number | null;
  readonly numPendingUpstreamAnalyses: number | null;
  readonly status: AnalysisStatus | null;
  readonly " $fragmentType": "AnalysisProgress_analysis";
};
export type AnalysisProgress_analysis$key = {
  readonly " $data"?: AnalysisProgress_analysis$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnalysisProgress_analysis">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnalysisProgress_analysis",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numPendingRuns",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numPendingUpstreamAnalyses",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalysisJob",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalysisJobStep",
          "kind": "LinkedField",
          "name": "steps",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Analysis",
  "abstractKey": null
};
})();

(node as any).hash = "7075123618a33e5a1e7688143d6ac58f";

export default node;
