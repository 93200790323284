import classNames from 'classnames';
import React from 'react';
import { RiSearchLine as SearchIcon } from 'react-icons/ri';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import './NoItemFound.scss';

interface Props {
  itemType: MessageDescriptor | React.ReactNode;
  dataTestId?: string;
}

const rootClass = 'NoItemFound';

function NoItemFound({ itemType, dataTestId = rootClass }: Props) {
  return (
    <div data-testid={dataTestId} className={rootClass}>
      <SearchIcon style={{ width: '3rem', height: '3rem' }} />
      <h3 className={classNames(`${rootClass}__text`)}>
        <FormattedMessage
          id="noItemFound.title"
          defaultMessage="No {itemType} found"
          values={{ itemType } as any}
        />
      </h3>
    </div>
  );
}

export default NoItemFound;
