/**
 * @generated SignedSource<<f5b5bd48321a6eea2460247d30bc544f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Libraries_libraryQuery$variables = {
  libraryHandle: string;
  tenantSlug: string;
};
export type Libraries_libraryQuery$data = {
  readonly item: {
    readonly tenant: {
      readonly id: string;
    } | null;
  } | null;
  readonly tenant: {
    readonly id: string;
  } | null;
};
export type Libraries_libraryQuery = {
  response: Libraries_libraryQuery$data;
  variables: Libraries_libraryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "libraryHandle"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": "tenant",
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "tenantSlug"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenantBySlug",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "libraryHandle"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Libraries_libraryQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": "item",
        "args": (v5/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Libraries_libraryQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": "item",
        "args": (v5/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d5a52510f0fba0c1f822ce7dcd27e10",
    "id": null,
    "metadata": {},
    "name": "Libraries_libraryQuery",
    "operationKind": "query",
    "text": "query Libraries_libraryQuery(\n  $tenantSlug: String!\n  $libraryHandle: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    id\n  }\n  item: library(handle: $libraryHandle) {\n    tenant {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "97da13ca7ffff1e37579d82ae7caa632";

export default node;
