/**
 * @generated SignedSource<<35cf9ba35c9e32721e2f55fc20136e42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_stepsSectionQuery$variables = {
  analysisHandle: string;
};
export type Analyses_stepsSectionQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisStepsSection_analysis">;
  } | null;
};
export type Analyses_stepsSectionQuery = {
  response: Analyses_stepsSectionQuery$data;
  variables: Analyses_stepsSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_stepsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisStepsSection_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_stepsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisJob",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisJobStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "index",
                    "storageKey": null
                  },
                  {
                    "alias": "numErrors",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "level",
                        "value": "error"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "numLogLines",
                    "storageKey": "numLogLines(level:\"error\")"
                  },
                  {
                    "alias": "numWarnings",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "level",
                        "value": "warning"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "numLogLines",
                    "storageKey": "numLogLines(level:\"warning\")"
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowRelease",
            "kind": "LinkedField",
            "name": "workflowRelease",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowDag",
                "kind": "LinkedField",
                "name": "dag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowDagLink",
                    "kind": "LinkedField",
                    "name": "links",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "u",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "v",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowDagNode",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8dbba63d292125917154d015ef08878d",
    "id": null,
    "metadata": {},
    "name": "Analyses_stepsSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_stepsSectionQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...AnalysisStepsSection_analysis\n    id\n  }\n}\n\nfragment AnalysisStepsSection_analysis on Analysis {\n  id\n  handle\n  status\n  ...WorkflowStepsDiagram_analysis\n  workflowRelease {\n    ...WorkflowStepsDiagram_workflowRelease\n    id\n  }\n  job {\n    id\n    status\n    endedAt\n    startedAt\n    steps {\n      index\n      numErrors: numLogLines(level: error)\n      numWarnings: numLogLines(level: warning)\n      status\n      endedAt\n      startedAt\n      id\n    }\n  }\n}\n\nfragment WorkflowStepsDiagram_analysis on Analysis {\n  id\n  handle\n  status\n  project {\n    id\n    handle\n  }\n  job {\n    id\n    status\n    endedAt\n    startedAt\n    steps {\n      index\n      numErrors: numLogLines(level: error)\n      numWarnings: numLogLines(level: warning)\n      status\n      endedAt\n      startedAt\n      id\n    }\n  }\n}\n\nfragment WorkflowStepsDiagram_workflowRelease on WorkflowRelease {\n  dag {\n    links {\n      u\n      v\n    }\n    nodes {\n      label\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74711654a0808de33e03f58347203068";

export default node;
