import classNames from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const rootClass = 'PageMain';

const PageMain = ({ className, children }: Props) => {
  return <main className={classNames(rootClass, className)}>{children}</main>;
};

export default PageMain;
