/**
 * @generated SignedSource<<61b054d8ff5190c0f33692e1092e35af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderSearchBar_SearchBarQuery$variables = {
  searchTerm: string;
  tenantSlug: string;
};
export type HeaderSearchBar_SearchBarQuery$data = {
  readonly tenant: {
    readonly projectConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly handle: string | null;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly tagConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly color: string | null;
          readonly handle: string | null;
          readonly id: string;
          readonly name: string | null;
        } | null;
      } | null> | null;
    } | null;
    readonly userAssociationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly userProfile: {
            readonly displayName: string | null;
            readonly handle: string | null;
            readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
          } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type HeaderSearchBar_SearchBarQuery = {
  response: HeaderSearchBar_SearchBarQuery$data;
  variables: HeaderSearchBar_SearchBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchTerm"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            (v4/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeaderSearchBar_SearchBarQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "TenantUserConnection",
            "kind": "LinkedField",
            "name": "userAssociationConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "userProfile",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Avatar_userProfile"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HeaderSearchBar_SearchBarQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v2/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "TenantUserConnection",
            "kind": "LinkedField",
            "name": "userAssociationConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "userProfile",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pictureUrl",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4443b9263413567f43dad1a1150574d9",
    "id": null,
    "metadata": {},
    "name": "HeaderSearchBar_SearchBarQuery",
    "operationKind": "query",
    "text": "query HeaderSearchBar_SearchBarQuery(\n  $tenantSlug: String!\n  $searchTerm: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    projectConnection(search: $searchTerm, first: 5) {\n      edges {\n        node {\n          handle\n          name\n          id\n        }\n      }\n    }\n    tagConnection(search: $searchTerm, first: 5) {\n      edges {\n        node {\n          color\n          handle\n          id\n          name\n        }\n      }\n    }\n    userAssociationConnection(search: $searchTerm, first: 5) {\n      edges {\n        node {\n          userProfile {\n            handle\n            displayName\n            ...Avatar_userProfile\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n"
  }
};
})();

(node as any).hash = "e5505c67f14cd6f444b4e7871e99f01b";

export default node;
