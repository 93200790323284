import React from 'react';

import './TitleMessage.scss';

type Props = {
  children?: React.ReactNode;
};

const rootClass = 'TitleMessage';

const TitleMessage = ({ children }: Props) => {
  return <h2 className={rootClass}>{children}</h2>;
};

export default TitleMessage;
