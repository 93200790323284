import classNames from 'classnames';
import React, { useId } from 'react';
import Form, { useField } from 'react-formal';
import type {
  FieldProps as BaseFieldProps,
  FieldRenderProps,
} from 'react-formal/Field';
import { FieldMeta } from 'react-formal/useField';
import { MessageDescriptor } from 'react-intl';

import './FieldGroup.scss';
import FormattedLabel from './FormattedLabel';

export declare type FormControlType = React.ElementType<{
  invalid: boolean;
}>;

export declare type FieldChildren<TFieldRenderProps = unknown> =
  | React.ReactNode
  | ((
      fieldProps: FieldRenderProps & TFieldRenderProps,
      meta: FieldMeta,
    ) => React.ReactNode);

export declare type FieldProps<
  TAs extends FormControlType = any,
  TFieldRenderProps = unknown,
> = Omit<BaseFieldProps<TAs>, 'children'> & {
  children?: FieldChildren<TFieldRenderProps>;
};

export declare type FieldGroupProps<TAs extends FormControlType = any> =
  FieldProps<TAs> & {
    name: string;
    label?: React.ReactNode;
    hideError?: boolean;
    description?: React.ReactNode;
    dataTestId?: string;
    className?: string;
    labelSrOnly?: boolean;
    addon?: boolean;
    // All other props
    [x: string]: any;
  };

const rootClass = 'FieldGroup';

const FieldGroup = ({
  name,
  label,
  hideError,
  description,
  dataTestId = rootClass,
  className,
  labelSrOnly,
  addon,
  children,
  ...rest
}: FieldGroupProps) => {
  const [props] = useField(name);

  const id = useId();

  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        rootClass,
        className,
        addon && `${rootClass}--addon`,
      )}
    >
      <div className={classNames(`${rootClass}__wrapper`)}>
        <label
          className={classNames(
            `${rootClass}__label`,
            labelSrOnly && `${rootClass}__label-wrapper--sr-only`,
          )}
          htmlFor={id}
        >
          {label}
        </label>
        {addon ? (
          <div className={classNames(`${rootClass}__addon`)}>
            <Form.Field {...props} {...rest} id={id}>
              {children}
            </Form.Field>
          </div>
        ) : (
          <Form.Field {...props} {...rest} id={id} dataTestId={dataTestId}>
            {children}
          </Form.Field>
        )}
        {description && (
          <div className={classNames(`${rootClass}__description`)}>
            {description}
          </div>
        )}
        {!hideError && name && (
          <Form.Message for={name}>
            {(m: MessageDescriptor[] | string[]) => (
              <>
                {m.map((e) => (
                  <div className={`${rootClass}__error`}>
                    {FormattedLabel({ label: e })}
                  </div>
                ))}
              </>
            )}
          </Form.Message>
        )}
      </div>
    </div>
  );
};

export default FieldGroup;
