import { defineMessages } from 'react-intl';

export default defineMessages({
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  description: {
    id: 'description',
    defaultMessage: 'Description',
  },
  true: {
    id: 'true',
    defaultMessage: 'True',
  },
  false: {
    id: 'false',
    defaultMessage: 'False',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'optional',
  },
  done: {
    id: 'done',
    defaultMessage: 'Done',
  },
  more: {
    id: 'more',
    defaultMessage: 'More...',
  },
  moreActions: {
    id: 'moreActions',
    defaultMessage: 'More Actions',
  },
  new: {
    id: 'new',
    defaultMessage: 'New',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  proceed: {
    id: 'proceed',
    defaultMessage: 'Proceed',
  },
  next: {
    id: 'next',
    defaultMessage: 'Next',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete',
  },
  copy: {
    id: 'copy',
    defaultMessage: 'Copy',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  rename: {
    id: 'rename',
    defaultMessage: 'Rename',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Showing search results for: {searchTerm}',
  },
  clearSearchResults: {
    id: 'clearSearchResults',
    defaultMessage: 'clear results',
  },
  downloadFile: {
    id: 'downloadFile',
    defaultMessage: 'Download file',
  },
  none: {
    id: 'none',
    defaultMessage: 'None',
  },
  copyEntity: {
    id: 'copyEntity',
    defaultMessage: '{name} (COPY)',
  },
});
