import React from 'react';

import Route from 'components/Route';

import SharedAppPage from './components/SharedAppPage';
import sharedAnalysisRoutes from './routes/Data/routes/Analyses/sharedRoutes';
import sharedRunRoutes from './routes/Data/routes/Runs/sharedRoutes';
import newsRoute from './routes/News';

export default (
  <Route path="-/shared" Component={SharedAppPage}>
    {newsRoute}
    {sharedRunRoutes}
    {sharedAnalysisRoutes}
  </Route>
);
