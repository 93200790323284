import React from 'react';
import { FormattedMessage } from 'react-intl';

import Section from 'components/Section';

import BlankPage from './BlankPage';
import LoginForm from './LoginForm';
import TitleMessage from './TitleMessage';

interface Props {
  email?: string;
}

function LoginPage({ email }: Props) {
  return (
    <BlankPage data-testid="LoginPage" size="medium">
      <TitleMessage>
        <FormattedMessage
          id="login.title"
          defaultMessage="Log in to Quantum-Si Platinum Analysis Software"
        />
      </TitleMessage>
      <Section size="small">
        <LoginForm email={email} />
      </Section>
    </BlankPage>
  );
}

export default LoginPage;
