/**
 * @generated SignedSource<<bda34d63bb19da422de9948dbc4087c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AcceptEmailChangeVerificationInput = {
  clientMutationId?: string | null;
  token: string;
};
export type VerifyEmailChangePageMutation$variables = {
  input: AcceptEmailChangeVerificationInput;
};
export type VerifyEmailChangePageMutation$data = {
  readonly acceptEmailChangeVerificationOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
  } | null;
};
export type VerifyEmailChangePageMutation = {
  response: VerifyEmailChangePageMutation$data;
  variables: VerifyEmailChangePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailChangePageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "acceptEmailChangeVerificationOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailChangePageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "acceptEmailChangeVerificationOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55defa5275af5ab38fe73d309e6c5423",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailChangePageMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailChangePageMutation(\n  $input: AcceptEmailChangeVerificationInput!\n) {\n  acceptEmailChangeVerificationOrError(input: $input) {\n    __typename\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c13123c6495fb1bc3013160de4ba4f9e";

export default node;
