import { defineMessages } from 'react-intl';

import { ChipSplitType } from 'config/runPlans';
import { Status, StatusCategory } from 'config/runStatus';

export default defineMessages({
  runs: {
    id: 'runs',
    defaultMessage: 'Runs',
  },

  copyRun: {
    id: 'runs.copyRun',
    defaultMessage: 'Copy and plan new run',
  },

  // FIXME: Most of these messages are not needed except on the data route.
  [Status.PLANNED]: {
    id: 'runs.plannedRun',
    defaultMessage: 'Planned',
  },
  [Status.CHIP_CHECK]: {
    id: 'runs.startedChipCheck',
    defaultMessage: 'Chip Check',
  },
  [Status.FINISHED_CHIP_CHECK]: {
    id: 'runs.finishedChipCheck',
    defaultMessage: 'Finished Chip Check',
  },
  [Status.RUNNING]: {
    id: 'runs.runningRun',
    defaultMessage: 'Running',
  },
  [StatusCategory.ACTIVE]: {
    id: 'runs.activeRun',
    defaultMessage: 'Active',
  },
  [Status.FINISHED]: {
    id: 'runs.finishedRun',
    defaultMessage: 'Finished',
  },
  [Status.FAILED]: {
    id: 'runs.failedRun',
    defaultMessage: 'Failed',
  },
  [Status.ABORTED]: {
    id: 'runs.abortedRun',
    defaultMessage: 'Aborted',
  },
  [Status.UPLOADING]: {
    id: 'runs.uploadingRun',
    defaultMessage: 'Uploading',
  },
  [Status.PROCESSING]: {
    id: 'runs.processingRun',
    defaultMessage: 'Processing',
  },
  [Status.RESTARTING]: {
    id: 'runs.restartingRun',
    defaultMessage: 'Restarting',
  },
  [ChipSplitType.WHOLE_CHIP]: {
    id: 'runs.wholeChip',
    defaultMessage: 'Whole Chip',
  },
  [ChipSplitType.SPLIT_CHIP]: {
    id: 'runs.splitChip',
    defaultMessage: 'Split Chip',
  },
  [ChipSplitType.QUAD_CHIP]: {
    id: 'runs.quadChip',
    defaultMessage: 'Quad Chip',
  },
  newRun: {
    id: 'runs.newRun',
    defaultMessage: 'Plan run',
  },
  abortRun: {
    id: 'runs.abortRun',
    defaultMessage: 'Abort run',
  },
  startRun: {
    id: 'runs.startRun',
    defaultMessage: 'Start run',
  },
  importRun: {
    id: 'runs.importRun',
    defaultMessage: 'Import run',
  },
  shareRun: {
    id: 'runs.shareRun',
    defaultMessage: 'Share run',
  },
  editRun: {
    id: 'runs.editRun',
    defaultMessage: 'Edit run',
  },
  downloadRun: {
    id: 'runs.downloadRun',
    defaultMessage: 'Download run',
  },
  emptyRunsHeading: {
    id: 'runs.emptyRunsHeading',
    defaultMessage: 'There are no runs',
  },
  plots: {
    id: 'runs.plots',
    defaultMessage: 'Plots',
  },
  splits: {
    id: 'runs.splits',
    defaultMessage: 'Splits',
  },
  splitsWholeChip: {
    id: 'runs.splitsWholeChip',
    defaultMessage: 'Run applies to whole chip',
  },
  splitsLanesChip: {
    id: 'runs.splitsLanesChip',
    defaultMessage: 'Run split between left and right lanes',
  },
  quadLanesChip: {
    id: 'runs.splitsQuadLaneChip',
    defaultMessage: 'Run split between 4 lanes',
  },
  insights: {
    id: 'runs.insights',
    defaultMessage: 'Insights',
  },
});

export const runType = defineMessages({
  genomic: {
    id: 'run.genomic',
    defaultMessage: 'Genomic',
  },
  proteomic: {
    id: 'run.proteomic',
    defaultMessage: 'Proteomic',
  },
});
