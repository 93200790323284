export class LoginError extends Error {
  detail: string;

  constructor(public payload: any | null) {
    super(JSON.stringify(payload));
    // null defensive checks make sure that creating this error doesn't
    // throw another error
    this.detail = payload?.errors[0]?.detail;
  }
}

const TOKEN_EXPIRATION_PAD = 5 * 60 * 1000; // five minutes

const AUTH_URL = `${window.qsiConfig.API_ORIGIN}/api/v1/oauth/token`;

export default async function login(username: string, password: string) {
  const resp = await fetch(AUTH_URL, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        username,
        password,
        grant_type: 'password',
      },
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  });

  const payload = await resp.json();

  if (!resp.ok) {
    throw new LoginError(payload);
  }

  const { data } = payload;
  const expiresInMs = data.expires_in * 1000;
  // we add some padding for safety
  const expiresAt = Date.now() + expiresInMs - TOKEN_EXPIRATION_PAD;

  return {
    accessToken: data.access_token,
    expiresAt,
  };
}
