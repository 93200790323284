import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route path="news">
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "dashboard" */ './components/NewsList'
        ).then((p) => p.default)
      }
      query={graphql`
        query NewsList_PageQuery {
          query {
            ...NewsList_query
          }
        }
      `}
    />
    <Route
      path=":newsHandle"
      getComponent={() =>
        import(/* webpackChunkName: "news" */ './components/NewsPage').then(
          (p) => p.default,
        )
      }
      query={graphql`
        query News_newsQuery($newsHandle: String!) {
          newsItem(handle: $newsHandle) {
            ...NewsPage_newsItem
          }
        }
      `}
    />
  </Route>
);
