import { useRouter } from 'found';
import Link from 'found/Link';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ReactRelayContext,
  createFragmentContainer,
  graphql,
} from 'react-relay';
import { Message, object, ref, string } from 'yup';

import FieldGroup from 'components/FieldGroup';
import MultiTextInputGroup from 'components/MultiTextInputGroup';
import PasswordRules from 'components/PasswordRules';
import messages from 'messages/password';
import { AlphaNumericPasswordRegex } from 'utils/regex';
import * as routes from 'utils/routes';

import AuthForm from './AuthForm';
import BlankPage from './BlankPage';
import ConfirmLogoutDialog from './ConfirmLogoutDialog';
import TitleMessage from './TitleMessage';
import { ResetPasswordPageMutation as Mutation } from './__generated__/ResetPasswordPageMutation.graphql';

const mutation = graphql`
  mutation ResetPasswordPageMutation($input: AcceptPasswordResetInput!) {
    acceptPasswordResetOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const schema = object({
  password: string()
    .required(messages.required as any)
    .matches(AlphaNumericPasswordRegex, {
      message: messages.strength as Message,
    }),
  confirmPassword: string()
    .required(messages.required as any)
    .matches(AlphaNumericPasswordRegex, {
      message: messages.strength as Message,
    })
    .oneOf([ref('password')], messages.mismatch as any),
});

const rootClass = 'ResetPasswordPage';

function ResetPasswordPage() {
  const { router, match } = useRouter();
  const [newPassword, setNewPassword] = useState('');
  const intl = useIntl();

  const onCompleted = () => {
    router.replace(routes.root());

    toast.success(
      <FormattedMessage
        id="resetPasswordPage.success"
        defaultMessage="Password reset successfully"
      />,
    );
  };

  const { token } = match.params;

  return (
    <div>
      <ConfirmLogoutDialog>
        <FormattedMessage
          id="resetPasswordPage.confirmLogout"
          defaultMessage="You are already logged in. Do you want to log out to reset your password?"
        />
      </ConfirmLogoutDialog>
      <BlankPage>
        <TitleMessage>
          <FormattedMessage
            id="resetPasswordPage.title"
            defaultMessage="Enter New Password"
          />
        </TitleMessage>
        <AuthForm<Mutation, typeof schema>
          mutation={mutation}
          schema={schema}
          defaultValue={schema.getDefault()}
          onCompleted={onCompleted}
          getInput={({ password }) => ({ password, token })}
        >
          <MultiTextInputGroup>
            <FieldGroup
              name="password"
              type="password"
              hideError
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNewPassword(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: 'resetPasswordPage.password',
                defaultMessage: 'New Password',
              })}
              data-testid={`${rootClass}-password`}
              label={<FormattedMessage {...messages.password} />}
            />

            <FieldGroup
              name="confirmPassword"
              type="password"
              hideError
              placeholder={intl.formatMessage({
                id: 'resetPasswordPage.confirmPassword',
                defaultMessage: 'Confirm Password',
              })}
              data-testid={`${rootClass}-confirmPassword`}
              labelSrOnly
              label={<FormattedMessage {...messages.password} />}
            />
          </MultiTextInputGroup>
          <PasswordRules password={newPassword} />
          <AuthForm.Submit>
            <FormattedMessage
              id="resetPasswordPage.submit"
              defaultMessage="Reset Password"
            />
          </AuthForm.Submit>
        </AuthForm>
        <Link to="/">
          <FormattedMessage
            id="resetPasswordPage.logIn"
            defaultMessage="Log In"
          />
        </Link>
      </BlankPage>
    </div>
  );
}

ResetPasswordPage.contextType = ReactRelayContext;

export default createFragmentContainer(
  ResetPasswordPage,

  {
    tokenValid: graphql`
      fragment ResetPasswordPage_tokenValid on ValidationResult {
        __typename
      }
    `,
  },
);
