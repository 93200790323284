import type { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';
import checkTenantItem from 'utils/checkTenantItem';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment Libraries_rootFragment on Query {
    tenant: tenantBySlug(slug: $tenantSlug) {
      ...NewLibraryPage_tenant
    }
    project: project(handle: $projectHandle) {
      ...NewLibraryPage_project
    }
  }
`;

export default (
  <Route path="libraries">
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "libraries" */ './components/NewLibraryPage'
        ).then((p) => p.default)
      }
      prepareVariables={(variables, { location }) => ({
        ...variables,
        libraryHandle: location.query.library || '',
        hasLibrary: !!location.query.library,
        sampleHandle: location.query.specimen || '',
        hasSample: !!location.query.specimen,
      })}
      fromSample={null}
      patternLibrary={null}
      query={graphql`
        query Libraries_newLibraryQuery(
          $tenantSlug: String!
          $projectHandle: String!
          $libraryHandle: String!
          $hasLibrary: Boolean!
          $sampleHandle: String!
          $hasSample: Boolean!
        ) {
          patternLibrary: library(handle: $libraryHandle)
            @include(if: $hasLibrary) {
            ...NewLibraryPage_patternLibrary
          }
          fromSample: sample(handle: $sampleHandle) @include(if: $hasSample) {
            ...NewLibraryPage_fromSample
          }
          ...Libraries_rootFragment @relay(mask: false)
        }
      `}
    />
    <Route
      query={graphql`
        query Libraries_libraryQuery(
          $tenantSlug: String!
          $libraryHandle: String!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: library(handle: $libraryHandle) {
            tenant {
              id
            }
          }
        }
      `}
      path=":libraryHandle"
      prerender={checkTenantItem}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "libraries" */ './components/LibraryPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Libraries_libraryPageQuery($libraryHandle: String!) {
            library(handle: $libraryHandle) {
              ...LibraryPage_library
            }
          }
        `}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "libraries" */ './components/LibrarySummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Libraries_librarySummarySectionQuery(
              $libraryHandle: String!
            ) {
              library(handle: $libraryHandle) {
                ...LibrarySummarySection_library
              }
            }
          `}
        />
        <Route
          path="analyses"
          getComponent={() =>
            import(
              /* webpackChunkName: "libraries" */ './components/LibraryAnalysesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Libraries_analysesSectionQuery(
              $libraryHandle: String!
              $count: Int!
              $cursor: String
              $sort: [AnalysisSorting!]
            ) {
              library(handle: $libraryHandle) {
                ...LibraryAnalysesSection_library
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="runs"
          getComponent={() =>
            import(
              /* webpackChunkName: "libraries" */ './components/LibraryRunsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Libraries_runsSectionQuery(
              $libraryHandle: String!
              $count: Int!
              $cursor: String
              $sort: [RunSorting!]
            ) {
              library(handle: $libraryHandle) {
                ...LibraryRunsSection_library
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "libraries" */ './components/LibraryNotesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Libraries_notesSectionQuery(
              $libraryHandle: String!
              $count: Int!
              $cursor: String
            ) {
              library(handle: $libraryHandle) {
                ...LibraryNotesSection_library
              }
            }
          `}
          path="notes"
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "libraries" */ './components/EditLibraryPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Libraries_editLibraryPageQuery($libraryHandle: String!) {
            library(handle: $libraryHandle) {
              ...EditLibraryPage_library
            }
          }
        `}
      />
    </Route>
  </Route>
);
