import useBreakpoint from '@restart/hooks/useBreakpoint';
import classNames from 'classnames';
import { useRouter } from 'found';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import PageMain from 'components/PageMain';
import SidePanel from 'components/SidePanel';
import { getSessionStore } from 'utils/SessionStore';

import AppNotifications from './AppNotifications';
import BaseAppPage from './BaseAppPage';
import MinimizedSidePanel from './MinimizedSidePanel';
import type { AppPageWithSidePanel_tenant$data as Tenant } from './__generated__/AppPageWithSidePanel_tenant.graphql';
import type { AppPageWithSidePanel_viewer$data as Viewer } from './__generated__/AppPageWithSidePanel_viewer.graphql';

import './AppPageWithSidePanel.scss';

interface Props {
  viewer: Viewer;
  tenant: Tenant;
  header?: React.ReactNode;
  children?: React.ReactNode;
  sidePanel: React.ReactNode;
}

const rootClass = 'AppPageWithSidePanel';

function AppPageWithSidePanel({
  header,
  viewer,
  tenant,
  children,
  sidePanel,
}: Props) {
  const { match } = useRouter();
  const session = getSessionStore(match);
  const hidePanel = useBreakpoint('md', 'down');
  const showSidePanel = viewer && !session.getMinimized();
  const shouldShowNothing = !viewer || hidePanel;

  return (
    <BaseAppPage header={header} viewer={viewer} tenant={tenant}>
      {!shouldShowNothing &&
        (showSidePanel ? (
          <SidePanel className={`${rootClass}_sidePanel`}>
            {sidePanel}
          </SidePanel>
        ) : (
          <MinimizedSidePanel />
        ))}

      <PageMain
        className={classNames(
          rootClass,
          !shouldShowNothing && !showSidePanel && `${rootClass}__minimized`,
        )}
      >
        <AppNotifications viewer={viewer} />
        {children}
      </PageMain>
    </BaseAppPage>
  );
}

const AppPageWithSidePanelContainer = createFragmentContainer(
  AppPageWithSidePanel,
  {
    viewer: graphql`
      fragment AppPageWithSidePanel_viewer on Viewer {
        ...BaseAppPage_viewer
        ...AppNotifications_viewer
      }
    `,
    tenant: graphql`
      fragment AppPageWithSidePanel_tenant on Tenant {
        ...BaseAppPage_tenant
      }
    `,
  },
);

export default AppPageWithSidePanelContainer;
