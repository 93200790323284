import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import ItemGrid, { TableColumns } from 'components/ItemGrid';
import { useTenantRoutes } from 'utils/tenantRoutes';

import CreatedByListItem from './CreatedByListItem';
import NoItemFound from './NoItemFound';
import type { SampleListSubscription } from './__generated__/SampleListSubscription.graphql';
import type { SampleList_samples$data as Samples } from './__generated__/SampleList_samples.graphql';

const subscription = graphql`
  subscription SampleListSubscription($input: UpdateSampleSubscriptionInput!) {
    updateSample(input: $input) {
      sample {
        ...SampleList_samples
      }
    }
  }
`;

function SampleListSubscriber({ sample }: { sample: Samples[number] }) {
  useSubscription<SampleListSubscription>({
    subscription,
    variables: {
      input: { sampleId: sample.id },
    },
  });
  return null;
}

interface Props {
  samples: Samples;
  showProject?: boolean;
}

function SampleList({ samples, showProject }: Props) {
  const routes = useTenantRoutes();
  const columns = React.useMemo<TableColumns<Samples[number]>>(
    () => [
      {
        Header: (
          <FormattedMessage id="sampleList.name" defaultMessage="Sample" />
        ),
        accessor: 'name',
      },
      {
        Header: (
          <FormattedMessage id="sampleList.project" defaultMessage="Project" />
        ),
        accessor: (row) => row.project?.name,
        id: 'project',
      },
      {
        Header: (
          <FormattedMessage id="sampleList.created" defaultMessage="Created" />
        ),
        accessor: 'createdAt',
        sort: 'CREATED_AT',
        Cell: ({ row }) => (
          <CreatedByListItem
            userProfile={row.original.createdBy?.userProfile ?? null}
            createdAt={row.original.createdAt}
          />
        ),
      },
    ],
    [],
  );

  if (samples.length === 0) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage
            id="sampleList.itemType"
            defaultMessage="samples"
          />
        }
      />
    );
  }

  return (
    <ItemGrid
      data={samples}
      columns={columns}
      initialState={{
        hiddenColumns: [!showProject && 'project'].filter(Boolean) as string[],
      }}
      linkTo={(sample: DeepNonNull<Samples[number]>) =>
        routes.specimen({ project: sample.project, sample })
      }
      subscriberComponent={(sample) => (
        <SampleListSubscriber sample={sample} />
      )}
      data-testid="SampleList"
    />
  );
}

export default createFragmentContainer(SampleList, {
  samples: graphql`
    fragment SampleList_samples on Sample @relay(plural: true) {
      id
      handle
      name
      createdAt
      createdBy {
        userProfile {
          ...CreatedByListItem_userProfile
        }
      }
      project {
        handle
        name
      }
    }
  `,
});
