// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-e856c6ccd3/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-e856c6ccd3/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-e856c6ccd3/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlankPage{background:#191924;background-position:bottom center;background-repeat:no-repeat;height:100vh;font-family:Arial,sans-serif}.BlankPage::before{content:" ";display:block;position:absolute;left:0;top:0;width:100%;height:100vh;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}),linear-gradient(#191924, lightgrey);background-repeat:no-repeat;background-position:bottom center;mix-blend-mode:overlay}.BlankPage__logo{position:fixed;top:4rem;left:4rem}.BlankPage h1,.BlankPage h2,.BlankPage h3,.BlankPage h4{font-weight:600;color:var(--white)}.BlankPage a{color:#5f7396;font-size:var(--font-size-lg);font-weight:600}.BlankPage input{caret-color:var(--white)}.BlankPage__navbar{background:rgba(0,0,0,0);border-bottom:none}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
