import { configureStore } from '@reduxjs/toolkit';
import FarceActionTypes from 'farce/ActionTypes';
import FarceActions from 'farce/Actions';
import BrowserProtocol from 'farce/BrowserProtocol';
import createHistoryEnhancer from 'farce/createHistoryEnhancer';
import queryMiddleware from 'farce/queryMiddleware';
import FoundActionTypes from 'found/ActionTypes';
import Matcher from 'found/Matcher';
import createMatchEnhancer from 'found/createMatchEnhancer';
import foundReducer from 'found/foundReducer';
import platform from 'platform';
import {
  Middleware,
  StoreEnhancer,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';

import Analytics from 'utils/Analytics';

import routes from './routes';

const segmentLogger: Middleware = () => (next) => (action: Obj) => {
  const { type } = action;
  if (type === FarceActionTypes.INIT) {
    Analytics.track('navigation', { initialLoad: true, platform });
  } else if (type === FoundActionTypes.RESOLVE_MATCH) {
    Analytics.track('navigation', { initialLoad: false });
  }
  return next(action);
};

export default function customConfigureStore() {
  const store = configureStore({
    reducer: combineReducers({
      found: foundReducer,
    }),
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers().concat(
        compose(
          createHistoryEnhancer({
            protocol: new BrowserProtocol(),
            middlewares: [queryMiddleware],
          }),
          createMatchEnhancer(new Matcher(routes)),
          applyMiddleware(segmentLogger),
        ) as StoreEnhancer,
      );
    },
  });

  store.dispatch(FarceActions.init() as any);
  return store;
}
