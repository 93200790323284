import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { getNodes } from 'utils/graphql';
import * as routes from 'utils/routes';

import type { firstProjectRedirect_tenant$data as Tenant } from './__generated__/firstProjectRedirect_tenant.graphql';

export default function firstProjectRedirect({
  slug,
  projectConnection,
}: OmitRelayKeys<DeepNonNull<Tenant>>) {
  const [project] = getNodes(projectConnection);

  if (!project) {
    throw new RedirectException({
      pathname: routes.projects({ tenant: { slug } }),
    });
  }

  throw new RedirectException({
    pathname: routes.project({ tenant: { slug }, project }),
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment firstProjectRedirect_tenant on Tenant {
    slug
    projectConnection(first: 1) {
      edges {
        node {
          handle
        }
      }
    }
  }
`;
