import { Location, Match } from 'found';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import { Resolver as ResolverBase } from 'found-relay';
import isNil from 'lodash/isNil';
import type { Environment } from 'react-relay';

import type { AuthContextValue } from 'components/AuthContext';

export default class Resolver extends ResolverBase {
  private latestTenantSlugLower: string | null = null;

  constructor(
    environment: Environment,
    private auth: AuthContextValue,
  ) {
    super(environment);
  }

  checkError(location: Location) {
    if (location.state?.foundHttpError) {
      throw new HttpError(location.state.foundHttpError);
    }
  }

  // eslint-disable-next-line require-await
  async *resolveElements(match: Match) {
    const { context, location, params, routes } = match;

    this.checkError(location);

    // Check for authenticated or unauthenticated.
    if (
      !this.auth.isAuthenticated() &&
      !routes.reduce(
        (prevUnauthenticated, { unauthenticated }) =>
          isNil(unauthenticated) ? prevUnauthenticated : unauthenticated,
        false,
      )
    ) {
      throw new HttpError(401);
    }

    const tenantSlugLower = params.tenantSlug
      ? params.tenantSlug.toLowerCase()
      : null;

    if (tenantSlugLower !== this.latestTenantSlugLower) {
      context.launchDarkly.clear();
    }

    this.latestTenantSlugLower = tenantSlugLower;

    // super() leads to a recursive call stack overflow due to compilation bug
    yield* ResolverBase.prototype.resolveElements.call(this, match);

    if (context.launchDarkly.waitingForClient) {
      context.launchDarkly.waitingForClient = false;
      throw new RedirectException(location);
    }
  }
}
