import { useRouter } from 'found';
import React from 'react';
import { FormHandle } from 'react-formal/esm/Form';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { object, string } from 'yup';

import Button from 'components/Button';
import FieldGroup from 'components/FieldGroup';
import InputGrid from 'components/InputGrid';
import Modal from 'components/Modal';
import RelayForm from 'components/RelayForm';
import { useTenantRoutes } from 'utils/tenantRoutes';
import useTenant from 'utils/useTenant';

import type { RegisterDeviceModal_Mutation as RegisterDeviceMutation } from './__generated__/RegisterDeviceModal_Mutation.graphql';
import type { RegisterDeviceModal_device$data as Device } from './__generated__/RegisterDeviceModal_device.graphql';

const mutation = graphql`
  mutation RegisterDeviceModal_Mutation($input: RegisterDeviceInput!) {
    registerDeviceOrError(input: $input) {
      ... on RegisterDevicePayload {
        device {
          handle
          ...DeviceList_devices
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const schema = object({
  deviceId: string().required(),
  userPin: string().required(),
}).required();

interface Props {
  trigger: React.ReactNode;
  device: DeepNonNull<Device> | null;
}

function RegisterDeviceModal({ trigger, device }: Props) {
  const modalFormRef = React.useRef<FormHandle>(null);
  const { tenantId } = useTenant();
  const { router } = useRouter();
  const routes = useTenantRoutes();

  const onCompleted = ({
    registerDeviceOrError,
  }: DeepNonNull<RegisterDeviceMutation['response']>) => {
    const { device: deviceResponse } = registerDeviceOrError;
    router.push(routes.deviceEdit({ device: deviceResponse }));
  };

  return (
    <Modal
      title={
        <FormattedMessage
          id="registerDeviceModal.title"
          defaultMessage="Register your Device"
        />
      }
      trigger={trigger}
    >
      <RelayForm<RegisterDeviceMutation, typeof schema>
        ref={modalFormRef}
        onCompleted={onCompleted}
        schema={schema}
        defaultValue={{
          ...schema.getDefault(),
          // the device handle (local ID) is used since we reference devices
          // by their serial number server side, which is the local ID field
          deviceId: device ? device.handle : '',
        }}
        mutation={mutation}
        getInput={(p) => ({
          tenantId,
          ...p,
        })}
      >
        <Modal.Body>
          <InputGrid>
            <FieldGroup
              name="deviceId"
              label={
                <FormattedMessage
                  id="registerDeviceModal.deviceId"
                  defaultMessage="Serial Number"
                />
              }
              disabled={!!device}
            />
            <FieldGroup
              name="userPin"
              label={
                <FormattedMessage
                  id="registerDeviceModal.userPin"
                  defaultMessage="PIN"
                />
              }
              placeholder="----"
            />
          </InputGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">
            <FormattedMessage
              defaultMessage="Register"
              id="registerDeviceModal.register"
            />
          </Button>
        </Modal.Footer>
      </RelayForm>
    </Modal>
  );
}

export default createFragmentContainer(RegisterDeviceModal, {
  device: graphql`
    fragment RegisterDeviceModal_device on Device {
      id
      handle
    }
  `,
});
