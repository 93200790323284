import type {
  RouteProps as BaseRouteProps,
  Match,
  RenderProps,
  RouteRenderArgs,
} from 'found';
import HttpError from 'found/HttpError';
import BaseRoute from 'found/Route';
import React from 'react';
import type { Environment, GraphQLTaggedNode } from 'relay-runtime';

import Spinner from './Spinner';

function defaultGetFetchPolicy({ location }: Match) {
  if (location.state?.force) {
    return 'network-only';
  }

  if (location.action === 'POP') {
    return 'store-or-network';
  }
  return 'store-and-network';
}

export type RelayRenderArgs<T = Obj> = Omit<RouteRenderArgs, 'props'> & {
  props?: RenderProps & T;
  resolving: boolean;
  error?: any;
  retry?: () => void;
  environment: Environment;
  variables: Obj;
};

export interface RouteProps extends BaseRouteProps {
  query?: GraphQLTaggedNode;
  getQuery?: (match: Match) => GraphQLTaggedNode;

  prerender?: (args: RelayRenderArgs) => void;
  prepareVariables?: (variables: Obj, match: Match) => Obj;
}

export function createRender({
  prerender,
  render,
  renderFetched,
}: RouteProps) {
  return (
    renderArgs: RelayRenderArgs & { resolving: boolean; error?: any },
  ) => {
    const { resolving, Component, props, error } = renderArgs;

    if (error) {
      // TODO: What if we're not resolving?
      throw new HttpError(500);
    }

    if (prerender && resolving) {
      prerender(renderArgs);
    }

    if (render) {
      return render(renderArgs);
    }

    if (props && renderFetched) {
      return renderFetched(renderArgs);
    }

    // An undefined component means the route has no defined component, in which case we want to ignore the route for rendering to match upstream.
    if (Component === undefined) {
      return null;
    }

    // A null component or props means that the route is not done loading, unless someone has done something weird and explicitly set Component to null.
    if (!Component || !props) {
      return <Spinner size="lg" centered />;
    }

    return <Component {...props} />;
  };
}

class Route extends BaseRoute {
  constructor(props: RouteProps) {
    if (!(props.query || props.getQuery)) {
      super(props);
      return;
    }

    super({
      getFetchPolicy: defaultGetFetchPolicy,
      ...props,
      render: createRender(props),
    });
  }
}

export default Route as React.ComponentClass<RouteProps>;
