import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { createCheckItem } from 'utils/checkItem';

import { commonRunRoutes } from '.';

export default (
  <Route
    /* eslint-disable relay/must-colocate-fragment-spreads */ // XXX: misbehaves
    query={graphql`
      query sharedRoutes_runQuery($runHandle: String!) {
        run(handle: $runHandle) {
          ...SharedRunPage_run
        }
      }
    `}
    path="runs/:runHandle"
    prerender={createCheckItem('run')}
    getComponent={() =>
      import(/* webpackChunkName: "runs" */ './components/SharedRunPage').then(
        (p) => p.default,
      )
    }
  >
    {commonRunRoutes}
  </Route>
);
