import Layout from '@4c/layout';
import classNames from 'classnames';
import React from 'react';

import DoneAnimation from './DoneAnimation';
import LogoSpinner from './LogoSpinner';
import ScaleReplaceTransition from './ScaleReplaceTransition';

import './BusyToDoneAnimation.scss';

interface Props {
  busy: boolean;
  theme?: 'success' | 'failure';
}

const rootClass = 'BusyToDoneAnimation';

function BusyToDoneAnimation({ busy, theme = 'success' }: Props) {
  return (
    <ScaleReplaceTransition in={busy} className={rootClass}>
      <Layout
        className={classNames(`${rootClass}__logo`)}
        align="center"
        justify="center"
      >
        <LogoSpinner />
      </Layout>
      <DoneAnimation theme={theme} />
    </ScaleReplaceTransition>
  );
}

export default BusyToDoneAnimation;
