/**
 * @generated SignedSource<<b44f6bee925fda2051b460c660da0319>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Protocols_protocolsQuery$variables = {
  tenantSlug: string;
};
export type Protocols_protocolsQuery$data = {
  readonly query: {
    readonly " $fragmentSpreads": FragmentRefs<"ManualsAndProtocolsPage_query">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"ManualsAndProtocolsPage_tenant">;
  } | null;
};
export type Protocols_protocolsQuery = {
  response: Protocols_protocolsQuery$data;
  variables: Protocols_protocolsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Protocols_protocolsQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ManualsAndProtocolsPage_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ManualsAndProtocolsPage_query"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Protocols_protocolsQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "type",
                "value": [
                  "SEQUENCING",
                  "LIBRARY"
                ]
              }
            ],
            "concreteType": "ProtocolConnection",
            "kind": "LinkedField",
            "name": "protocolConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProtocolEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "protocolConnection(first:10,type:[\"SEQUENCING\",\"LIBRARY\"])"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Query",
        "kind": "LinkedField",
        "name": "query",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "ManualConnection",
            "kind": "LinkedField",
            "name": "manualConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ManualEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Manual",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "manualConnection(first:10)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3776260e8aa8e6e47cec3bef9e672060",
    "id": null,
    "metadata": {},
    "name": "Protocols_protocolsQuery",
    "operationKind": "query",
    "text": "query Protocols_protocolsQuery(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...ManualsAndProtocolsPage_tenant\n    id\n  }\n  query {\n    ...ManualsAndProtocolsPage_query\n  }\n}\n\nfragment ManualList_query on Query {\n  manualConnection(first: 10) {\n    edges {\n      node {\n        handle\n        name\n        description\n        createdAt\n        id\n      }\n    }\n  }\n}\n\nfragment ManualsAndProtocolsPage_query on Query {\n  ...ManualList_query\n}\n\nfragment ManualsAndProtocolsPage_tenant on Tenant {\n  ...ProtocolList_protocols\n}\n\nfragment ProtocolList_protocols on Tenant {\n  protocolConnection(first: 10, type: [SEQUENCING, LIBRARY]) {\n    edges {\n      node {\n        __typename\n        handle\n        name\n        createdAt\n        type\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e60a98744ae018ad0608e0b28319982";

export default node;
