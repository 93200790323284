/**
 * @generated SignedSource<<bbeec498acedd3eda44a2929008edaaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apps_DeveloperSandboxPage_Query$variables = {
  tenantSlug: string;
};
export type Apps_DeveloperSandboxPage_Query$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"DeveloperSandboxPage_tenant">;
  } | null;
};
export type Apps_DeveloperSandboxPage_Query = {
  response: Apps_DeveloperSandboxPage_Query$data;
  variables: Apps_DeveloperSandboxPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_DeveloperSandboxPage_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeveloperSandboxPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Apps_DeveloperSandboxPage_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 5
              }
            ],
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projectConnection(first:5)"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cb1052849e38fe330032d97c7f9660b",
    "id": null,
    "metadata": {},
    "name": "Apps_DeveloperSandboxPage_Query",
    "operationKind": "query",
    "text": "query Apps_DeveloperSandboxPage_Query(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...DeveloperSandboxPage_tenant\n    id\n  }\n}\n\nfragment DeveloperSandboxPage_tenant on Tenant {\n  projectConnection(first: 5) {\n    edges {\n      node {\n        name\n        id\n        handle\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2dce8ef766cfafa57d18c573433d0e2a";

export default node;
