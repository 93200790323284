import classNames from 'classnames';
import React from 'react';

import './LogoSpinner.scss';

interface Props {
  className?: string;
  spin?: boolean;
}

const rootClass = 'LogoSpinner';

function LogoSpinner({ className, spin, ...props }: Props) {
  return (
    <svg
      {...props}
      className={classNames(rootClass, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 544.32 544.32"
    >
      {/* this is the "Si" */}
      <path
        fill="#404041"
        d="M252.95 256.24c-23.1-8.76-33.18-13.81-33.18-25.22 0-9.29 8.5-17.25 26-17.25a84.98 84.98 0 0 1 37.43 8.5l9.02-32.65c-10.61-4.77-25.48-9.03-45.64-9.03-42.2 0-67.68 23.36-67.68 53.88 0 26 19.38 42.46 49.1 52.81 21.49 7.7 29.99 14.07 29.99 25.22 0 11.67-9.82 19.37-28.4 19.37-17.25 0-33.97-5.57-44.85-11.14l-8.22 33.43c10.08 5.58 30.24 10.9 50.68 10.9 49.1 0 72.19-25.5 72.19-55.49 0-25.2-14.86-41.65-46.44-53.33m92.62-80.16c-13.01 0-21.5 8.76-21.24 20.44-.26 11.15 8.23 20.16 20.98 20.16 13.27 0 21.76-9 21.76-20.16-.27-11.68-8.5-20.44-21.5-20.44M325.4 362.4h40.34V232.6h-40.35v129.8z"
      />
      <g className={classNames(spin && `${rootClass}__ring`)}>
        <path
          fill="#68be87"
          d="M144.63 51.06L119.4 183.4l62.57-68.35 90.17-20L144.9 50.88z"
        />
        <path
          fill="#f7ce33"
          d="M399.64 51.03l25.27 132.37-62.92-68.22-89.83-20.15L399.21 50.8l.43.23z"
        />
        <path
          fill="#5ab25a"
          d="M272.16 95.03L399.2 50.8a39.72 39.72 0 0 0-13.91-4.7h-.14c-.75-.1-1.52-.18-2.3-.23l-.48-.01c-.65-.03-1.32-.07-2-.07h-216.5l-.53.01a42.03 42.03 0 0 0-4.02.27 41.2 41.2 0 0 0-14.42 4.82z"
        />
        <path
          fill="#e76e6b"
          d="M424.49 359.36l-52.15 65.7-100.18 22.3 126.73 44.4.04-.03s17.76-91.9 25.56-132.37z"
        />
        <path
          fill="#c1c1c1"
          d="M443.27 425.73l60.92-105.52-61.05 105.39.13.13z"
        />
        <path
          fill="#f7ce33"
          d="M92.14 271.2v-.01l27.27-87.8-101.68 87.64-.01.33 102.11 88-27.7-88.16z"
        />
        <path
          fill="#5ab25a"
          d="M144.62 51.06a40.86 40.86 0 0 0-10.78 9.47 39.27 39.27 0 0 0-2.8 4.19l-78.92 136.7-29.35 50.97a38.03 38.03 0 0 0-5.04 18.64L119.4 183.4l25.22-132.33z"
        />
        <path
          fill="#f7ce33"
          d="M452.06 271.16L424.4 183l102.1 88v.32l-101.94 87.99z"
        />
        <path
          fill="#eca132"
          d="M526.59 271.03a37.94 37.94 0 0 0-5.04-18.63l-28.66-49.8-79.6-137.89a37.66 37.66 0 0 0-2.82-4.18c-2.86-3.7-6.9-7.1-10.83-9.5L424.9 183.4l101.69 87.63z"
        />
        <path
          fill="#f7ce33"
          d="M145.42 491.76l126.73-44.4-90-20.18-62.31-67.81 25.3 132.12.28.27z"
        />
        <path
          fill="#c1c1c1"
          d="M161.5 496.52c.78.05 1.56.08 2.35.08h216.59-216.5c-.82 0-1.63-.02-2.44-.08"
        />
        <path
          fill="#df3c31"
          d="M272.15 447.36l-126.73 44.4a38.17 38.17 0 0 0 16.01 4.76h.07c.81.06 1.62.08 2.44.08h216.5l.83-.02a37.88 37.88 0 0 0 17.62-4.82l-126.74-44.4z"
        />
        <path fill="#c1c1c1" d="M161.5 496.52h-.07.07" />
        <path
          fill="#eca132"
          d="M145.13 491.48l-25.3-132.12-102.1-88a38.12 38.12 0 0 0 .51 6.13c.06.36.1.7.18 1.06a37.7 37.7 0 0 0 4.36 11.61l16.54 28.66 92.04 158.88a37.97 37.97 0 0 0 13.77 13.78"
        />
        <path
          fill="#df3c31"
          d="M424.49 359.36l-25.57 132.37c4.8-2.82 9-6.69 12.22-11.4 0-.03.02-.05.03-.07.46-.67.9-1.35 1.3-2.05l108.87-188.42.4-.73a37.88 37.88 0 0 0 4.76-17.63z"
        />
      </g>
      {/* this Q cutout piece that doesn't move */}
      <path
        fill="#fff"
        d="M382.7 367.05l102.35 95.77L426.6 519 313.58 406.2z"
      />
    </svg>
  );
}

export default LogoSpinner;
