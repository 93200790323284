/**
 * @generated SignedSource<<28f69c0129bd857b6dfe0025ca1edeea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_NewReferencePageQuery$variables = {
  tenantSlug: string;
};
export type References_NewReferencePageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"NewReferencePage_tenant">;
  } | null;
};
export type References_NewReferencePageQuery = {
  response: References_NewReferencePageQuery$data;
  variables: References_NewReferencePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_NewReferencePageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewReferencePage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_NewReferencePageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e449bbbfc6a20ff0f9625bcce967f4c",
    "id": null,
    "metadata": {},
    "name": "References_NewReferencePageQuery",
    "operationKind": "query",
    "text": "query References_NewReferencePageQuery(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...NewReferencePage_tenant\n    id\n  }\n}\n\nfragment NewReferencePage_tenant on Tenant {\n  id\n  slug\n}\n"
  }
};
})();

(node as any).hash = "96da8f5bd91f3358b4e4336a201d9431";

export default node;
