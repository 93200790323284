/* eslint-disable import/order */
import 'isomorphic-fetch';
import React from 'react';

// Need to bootstrap styles before importing components
import './styles/bootstrap.scss';
import './styles/index.scss';

import ErrorPage, { ERROR_CODES } from 'components/ErrorPage';
import createApplication from './createApplication';
import LoginPage from './components/LoginPage';

export default function clientApplication() {
  return createApplication({
    renderError({ error }) {
      const { data, status } = error;
      if (ERROR_CODES.has(status)) {
        return <ErrorPage code={status as any} data={data} />;
      }

      if (status === 401) {
        return <LoginPage email={data?.email} />;
      }

      return null;
    },
  });
}
