import { useInterval } from '@restart/hooks';
import React, { useState } from 'react';

import { toTimeParts } from 'utils/duration';

export default function ImpersonationCallout({
  tokenState,
}: {
  tokenState: TokenState;
}) {
  const [, refresh] = useState<Obj>();
  useInterval(() => refresh({}), 1000);
  const { seconds, minutes, hours } = toTimeParts(
    (tokenState.expiresAt - Date.now()) / 1000,
  );

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        background: 'red',
        textAlign: 'center',
        padding: '4px',
      }}
    >
      You are impersonating user {tokenState.email}. Time remaining {hours}:
      {minutes}:{Math.floor(seconds)}. Logout to terminate.
    </div>
  );
}
