/**
 * @generated SignedSource<<54b4ba7531cabe639a500abd7bce8805>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Samples_newSampleQuery$variables = {
  hasSample: boolean;
  projectHandle: string;
  sampleHandle: string;
  tenantSlug: string;
};
export type Samples_newSampleQuery$data = {
  readonly patternSample?: {
    readonly " $fragmentSpreads": FragmentRefs<"NewSamplePage_patternSample">;
  } | null;
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"NewSamplePage_project">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"NewSamplePage_tenant">;
  } | null;
};
export type Samples_newSampleQuery = {
  response: Samples_newSampleQuery$data;
  variables: Samples_newSampleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasSample"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectHandle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sampleHandle"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "projectHandle"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "sampleHandle"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 4
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Samples_newSampleQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v4/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewSamplePage_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewSamplePage_project"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasSample",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "patternSample",
            "args": (v6/*: any*/),
            "concreteType": "Sample",
            "kind": "LinkedField",
            "name": "sample",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NewSamplePage_patternSample"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Samples_newSampleQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v4/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "tagConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tagConnection(first:4)"
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_tagConnection",
            "kind": "LinkedHandle",
            "name": "tagConnection"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v11/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "Analysis",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "Library",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "Run",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "Sample",
                "abstractKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasSample",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "patternSample",
            "args": (v6/*: any*/),
            "concreteType": "Sample",
            "kind": "LinkedField",
            "name": "sample",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Entity",
                "abstractKey": "__isEntity"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9ea48f5754dea9f42a2e1d3745ed3f7b",
    "id": null,
    "metadata": {},
    "name": "Samples_newSampleQuery",
    "operationKind": "query",
    "text": "query Samples_newSampleQuery(\n  $tenantSlug: String!\n  $projectHandle: String!\n  $sampleHandle: String!\n  $hasSample: Boolean!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...NewSamplePage_tenant\n    id\n  }\n  project(handle: $projectHandle) {\n    ...NewSamplePage_project\n    id\n  }\n  patternSample: sample(handle: $sampleHandle) @include(if: $hasSample) {\n    ...NewSamplePage_patternSample\n    id\n  }\n}\n\nfragment EntityFormSection_tenant on Tenant {\n  ...TagsInput_tenant\n}\n\nfragment NewSamplePage_patternSample on Sample {\n  ... on Entity {\n    __isEntity: __typename\n    description\n    name\n    tags {\n      color\n      handle\n      id\n      name\n    }\n  }\n}\n\nfragment NewSamplePage_project on Project {\n  id\n  handle\n  ... on Entity {\n    __isEntity: __typename\n    name\n    ... on Project {\n      handle\n    }\n    ... on Analysis {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Library {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Run {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Sample {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment NewSamplePage_tenant on Tenant {\n  id\n  ...SampleFormFields_tenant\n}\n\nfragment SampleFormFields_tenant on Tenant {\n  ...EntityFormSection_tenant\n}\n\nfragment TagInputRow_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment Tag_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment TagsInput_tenant on Tenant {\n  id\n  slug\n  tagConnection(first: 4) {\n    edges {\n      node {\n        color\n        handle\n        id\n        name\n        ...Tag_tag\n        ...TagInputRow_tag\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef4fbfd324399a5bca6fa5649a66d3cd";

export default node;
