import type { Match } from 'found';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  ConnectionConfig,
  GraphQLTaggedNode,
  RelayPaginationProp,
  createPaginationContainer,
} from 'react-relay';

import Spinner from '../Spinner';

export const PAGE_SIZE = 30;

export function getInfiniteListVariables(vars?: Obj) {
  return { ...vars, count: PAGE_SIZE };
}

export function getSortVariables(vars?: Obj, match?: Match) {
  const sort = match?.location?.query?.sort;
  return { ...vars, sort: sort && [sort] };
}

export function getEntitySearchVariables(vars?: Obj, match?: Match) {
  const query = match?.location?.query;
  const search = query?.entitySearch || null;
  const status = query?.status || null;
  const selectedTagHandle = query?.tag || null;
  const tagIdSearchFilters = selectedTagHandle;
  const selectedUserProfileHandle = query?.createdBy || null;
  const userProfileIdSearchFilters = selectedUserProfileHandle;

  return {
    ...vars,
    search,
    status,
    tagIdSearchFilters,
    selectedTagHandle,
    hasTag: !!selectedTagHandle,
    userProfileIdSearchFilters,
    selectedUserProfileHandle,
    hasUserProfile: !!selectedUserProfileHandle,
  };
}

type Config<Props> = Omit<
  ConnectionConfig<Props>,
  'direction' | 'getVariables'
> &
  Partial<Pick<ConnectionConfig<Props>, 'getVariables'>>;

export default function createInfiniteList<Props extends Obj>(
  Component: React.ComponentType<Props>,
  fragmentSpec: Obj<GraphQLTaggedNode>,
  { getVariables, ...connectionConfig }: Config<Props>,
) {
  function ListComponent(props: Props & { relay: RelayPaginationProp }) {
    const { relay } = props;

    // TODO: getScrollParent is very hacky and very fragile. Eventually we need to clean up the page layout. And pass down a ref to the scrollable parent

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={() => relay.loadMore(PAGE_SIZE)}
        hasMore={!!relay.hasMore}
        useWindow={false}
        getScrollParent={() => document.getElementById('PageContainer')}
        loader={relay.hasMore() ? <Spinner size="lg" centered /> : undefined}
      >
        <Component {...props} />
      </InfiniteScroll>
    );
  }

  return createPaginationContainer(ListComponent, fragmentSpec, {
    direction: 'forward',
    getVariables: (props, paginationInfo, fragmentVariables) => ({
      ...fragmentVariables,
      ...paginationInfo,
      ...(getVariables &&
        getVariables(props as any, paginationInfo, fragmentVariables)),
    }),
    ...(connectionConfig as any),
  });
}
