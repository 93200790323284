import { useMemo } from 'react';

import type { AnalysisProgress_analysis$data as Analysis } from './__generated__/AnalysisProgress_analysis.graphql';

export default function useAnalysisProgressInfo(
  steps: DeepNonNull<Analysis>['job']['steps'],
) {
  const [numPending, numRunning, numCompleted] = useMemo(() => {
    let pending = 0;
    let running = 0;
    let completed = 0;

    for (const step of steps) {
      switch (step.status) {
        case 'PENDING':
          pending++;
          break;

        case 'ABORTED':
        case 'COMPLETED':
        case 'FAILED':
          completed++;
          break;

        case 'RUNNING':
          running++;
          break;

        default:
          break;
      }
    }

    return [pending, running, completed];
  }, [steps]);

  return { numPending, numRunning, numCompleted };
}
