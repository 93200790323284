/**
 * @generated SignedSource<<83c06a0f97998827ba394d966b842ed8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResetPasswordPage_tokenValid$data = {
  readonly __typename: "ValidationResult";
  readonly " $fragmentType": "ResetPasswordPage_tokenValid";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ResetPasswordPage_tokenValid";
};
export type ResetPasswordPage_tokenValid$key = {
  readonly " $data"?: ResetPasswordPage_tokenValid$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResetPasswordPage_tokenValid">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResetPasswordPage_tokenValid",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "ValidationResult",
  "abstractKey": null
};

(node as any).hash = "4a8ba1adf9bcd3bae998fd880b1cebc8";

export default node;
