import Layout from '@4c/layout';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import FormattedLabel from 'components/FormattedLabel';
import commonMessages from 'messages/common';

import Modal from './Modal';

import './Progress.scss';

interface ErrorControlProps {
  error: { label: string; details: string };
}

const rootClass = 'Progress';

function ErrorControl({ error }: ErrorControlProps) {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      open={open}
      title={error.label}
      trigger={
        <Button
          variant="text-danger"
          className={classNames(`${rootClass}__error-message`)}
          onClick={() => {
            setOpen(true);
          }}
        >
          <FormattedLabel label={error.label} />
        </Button>
      }
    >
      <Modal.Body>{error.details}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface Props {
  progress?: number | null;
  status?: React.ComponentProps<typeof FormattedLabel>['label'];
  indeterminate?: boolean;
  error?: ErrorControlProps['error'];
  className?: string;
}

function LoadingStatus({ progress }: { progress: number }) {
  return <div style={{ width: `${progress * 100}%` }} />;
}

function IndeterminateStatus() {
  return <div className={classNames(`${rootClass}__indeterminate`)} />;
}

function Progress({
  progress,
  indeterminate = false,
  error,
  status,
  className,
}: Props) {
  const showProgressBar = !isNil(progress) || indeterminate;

  const content = useMemo(() => {
    if (status) {
      return <FormattedLabel label={status} />;
    }
    if (error) {
      return <ErrorControl error={error} />;
    }
    return null;
  }, [error, status]);

  return (
    <Layout direction="column" className={classNames(rootClass, className)}>
      {showProgressBar && (
        <div className={classNames(`${rootClass}__progress-bar`)}>
          {indeterminate ? (
            <IndeterminateStatus />
          ) : (
            <LoadingStatus progress={progress!} />
          )}
        </div>
      )}
      <div>{content}</div>
    </Layout>
  );
}

export default Progress;
