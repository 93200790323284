/**
 * @generated SignedSource<<3be52a5266ed0aaeb36ce44821815a58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Libraries_libraryPageQuery$variables = {
  libraryHandle: string;
};
export type Libraries_libraryPageQuery$data = {
  readonly library: {
    readonly " $fragmentSpreads": FragmentRefs<"LibraryPage_library">;
  } | null;
};
export type Libraries_libraryPageQuery = {
  response: Libraries_libraryPageQuery$data;
  variables: Libraries_libraryPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "libraryHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "libraryHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Libraries_libraryPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LibraryPage_library"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Libraries_libraryPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Library",
        "kind": "LinkedField",
        "name": "library",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followingStatus",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numRuns",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Analysis",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Library",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Run",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Sample",
                "abstractKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4b2fd38c2871b01df350d185657acf0",
    "id": null,
    "metadata": {},
    "name": "Libraries_libraryPageQuery",
    "operationKind": "query",
    "text": "query Libraries_libraryPageQuery(\n  $libraryHandle: String!\n) {\n  library(handle: $libraryHandle) {\n    ...LibraryPage_library\n    id\n  }\n}\n\nfragment CopyLibraryControl_library on Library {\n  handle\n}\n\nfragment DeleteLibraryControl_library on Library {\n  id\n  project {\n    id\n  }\n  tenant {\n    id\n  }\n}\n\nfragment EditLibraryControl_library on Library {\n  handle\n}\n\nfragment FollowLibraryControl_library on Library {\n  followingStatus\n  id\n}\n\nfragment LibraryPageHeader_library on Library {\n  ... on Entity {\n    __isEntity: __typename\n    name\n    ... on Project {\n      handle\n    }\n    ... on Analysis {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Library {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Run {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Sample {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n  }\n  ...FollowLibraryControl_library\n  ...CopyLibraryControl_library\n  ...EditLibraryControl_library\n  ...DeleteLibraryControl_library\n}\n\nfragment LibraryPageTabs_library on Library {\n  handle\n  numRuns\n  numAnalyses\n  numNotes\n}\n\nfragment LibraryPage_library on Library {\n  ...LibraryPageHeader_library\n  ...LibraryPageTabs_library\n}\n"
  }
};
})();

(node as any).hash = "3c9c3255801d06ab1bede39973d2c658";

export default node;
