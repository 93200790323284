import React from 'react';

import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route from 'components/Route';

export default (
  <Route path="prediction-tool">
    <LaunchDarklyRoute
      getVariation={(ldClient) =>
        ldClient.variation<'sqope'>('sqope', false) && {
          getComponent: () =>
            import(
              /* webpackChunkName: "prediction-tool" */ './components/SqopePage'
            ).then((p) => p.default),
        }
      }
    />
  </Route>
);
