/**
 * @generated SignedSource<<17d6c0ea41d36f9daa07d701b41ca36d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileNotificationPageQuery$variables = {};
export type ProfileNotificationPageQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"NotificationProfilePage_viewer">;
  } | null;
};
export type ProfileNotificationPageQuery = {
  response: ProfileNotificationPageQuery$data;
  variables: ProfileNotificationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileNotificationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationProfilePage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileNotificationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNotificationSetting",
                "kind": "LinkedField",
                "name": "notificationSettings",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deliveryType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notificationType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FollowingDefaults",
                "kind": "LinkedField",
                "name": "followingDefaults",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "followingType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07e5272779f5fd9c6dc126a558e46195",
    "id": null,
    "metadata": {},
    "name": "ProfileNotificationPageQuery",
    "operationKind": "query",
    "text": "query ProfileNotificationPageQuery {\n  viewer {\n    ...NotificationProfilePage_viewer\n    id\n  }\n}\n\nfragment DeliverySettingsForm_user on User {\n  id\n  notificationSettings {\n    active\n    deliveryType\n    notificationType\n  }\n}\n\nfragment FollowingDefaultsForm_user on User {\n  id\n  followingDefaults {\n    followingType\n    value\n  }\n}\n\nfragment NotificationProfilePage_viewer on Viewer {\n  user {\n    ...DeliverySettingsForm_user\n    ...FollowingDefaultsForm_user\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "903fce195b9f7cdd45bc2e573cea37c9";

export default node;
