import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Breadcrumbs, { PageTitle } from 'components/Breadcrumbs';
import Header from 'components/Header';
import Section from 'components/Section';
import { analysisStatus } from 'messages/analyses';
import libraryMessages from 'messages/libraries';
import runMessages from 'messages/runs';

import type { SearchPage_project$data as Project } from './__generated__/SearchPage_project.graphql';
import type { SearchPage_tag$data as Tag } from './__generated__/SearchPage_tag.graphql';
import type { SearchPage_userProfile$data as UserProfile } from './__generated__/SearchPage_userProfile.graphql';

const MESSAGES_MAP: Record<string, Record<string, MessageDescriptor>> = {
  libraries: libraryMessages,
  runs: runMessages,
  analyses: analysisStatus,
};

type EntityType = keyof typeof MESSAGES_MAP;
interface Props {
  children: React.ReactNode;
  userProfile: UserProfile | null;
  project: Project | null;
  tag: Tag | null;
  search: string | null;
  status: string | null;
}

function SearchPage({
  userProfile,
  project,
  tag,
  search,
  status,
  children,
}: Props) {
  const { match } = useRouter();
  const statuses = MESSAGES_MAP[match.location.query.entityType as EntityType];

  const searchText = search && (
    <FormattedMessage
      id="runSearchPage.searchLabel"
      defaultMessage='for "{search}"'
      values={{ search }}
    />
  );

  const projectText = project ? (
    <FormattedMessage
      id="runSearchPage.inProjectLabel"
      defaultMessage="in {projectName}"
      values={{
        projectName: project.name,
      }}
    />
  ) : (
    <FormattedMessage
      id="runSearchPage.inAllProjectsLabel"
      defaultMessage="in all projects"
    />
  );

  const createdByText = userProfile && (
    <FormattedMessage
      id="runSearchPage.createdByLabel"
      defaultMessage="created by {userName}"
      values={{
        userName: userProfile.displayName,
      }}
    />
  );

  const tagText = tag && (
    <FormattedMessage
      id="runSearchPage.withTagLabel"
      defaultMessage="with tag {tagName}"
      values={{
        tagName: tag.name,
      }}
    />
  );

  const statusText = status && (
    <FormattedMessage
      id="runSearchPage.onStatusLabel"
      defaultMessage='in status "{status}"'
      values={{
        status: <FormattedMessage {...statuses[status]} />,
      }}
    />
  );

  return (
    <>
      <Header.PageHeader>
        <Breadcrumbs>
          <PageTitle>
            <FormattedMessage
              id="runSearchPage.searchSummary"
              defaultMessage="Results {searchText} {projectText} {createdByText} {tagText} {statusText}"
              values={{
                searchText,
                projectText,
                createdByText,
                tagText,
                statusText,
              }}
            />
          </PageTitle>
        </Breadcrumbs>
      </Header.PageHeader>
      <Section data-testid="SearchPage">{children}</Section>
    </>
  );
}

export default createFragmentContainer(SearchPage, {
  userProfile: graphql`
    fragment SearchPage_userProfile on UserProfile {
      displayName
    }
  `,
  project: graphql`
    fragment SearchPage_project on Project {
      name
    }
  `,
  tag: graphql`
    fragment SearchPage_tag on Tag {
      name
    }
  `,
});
