/**
 * @generated SignedSource<<5110ec6d76e53cde6b19135fdb239b10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_EditReferenceAnnotationPageQuery$variables = {
  referenceAnnotationHandle: string;
};
export type References_EditReferenceAnnotationPageQuery$data = {
  readonly referenceAnnotation: {
    readonly " $fragmentSpreads": FragmentRefs<"EditReferenceAnnotationPage_referenceAnnotation">;
  } | null;
};
export type References_EditReferenceAnnotationPageQuery = {
  response: References_EditReferenceAnnotationPageQuery$data;
  variables: References_EditReferenceAnnotationPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referenceAnnotationHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceAnnotationHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "References_EditReferenceAnnotationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditReferenceAnnotationPage_referenceAnnotation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "References_EditReferenceAnnotationPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ReferenceAnnotation",
        "kind": "LinkedField",
        "name": "referenceAnnotation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Reference",
            "kind": "LinkedField",
            "name": "reference",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequenceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "annotationType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bd9d843912687f94767841624f9a989",
    "id": null,
    "metadata": {},
    "name": "References_EditReferenceAnnotationPageQuery",
    "operationKind": "query",
    "text": "query References_EditReferenceAnnotationPageQuery(\n  $referenceAnnotationHandle: String!\n) {\n  referenceAnnotation(handle: $referenceAnnotationHandle) {\n    ...EditReferenceAnnotationPage_referenceAnnotation\n    id\n  }\n}\n\nfragment EditReferenceAnnotationPage_referenceAnnotation on ReferenceAnnotation {\n  id\n  handle\n  reference {\n    id\n    handle\n    name\n  }\n  tenant {\n    id\n  }\n  name\n  description\n  sequenceType\n  annotationType\n}\n"
  }
};
})();

(node as any).hash = "2c7effe91a63ac18ecce6e510514fbfc";

export default node;
