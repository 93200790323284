import classNames from 'classnames';
import React from 'react';

import './PageContainer.scss';

interface Props {
  children: React.ReactNode;
  center?: boolean;
  className?: string;
  dataTestId?: string;
}

const rootClass = 'PageContainer';

const PageContainer = ({
  dataTestId = rootClass,
  center,
  className,
  children,
  ...props
}: Props) => {
  return (
    <div
      id={rootClass}
      data-testid={dataTestId}
      className={classNames(
        rootClass,
        className,
        center && `${rootClass}--center`,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default PageContainer;
