import classNames from 'classnames';
import React from 'react';

import ErrorMessage from './ErrorMessage';

import './ErrorMessageBox.scss';

const rootClass = 'ErrorMessageBox';

const ErrorMessageBox = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <ErrorMessage className={classNames(rootClass, className)}>
      {children}
    </ErrorMessage>
  );
};

export default ErrorMessageBox;
