/**
 * @generated SignedSource<<6358ffec7097d4f5d570ec678080650a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderSearchBar_userProfile$data = {
  readonly displayName: string | null;
  readonly handle: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "HeaderSearchBar_userProfile";
};
export type HeaderSearchBar_userProfile$key = {
  readonly " $data"?: HeaderSearchBar_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderSearchBar_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "01318fb43971209e26bf93a60a16d771";

export default node;
