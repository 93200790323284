import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import Button from 'components/Button';

import type { VerifyEmailControl_SendEmailVerificationMutation as Mutation } from './__generated__/VerifyEmailControl_SendEmailVerificationMutation.graphql';
import type { VerifyEmailControl_viewer$data as Viewer } from './__generated__/VerifyEmailControl_viewer.graphql';

const mutation = graphql`
  mutation VerifyEmailControl_SendEmailVerificationMutation(
    $input: SendUserEmailVerificationInput!
  ) {
    sendUserEmailVerificationOrError(input: $input) {
      ... on SendUserEmailVerificationPayload {
        __typename
      }

      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  viewer: Viewer;
}

function VerifyEmailControl({ viewer }: Props) {
  const [busy, setBusy] = useState(false);
  const [mutate] = useMutation<Mutation>(mutation, {
    variables: { input: { userId: viewer.user!.id } },
  });

  const onClick = async () => {
    try {
      setBusy(true);
      await mutate();
    } finally {
      setBusy(false);
    }
  };

  return (
    <Button busy={busy} onClick={onClick}>
      <FormattedMessage
        id="verifyEmailControl.sendEmailVerification"
        defaultMessage="Send me the email again"
      />
    </Button>
  );
}

export default createFragmentContainer(VerifyEmailControl, {
  viewer: graphql`
    fragment VerifyEmailControl_viewer on Viewer {
      user {
        id
      }
    }
  `,
});
