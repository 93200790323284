/**
 * @generated SignedSource<<be64225cf4c3ee7e9b80004c0aa50f01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type DevicePresence = "offline" | "online";
export type UpdateDevicePresenceSubscriptionInput = {
  clientSubscriptionId?: string | null;
  deviceId: String;
  tenantId: String;
};
export type DeviceStatusPresenceSubscription$variables = {
  input: UpdateDevicePresenceSubscriptionInput;
};
export type DeviceStatusPresenceSubscription$data = {
  readonly updateDevicePresence: {
    readonly device: {
      readonly error: {
        readonly __typename: "DeviceError";
      } | null;
      readonly presence: DevicePresence | null;
      readonly state: {
        readonly __typename: "DeviceState";
      } | null;
    } | null;
  } | null;
};
export type DeviceStatusPresenceSubscription = {
  response: DeviceStatusPresenceSubscription$data;
  variables: DeviceStatusPresenceSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "presence",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceError",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceState",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceStatusPresenceSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDevicePresenceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevicePresence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceStatusPresenceSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDevicePresenceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevicePresence",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a6e89e1afdc20c5909eb839481bf45a",
    "id": null,
    "metadata": {},
    "name": "DeviceStatusPresenceSubscription",
    "operationKind": "subscription",
    "text": "subscription DeviceStatusPresenceSubscription(\n  $input: UpdateDevicePresenceSubscriptionInput!\n) {\n  updateDevicePresence(input: $input) {\n    device {\n      presence\n      error {\n        __typename\n      }\n      state {\n        __typename\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "79499c97608aa8aeb71fe6d010d7c96b";

export default node;
