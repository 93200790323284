import {
  ConnectionHandler,
  DataID,
  RecordProxy,
  RecordSourceSelectorProxy,
  Variables,
} from 'relay-runtime';

export declare type GenericConnection<T> = {
  edges: ReadonlyArray<{
    node: T | null;
  } | null> | null;
};

declare type InsertNodeOptions = {
  parentId: DataID;
  connectionKey: string;
  rootFieldName: string;
  edgeName: string;
  rangeBehavior?: 'prepend' | 'append';
  connectionFilters?: Variables;
};

declare type DeleteNodeOptions = {
  parentId: DataID;
  connectionKey: string;
  deleteId: string;
  connectionFilters?: Variables;
};

export function getNodes<T>(
  connection: GenericConnection<T> | null | undefined,
): NonNullable<T>[] {
  return ((connection == null ? undefined : connection.edges) || []).map(
    (i: { node: any }) => i.node,
  );
}

export function insertNode(
  store: RecordSourceSelectorProxy<object>,
  options: InsertNodeOptions,
): RecordProxy<object> | null {
  const {
    parentId,
    connectionKey,
    rootFieldName,
    edgeName,
    rangeBehavior,
    connectionFilters,
  } = options;

  const entityRecord = store.get(parentId);
  if (entityRecord) {
    const connectionRecord = ConnectionHandler.getConnection(
      entityRecord,
      connectionKey,
      connectionFilters,
    );
    const payload = store.getRootField(rootFieldName);

    const noteEdge = payload?.getLinkedRecord(edgeName);

    if (connectionRecord) {
      const newEdge = ConnectionHandler.buildConnectionEdge(
        store,
        connectionRecord,
        noteEdge,
      );

      if (newEdge) {
        if (rangeBehavior === 'prepend')
          ConnectionHandler.insertEdgeBefore(connectionRecord, newEdge);
        else ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        return newEdge;
      }
    }
  }

  return null;
}

export function deleteNode(
  store: RecordSourceSelectorProxy<object>,
  options: DeleteNodeOptions,
) {
  const { parentId, connectionKey, deleteId, connectionFilters } = options;

  const nodeRecord = store.get(parentId);
  if (nodeRecord) {
    const connectionRecord = ConnectionHandler.getConnection(
      nodeRecord,
      connectionKey,
      connectionFilters,
    );

    if (connectionRecord)
      ConnectionHandler.deleteNode(connectionRecord, deleteId);
  }
}
