/**
 * @generated SignedSource<<5b0f7538be1d440bdc1d49b93c4c79c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Users_MembersPageQuery$variables = {
  tenantSlug: string;
};
export type Users_MembersPageQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"MembersPage_tenant">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"MembersPage_viewer">;
  } | null;
};
export type Users_MembersPageQuery = {
  response: Users_MembersPageQuery$data;
  variables: Users_MembersPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Users_MembersPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembersPage_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MembersPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Users_MembersPageQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numUsers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numInvitations",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "TenantUserConnection",
            "kind": "LinkedField",
            "name": "userAssociationConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantUser",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserProfile",
                        "kind": "LinkedField",
                        "name": "userProfile",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pictureUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "userAssociationConnection(first:2147483647)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_userAssociationConnection",
            "kind": "LinkedHandle",
            "name": "userAssociationConnection"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "InvitationConnection",
            "kind": "LinkedField",
            "name": "invitationConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InvitationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Invitation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tenant",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": "invitationConnection(first:2147483647)"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_invitationConnection",
            "kind": "LinkedHandle",
            "name": "invitationConnection"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07839e353ccd8f338e43e6a4d90e21d5",
    "id": null,
    "metadata": {},
    "name": "Users_MembersPageQuery",
    "operationKind": "query",
    "text": "query Users_MembersPageQuery(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...MembersPage_tenant\n    id\n  }\n  viewer {\n    ...MembersPage_viewer\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment InviteMemberControl_tenant on Tenant {\n  currentUser {\n    role\n    id\n  }\n  ...InviteMemberModal_tenant\n}\n\nfragment InviteMemberModal_tenant on Tenant {\n  id\n}\n\nfragment MemberRoleCell_invitation on Invitation {\n  id\n  ...ResendInvitationMenuItem_invitation\n}\n\nfragment MemberRoleCell_tenant on Tenant {\n  id\n  currentUser {\n    id\n    role\n  }\n}\n\nfragment MemberRoleCell_tenantUser on TenantUser {\n  id\n  role\n  userProfile {\n    id\n  }\n}\n\nfragment MemberUserCell_userProfile on UserProfile {\n  ...Avatar_userProfile\n}\n\nfragment MembersPage_tenant on Tenant {\n  numUsers\n  numInvitations\n  ...InviteMemberControl_tenant\n  ...MembersTable_tenant\n}\n\nfragment MembersPage_viewer on Viewer {\n  ...MembersTable_viewer\n}\n\nfragment MembersTable_tenant on Tenant {\n  userAssociationConnection(first: 2147483647) {\n    edges {\n      node {\n        id\n        userProfile {\n          id\n          displayName\n          email\n          ...MemberUserCell_userProfile\n        }\n        role\n        ...MemberRoleCell_tenantUser\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  invitationConnection(first: 2147483647) {\n    edges {\n      node {\n        id\n        email\n        ...MemberRoleCell_invitation\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...MemberRoleCell_tenant\n}\n\nfragment MembersTable_viewer on Viewer {\n  user {\n    profile {\n      id\n    }\n    id\n  }\n}\n\nfragment ResendInvitationMenuItem_invitation on Invitation {\n  email\n  tenant {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8abac35426098a8316bcdd79d445c1b";

export default node;
