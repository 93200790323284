/**
 * @generated SignedSource<<5b3774456ecd17570f1512f29e1a9775>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunSorting = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "ENDED_AT_ASC" | "ENDED_AT_DESC" | "NAME_ASC" | "NAME_DESC" | "NAME_LENGTH_ASC" | "NAME_LENGTH_DESC" | "STARTED_AT_ASC" | "STARTED_AT_DESC";
export type RunStatus = "aborted" | "chip_check" | "failed" | "finished" | "finished_chip_check" | "planned" | "planned_chip_check" | "processing" | "running" | "uploading";
export type RunSearchListQuery$variables = {
  count: number;
  cursor?: string | null;
  projectHandles?: ReadonlyArray<any> | null;
  search?: string | null;
  sort?: ReadonlyArray<RunSorting> | null;
  statuses?: ReadonlyArray<RunStatus> | null;
  tagHandles?: ReadonlyArray<any> | null;
  tenantSlug: string;
  userProfileHandles?: ReadonlyArray<any> | null;
};
export type RunSearchListQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"RunSearchList_tenant">;
  } | null;
};
export type RunSearchListQuery = {
  response: RunSearchListQuery$data;
  variables: RunSearchListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectHandles"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statuses"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagHandles"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userProfileHandles"
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "createdBy",
    "variableName": "userProfileHandles"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "project",
    "variableName": "projectHandles"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "statuses"
  },
  {
    "kind": "Variable",
    "name": "tag",
    "variableName": "tagHandles"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RunSearchListQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RunSearchList_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RunSearchListQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "RunConnection",
            "kind": "LinkedField",
            "name": "runConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RunEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Run",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pictureUrl",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RunLibraryAssociation",
                        "kind": "LinkedField",
                        "name": "libraryAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Library",
                            "kind": "LinkedField",
                            "name": "library",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Sample",
                                "kind": "LinkedField",
                                "name": "sample",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeviceState",
                            "kind": "LinkedField",
                            "name": "state",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "code",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "progress",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timeRemainingSeconds",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DeviceError",
                            "kind": "LinkedField",
                            "name": "error",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "details",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [
              "status",
              "sort",
              "search",
              "createdBy",
              "project",
              "tag"
            ],
            "handle": "connection",
            "key": "Tenant_runConnection",
            "kind": "LinkedHandle",
            "name": "runConnection"
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2671b325ff27e08cfa4a986ae45cc7db",
    "id": null,
    "metadata": {},
    "name": "RunSearchListQuery",
    "operationKind": "query",
    "text": "query RunSearchListQuery(\n  $tenantSlug: String!\n  $count: Int!\n  $cursor: String\n  $statuses: [RunStatus!]\n  $sort: [RunSorting!]\n  $search: String\n  $userProfileHandles: [IdOrHandle!]\n  $tagHandles: [IdOrHandle!]\n  $projectHandles: [IdOrHandle!]\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...RunSearchList_tenant\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment RunList_runs on Run {\n  id\n  handle\n  name\n  startedAt\n  endedAt\n  status\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n  libraryAssociations {\n    library {\n      name\n      sample {\n        name\n        id\n      }\n      id\n    }\n  }\n  project {\n    handle\n    name\n    id\n  }\n  ...RunProgress_run\n}\n\nfragment RunProgress_run on Run {\n  status\n  device {\n    state {\n      label\n    }\n    ...DeviceProgress_device\n    id\n  }\n}\n\nfragment RunSearchList_tenant on Tenant {\n  runConnection(status: $statuses, sort: $sort, search: $search, createdBy: $userProfileHandles, project: $projectHandles, tag: $tagHandles, first: $count, after: $cursor) {\n    edges {\n      node {\n        ...RunList_runs\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "85d974a57720312d2eb05023de416678";

export default node;
