import loadSegment from 'analytics.js-loader';

const analytics = loadSegment({
  writeKey: window.qsiConfig.SEGMENT_WRITE_KEY!,
  skipPageCall: true,
});

// We wrap the analytics library to reliably avoid leaking
// query parameters

function redactUrl(url: string) {
  return url.slice(0, url.indexOf('?'));
}

function getPageOverrides() {
  return {
    search: '[redacted]',
    url: redactUrl(window.location.href),
    referrer: redactUrl(document.referrer),
  };
}

function setOptions(opts: { context?: any }) {
  // eslint-disable-next-line no-param-reassign
  opts.context = opts.context || {};
  const { context } = opts;
  context.page = {
    ...context.page,
    ...getPageOverrides(),
  };
}

analytics.page(getPageOverrides());

function identify(
  userId: string,
  traits: Obj,
  opts = {},
  callback?: () => void,
) {
  setOptions(opts);
  return analytics.identify(userId, traits, opts, callback);
}

function track(event: string, props: Obj, opts = {}, callback?: () => void) {
  setOptions(opts);
  return analytics.track(event, props, opts, callback);
}

function group(groupId: string, traits = {}) {
  return analytics.group(groupId, traits);
}

export default {
  track,
  group,
  identify,
  reset: () => analytics.reset(),
};
