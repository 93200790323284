/**
 * @generated SignedSource<<1e74efa6d28a18d1fcb621042532a129>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignupPage_invitation$data = {
  readonly email: string | null;
  readonly tenantInfo: {
    readonly name: string | null;
    readonly slug: string | null;
  } | null;
  readonly " $fragmentType": "SignupPage_invitation";
};
export type SignupPage_invitation$key = {
  readonly " $data"?: SignupPage_invitation$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignupPage_invitation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignupPage_invitation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantInfo",
      "kind": "LinkedField",
      "name": "tenantInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InvitationInfo",
  "abstractKey": null
};

(node as any).hash = "c9c0bb175c3ef7460bf252eeaa0b61d4";

export default node;
