import classNames from 'classnames';
import React from 'react';
import Form from 'react-formal';
import type { AnyObjectSchema } from 'yup';

import Button from 'components/Button';
import ErrorMessageBox from 'components/ErrorMessageBox';
import FormattedLabel from 'components/FormattedLabel';
import RelayForm, {
  RelayFormProps,
  RelayOperationWithError,
} from 'components/RelayForm';
import './AuthForm.scss';

interface Props<
  T extends RelayOperationWithError,
  TSchema extends AnyObjectSchema,
> extends Partial<RelayFormProps<T, TSchema>> {
  children: React.ReactNode;
}

const rootClass = 'AuthForm';

function AuthForm<
  T extends RelayOperationWithError,
  TSchema extends AnyObjectSchema,
>({ children, ...props }: Props<T, TSchema>) {
  let Component: React.ComponentType<any>;
  let extraProps: any;

  if (props.mutation) {
    Component = RelayForm;
    extraProps = { addServerErrorsToFormErrors: true };
  } else {
    Component = Form;
    extraProps = {};
  }

  return (
    <Component
      {...props}
      {...extraProps}
      className={classNames(rootClass, props.className)}
    >
      <Form.Message for={[]}>
        {(errors: any[0]) => (
          <ErrorMessageBox className={`${rootClass}__error`}>
            {errors && <FormattedLabel label={errors[0]} />}
          </ErrorMessageBox>
        )}
      </Form.Message>
      {children}
    </Component>
  );
}

const AuthFormSubmit = ({
  finished,
  children,
  ...rest
}: {
  finished?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Button
      {...rest}
      type="submit"
      disabled={finished}
      style={{
        marginTop: '4rem',
        width: '100%',
        height: '48px',
        borderRadius: '24px',
        fontSize: 'var(--font-size-xl)',
      }}
    >
      {children}
    </Button>
  );
};

AuthForm.Submit = AuthFormSubmit;
export default AuthForm;
