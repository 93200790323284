import classNames from 'classnames';
import React from 'react';
import {
  RiCheckFill as CheckMarkSmall,
  RiCloseFill as InvalidMarkSmall,
} from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import {
  AlphaNumericPasswordRegex,
  LowercaseRegex,
  NumericRegex,
  SpecialCharacterRegex,
  UppercaseRegex,
} from 'utils/regex';

interface Props {
  password: string;
}

import './PasswordRules.scss';

const rootClass = 'password_rules';

const PasswordRules = ({ password }: Props) => {
  if (!password?.match(AlphaNumericPasswordRegex)) {
    return (
      <div className={classNames(`${rootClass}`)}>
        <ul>
          <li>
            <FormattedMessage
              id="messages.passwordRequirements"
              defaultMessage="Password must be:"
            />
            <ul>
              <li>
                <FormattedMessage
                  id="messages.passwordRequirements.length"
                  defaultMessage="at least 8 characters long"
                />
                {password?.length >= 8 ? (
                  <CheckMarkSmall
                    className={classNames(`${rootClass}__icon--success`)}
                  />
                ) : (
                  <InvalidMarkSmall
                    className={classNames(`${rootClass}__icon--error`)}
                  />
                )}
              </li>
            </ul>
          </li>
        </ul>
        <ul>
          <li>
            <FormattedMessage
              id="messages.passwordRequirements.contain"
              defaultMessage="Password must contain at least:"
            />
            <ul>
              <li>
                <FormattedMessage
                  id="messages.passwordRequirements.uppercase"
                  defaultMessage="one uppercase"
                />
                {password?.match(UppercaseRegex) ? (
                  <CheckMarkSmall
                    className={classNames(`${rootClass}__icon--success`)}
                  />
                ) : (
                  <InvalidMarkSmall
                    className={classNames(`${rootClass}__icon--error`)}
                  />
                )}
              </li>
              <li>
                <FormattedMessage
                  id="messages.passwordRequirements.lowercase"
                  defaultMessage="one lowercase"
                />
                {password?.match(LowercaseRegex) ? (
                  <CheckMarkSmall
                    className={classNames(`${rootClass}__icon--success`)}
                  />
                ) : (
                  <InvalidMarkSmall
                    className={classNames(`${rootClass}__icon--error`)}
                  />
                )}
              </li>
              <li>
                <FormattedMessage
                  id="messages.passwordRequirements.number"
                  defaultMessage="one number"
                />
                {password?.match(NumericRegex) ? (
                  <CheckMarkSmall
                    className={classNames(`${rootClass}__icon--success`)}
                  />
                ) : (
                  <InvalidMarkSmall
                    className={classNames(`${rootClass}__icon--error`)}
                  />
                )}
              </li>
              <li>
                <FormattedMessage
                  id="messages.passwordRequirements.special"
                  defaultMessage="one special character"
                />
                {password?.match(SpecialCharacterRegex) ? (
                  <CheckMarkSmall
                    className={classNames(`${rootClass}__icon--success`)}
                  />
                ) : (
                  <InvalidMarkSmall
                    className={classNames(`${rootClass}__icon--error`)}
                  />
                )}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }

  return null;
};

export default PasswordRules;
