import type { RouteMatch, RouteProps } from 'found';
import HttpError from 'found/HttpError';
import { LDClient } from 'launchdarkly-js-client-sdk';
import React, { ComponentClass, ComponentType } from 'react';
import type { GraphQLTaggedNode } from 'react-relay';

import Route, { createRender } from './Route';

type Getter<T> = (
  this: LaunchDarklyRoute,
  match: RouteMatch,
) => T | Promise<T>;

interface LaunchDarklyRouteVariation {
  Component?: React.ComponentType<any>;
  getComponent?: Getter<React.ComponentType<any>>;

  query?: GraphQLTaggedNode;
  getQuery?: (
    match: RouteMatch,
  ) => GraphQLTaggedNode | Promise<GraphQLTaggedNode>;
}

interface LaunchDarklyRouteProps extends RouteProps {
  getVariation?: (
    ldClient: LDClient,
  ) => LaunchDarklyRouteVariation | false | null | undefined;
}

function getRouteValueGetter<T>(
  getVariation: NonNullable<LaunchDarklyRouteProps['getVariation']>,
  ldUnavailableFallback: T,
  getGetter: (variation: LaunchDarklyRouteVariation) => Getter<T> | undefined,
  getValue: (variation: LaunchDarklyRouteVariation) => T | undefined,
) {
  function routeValueGetter(this: LaunchDarklyRoute, match: RouteMatch) {
    const ld = match.context.launchDarkly;
    if (!ld.client) {
      // this flag is checked in Resolver after all routes are resolved
      // if true it redirects to itself, where the LD client will now be ready
      ld.waitingForClient = true;
      return ldUnavailableFallback;
    }

    const variation = getVariation(ld.client);
    if (!variation) {
      throw new HttpError(404);
    }

    const getter = getGetter(variation);

    return getter ? getter.call(this, match) : getValue(variation);
  }

  return routeValueGetter;
}

function createLdRender({ prerender, render, renderFetched }: RouteProps) {
  const baseRender = createRender({ prerender, render, renderFetched });

  return (renderArgs: any) => {
    const { match } = renderArgs;

    return baseRender({
      ...renderArgs,
      ldClient: match.context.launchDarkly.client,
    });
  };
}

class LaunchDarklyRoute extends Route {
  constructor({
    render,
    prerender,
    renderFetched,
    ...props
  }: LaunchDarklyRouteProps) {
    const variationProps = props.getVariation && {
      getQuery: getRouteValueGetter(
        props.getVariation,
        null,
        (v) => v.getQuery,
        (v) => v.query,
      ) as any,
      getComponent: getRouteValueGetter<ComponentType<any>>(
        props.getVariation,
        () => null as any,
        (v) => v.getComponent,
        (v) => v.Component,
      ) as any,
    };

    super({
      ...props,
      render: createLdRender({ render, prerender, renderFetched }),
      ...variationProps,
    });
  }
}

export default LaunchDarklyRoute as ComponentClass<LaunchDarklyRouteProps>;
