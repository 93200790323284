import { HttpError } from 'found';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';

import { ModalProvider } from 'components/ModalProvider';

import AuthProvider from './AuthProvider';
import WebTokenStorage from './WebTokenStorage';
import configureStore from './configureStore';
import createAppRouter from './createAppRouter';
import LaunchDarklyManager from './utils/LaunchDarklyManager';

interface Props {
  renderError({ error }: { error: HttpError }): React.ReactNode;
}

// this isn't a component
export default function createApplication({ renderError }: Props) {
  const store = configureStore();

  const Router = createAppRouter({
    store,
    renderError,
  });

  // TODO: If we use SSR, we should get this preferably from the server.
  const { navigator } = window;
  const language =
    (navigator.languages && navigator.languages[0]) || navigator.language;

  const locale = language.split('-')[0];

  const tokenStorage = new WebTokenStorage(store);
  const launchDarkly = new LaunchDarklyManager();

  return (
    <IntlProvider locale={locale} textComponent={React.Fragment}>
      <ModalProvider>
        <AuthProvider tokenStorage={tokenStorage}>
          {({ environment, auth, viewerLocalId }) => (
            <Router
              environment={environment}
              auth={auth}
              viewerLocalId={viewerLocalId}
              launchDarkly={launchDarkly}
            />
          )}
        </AuthProvider>
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: 'Toast',
            duration: 5000,
          }}
        />
      </ModalProvider>
    </IntlProvider>
  );
}
