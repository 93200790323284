import { useRouter } from 'found';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, useFragment } from 'react-relay';
import { useMutation } from 'react-relay-mutation';
import { SelectorStoreUpdater } from 'relay-runtime';

import { deleteNode } from 'utils/graphql';
import { useTenantRoutes } from 'utils/tenantRoutes';

import type {
  useNotificationDataMutation as MarkAsReadMutation,
  useNotificationDataMutation$data as useNotificationDataMutationResponse,
} from './__generated__/useNotificationDataMutation.graphql';
import type {
  useNotificationData_notification$key as Notification,
  NotificationType,
} from './__generated__/useNotificationData_notification.graphql';
import type { useNotificationData_tenant$key as Tenant } from './__generated__/useNotificationData_tenant.graphql';

export interface useNotificationDataProps {
  notification: Notification;
  tenant: Tenant;
}

const markAsReadMutation = graphql`
  mutation useNotificationDataMutation($input: MarkNotificationAsReadInput!) {
    markNotificationAsReadOrError(input: $input) {
      ... on MarkNotificationAsReadPayload {
        notificationEdge {
          node {
            readAt
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const useNotificationData = (props: useNotificationDataProps) => {
  const notification = useFragment<Notification>(
    graphql`
      fragment useNotificationData_notification on Notification {
        notificationType

        ... on Node {
          id
        }

        ... on AnalysisNotification {
          analysis {
            handle
            name

            project {
              handle
            }
          }
        }
        ... on DeviceAlarmNotification {
          device {
            handle
            name
          }
        }
        ... on LibraryNotification {
          library {
            handle
            name

            project {
              handle
            }
          }
        }
        ... on RunNotification {
          run {
            handle
            name

            project {
              handle
            }
          }
        }
        ... on SampleNotification {
          sample {
            handle
            name

            project {
              handle
            }
          }
        }
        ... on ProjectNotification {
          project {
            handle
            name
          }
        }
        ... on ReferenceNotification {
          reference {
            handle
            name
          }
        }
        ... on NewsNotification {
          newsItem {
            handle
            title
          }
        }
        ... on WorkflowReleaseNotification {
          workflowRelease {
            versionName
            workflow {
              name
              handle
            }
          }
        }
      }
    `,
    props.notification,
  ) as GetDeepData<Notification>;
  const tenant = useFragment<Tenant>(
    graphql`
      fragment useNotificationData_tenant on Tenant {
        currentUser {
          id
        }
      }
    `,
    props.tenant,
  ) as GetDeepData<Tenant>;
  const routes = useTenantRoutes();
  const { router } = useRouter();
  const parentId = tenant.currentUser.id;

  const notificationData = useMemo(() => {
    const analysisRoute = () =>
      routes.analysis({
        analysis: notification.analysis,
        project: notification.analysis.project,
      });
    const libraryRoute = () =>
      routes.library({
        library: notification.library,
        project: notification.library.project,
      });
    const runRoute = () =>
      routes.run({
        run: notification.run,
        project: notification.run.project,
      });
    const sampleRoute = () =>
      routes.specimen({
        sample: notification.sample,
        project: notification.sample.project,
      });
    const projectRoute = () =>
      routes.project({
        project: notification.project,
      });
    const deviceAlarmRoute = () =>
      routes.device({
        device: notification.device,
      });
    // const noteRoute = () =>
    //   routes.note({
    //     note: notification.note,
    //   });
    const newsRoute = () =>
      routes.newsArticle({ news: notification.newsItem });
    const noteRoute = () => routes.project({ project: notification.project });
    const referenceRoute = () =>
      routes.reference({
        reference: notification.reference,
      });
    const workflowRoute = () =>
      routes.developersWorkflow({
        workflow: notification.workflowRelease.workflow,
      });

    const notificationRoutes: Record<NotificationType, () => string> = {
      ANALYSIS_ABORTED: analysisRoute,
      ANALYSIS_COMPLETED: analysisRoute,
      ANALYSIS_CREATED: analysisRoute,
      ANALYSIS_FAILED: analysisRoute,
      LIBRARY_ABORTED: libraryRoute,
      LIBRARY_CREATED: libraryRoute,
      LIBRARY_FAILED: libraryRoute,
      LIBRARY_READY: libraryRoute,
      RUN_ABORTED: runRoute,
      RUN_CREATED: runRoute,
      RUN_FAILED: runRoute,
      RUN_FINISHED: runRoute,
      SAMPLE_CREATED: sampleRoute,
      PROJECT_CREATED: projectRoute,
      DEVICE_ALARM: deviceAlarmRoute,
      NOTE_CREATED: noteRoute,
      NEWS_CREATED: newsRoute,
      REFERENCE_GENOME_INDEXING_FAILED: referenceRoute,
      REFERENCE_GENOME_READY: referenceRoute,
      REFERENCE_PROTEOME_VALIDATING_FAILED: referenceRoute,
      REFERENCE_PROTEOME_READY: referenceRoute,
      WORKFLOW_RELEASE_CREATED: workflowRoute,
      WORKFLOW_RELEASE_UPDATED: workflowRoute,
    };

    const notificationMessages: Record<
      NotificationType,
      () => React.ReactNode
    > = {
      ANALYSIS_ABORTED: () => (
        <FormattedMessage
          id="notificationsMenu.ANALYSIS_ABORTED"
          defaultMessage='Analysis "{name}" aborted'
          values={{
            name: notification.analysis.name,
          }}
        />
      ),
      ANALYSIS_COMPLETED: () => (
        <FormattedMessage
          id="notificationsMenu.ANALYSIS_COMPLETED"
          defaultMessage='Analysis "{name}" completed'
          values={{
            name: notification.analysis.name,
          }}
        />
      ),
      ANALYSIS_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.ANALYSIS_CREATED"
          defaultMessage='Analysis "{name}" created'
          values={{
            name: notification.analysis.name,
          }}
        />
      ),
      ANALYSIS_FAILED: () => (
        <FormattedMessage
          id="notificationsMenu.ANALYSIS_FAILED"
          defaultMessage='Analysis "{name}" failed'
          values={{
            name: notification.analysis.name,
          }}
        />
      ),
      LIBRARY_ABORTED: () => (
        <FormattedMessage
          id="notificationsMenu.LIBRARY_ABORTED"
          defaultMessage='Library "{name}" aborted'
          values={{
            name: notification.library.name,
          }}
        />
      ),
      LIBRARY_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.LIBRARY_CREATED"
          defaultMessage='Library "{name}" created'
          values={{
            name: notification.library.name,
          }}
        />
      ),
      LIBRARY_FAILED: () => (
        <FormattedMessage
          id="notificationsMenu.LIBRARY_FAILED"
          defaultMessage='Library "{name}" failed'
          values={{
            name: notification.library.name,
          }}
        />
      ),
      LIBRARY_READY: () => (
        <FormattedMessage
          id="notificationsMenu.LIBRARY_READY"
          defaultMessage='Library "{name}" ready'
          values={{
            name: notification.library.name,
          }}
        />
      ),
      RUN_ABORTED: () => (
        <FormattedMessage
          id="notificationsMenu.RUN_ABORTED"
          defaultMessage='Run "{name}" aborted'
          values={{
            name: notification.run.name,
          }}
        />
      ),
      RUN_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.RUN_CREATED"
          defaultMessage='Run "{name}" created'
          values={{
            name: notification.run.name,
          }}
        />
      ),
      RUN_FAILED: () => (
        <FormattedMessage
          id="notificationsMenu.RUN_FAILED"
          defaultMessage='Run "{name}" failed'
          values={{
            name: notification.run.name,
          }}
        />
      ),
      RUN_FINISHED: () => (
        <FormattedMessage
          id="notificationsMenu.RUN_FINISHED"
          defaultMessage='Run "{name}" finished'
          values={{
            name: notification.run.name,
          }}
        />
      ),
      SAMPLE_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.SAMPLE_CREATED"
          defaultMessage='Sample "{name}" created'
          values={{
            name: notification.sample.name,
          }}
        />
      ),
      PROJECT_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.PROJECT_CREATED"
          defaultMessage='Project "{name}" created'
          values={{
            name: notification.project.name,
          }}
        />
      ),
      NEWS_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.NEWS_CREATED"
          defaultMessage="News article: {title} added"
          values={{
            title: notification.newsItem.title,
          }}
        />
      ),
      NOTE_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.NOTE_CREATED"
          defaultMessage="Note created"
        />
      ),
      DEVICE_ALARM: () => (
        <FormattedMessage
          id="notificationsMenu.DEVICE_ALARM"
          defaultMessage='Device "{name}" raised an alarm'
          values={{
            name: notification.device.name,
          }}
        />
      ),
      REFERENCE_GENOME_INDEXING_FAILED: () => (
        <FormattedMessage
          id="notificationsMenu.REFERENCE_GENOME_INDEXING_FAILED"
          defaultMessage='Reference "{name}" indexing failed'
          values={{
            name: notification.reference.name,
          }}
        />
      ),
      REFERENCE_GENOME_READY: () => (
        <FormattedMessage
          id="notificationsMenu.REFERENCE_GENOME_READY"
          defaultMessage='Reference "{name}" ready'
          values={{
            name: notification.reference.name,
          }}
        />
      ),
      REFERENCE_PROTEOME_VALIDATING_FAILED: () => (
        <FormattedMessage
          id="notificationsMenu.REFERENCE_PROTEOME_VALIDATING_FAILED"
          defaultMessage='Reference "{name}" failed validation'
          values={{
            name: notification.reference.name,
          }}
        />
      ),
      REFERENCE_PROTEOME_READY: () => (
        <FormattedMessage
          id="notificationsMenu.REFERENCE_PROTEOME_READY"
          defaultMessage='Reference "{name}" ready'
          values={{
            name: notification.reference.name,
          }}
        />
      ),
      WORKFLOW_RELEASE_CREATED: () => (
        <FormattedMessage
          id="notificationsMenu.WORKFLOW_RELEASE_CREATED"
          defaultMessage="Workflow Release {name}: created"
          values={{
            name: notification.workflowRelease.workflow.name,
          }}
        />
      ),
      WORKFLOW_RELEASE_UPDATED: () => (
        <FormattedMessage
          id="notificationsMenu.WORKFLOW_RELEASE_CREATED"
          defaultMessage="Workflow Release {name}: updated"
          values={{
            name: notification.workflowRelease.workflow.name,
          }}
        />
      ),
    };

    const route = notificationRoutes[notification.notificationType];
    const message = notificationMessages[notification.notificationType];

    if (!route || !message) {
      throw new Error(
        `Invalid notification type was added: ${notification.notificationType}`,
      );
    }

    return { route, message };
  }, [notification, routes]);

  const updater: SelectorStoreUpdater<useNotificationDataMutationResponse> = (
    store,
  ) => {
    const connectionFilters = { hideRead: true };
    deleteNode(store, {
      parentId,
      connectionKey: 'TenantUser_notificationConnection',
      connectionFilters,
      deleteId: notification.id,
    });
    const numNotifications = store
      .get<{ numNotifications: number }>(parentId)!
      .getValue('numNotifications', connectionFilters);
    store
      .get(parentId)!
      .setValue(numNotifications - 1, 'numNotifications', connectionFilters);
  };

  const [mutate] = useMutation<MarkAsReadMutation>(markAsReadMutation, {
    variables: { input: { id: notification.id } },
    updater,
    optimisticUpdater: updater,
  });

  return {
    mutate: () => mutate(),
    route: () => {
      router.push(notificationData.route());
      mutate();
    },
    message: notificationData.message(),
  };
};

export default useNotificationData;
