/**
 * @generated SignedSource<<032d235fa6b6f13098e4804e5db567d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnalysisJobUpdatedInput = {
  analysisJobId: String;
  clientSubscriptionId?: string | null;
};
export type AnalysisProgressSubscription$variables = {
  input: AnalysisJobUpdatedInput;
};
export type AnalysisProgressSubscription$data = {
  readonly analysisJobUpdated: {
    readonly analysisJob: {
      readonly " $fragmentSpreads": FragmentRefs<"AnalysisProgress_analysisJob">;
    } | null;
  } | null;
};
export type AnalysisProgressSubscription = {
  response: AnalysisProgressSubscription$data;
  variables: AnalysisProgressSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnalysisProgressSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisJobUpdatedPayload",
        "kind": "LinkedField",
        "name": "analysisJobUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisJob",
            "kind": "LinkedField",
            "name": "analysisJob",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AnalysisProgress_analysisJob"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnalysisProgressSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisJobUpdatedPayload",
        "kind": "LinkedField",
        "name": "analysisJobUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisJob",
            "kind": "LinkedField",
            "name": "analysisJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisJobStep",
                "kind": "LinkedField",
                "name": "steps",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "feaa45907b52c1f43da2b20c6c552649",
    "id": null,
    "metadata": {},
    "name": "AnalysisProgressSubscription",
    "operationKind": "subscription",
    "text": "subscription AnalysisProgressSubscription(\n  $input: AnalysisJobUpdatedInput!\n) {\n  analysisJobUpdated(input: $input) {\n    analysisJob {\n      ...AnalysisProgress_analysisJob\n      id\n    }\n  }\n}\n\nfragment AnalysisProgress_analysisJob on AnalysisJob {\n  id\n  status\n  steps {\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6379fc50b11d8c6463cb81ff5d2d97d";

export default node;
