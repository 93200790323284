/**
 * @generated SignedSource<<02ab6491c22256fa0b944ffbc6902b5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_logSectionQuery$variables = {
  analysisHandle: string;
};
export type Analyses_logSectionQuery$data = {
  readonly analysis: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisLogSection_analysis">;
  } | null;
};
export type Analyses_logSectionQuery = {
  response: Analyses_logSectionQuery$data;
  variables: Analyses_logSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "log",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_logSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisLogSection_analysis"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_logSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalysisJob",
            "kind": "LinkedField",
            "name": "job",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "numLogErrors",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "level",
                    "value": [
                      "error"
                    ]
                  }
                ],
                "kind": "ScalarField",
                "name": "numLogLines",
                "storageKey": "numLogLines(level:[\"error\"])"
              },
              {
                "alias": "numLogWarnings",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "level",
                    "value": [
                      "warning"
                    ]
                  }
                ],
                "kind": "ScalarField",
                "name": "numLogLines",
                "storageKey": "numLogLines(level:[\"warning\"])"
              },
              {
                "alias": "logAnnotations",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "level",
                    "value": [
                      "warning",
                      "error"
                    ]
                  }
                ],
                "concreteType": "LogLine",
                "kind": "LinkedField",
                "name": "logLines",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "level",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jobId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "time",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": "logLines(level:[\"warning\",\"error\"])"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24ee0e67abd322f7e9865dc977324078",
    "id": null,
    "metadata": {},
    "name": "Analyses_logSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_logSectionQuery(\n  $analysisHandle: String!\n) {\n  analysis(handle: $analysisHandle) {\n    ...AnalysisLogSection_analysis\n    id\n  }\n}\n\nfragment AnalysisLogSection_analysis on Analysis {\n  job {\n    log\n    numLogErrors: numLogLines(level: [error])\n    numLogWarnings: numLogLines(level: [warning])\n    logAnnotations: logLines(level: [warning, error]) {\n      level\n      log\n      jobId\n      time\n      id\n    }\n    startedAt\n    endedAt\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "236b2f9fcf674ec5ee766afd93584cb0";

export default node;
