import { useRouter } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Listbox from 'components/Listbox';
import createInfiniteList from 'components/utils/createInfiniteList';
import { getNodes } from 'utils/graphql';
import * as routes from 'utils/routes';

import type { SwitchTenantList_viewer$data as Viewer } from './__generated__/SwitchTenantList_viewer.graphql';

type Tenant = DeepNonNull<Viewer>['tenantConnection']['edges'][0]['node'];

interface Props {
  viewer: DeepNonNull<Viewer>;
  activeTenant?: string;
  handleSelect?: () => void;
}

export default createInfiniteList(
  ({ viewer, activeTenant, handleSelect }: Props) => {
    const { router } = useRouter();

    const onSelect = (tenant: Tenant) => {
      handleSelect?.();
      router.push(routes.tenant({ tenant }));
    };

    const tenants = getNodes(viewer.tenantConnection);

    return (
      <Listbox<Tenant>
        data={tenants}
        value={activeTenant}
        textField="name"
        dataKey="id"
        onClick={onSelect}
      />
    );
  },
  {
    viewer: graphql`
      fragment SwitchTenantList_viewer on Viewer {
        tenantConnection(first: $count, after: $cursor)
          @connection(key: "Viewer_tenantConnection") {
          edges {
            node {
              id
              name
              slug
            }
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query SwitchTenantListQuery($count: Int!, $cursor: String) {
        viewer {
          ...SwitchTenantList_viewer
        }
      }
    `,
  },
);
