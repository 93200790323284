/**
 * @generated SignedSource<<9ff94586460fa41efa8e468da08519b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Samples_samplePageQuery$variables = {
  sampleHandle: string;
};
export type Samples_samplePageQuery$data = {
  readonly sample: {
    readonly " $fragmentSpreads": FragmentRefs<"SamplePage_sample">;
  } | null;
};
export type Samples_samplePageQuery = {
  response: Samples_samplePageQuery$data;
  variables: Samples_samplePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sampleHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "sampleHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Samples_samplePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sample",
        "kind": "LinkedField",
        "name": "sample",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SamplePage_sample"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Samples_samplePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Sample",
        "kind": "LinkedField",
        "name": "sample",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followingStatus",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numLibraries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numRuns",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Analysis",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Library",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Run",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Sample",
                "abstractKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7537932e0bca3bdb2f1d807f5c138066",
    "id": null,
    "metadata": {},
    "name": "Samples_samplePageQuery",
    "operationKind": "query",
    "text": "query Samples_samplePageQuery(\n  $sampleHandle: String!\n) {\n  sample(handle: $sampleHandle) {\n    ...SamplePage_sample\n    id\n  }\n}\n\nfragment CopySampleControl_sample on Sample {\n  handle\n}\n\nfragment DeleteSampleControl_sample on Sample {\n  id\n  project {\n    id\n  }\n  tenant {\n    id\n  }\n}\n\nfragment EditSampleControl_sample on Sample {\n  handle\n}\n\nfragment FollowSampleControl_sample on Sample {\n  followingStatus\n  id\n}\n\nfragment SamplePageHeader_sample on Sample {\n  ... on Entity {\n    __isEntity: __typename\n    name\n    ... on Project {\n      handle\n    }\n    ... on Analysis {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Library {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Run {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Sample {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n  }\n  ...FollowSampleControl_sample\n  ...CopySampleControl_sample\n  ...EditSampleControl_sample\n  ...DeleteSampleControl_sample\n}\n\nfragment SamplePageTabs_sample on Sample {\n  handle\n  numAnalyses\n  numLibraries\n  numRuns\n  numNotes\n}\n\nfragment SamplePage_sample on Sample {\n  ...SamplePageHeader_sample\n  ...SamplePageTabs_sample\n}\n"
  }
};
})();

(node as any).hash = "f1253739e75aa9eaec9c4181b9bd52ed";

export default node;
