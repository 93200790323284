import type { Match } from 'found';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { RelayRenderArgs } from 'components/Route';
import { getSessionStore } from 'utils/SessionStore';
import { getNodes } from 'utils/graphql';

import type { protocolRedirectTenantQuery$data as RootResponse } from './__generated__/protocolRedirectTenantQuery.graphql';

function throwProtocolRedirect(tenantSlug: string) {
  throw new RedirectException({
    pathname: `/${tenantSlug}/protocols`,
  });
}

function protocolRedirect({ props }: RelayRenderArgs<RootResponse>) {
  if (!props) return;

  const [tenant] = getNodes(props.viewer!.tenantConnection);

  if (tenant?.slug) {
    throwProtocolRedirect(tenant.slug);
  }
}

function getQuery(match: Match) {
  const { tenant } = getSessionStore(match).getLastVisitedTenantAndProject();

  if (tenant) {
    throwProtocolRedirect(tenant.slug);
  }

  return graphql`
    query protocolRedirectTenantQuery {
      viewer {
        tenantConnection(first: 1) {
          edges {
            node {
              slug
            }
          }
        }
      }
    }
  `;
}

protocolRedirect.getQuery = getQuery;

export default protocolRedirect;
