import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import AnalysisList from 'components/AnalysisList';
import LibraryList from 'components/LibraryList';
import NoItemFound from 'components/NoItemFound';
import RunList from 'components/RunList';
import SampleList from 'components/SampleList';
import Section from 'components/Section';
import SectionHeader from 'components/SectionHeader';

import { entityMessages } from './SearchBar';
import SearchList from './SearchList';
import type { AllSearchList_tenant$data as Tenant } from './__generated__/AllSearchList_tenant.graphql';

import './AllSearchList.scss';

interface AllSearchListProps {
  tenant: DeepNonNull<Tenant>;
}

const rootClass = 'AllSearchList';

function AllSearchList({ tenant }: AllSearchListProps) {
  const intl = useIntl();

  if (
    tenant.sampleConnection.edges.length === 0 &&
    tenant.libraryConnection.edges.length === 0 &&
    tenant.runConnection.edges.length === 0 &&
    tenant.analysisConnection.edges.length === 0
  ) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage
            id="allSearchList.results"
            defaultMessage="results"
          />
        }
      />
    );
  }

  return (
    <div className={rootClass}>
      {tenant.sampleConnection.edges.length > 0 && (
        <Section className={classNames(`${rootClass}__search-section`)}>
          <SectionHeader
            className={classNames(`${rootClass}__search-section-header`)}
          >
            {intl.formatMessage(entityMessages.specimens)}
          </SectionHeader>
          <SearchList
            connection={tenant.sampleConnection}
            entityType="specimens"
            Component={SampleList}
            showMore
          />
        </Section>
      )}

      {tenant.libraryConnection.edges.length > 0 && (
        <Section className={classNames(`${rootClass}__search-section`)}>
          <SectionHeader
            className={classNames(`${rootClass}__search-section-header`)}
          >
            {intl.formatMessage(entityMessages.libraries)}
          </SectionHeader>
          <SearchList
            connection={tenant.libraryConnection}
            entityType="libraries"
            Component={LibraryList}
            showMore
          />
        </Section>
      )}

      {tenant.runConnection.edges.length > 0 && (
        <Section className={classNames(`${rootClass}__search-section`)}>
          <SectionHeader
            className={classNames(`${rootClass}__search-section-header`)}
          >
            {intl.formatMessage(entityMessages.runs)}
          </SectionHeader>
          <SearchList
            connection={tenant.runConnection}
            entityType="runs"
            Component={RunList}
            showMore
          />
        </Section>
      )}

      {tenant.analysisConnection.edges.length > 0 && (
        <Section className={classNames(`${rootClass}__search-section`)}>
          <SectionHeader
            className={classNames(`${rootClass}__search-section-header`)}
          >
            {intl.formatMessage(entityMessages.analyses)}
          </SectionHeader>
          <SearchList
            connection={tenant.analysisConnection}
            entityType="analyses"
            Component={AnalysisList}
            showMore
          />
        </Section>
      )}
    </div>
  );
}

export default createFragmentContainer(AllSearchList, {
  tenant: graphql`
    fragment AllSearchList_tenant on Tenant {
      name
      analysisConnection(
        search: $search
        sort: $analysisSort
        createdBy: $userProfileHandles
        project: $projectHandles
        tag: $tagHandles
        first: 5
      )
        # XXX: if the connection accumulates more items, they are shown when navigating back to this
        # page per https://relay.dev/docs/next/guided-tour/list-data/advanced-pagination/#custom-connection-state
        # until this is resolved, we name this connection differently, although less efficient
        @connection(key: "AllSearchList_analysisConnection") {
        edges {
          node {
            ...AnalysisList_analyses
          }
        }
      }
      sampleConnection(
        search: $search
        sort: $sampleSort
        createdBy: $userProfileHandles
        project: $projectHandles
        tag: $tagHandles
        first: 5
      ) @connection(key: "AllSearchList_sampleConnection") {
        edges {
          node {
            ...SampleList_samples
          }
        }
      }
      runConnection(
        search: $search
        sort: $runSort
        createdBy: $userProfileHandles
        project: $projectHandles
        tag: $tagHandles
        first: 5
      ) @connection(key: "AllSearchList_runConnection") {
        edges {
          node {
            ...RunList_runs
          }
        }
      }
      libraryConnection(
        search: $search
        sort: $librarySort
        createdBy: $userProfileHandles
        project: $projectHandles
        tag: $tagHandles
        first: 5
      ) @connection(key: "AllSearchList_libraryConnection") {
        edges {
          node {
            ...LibraryList_libraries
          }
        }
      }
    }
  `,
});
