import { defineMessages } from 'react-intl';

export default defineMessages({
  password: {
    id: 'resetPasswordPage.password',
    defaultMessage: 'Password',
  },
  newPassword: {
    id: 'resetPasswordPage.newPassword',
    defaultMessage: 'New Password',
  },
  confirmPassword: {
    id: 'resetPasswordPage.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  help: {
    id: 'resetPasswordPage.password.help',
    defaultMessage: 'Must be at least 8 characters long',
  },
  required: {
    id: 'resetPasswordPage.required',
    defaultMessage: 'Please enter a password',
  },
  strength: {
    id: 'resetPassword.strength',
    defaultMessage:
      'Password must be at least 8 characters long and contain one uppercase, one lowercase, one number and one special character',
  },
  mismatch: {
    id: 'resetPassword.mismatch',
    defaultMessage: "Passwords don't match",
  },
});
