/**
 * @generated SignedSource<<465d3764e51a5c7ad2c6ebf45bc96bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apps_NewReleasePage_Query$variables = {
  workflowHandle: string;
};
export type Apps_NewReleasePage_Query$data = {
  readonly workflow: {
    readonly " $fragmentSpreads": FragmentRefs<"NewReleasePage_workflow">;
  } | null;
};
export type Apps_NewReleasePage_Query = {
  response: Apps_NewReleasePage_Query$data;
  variables: Apps_NewReleasePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workflowHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "workflowHandle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_NewReleasePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewReleasePage_workflow"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Apps_NewReleasePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "594d7c73b73dc18678e1c138087e358e",
    "id": null,
    "metadata": {},
    "name": "Apps_NewReleasePage_Query",
    "operationKind": "query",
    "text": "query Apps_NewReleasePage_Query(\n  $workflowHandle: String!\n) {\n  workflow(handle: $workflowHandle) {\n    ...NewReleasePage_workflow\n    id\n  }\n}\n\nfragment NewReleasePage_workflow on Workflow {\n  id\n  handle\n  name\n}\n"
  }
};
})();

(node as any).hash = "ed166b0034f0a00dc28efb4da595161e";

export default node;
