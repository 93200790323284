import type { Match } from 'found';
import orderBy from 'lodash/orderBy';
import store from 'store/dist/store.modern';

import { getDateTimeString } from './date';

// the `*At` properties could be dates, but that couldn't be serialized,
// so we'd need to have to different interfaces. for the sake of simplicity,
// we'll keep everything as string and convert to date when needed

interface ProjectSession {
  handle: string;
  lastVisitedAt: string;
}

interface TenantSession {
  slug: string;
  lastVisitedProjects: ProjectSession[];
  lastVisitedAt: string;
}

interface Session {
  createdAt: string;
  tenants: TenantSession[];
}

export const MAX_RECENT_PROJECTS = 10;

function getSessionKey(viewerId: string) {
  return `qsi:userSession:${viewerId}`;
}

class SessionStore {
  constructor(private viewerLocalId: string) {}

  private getSession(): Session {
    const key = getSessionKey(this.viewerLocalId);

    return (
      store.get(key) || {
        createdAt: getDateTimeString(new Date()),
        tenants: [],
      }
    );
  }

  private storeSession(session: Session) {
    const key = getSessionKey(this.viewerLocalId);
    store.set(key, session);
  }

  setMinimized(minimized: boolean) {
    store.set('qsi:userSession:minimized', minimized);
  }

  getMinimized() {
    return store.get('qsi:userSession:minimized');
  }

  setProjectAsVisited(tenantSlug: string, projectHandle: string) {
    const session = this.getSession();

    let tenant = session.tenants.find((t) => t.slug === tenantSlug);
    if (!tenant) {
      tenant = {
        slug: tenantSlug,
        lastVisitedAt: getDateTimeString(new Date()),
        lastVisitedProjects: [],
      };
      session.tenants.push(tenant);
    }
    tenant.lastVisitedAt = getDateTimeString(new Date());
    session.tenants = orderBy(
      session.tenants,
      (t) => t.lastVisitedAt,
      'desc',
    ).slice(0, 20); // keep only last 20 tenants

    let project = tenant.lastVisitedProjects.find(
      (p) => p.handle === projectHandle,
    );
    if (!project) {
      project = {
        handle: projectHandle,
        lastVisitedAt: getDateTimeString(new Date()),
      };
      tenant.lastVisitedProjects.push(project);
    }
    project.lastVisitedAt = getDateTimeString(new Date());
    tenant.lastVisitedProjects = orderBy(
      tenant.lastVisitedProjects,
      (t) => t.lastVisitedAt,
      'desc',
    ).slice(0, MAX_RECENT_PROJECTS); // keep only last MAX_RECENT_PROJECTS

    this.storeSession(session);
  }

  getLastVisitedTenantAndProject(): {
    tenant?: TenantSession;
    project?: ProjectSession;
  } {
    const session = this.getSession();

    const tenant = orderBy(session.tenants, (t) => t.lastVisitedAt, 'desc')[0];
    if (!tenant) return {};

    return {
      tenant,
      project: orderBy(
        tenant.lastVisitedProjects,
        (p) => p.lastVisitedAt,
        'desc',
      )[0],
    };
  }

  getTenantSession(tenantSlug: string) {
    const session = this.getSession();
    const tenant = session.tenants.find((t) => t.slug === tenantSlug);
    return (
      tenant ?? {
        lastVisitedAt: new Date().toString(),
        slug: tenantSlug,
        lastVisitedProjects: [],
      }
    );
  }

  getLastVisitedProjectForTenant(tenantSlug: string) {
    const tenant = this.getTenantSession(tenantSlug);
    if (!tenant) return null;

    const { lastVisitedProjects } = tenant;
    return orderBy(lastVisitedProjects, (p) => p.lastVisitedAt, 'desc')[0];
  }

  removeTenantFromSession = (tenantSlug: string) => {
    const session = this.getSession();

    session.tenants = session.tenants.filter((t) => t.slug !== tenantSlug);

    this.storeSession(session);
  };

  removeProjectFromSession = (tenantSlug: string, projectHandle: string) => {
    const session = this.getSession();

    const tenant = session.tenants.find((t) => t.slug === tenantSlug);
    if (!tenant) return;

    tenant.lastVisitedProjects = tenant.lastVisitedProjects.filter(
      (p) => p.handle !== projectHandle,
    );

    this.storeSession(session);
  };
}

export function getSessionStore({ context: { viewerLocalId } }: Match) {
  return new SessionStore(viewerLocalId);
}
