/**
 * @generated SignedSource<<0f1004c17e263a16e6ab3779ea1a4cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Devices_DeviceSummarySectionQuery$variables = {
  deviceHandle: string;
};
export type Devices_DeviceSummarySectionQuery$data = {
  readonly device: {
    readonly " $fragmentSpreads": FragmentRefs<"DeviceSummarySection_device">;
  } | null;
};
export type Devices_DeviceSummarySectionQuery = {
  response: Devices_DeviceSummarySectionQuery$data;
  variables: Devices_DeviceSummarySectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "deviceHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_DeviceSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviceSummarySection_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Devices_DeviceSummarySectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "family",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softwareVersion",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "presence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SoftwareVersion",
            "kind": "LinkedField",
            "name": "availableSoftwareUpgrades",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceError",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "details",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceState",
            "kind": "LinkedField",
            "name": "state",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "progress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeRemainingSeconds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc54214c40eb4aba91796d374ca50193",
    "id": null,
    "metadata": {},
    "name": "Devices_DeviceSummarySectionQuery",
    "operationKind": "query",
    "text": "query Devices_DeviceSummarySectionQuery(\n  $deviceHandle: String!\n) {\n  device(handle: $deviceHandle) {\n    ...DeviceSummarySection_device\n    id\n  }\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment DeviceSoftwareVersion_device on Device {\n  id\n  softwareVersion\n  state {\n    code\n    label\n    progress\n  }\n}\n\nfragment DeviceStatus_device on Device {\n  id\n  presence\n  availableSoftwareUpgrades {\n    __typename\n    id\n  }\n  error {\n    __typename\n  }\n  state {\n    __typename\n  }\n  ...DeviceProgress_device\n}\n\nfragment DeviceSummarySection_device on Device {\n  handle\n  family\n  softwareVersion\n  ...DeviceStatus_device\n  ...DeviceSoftwareVersion_device\n}\n"
  }
};
})();

(node as any).hash = "9c0f56ba1fea0e04a365f466d465fde6";

export default node;
