import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BodyStyle from 'components/BodyStyle';
import Page from 'components/Page';
import PageContainer from 'components/PageContainer';

import AppHeader from './AppHeader';
import type { BaseAppPage_tenant$data as Tenant } from './__generated__/BaseAppPage_tenant.graphql';
import type { BaseAppPage_viewer$data as Viewer } from './__generated__/BaseAppPage_viewer.graphql';
import './BaseAppPage.scss';

export interface Props {
  viewer: Viewer | null;
  tenant: Tenant | null;
  header?: React.ReactNode;
  children: React.ReactNode;
}

const rootClass = 'BaseAppPage';

function BaseAppPage({ header, viewer, children, tenant }: Props) {
  return (
    <Page>
      <AppHeader viewer={viewer} tenant={tenant}>
        {header}
      </AppHeader>
      <PageContainer className="BaseAppPage__container">
        {children}
      </PageContainer>
      <BodyStyle className={rootClass} />
    </Page>
  );
}

export default createFragmentContainer(BaseAppPage, {
  viewer: graphql`
    fragment BaseAppPage_viewer on Viewer {
      ...AppHeader_viewer
    }
  `,
  tenant: graphql`
    fragment BaseAppPage_tenant on Tenant {
      ...AppHeader_tenant
    }
  `,
});
