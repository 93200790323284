import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { getInfiniteListVariables } from 'components/utils/createInfiniteList';
import checkTenantItem from 'utils/checkTenantItem';

export const commonRoutes = (
  <>
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisSummarySection'
        ).then((p) => p.default)
      }
      query={graphql`
        query Analyses_analysisSummarySectionQuery($analysisHandle: String!) {
          analysis(handle: $analysisHandle) {
            ...AnalysisSummarySection_analysis
          }
        }
      `}
    />
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisMetricsSection'
        ).then((p) => p.default)
      }
      path="metrics"
      query={graphql`
        query Analyses_metricsSectionQuery($analysisHandle: String!) {
          analysis(handle: $analysisHandle) {
            ...AnalysisMetricsSection_analysis
          }
        }
      `}
    />
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisPlotsTabs'
        ).then((p) => p.default)
      }
      path="plots"
      query={graphql`
        query Analyses_plotsTabsQuery($analysisHandle: String!) {
          analysis(handle: $analysisHandle) {
            ...AnalysisPlotsTabs_analysis
          }
        }
      `}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "analyses" */ './components/AnalysisPlotsSection'
          ).then((p) => p.default)
        }
        path=":grouping?"
        query={graphql`
          query Analyses_plotsSectionQuery($analysisHandle: String!) {
            analysis(handle: $analysisHandle) {
              ...AnalysisPlotsSection_analysis
            }
          }
        `}
      />
    </Route>
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisFilesSection'
        ).then((p) => p.default)
      }
      path="files"
      query={graphql`
        query Analyses_filesSectionQuery($analysisHandle: String!) {
          analysis(handle: $analysisHandle) {
            ...AnalysisFilesSection_analysis
          }
        }
      `}
    />
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisFileHtmlSection'
        ).then((p) => p.default)
      }
      path="files/:fileHandle"
      query={graphql`
        query Analyses_fileHtmlSectionQuery($fileHandle: String!) {
          analysisFile(handle: $fileHandle) {
            ...AnalysisFileHtmlSection_analysisFile
          }
        }
      `}
    />
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/AnalysisNotesSection'
        ).then((p) => p.default)
      }
      query={graphql`
        query Analyses_notesSectionQuery(
          $analysisHandle: String!
          $count: Int!
          $cursor: String
        ) {
          analysis(handle: $analysisHandle) {
            ...AnalysisNotesSection_analysis
          }
        }
      `}
      path="notes"
      prepareVariables={getInfiniteListVariables}
    />
  </>
);

export default (
  <Route path="analyses">
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "analyses" */ './components/NewAnalysisPage'
        ).then((p) => p.default)
      }
      prepareVariables={(variables, { location }) => ({
        ...variables,
        analysisHandle: location.query.analysis || '',
        hasAnalysis: !!location.query.analysis,
        workflowHandle: location.query.workflow || '',
        hasWorkflow: !!location.query.workflow,
        sampleHandle: location.query.specimen || '',
        hasSample: !!location.query.specimen,
        runHandle: location.query.run || '',
        hasRun: !!location.query.run,
      })}
      fromAnalysis={null}
      fromRun={null}
      fromSample={null}
      fromWorkflow={null}
      query={graphql`
        query Analyses_newAnalysisQuery(
          $tenantSlug: String!
          $projectHandle: String!
          $sampleHandle: String!
          $hasSample: Boolean!
          $runHandle: String!
          $hasRun: Boolean!
          $workflowHandle: String!
          $hasWorkflow: Boolean!
          $analysisHandle: String!
          $hasAnalysis: Boolean!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NewAnalysisPage_tenant
          }
          project(handle: $projectHandle) {
            ...NewAnalysisPage_project
          }
          fromWorkflow: workflow(handle: $workflowHandle)
            @include(if: $hasWorkflow) {
            ...NewAnalysisPage_fromWorkflow
          }
          fromAnalysis: analysis(handle: $analysisHandle)
            @include(if: $hasAnalysis) {
            ...NewAnalysisPage_fromAnalysis
          }
          fromSample: sample(handle: $sampleHandle) @include(if: $hasSample) {
            ...NewAnalysisPage_fromSample
          }
          fromRun: run(handle: $runHandle) @include(if: $hasRun) {
            ...NewAnalysisPage_fromRun
          }
        }
      `}
    />
    <Route
      query={graphql`
        query Analyses_analysisQuery(
          $tenantSlug: String!
          $analysisHandle: String!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: analysis(handle: $analysisHandle) {
            tenant {
              id
            }
          }
        }
      `}
      path=":analysisHandle"
      prerender={checkTenantItem}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "analyses" */ './components/AnalysisPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Analyses_analysisPageQuery(
            $tenantSlug: String!
            $analysisHandle: String!
          ) {
            analysis(handle: $analysisHandle) {
              ...AnalysisPage_analysis
            }
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...AnalysisPage_tenant
            }
          }
        `}
      >
        {commonRoutes}
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "analyses" */ './components/AnalysisStepsSection'
            ).then((p) => p.default)
          }
          path="workflow"
          query={graphql`
            query Analyses_stepsSectionQuery($analysisHandle: String!) {
              analysis(handle: $analysisHandle) {
                ...AnalysisStepsSection_analysis
              }
            }
          `}
        >
          <Route
            getComponent={() =>
              import(
                /* webpackChunkName: "analyses" */ './components/AnalysisLogSection'
              ).then((p) => p.default)
            }
            query={graphql`
              query Analyses_logSectionQuery($analysisHandle: String!) {
                analysis(handle: $analysisHandle) {
                  ...AnalysisLogSection_analysis
                }
              }
            `}
          />
          <Route
            getComponent={() =>
              import(
                /* webpackChunkName: "analyses" */ './components/AnalysisStepLogSection'
              ).then((p) => p.default)
            }
            path=":stepIndex"
            prepareVariables={(variables: any) => ({
              ...variables,
              analysisJobStepId: {
                analysisHandle: variables.analysisHandle,
                index: variables.stepIndex,
              },
            })}
            query={graphql`
              query Analyses_stepLogSectionQuery(
                $analysisJobStepId: AnalysisJobStepIdInput!
              ) {
                analysisJobStep(analysisJobStepId: $analysisJobStepId) {
                  ...AnalysisStepLogSection_analysisJobStep
                }
              }
            `}
          />
        </Route>
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "analyses" */ './components/EditAnalysisPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Analyses_editAnalysisPageQuery(
            $tenantSlug: String!
            $analysisHandle: String!
          ) {
            analysis(handle: $analysisHandle) {
              ...EditAnalysisPage_analysis
            }
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...EditAnalysisPage_tenant
            }
          }
        `}
      />
    </Route>
  </Route>
);
