import Layout from '@4c/layout';
import classNames from 'classnames';
import React, { useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import ReplaceTransition from 'react-transition-group/ReplaceTransition';

import './ScaleReplaceTransition.scss';

function forceReflow(node: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  node.clientWidth;
}

const rootClass = 'ScaleReplaceTransition';

function Fade(props: any) {
  return (
    <CSSTransition
      {...props}
      unmountOnExit
      mountOnEnter
      classNames={{
        enter: `${rootClass}__enter`,
        enterActive: `${rootClass}__enter-active`,
        exit: `${rootClass}__exit`,
        exitActive: `${rootClass}__exit-active`,
      }}
      timeout={{ exit: 300, enter: 300 }}
    />
  );
}

interface Props {
  className?: string;
  in: any;
  children: React.ReactNode;
}

function ScaleReplaceTransition({ className, children, ...props }: Props) {
  const [height, setHeight] = useState<number>();

  /* eslint-disable no-param-reassign */
  const setInitialHeight = (node: any) => {
    setHeight(node.firstElementChild.offsetHeight);
    node.style.height = `${node.lastElementChild.offsetHeight}px`;
  };

  const setNextHeight = (node: any) => {
    forceReflow(node);
    node.style.height = `${height}px`;
  };

  const clearHeight = (node: any) => {
    node.style.height = '';
  };
  /* eslint-enable no-param-reassign */

  return (
    <ReplaceTransition
      {...props}
      component={Layout}
      direction="column"
      className={classNames(rootClass, className)}
      onEnter={setInitialHeight}
      onEntering={setNextHeight}
      onEntered={clearHeight}
      onExit={setInitialHeight}
      onExiting={setNextHeight}
      onExited={clearHeight}
    >
      {React.Children.map(children, (c) => (
        <Fade>{c}</Fade>
      ))}
    </ReplaceTransition>
  );
}

export default ScaleReplaceTransition;
