import * as TooltipPrimitave from '@radix-ui/react-tooltip';
import classNames from 'classnames';
import React from 'react';

export type Side = 'top' | 'right' | 'bottom' | 'left';

interface Props {
  tooltipSide?: Side;
  tooltipMessage: React.ReactNode;
  tooltipTrigger: React.ReactNode;
}

import './Tooltip.scss';

const rootClass = 'tooltip';

const Tooltip = ({
  tooltipMessage,
  tooltipTrigger,
  tooltipSide = 'top',
}: Props) => {
  return (
    <TooltipPrimitave.Provider delayDuration={0}>
      <TooltipPrimitave.Root>
        <TooltipPrimitave.Trigger id="workflowStepsDiagram.toolTip" asChild>
          {tooltipTrigger}
        </TooltipPrimitave.Trigger>
        <TooltipPrimitave.Content
          side={tooltipSide as Side}
          className={classNames(`${rootClass}__content`)}
        >
          {tooltipMessage}
        </TooltipPrimitave.Content>
      </TooltipPrimitave.Root>
    </TooltipPrimitave.Provider>
  );
};

export default Tooltip;
