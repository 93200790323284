import classNames from 'classnames';
import { Match, useRouter } from 'found';
import escapeRegExp from 'lodash/escapeRegExp';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RiSearchLine as SearchIcon } from 'react-icons/ri';
import {
  FormattedMessage,
  IntlFormatters,
  defineMessages,
  useIntl,
} from 'react-intl';
import List, { ListHandle } from 'react-widgets/List';
import Multiselect from 'react-widgets/Multiselect';
import MultiselectTag, {
  MultiselectTagProps,
} from 'react-widgets/MultiselectTag';
import type { Environment } from 'relay-runtime';
import { useDebouncedCallback } from 'use-debounce';

import DropdownList from 'components/DropdownList';
import Highlighter from 'components/Highlighter';
import Spinner from 'components/Spinner';
import { searchDebounceConfig } from 'utils/debounce';
import useRelayContext from 'utils/useRelayContext';
import useTenant from 'utils/useTenant';

import './SearchBar.scss';

export const AVATAR_WIDTH = 20;
export const SEARCH_TERM_MIN_LENGTH = 1;

export interface ItemSearchProps<TSearchItem, TTenant = any, TFilters = any> {
  // semantic term used for the type of item for managing what is what
  // doesn't necessarily need to be the same as the GraphQL type, but it must be unique
  type: string;

  // how the items will be grouped up
  groupName?: string;

  // what the item itself will look like when searching
  renderItem?({
    renderHighlighted,
    item,
    searchTerm,
  }: {
    renderHighlighted(item: TSearchItem, searchTerm: string): React.ReactNode;
    item: TSearchItem;
    searchTerm: string;
  }): React.ReactNode;

  // what the pill will look like once an item is selected
  renderPill?({
    item,
    getTextField,
  }: {
    item: TSearchItem;
    getTextField(params: any): string;
  }): React.ReactNode;

  // the text of the field
  getTextField?({
    item,
    formatMessage,
  }: {
    item: TSearchItem;
    formatMessage: IntlFormatters['formatMessage'];
  }): string;

  // what the query param will be
  getQuery?(item: TSearchItem): Obj<string>;

  // how to get the values for search autocompletion
  getValues?(item: TTenant): Partial<TSearchItem>[];

  // what prop this will be put on to the component when selected
  getParam?(props: TFilters, match?: Match): any;

  // how the order of pills will display in the search bar
  // if left undefined, it will be ordered alphabetically
  displayIndex?: number;
}

export const freeTextSearchFn = {
  type: 'FREE_TEXT_SEARCH',
  renderItem: ({ searchTerm }: { searchTerm: string }) =>
    searchTerm.length ? (
      <FormattedMessage
        id="searchBar.freeText"
        defaultMessage="Search for {searchTerm}"
        values={{
          searchTerm: <span> “{searchTerm}”</span>,
        }}
      />
    ) : (
      <FormattedMessage
        id="searchBar.freeText"
        defaultMessage="Clear Search"
      />
    ),
};

export const searchMessages = defineMessages({
  placeholder: {
    id: 'searchBar.search',
    defaultMessage: 'Search',
  },
});

export const entityMessages = defineMessages({
  all: {
    id: 'searchBar.all',
    defaultMessage: 'All',
  },
  analyses: {
    id: 'searchBar.analysis',
    defaultMessage: 'Analyses',
  },
  runs: {
    id: 'searchBar.runs',
    defaultMessage: 'Runs',
  },
  specimens: {
    id: 'searchBar.specimens',
    defaultMessage: 'Samples',
  },
  libraries: {
    id: 'searchBar.libraries',
    defaultMessage: 'Libraries',
  },
});

export function renderGroup({ group }: { group: string }) {
  if (group === 'FREE_TEXT_SEARCH') {
    return null;
  }
  return (
    <div
      style={{
        textTransform: 'uppercase',
        padding: '0.5rem',
        background: 'var(--light-grey)',
        fontSize: 'var(--font-size-sm)',
        lineHeight: '1',
      }}
    >
      <span style={{ verticalAlign: 'sub' }}>{group}</span>
    </div>
  );
}

export function renderTag(
  props: MultiselectTagProps & { dataItem: any } & Obj,
) {
  return <MultiselectTag className={classNames(props.className)} {...props} />;
}

export const SearchBarList = React.forwardRef<ListHandle, any>(
  ({ busy, ...props }, ref) => (
    <div style={{ overflow: 'hidden' }}>
      {busy && (
        <div style={{ height: '3rem', margin: '1rem', textAlign: 'center' }}>
          <Spinner />
        </div>
      )}

      <List
        {...props}
        ref={ref}
        className={classNames(busy && 'd-none')}
        renderGroup={renderGroup}
      />
    </div>
  ),
);

export interface SearchBarHandle {
  focus(): void;
}

interface Props<T, TFilters, TFilterItemType, TSearchableType extends string> {
  searchType?: TSearchableType;
  searchRoute: string;
  searchQueryArg: string;
  searchTypeQueryArg: string;
  fetchSearchInfo: (
    environment: Environment,
    tenantSlug: string,
    searchTerm: string,
  ) => Promise<{ tenant: T }>;
  itemFunctions: ItemSearchProps<TFilterItemType, T, TFilters>[];
  className?: string;
  dataTestId?: string;
  defaultSearchType: TSearchableType;
  renderFunctions: Partial<Record<TSearchableType, ItemSearchProps<any>[]>>;
  dropdownProps?: DropdownProps<TSearchableType>;
}

interface DropdownProps<TSearchableType extends string> {
  searchableTypes: TSearchableType[];
  searchableTypeMessages: Partial<Record<TSearchableType, any>>;
}

const rootClass = 'SearchBar';

function SearchBar<
  T,
  TFilters,
  TFilterItemType extends { type: string },
  TSearchableType extends string,
>({
  searchType,
  searchRoute,
  searchQueryArg,
  searchTypeQueryArg,
  fetchSearchInfo,
  itemFunctions,
  className,
  dataTestId = rootClass,
  defaultSearchType,
  renderFunctions,
  dropdownProps,
  ...props
}: Props<T, TFilters, TFilterItemType, TSearchableType>) {
  const { environment } = useRelayContext();
  const { router, match } = useRouter();
  const { tenantSlug } = useTenant();
  const intl = useIntl();

  const [data, setData] = useState<TFilterItemType[]>([]);
  const searchTypeFromQuery = match.location.query[searchTypeQueryArg];
  const searchTypeCached = useMemo<TSearchableType>(
    () =>
      searchType ||
      ((searchTypeFromQuery as TSearchableType) ?? defaultSearchType),
    [searchType, searchTypeFromQuery, defaultSearchType],
  );

  const renderFuncs = useMemo<ItemSearchProps<any>[]>(() => {
    return (
      renderFunctions[searchTypeCached as keyof typeof renderFunctions] ?? []
    );
  }, [searchTypeCached, renderFunctions]);

  const filtersFromParams = useMemo(() => {
    const response = [...itemFunctions, ...renderFuncs]
      .filter(({ getParam }) => getParam)
      .map(({ getParam, type }) => {
        const p = getParam && getParam(props, match);
        return [type, p ? { ...p, type } : null];
      });
    return Object.fromEntries(response);
  }, [itemFunctions, match, props, renderFuncs]);

  const [busy, setBusy] = useState(false);
  const initialSearchTerm = match.location.query[searchQueryArg] || '';
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.location.pathname, initialSearchTerm]);

  const getRenderFunction = useCallback(
    (item: Pick<TFilterItemType, 'type'>) => {
      const types = [...itemFunctions, ...renderFuncs];
      const foundItem = types.find(({ type }) => type === item.type);
      if (!foundItem) throw new Error(`invalid item ${item.type}`);
      return foundItem;
    },
    [itemFunctions, renderFuncs],
  );

  const getTextField = useCallback(
    (item: TFilterItemType) => {
      const foundItem = getRenderFunction(item);
      if (!foundItem.getTextField) return (item as any).name;
      return foundItem.getTextField({
        item,
        formatMessage: intl.formatMessage,
      });
    },
    [intl.formatMessage, getRenderFunction],
  );

  const renderPill = useCallback(
    ({ item }: { item: TFilterItemType }): React.ReactNode => {
      const foundItem = getRenderFunction(item);
      // every render function without a renderPill function _must_ have a name prop for fallback
      if (!foundItem.renderPill) return <span>{(item as any).name}</span>;
      return foundItem.renderPill!({ item, getTextField });
    },
    [getRenderFunction, getTextField],
  );

  const renderHighlighted = useCallback(
    (item: TFilterItemType, searchValue: string) => {
      const text = getTextField(item);

      return <Highlighter text={text} search={searchValue} />;
    },
    [getTextField],
  );

  const renderItem = useCallback(
    ({ item }: { item: TFilterItemType }) => {
      const foundItem = getRenderFunction(item);
      if (!foundItem.renderItem) return renderHighlighted(item, searchTerm);
      return foundItem.renderItem({ item, searchTerm, renderHighlighted });
    },
    [getRenderFunction, renderHighlighted, searchTerm],
  );

  const doSearchForFilters = useDebouncedCallback(
    useCallback(async () => {
      if (searchTerm.length < SEARCH_TERM_MIN_LENGTH) return;

      setBusy(true);
      const { tenant: fetchedTenant } = await fetchSearchInfo(
        environment as Environment,
        tenantSlug,
        searchTerm,
      );

      const searchRegex = new RegExp(
        `(^|\\s)${escapeRegExp(searchTerm)}`,
        'i',
      );

      const items = Object.keys(filtersFromParams)
        .map((type) => {
          const item = getRenderFunction({ type });
          return item.getValues!(fetchedTenant).map((t) => ({
            ...t,
            type,
          }));
        })
        .flat()
        .filter((item) =>
          getTextField(item as TFilterItemType).match(searchRegex),
        );

      setData(items as TFilterItemType[]);
      setBusy(false);
    }, [
      searchTerm,
      fetchSearchInfo,
      environment,
      tenantSlug,
      filtersFromParams,
      getRenderFunction,
      getTextField,
    ]),
    searchDebounceConfig.getWaitTime(),
  );

  const searchFilterItems = useMemo<TFilterItemType[]>(() => {
    const isSearchPage = router.isActive(match, {
      pathname: searchRoute,
    });
    const nextValue: TFilterItemType[] = [];

    if (isSearchPage) {
      Object.entries(filtersFromParams).forEach(
        ([type, fragmentValue]: [string, ItemSearchProps<any>]) => {
          const foundItem = getRenderFunction({ type });
          if (foundItem.getQuery && fragmentValue) {
            nextValue.push({
              ...fragmentValue,
              ...(foundItem as TFilterItemType),
            });
          }
        },
      );
    }

    return sortBy(nextValue, ['displayIndex']);
  }, [router, match, searchRoute, filtersFromParams, getRenderFunction]);

  const handleSearchUpdated = useCallback(
    (
      searchTypeIn: TSearchableType,
      searchFilterItemsIn: Record<string, ItemSearchProps<TFilterItemType>>,
      searchTermIn: string,
      includeFilters = true,
    ) => {
      let queryArgs: Record<string, string> = {
        [searchQueryArg]: searchTermIn,
      };

      if (includeFilters) {
        Object.entries(searchFilterItemsIn)
          .filter(([_, v]) => !!v)
          .forEach(([type, v]) => {
            const foundItem = getRenderFunction({ type });
            if (foundItem.getQuery) {
              const queryObj = foundItem.getQuery(v);
              queryArgs = { ...queryArgs, ...queryObj };
            }
          });
      }

      queryArgs[searchTypeQueryArg] = searchTypeIn;

      setData([]);
      router.push({
        pathname: searchRoute,
        query: queryArgs,
      });
    },
    [
      getRenderFunction,
      router,
      searchQueryArg,
      searchRoute,
      searchTypeQueryArg,
    ],
  );

  const handleChange = useCallback(
    (searchFilterItemsIn: TFilterItemType[], meta: any) => {
      const nextValueByType = keyBy(searchFilterItemsIn, 'type');

      let nextSearchTerm: string;
      if (
        meta.action === 'insert' &&
        meta.dataItem.type !== 'FREE_TEXT_SEARCH'
      ) {
        // We're searching on this as a non-free-text item. Clear the search.
        nextSearchTerm = '';
        setSearchTerm('');
      } else {
        nextSearchTerm = searchTerm;
      }

      // In the above, update the state immediately so the search UI reflects
      // the selections; no need to wait for navigation query.
      handleSearchUpdated(searchTypeCached, nextValueByType, nextSearchTerm);

      setActive(false);
    },
    [handleSearchUpdated, searchTypeCached, searchTerm],
  );

  const handleSearch = useCallback(
    (nextSearchTerm: string, meta: any) => {
      // Don't let react-widgets automatically clear the search term on blur or
      // on other events. We will manually handle the update.
      if (meta?.action === 'clear') {
        return;
      }

      setSearchTerm(nextSearchTerm);
      doSearchForFilters();
    },
    [doSearchForFilters],
  );

  const groupItems = (item: TFilterItemType) =>
    getRenderFunction(item).groupName || item.type;

  const fullData = useMemo(
    () => [{ type: 'FREE_TEXT_SEARCH' }, ...data],
    [data],
  );

  return (
    <div
      className={classNames([rootClass, className])}
      data-testid={dataTestId}
    >
      {!!dropdownProps && (
        <DropdownList
          data-testid="SearchBarSelect"
          data={dropdownProps.searchableTypes}
          className={`${rootClass}__entity-select`}
          textField={(t: TSearchableType) =>
            dropdownProps.searchableTypeMessages[t]
          }
          value={searchTypeCached}
          name="searchTypeList"
          onChange={(searchTypeIn: TSearchableType) => {
            handleSearchUpdated(
              searchTypeIn,
              filtersFromParams,
              searchTerm,
              false,
            );
          }}
        />
      )}

      <div className={`${rootClass}__multiselect-container`}>
        <Multiselect
          data-testid={`${rootClass}-search-input`}
          data={fullData}
          groupBy={groupItems}
          className={classNames([`${rootClass}__multiselect`])}
          value={searchFilterItems}
          listProps={{ busy }}
          searchTerm={searchTerm}
          focusFirstItem
          filter={false}
          open={active}
          onChange={handleChange}
          onSearch={handleSearch}
          onToggle={setActive}
          listComponent={SearchBarList}
          renderListGroup={renderGroup}
          renderListItem={renderItem as any}
          renderTagValue={renderPill}
          tagOptionComponent={renderTag}
          busy={busy}
          busySpinner={null}
          placeholder={intl.formatMessage(searchMessages.placeholder)}
          showPlaceholderWithValues
        />
        <SearchIcon className={`${rootClass}__multiselect-icon`} />
      </div>
    </div>
  );
}

export default SearchBar;
