/**
 * @generated SignedSource<<46712b116b76ab33832ea636e143f5e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routes_sidePanelQuery$variables = {
  hasP1: boolean;
  hasP2: boolean;
  hasP3: boolean;
  hasP4: boolean;
  hasP5: boolean;
  p1: string;
  p2: string;
  p3: string;
  p4: string;
  p5: string;
  tenantSlug: string;
};
export type routes_sidePanelQuery$data = {
  readonly p1?: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
  } | null;
  readonly p2?: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
  } | null;
  readonly p3?: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
  } | null;
  readonly p4?: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
  } | null;
  readonly p5?: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_recentProjects">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"TenantSidePanelItem_tenant">;
  } | null;
};
export type routes_sidePanelQuery = {
  response: routes_sidePanelQuery$data;
  variables: routes_sidePanelQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasP1"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasP2"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasP3"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasP4"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasP5"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "p1"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "p2"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "p3"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "p4"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "p5"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v11 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v12 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "p1"
  }
],
v13 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "TenantSidePanelItem_recentProjects"
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "p2"
  }
],
v15 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "p3"
  }
],
v16 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "p4"
  }
],
v17 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "p5"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  }
],
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v23 = [
  (v20/*: any*/),
  (v21/*: any*/),
  (v22/*: any*/),
  (v18/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_sidePanelQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v11/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TenantSidePanelItem_tenant"
          }
        ],
        "storageKey": null
      },
      {
        "condition": "hasP1",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p1",
            "args": (v12/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP2",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p2",
            "args": (v14/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP3",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p3",
            "args": (v15/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP4",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p4",
            "args": (v16/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP5",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p5",
            "args": (v17/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v10/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v3/*: any*/),
      (v9/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_sidePanelQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v11/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "concreteType": "ProjectConnection",
            "kind": "LinkedField",
            "name": "projectConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Project",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projectConnection(first:20)"
          },
          {
            "alias": null,
            "args": (v19/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_projectConnection",
            "kind": "LinkedHandle",
            "name": "projectConnection"
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "hasP1",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p1",
            "args": (v12/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP2",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p2",
            "args": (v14/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP3",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p3",
            "args": (v15/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP4",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p4",
            "args": (v16/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasP5",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "p5",
            "args": (v17/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v23/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "801bb09890efa4e529976f20f39c8996",
    "id": null,
    "metadata": {},
    "name": "routes_sidePanelQuery",
    "operationKind": "query",
    "text": "query routes_sidePanelQuery(\n  $tenantSlug: String!\n  $p1: String!\n  $hasP1: Boolean!\n  $p2: String!\n  $hasP2: Boolean!\n  $p3: String!\n  $hasP3: Boolean!\n  $p4: String!\n  $hasP4: Boolean!\n  $p5: String!\n  $hasP5: Boolean!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...TenantSidePanelItem_tenant\n    id\n  }\n  p1: project(handle: $p1) @include(if: $hasP1) {\n    ...TenantSidePanelItem_recentProjects\n    id\n  }\n  p2: project(handle: $p2) @include(if: $hasP2) {\n    ...TenantSidePanelItem_recentProjects\n    id\n  }\n  p3: project(handle: $p3) @include(if: $hasP3) {\n    ...TenantSidePanelItem_recentProjects\n    id\n  }\n  p4: project(handle: $p4) @include(if: $hasP4) {\n    ...TenantSidePanelItem_recentProjects\n    id\n  }\n  p5: project(handle: $p5) @include(if: $hasP5) {\n    ...TenantSidePanelItem_recentProjects\n    id\n  }\n}\n\nfragment TenantSidePanelItem_recentProjects on Project {\n  handle\n  name\n  createdAt\n}\n\nfragment TenantSidePanelItem_tenant on Tenant {\n  currentUser {\n    role\n    id\n  }\n  projectConnection(first: 20) {\n    edges {\n      node {\n        handle\n        name\n        createdAt\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bfa96acd47896d96e877fa1b2322cffc";

export default node;
