import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route
    path="profile"
    getComponent={() =>
      import(
        /* webpackChunkName: "profile" */ './components/ProfilePage'
      ).then((p) => p.default)
    }
  >
    <Route
      exact
      getComponent={() =>
        import(
          /* webpackChunkName: "profile" */ './components/AccountProfilePage'
        ).then((p) => p.default)
      }
      query={graphql`
        query ProfileAccountPageQuery {
          viewer {
            ...AccountProfilePage_viewer
          }
        }
      `}
    />
    <Route
      path="notifications"
      getComponent={() =>
        import(
          /* webpackChunkName: "profile" */ './components/NotificationProfilePage'
        ).then((p) => p.default)
      }
      query={graphql`
        query ProfileNotificationPageQuery {
          viewer {
            ...NotificationProfilePage_viewer
          }
        }
      `}
    />
  </Route>
);
