/**
 * @generated SignedSource<<9279349abd1353126eb7dcaa2349f001>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Project_newProjectQuery$variables = {
  tenantSlug: string;
};
export type Project_newProjectQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"NewProjectPage_tenant">;
  } | null;
};
export type Project_newProjectQuery = {
  response: Project_newProjectQuery$data;
  variables: Project_newProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 4
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Project_newProjectQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NewProjectPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Project_newProjectQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "tagConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "color",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "tagConnection(first:4)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Tenant_tagConnection",
            "kind": "LinkedHandle",
            "name": "tagConnection"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c13534ea83c525e047ef6367b63667c",
    "id": null,
    "metadata": {},
    "name": "Project_newProjectQuery",
    "operationKind": "query",
    "text": "query Project_newProjectQuery(\n  $tenantSlug: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...NewProjectPage_tenant\n    id\n  }\n}\n\nfragment EntityFormSection_tenant on Tenant {\n  ...TagsInput_tenant\n}\n\nfragment NewProjectPage_tenant on Tenant {\n  id\n  ...ProjectFormFields_tenant\n}\n\nfragment ProjectFormFields_tenant on Tenant {\n  ...EntityFormSection_tenant\n}\n\nfragment TagInputRow_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment Tag_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment TagsInput_tenant on Tenant {\n  id\n  slug\n  tagConnection(first: 4) {\n    edges {\n      node {\n        color\n        handle\n        id\n        name\n        ...Tag_tag\n        ...TagInputRow_tag\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1adbef87d15752aab390bd8d22684da8";

export default node;
