import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route
    path="users"
    getComponent={() =>
      import(/* webpackChunkName: "users" */ './components/MembersPage').then(
        (p) => p.default,
      )
    }
    query={graphql`
      query Users_MembersPageQuery($tenantSlug: String!) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          ...MembersPage_tenant
        }
        viewer {
          ...MembersPage_viewer
        }
      }
    `}
  />
);
