/**
 * @generated SignedSource<<35d6bcbe6c5e8778ad91deb1568e3ebd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LibraryOrigin = "CARBON" | "MANUAL";
export type LibraryStatus = "ABORTED" | "FAILED" | "IN_PROGRESS" | "PLANNED" | "READY";
import { FragmentRefs } from "relay-runtime";
export type LibraryList_libraries$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly userProfile: {
      readonly " $fragmentSpreads": FragmentRefs<"CreatedByListItem_userProfile">;
    } | null;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly origin: LibraryOrigin | null;
  readonly project: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly sample: {
    readonly name: string | null;
  } | null;
  readonly status: LibraryStatus | null;
  readonly " $fragmentSpreads": FragmentRefs<"LibraryProgress_library">;
  readonly " $fragmentType": "LibraryList_libraries";
}>;
export type LibraryList_libraries$key = ReadonlyArray<{
  readonly " $data"?: LibraryList_libraries$data;
  readonly " $fragmentSpreads": FragmentRefs<"LibraryList_libraries">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LibraryList_libraries",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "origin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Sample",
      "kind": "LinkedField",
      "name": "sample",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LibraryProgress_library"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantUser",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "userProfile",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreatedByListItem_userProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Library",
  "abstractKey": null
};
})();

(node as any).hash = "c519a636b5bd96d037093de6d1585cd6";

export default node;
