import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import DeviceProgress from 'components/DeviceProgress';
import Progress from 'components/Progress';
import useTenant from 'utils/useTenant';

import type { DeviceStatusPresenceSubscription } from './__generated__/DeviceStatusPresenceSubscription.graphql';
import type { DeviceStatus_device$data as Device } from './__generated__/DeviceStatus_device.graphql';

const subscription = graphql`
  subscription DeviceStatusPresenceSubscription(
    $input: UpdateDevicePresenceSubscriptionInput!
  ) {
    updateDevicePresence(input: $input) {
      device {
        presence
        error {
          __typename
        }
        state {
          __typename
        }
      }
    }
  }
`;

interface Props {
  device: DeepNonNull<Device>;
  className?: string;
}

function DeviceStatus({ device, ...rest }: Props) {
  const { tenantId } = useTenant();
  const {
    availableSoftwareUpgrades,
    id: deviceId,
    error,
    presence,
    state,
  } = device;

  useSubscription<DeviceStatusPresenceSubscription>({
    subscription,
    variables: {
      input: { deviceId, tenantId },
    },
  });

  if (error || state) {
    return <DeviceProgress device={device} {...rest} />;
  }

  if (presence === 'offline') {
    return (
      <Progress
        status={
          <FormattedMessage
            id="deviceProgress.offlineStatus"
            defaultMessage="Offline"
          />
        }
        {...rest}
      />
    );
  }

  if (availableSoftwareUpgrades.length > 0) {
    return (
      <Progress
        status={
          <FormattedMessage
            id="deviceStatus.updateAvailable"
            defaultMessage="Update Available"
          />
        }
        {...rest}
      />
    );
  }

  return (
    <Progress
      status={
        <FormattedMessage id="deviceProgress.idle" defaultMessage="Idle" />
      }
      {...rest}
    />
  );
}

export default createFragmentContainer(DeviceStatus, {
  device: graphql`
    fragment DeviceStatus_device on Device {
      id
      presence

      # TODO: replace with something faster
      availableSoftwareUpgrades {
        __typename
      }

      error {
        __typename
      }
      state {
        __typename
      }
      ...DeviceProgress_device
    }
  `,
});
