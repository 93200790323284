import React from 'react';

import Breadcrumbs, { TagsBreadcrumb } from 'components/Breadcrumbs';
import Header from 'components/Header';

interface Props {
  children: React.ReactNode;
}

export default function TagsPage({ children }: Props) {
  return (
    <>
      <Header.PageHeader data-testid="TagsPage">
        <Breadcrumbs>
          <TagsBreadcrumb />
        </Breadcrumbs>
      </Header.PageHeader>

      {children}
    </>
  );
}
