/**
 * @generated SignedSource<<c17253d1ef8ab10bff8da60b4a2cd98a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfile_userProfile$data = {
  readonly displayName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
  readonly " $fragmentType": "UserProfile_userProfile";
};
export type UserProfile_userProfile$key = {
  readonly " $data"?: UserProfile_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfile_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfile_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatar_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "93512d66cd90a5c63b805fc4f97d5405";

export default node;
