/**
 * @generated SignedSource<<ae0e0050e441b196b90fd010aea885be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BaseAppPage_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppHeader_tenant">;
  readonly " $fragmentType": "BaseAppPage_tenant";
};
export type BaseAppPage_tenant$key = {
  readonly " $data"?: BaseAppPage_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"BaseAppPage_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BaseAppPage_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppHeader_tenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "0c7534f52078cdde498a839eca636d03";

export default node;
