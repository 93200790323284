import classNames from 'classnames';
import { Link, LinkPropsCommon, useRouter } from 'found';
import type { LocationDescriptor } from 'found';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import React, { useMemo } from 'react';
import { RiArrowLeftLine as ArrowLeftIcon } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Button from 'components/Button';
import { useVariation } from 'components/LaunchDarklyContext';
import { MAX_RECENT_PROJECTS, getSessionStore } from 'utils/SessionStore';
import { getNodes } from 'utils/graphql';
import { useTenantRoutes } from 'utils/tenantRoutes';
import { isAdmin } from 'utils/useTenant';

import type { TenantSidePanelItem_recentProjects$data as RecentProjects } from './__generated__/TenantSidePanelItem_recentProjects.graphql';
import type { TenantSidePanelItem_tenant$data as Tenant } from './__generated__/TenantSidePanelItem_tenant.graphql';

import './TenantSidePanelItem.scss';

interface Props {
  tenant: DeepNonNull<Tenant>;
  recentProjects: DeepNonNull<RecentProjects>;
  dataTestId?: string;
}

interface HeaderProps {
  children: React.ReactNode;
  to: LocationDescriptor;
  className?: string;
  dataTestId?: string;
  exact?: boolean;
}

interface LineItemProps extends LinkPropsCommon {
  children: React.ReactNode;
  to: LocationDescriptor;
  className?: string;
  dataTestId?: string;
}

const rootClass = 'TenantSidePanelItem';

function Header({ children, to, className, dataTestId, exact }: HeaderProps) {
  return (
    <Link to={to} data-testid={dataTestId} exact={exact}>
      {({ active, onClick }) => (
        <h2
          className={classNames(
            `${rootClass}__header`,
            active && `${rootClass}__active`,
            className,
          )}
          onClick={onClick}
          role="presentation"
          data-testid={dataTestId}
        >
          {children}
        </h2>
      )}
    </Link>
  );
}

function LineItem({ children, to, className, ...props }: LineItemProps) {
  return (
    <Link
      to={to}
      {...props}
      className={classNames(`${rootClass}__line`, className)}
      activeClassName={classNames(`${rootClass}__active`)}
    >
      <span className={classNames(`${rootClass}__submenu`, 'ellipsis')}>
        {children}
      </span>
    </Link>
  );
}

function TenantSidePanelItem({
  tenant,
  recentProjects,
  dataTestId = rootClass,
}: Props) {
  const routes = useTenantRoutes();
  const canSeeDeveloperPortal =
    useVariation('workflow-developer', false) && isAdmin(tenant!.currentUser);
  const { router, match } = useRouter();
  const toggleSidePanel = () => {
    const session = getSessionStore(match);
    session.setMinimized(true);
    router.replace(match.location); // force re-render
  };

  const projects = useMemo(() => {
    const topProjects = uniqBy(
      [...recentProjects, ...getNodes(tenant.projectConnection)],
      (p) => p.handle,
    ).slice(0, MAX_RECENT_PROJECTS);

    return orderBy(topProjects, (p) => p.createdAt, 'desc');
  }, [recentProjects, tenant.projectConnection]);

  return (
    <nav className={classNames(rootClass)}>
      <div className={classNames(`${rootClass}__minimize`)}>
        <Button
          onClick={toggleSidePanel}
          className={classNames(`${rootClass}__minimize__button`)}
          role="button"
          data-testid={`${dataTestId}-minimize`}
        >
          <ArrowLeftIcon
            height={10}
            className={classNames(`${rootClass}__minimize__icon`)}
          />
        </Button>
      </div>

      <Header
        to={routes.dashboard()}
        dataTestId={`${dataTestId}-dashboardNav`}
      >
        <FormattedMessage
          id="tenantSidePanelItem.dashboard"
          defaultMessage="Dashboard"
        />
      </Header>

      <Header
        to={routes.projects()}
        dataTestId={`${dataTestId}-projects`}
        exact
      >
        <FormattedMessage
          id="tenantSidePanelItem.projects"
          defaultMessage="Projects"
        />
      </Header>

      {projects.map((project, index) => (
        <LineItem
          key={project.handle}
          to={routes.project({ project })}
          data-testid={`${dataTestId}-project-${index}`}
        >
          {project.name}
        </LineItem>
      ))}
      <LineItem
        to={routes.projectNew()}
        exact
        className={classNames(`${rootClass}__add-new-project-link`)}
        data-testid={`${dataTestId}-addNewProject`}
      >
        <FormattedMessage
          id="tenantSidePanelItem.allProjects"
          defaultMessage="Add a new project"
        />
      </LineItem>

      <div className={classNames(`${rootClass}__horizontal-rule`)} />

      <Header to={routes.devices()} dataTestId={`${dataTestId}-devicesNav`}>
        <FormattedMessage
          id="tenantSidePanelItem.devices"
          defaultMessage="Instruments"
        />
      </Header>
      <Header
        to={routes.references()}
        dataTestId={`${dataTestId}-referencesNav`}
      >
        <FormattedMessage
          id="tenantSidePanelItem.references"
          defaultMessage="References"
        />
      </Header>
      <Header to={routes.tags()} dataTestId={`${dataTestId}-tagsNav`}>
        <FormattedMessage
          id="tenantSidePanelItem.tags"
          defaultMessage="Tags"
        />
      </Header>
      <Header to={routes.protocols()} dataTestId={`${dataTestId}-resources`}>
        <FormattedMessage
          id="tenantSidePanelItem.resources"
          defaultMessage="Resources"
        />
      </Header>
      <Header
        to={routes.workflows()}
        dataTestId={`${dataTestId}-workflows`}
        exact
      >
        <FormattedMessage
          id="tenantSidePanelItem.workflows"
          defaultMessage="Workflows"
        />
      </Header>
      {canSeeDeveloperPortal && (
        <LineItem
          to={routes.developers()}
          data-testid={`${dataTestId}-developerPortalNav`}
        >
          <FormattedMessage
            id="tenantSidePanelItem.developerPortal"
            defaultMessage="Developer Portal"
          />
        </LineItem>
      )}
      <LineItem
        to={routes.installations()}
        exact
        data-testid={`${dataTestId}-installations`}
      >
        <FormattedMessage
          id="tenantSidePanelItem.installations"
          defaultMessage="Installations"
        />
      </LineItem>
    </nav>
  );
}

export default createFragmentContainer(TenantSidePanelItem, {
  recentProjects: graphql`
    fragment TenantSidePanelItem_recentProjects on Project
    @relay(plural: true) {
      handle
      name
      createdAt
    }
  `,
  tenant: graphql`
    fragment TenantSidePanelItem_tenant on Tenant {
      currentUser {
        role
      }

      # first should be double MAX_RECENT_PROJECTS
      projectConnection(first: 20)
        @connection(key: "Tenant_projectConnection") {
        edges {
          node {
            handle
            name
            createdAt
          }
        }
      }
    }
  `,
});
