import React from 'react';
import { graphql } from 'react-relay';

import createInfiniteList from 'components/utils/createInfiniteList';
import { getNodes } from 'utils/graphql';

import DeviceList from './DeviceList';
import type { DevicesListSection_tenant$data as Tenant } from './__generated__/DevicesListSection_tenant.graphql';

interface Props {
  tenant: DeepNonNull<Tenant>;
}

export default createInfiniteList(
  ({ tenant }: Props) => (
    <DeviceList devices={getNodes(tenant.deviceConnection)} />
  ),
  {
    tenant: graphql`
      fragment DevicesListSection_tenant on Tenant {
        deviceConnection(first: $count, after: $cursor)
          @connection(key: "Tenant_deviceConnection") {
          edges {
            node {
              ...DeviceList_devices
            }
          }
        }
      }
    `,
  },
  {
    query: graphql`
      query DevicesListSectionQuery(
        $tenantSlug: String!
        $count: Int!
        $cursor: String
      ) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          ...DevicesListSection_tenant
        }
      }
    `,
  },
);
