import React from 'react';

import { IsSharedContext } from 'utils/isSharedContext';

import AppPage from './AppPage';

interface Props {
  children: React.ReactNode;
}

function SharedAppPage({ children }: Props) {
  return (
    <IsSharedContext.Provider value>
      <AppPage viewer={null}>{children}</AppPage>
    </IsSharedContext.Provider>
  );
}

export default SharedAppPage;
