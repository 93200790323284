import React from 'react';
import { graphql } from 'react-relay';

import RunList from 'components/RunList';
import createInfiniteList from 'components/utils/createInfiniteList';

import SearchList from './SearchList';
import type { RunSearchList_tenant$data as Tenant } from './__generated__/RunSearchList_tenant.graphql';

interface Props {
  tenant: DeepNonNull<Tenant>;
}

export const runSearchListQuery = graphql`
  query RunSearchListQuery(
    $tenantSlug: String!
    $count: Int!
    $cursor: String
    $statuses: [RunStatus!]
    $sort: [RunSorting!]
    $search: String
    $userProfileHandles: [IdOrHandle!]
    $tagHandles: [IdOrHandle!]
    $projectHandles: [IdOrHandle!]
  ) {
    tenant: tenantBySlug(slug: $tenantSlug) {
      ...RunSearchList_tenant
    }
  }
`;

export default createInfiniteList(
  ({ tenant }: Props) => (
    <SearchList
      connection={tenant.runConnection}
      entityType="runs"
      Component={RunList}
    />
  ),
  {
    tenant: graphql`
      fragment RunSearchList_tenant on Tenant {
        runConnection(
          status: $statuses
          sort: $sort
          search: $search
          createdBy: $userProfileHandles
          project: $projectHandles
          tag: $tagHandles
          first: $count
          after: $cursor
        ) @connection(key: "Tenant_runConnection") {
          edges {
            node {
              ...RunList_runs
            }
          }
        }
      }
    `,
  },
  {
    query: runSearchListQuery,
  },
);
