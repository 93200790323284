import { createRoot } from 'react-dom/client';

import clientApplication from './clientApplication';
// Import yupConfig before other local code for its side effects.
import './yupConfig';

function renderApp() {
  const container = document.getElementById('app')!;
  const root = createRoot(container);
  root.render(clientApplication());
}

interface HotLoadedNodeModule extends NodeModule {
  hot: any;
}

function runApp() {
  renderApp();
  const moduleObj = module as HotLoadedNodeModule;

  if (moduleObj.hot) {
    moduleObj.hot.accept(/clientApplication/, renderApp);
  }
}

window.addEventListener('load', runApp);
