/**
 * @generated SignedSource<<af9903121f23f2481c4b1e01d34b2c62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnalysisSearchList_tenant$data = {
  readonly analysisConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"AnalysisList_analyses">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AnalysisSearchList_tenant";
};
export type AnalysisSearchList_tenant$key = {
  readonly " $data"?: AnalysisSearchList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnalysisSearchList_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "projectHandles"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "statuses"
    },
    {
      "kind": "RootArgument",
      "name": "tagHandles"
    },
    {
      "kind": "RootArgument",
      "name": "userProfileHandles"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "analysisConnection"
        ]
      }
    ]
  },
  "name": "AnalysisSearchList_tenant",
  "selections": [
    {
      "alias": "analysisConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "createdBy",
          "variableName": "userProfileHandles"
        },
        {
          "kind": "Variable",
          "name": "project",
          "variableName": "projectHandles"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "statuses"
        },
        {
          "kind": "Variable",
          "name": "tag",
          "variableName": "tagHandles"
        }
      ],
      "concreteType": "AnalysisConnection",
      "kind": "LinkedField",
      "name": "__Tenant_analysisConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalysisEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Analysis",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AnalysisList_analyses"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "603734bbb181f5e131b9de3e72808fc5";

export default node;
