import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import ItemGrid, { TableColumns } from 'components/ItemGrid';
import { useTenantRoutes } from 'utils/tenantRoutes';

import AnalysisProgress from './AnalysisProgress';
import CreatedByListItem from './CreatedByListItem';
import NoItemFound from './NoItemFound';
import type { AnalysisListSubscription } from './__generated__/AnalysisListSubscription.graphql';
import type { AnalysisList_analyses$data as Analyses } from './__generated__/AnalysisList_analyses.graphql';

const subscription = graphql`
  subscription AnalysisListSubscription(
    $input: UpdateAnalysisSubscriptionInput!
  ) {
    updateAnalysis(input: $input) {
      analysis {
        ...AnalysisList_analyses
      }
    }
  }
`;

function AnalysisListSubscriber({ analysis }: { analysis: Analyses[number] }) {
  useSubscription<AnalysisListSubscription>({
    subscription,
    variables: {
      input: { analysisId: analysis.id },
    },
  });
  return null;
}

interface Props {
  analyses: Analyses;
  showProject?: boolean;
}

function AnalysisList({ analyses, showProject }: Props) {
  const routes = useTenantRoutes();
  const columns = React.useMemo<TableColumns<Analyses[number]>>(
    () => [
      {
        Header: (
          <FormattedMessage id="analysisList.name" defaultMessage="Name" />
        ),
        accessor: 'name',
      },
      {
        Header: (
          <FormattedMessage
            id="analysisList.workflow"
            defaultMessage="Workflow"
          />
        ),
        accessor: 'workflow',
        Cell: ({ row }) => (
          <>{`${row.original.workflow!.name} v${
            row.original.workflowRelease?.versionName
          }`}</>
        ),
      },
      {
        Header: (
          <FormattedMessage id="analysisList.status" defaultMessage="Status" />
        ),
        accessor: 'status',
        Cell: ({ row }) => <AnalysisProgress analysis={row.original} />,
      },
      {
        Header: (
          <FormattedMessage
            id="analysisList.project"
            defaultMessage="Project"
          />
        ),
        accessor: (row) => row.project?.name,
        id: 'project',
      },
      {
        Header: (
          <FormattedMessage
            id="analysisList.created"
            defaultMessage="Created"
          />
        ),
        accessor: 'createdAt',
        sort: 'CREATED_AT',
        Cell: ({ row }) => (
          <CreatedByListItem
            userProfile={row.original.createdBy?.userProfile ?? null}
            createdAt={row.original.createdAt}
          />
        ),
      },
    ],
    [],
  );

  if (analyses.length === 0) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage
            id="analysisList.itemType"
            defaultMessage="analyses"
          />
        }
      />
    );
  }

  return (
    <ItemGrid
      data={analyses}
      columns={columns}
      initialState={{
        hiddenColumns: [!showProject && 'project'].filter(Boolean) as string[],
      }}
      linkTo={(analysis: DeepNonNull<Analyses[number]>) =>
        routes.analysis({ project: analysis.project, analysis })
      }
      subscriberComponent={(analysis) => (
        <AnalysisListSubscriber analysis={analysis} />
      )}
      data-testid="AnalysisList"
    />
  );
}

export default createFragmentContainer(AnalysisList, {
  analyses: graphql`
    fragment AnalysisList_analyses on Analysis @relay(plural: true) {
      id
      handle
      name
      project {
        handle
        name
      }
      workflow {
        name
      }
      workflowRelease {
        versionName
      }
      workflowVersion
      status
      createdAt
      createdBy {
        userProfile {
          ...CreatedByListItem_userProfile
        }
      }

      ...AnalysisProgress_analysis
    }
  `,
});
