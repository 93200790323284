import classNames from 'classnames';
import React from 'react';

import BodyStyle from 'components/BodyStyle';
import Page from 'components/Page';
import PageContainer from 'components/PageContainer';
import Section from 'components/Section';

import NavbarBrand from './NavbarBrand';

import './BlankPage.scss';

interface Props {
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const rootClass = 'BlankPage';

export default function BlankPage({
  children,
  size = 'small',
  className,
  ...props
}: Props) {
  return (
    <Page>
      <div className={`${rootClass}__logo`}>
        <NavbarBrand />
      </div>

      <PageContainer center {...props}>
        <Section size={size} centerText>
          {children}
        </Section>
      </PageContainer>

      <BodyStyle className={classNames(`${rootClass}`, className)} />
    </Page>
  );
}
