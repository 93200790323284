import invariant from 'invariant';
import { createContext, useContext } from 'react';
import { graphql } from 'react-relay';

import UserRole from 'config/userRole';

import type { useTenantQuery$data as QueryResponse } from './__generated__/useTenantQuery.graphql';

export const useTenantQuery = graphql`
  query useTenantQuery($tenantSlug: String!) {
    tenant: tenantBySlug(slug: $tenantSlug) {
      id
      handle
      name
      slug

      currentUser {
        role
      }
    }
  }
`;

type TenantContextType = DeepNonNull<
  QueryResponse['tenant'] & { readonly tenantSlug: string }
>;
export const TenantContext = createContext<TenantContextType>(null as any);

// A consumer does not necessarily need to pass a full user object to use this function
type UserRole = { role: number };

export function isAdmin(user: UserRole) {
  return user.role >= UserRole.ADMIN;
}

function useTenant() {
  const tenant = useContext(TenantContext);

  invariant(tenant, '`useTenant` must be used within a tenant route.');

  return {
    ...tenant,
    isAdmin: isAdmin(tenant.currentUser),
    tenantSlug: tenant.slug,
    tenantId: tenant.id,
  };
}

export default useTenant;
