/**
 * @generated SignedSource<<cdf5b7fb9718ba613a4c446fcae7f363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Runs_runPageQuery$variables = {
  runHandle: string;
};
export type Runs_runPageQuery$data = {
  readonly run: {
    readonly " $fragmentSpreads": FragmentRefs<"RunPage_run">;
  } | null;
};
export type Runs_runPageQuery = {
  response: Runs_runPageQuery$data;
  variables: Runs_runPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "runHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "runHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v7/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Runs_runPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Run",
        "kind": "LinkedField",
        "name": "run",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RunPage_run"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Runs_runPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Run",
        "kind": "LinkedField",
        "name": "run",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "LibraryLoadingConnection",
            "kind": "LinkedField",
            "name": "libraryLoadingConnection",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "libraryLoadingConnection(first:0)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "RunDataPlotConnection",
                "kind": "LinkedField",
                "name": "plotConnection",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "plotConnection(first:0)"
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "RunConnection",
            "kind": "LinkedField",
            "name": "splitRunsConnection",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": "splitRunsConnection(first:0)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "origin",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followingStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RawParam",
            "kind": "LinkedField",
            "name": "customParams",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "Analysis",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "Library",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "Run",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "Sample",
                "abstractKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "639153461e2d7a3679544d879a28c649",
    "id": null,
    "metadata": {},
    "name": "Runs_runPageQuery",
    "operationKind": "query",
    "text": "query Runs_runPageQuery(\n  $runHandle: String!\n) {\n  run(handle: $runHandle) {\n    ...RunPage_run\n    id\n  }\n}\n\nfragment AbortRunControl_run on Run {\n  id\n  status\n}\n\nfragment CopyRunControl_run on Run {\n  handle\n  origin\n}\n\nfragment DeleteRunControl_run on Run {\n  id\n  project {\n    id\n  }\n  tenant {\n    id\n  }\n}\n\nfragment EditRunControl_run on Run {\n  handle\n}\n\nfragment FollowRunControl_run on Run {\n  followingStatus\n  id\n}\n\nfragment RunPageHeader_run on Run {\n  ... on Entity {\n    __isEntity: __typename\n    name\n    ... on Project {\n      handle\n    }\n    ... on Analysis {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Library {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Run {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Sample {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n  }\n  ...CopyRunControl_run\n  ...EditRunControl_run\n  ...AbortRunControl_run\n  ...ShareRunControl_run\n  ...DeleteRunControl_run\n  ...FollowRunControl_run\n  customParams {\n    key\n    value\n  }\n}\n\nfragment RunPageTabs_run on Run {\n  handle\n  numAnalyses\n  numNotes\n  status\n  libraryLoadingConnection(first: 0) {\n    pageInfo {\n      hasNextPage\n    }\n  }\n  data {\n    __typename\n    fileUrl\n    plotConnection(first: 0) {\n      pageInfo {\n        hasNextPage\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  splitRunsConnection(first: 0) {\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment RunPage_run on Run {\n  status\n  ...RunPageTabs_run\n  ...RunPageHeader_run\n}\n\nfragment ShareRunControl_run on Run {\n  status\n  ...ShareRunModal_run\n}\n\nfragment ShareRunModal_run on Run {\n  id\n  handle\n}\n"
  }
};
})();

(node as any).hash = "73b959662e375a0952af242bdb2f94dc";

export default node;
