import classNames from 'classnames';
import { useRouter } from 'found';
import React, { useEffect, useState } from 'react';
import {
  RiCloseFill as IcoClose,
  RiErrorWarningFill as IcoFailure,
  RiCheckboxCircleFill as IcoSuccess,
  RiLoader3Fill as IcoSyncing,
} from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';
import Dropdown, { DropdownMenuLabel } from 'components/Dropdown';
import Icon from 'components/Icon';
import Progress from 'components/Progress';
import { Upload, useUploader } from 'components/UploadProvider';

import './UploadManager.scss';

interface Props {
  dataTestId?: string;
}

const rootClass = 'UploadManager';

function renderProgress(upload: Upload) {
  if (upload.status === 'completed')
    return (
      <span className={classNames(`${rootClass}__success`)}>
        <FormattedMessage
          id="uploadManager.completed"
          defaultMessage="Completed"
        />
      </span>
    );
  if (upload.status === 'failed')
    return (
      <span className={classNames(`${rootClass}__success`)}>
        <FormattedMessage id="uploadManager.failed" defaultMessage="Failed" />
      </span>
    );

  return <Progress progress={upload.status} />;
}

function renderIcon(uploads: Upload[]) {
  if (uploads.some((u) => u.status === 'failed'))
    return (
      <Icon>
        <IcoFailure />
      </Icon>
    );
  if (uploads.every((u) => u.status === 'completed'))
    return (
      <Icon>
        <IcoSuccess />
      </Icon>
    );

  return (
    <Icon>
      <IcoSyncing className={classNames(`${rootClass}__spinner`)} />
    </Icon>
  );
}

export default function UploadManager({ dataTestId = rootClass }: Props) {
  const { router } = useRouter();
  const { subscribe, clearUpload } = useUploader();
  const [uploads, setUploads] = useState<Upload[]>([]);

  function isUploadDone(upload: Upload) {
    const { status } = upload;
    return status === 'completed' || status === 'failed';
  }

  useEffect(() => subscribe(setUploads), [subscribe]);

  useEffect(
    () =>
      router.addNavigationListener(
        (location) => {
          if (!uploads.some((u) => !isUploadDone(u))) return undefined;
          return !!location;
        },
        { beforeUnload: true },
      ),
    [router, uploads],
  );

  if (uploads.length === 0) {
    return null;
  }

  return (
    <Dropdown
      dataTestId={dataTestId}
      trigger={<Button variant="navbar">{renderIcon(uploads)}</Button>}
    >
      <DropdownMenuLabel>
        <FormattedMessage
          id="uploadManager.header"
          defaultMessage="Upload status"
        />
      </DropdownMenuLabel>

      {uploads.map((upload) => (
        <DropdownMenuLabel className={`${rootClass}__dd-row`}>
          <span
            className={classNames(`${rootClass}__title`, 'ellipsis')}
            title={upload.title}
          >
            {upload.title}
          </span>

          <div className={classNames(`${rootClass}__progress`)}>
            {renderProgress(upload)}
            {isUploadDone(upload) && (
              <Button
                variant="text-secondary"
                className={classNames(`${rootClass}__close-button`)}
                onClick={() => clearUpload(upload)}
              >
                <IcoClose className={classNames(`${rootClass}__icon`)} />
              </Button>
            )}
          </div>
        </DropdownMenuLabel>
      ))}
    </Dropdown>
  );
}
