/**
 * @generated SignedSource<<504c9c983c245efc2e01052fd3d4d944>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeviceSoftwareVersion_device$data = {
  readonly id: string;
  readonly softwareVersion: string | null;
  readonly state: {
    readonly code: string | null;
    readonly label: string | null;
    readonly progress: number | null;
  } | null;
  readonly " $fragmentType": "DeviceSoftwareVersion_device";
};
export type DeviceSoftwareVersion_device$key = {
  readonly " $data"?: DeviceSoftwareVersion_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceSoftwareVersion_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceSoftwareVersion_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "softwareVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceState",
      "kind": "LinkedField",
      "name": "state",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "progress",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "9f570583f28ce97fe8c9513b26809512";

export default node;
