import classNames from 'classnames';
import React, { Key } from 'react';
import { RiCheckFill } from 'react-icons/ri';

interface Props<DataItem> {
  data: DataItem[];
  textField: keyof DataItem;
  dataKey: keyof DataItem;
  onClick: (arg0: DataItem) => void;
  value?: string;
}

import './Listbox.scss';

const rootClass = 'Listbox';

function Listbox<DataItem>({
  data,
  value,
  textField,
  dataKey,
  onClick,
}: Props<DataItem>) {
  return (
    <ul role="menu" className={rootClass}>
      {data.map((item: DataItem) => (
        <li
          key={item[dataKey] as Key}
          role="option"
          onClick={() => onClick(item)}
          onKeyDown={() => onClick(item)}
          className={classNames(
            `${rootClass}__menuitem`,
            value === item[dataKey] && `${rootClass}__menuitem--active`,
          )}
          aria-selected={value === item[dataKey]}
        >
          <div className={`${rootClass}__item-icon`}>
            {value === item[dataKey] && <RiCheckFill />}
          </div>
          <div className={`${rootClass}__item-detail`}>
            {item[textField] as string}
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Listbox;
