import { useRouter } from 'found';
import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import InfoPageCard from 'components/InfoPageCard';
import { REDIRECT_ON_FINISH } from 'config/delays';
import * as routes from 'utils/routes';

import BlankPage from './BlankPage';
import type { VerifyEmailChangePageMutation as Mutation } from './__generated__/VerifyEmailChangePageMutation.graphql';
import BusyToDoneAnimation from './animation/BusyToDoneAnimation';

const mutation = graphql`
  mutation VerifyEmailChangePageMutation(
    $input: AcceptEmailChangeVerificationInput!
  ) {
    acceptEmailChangeVerificationOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const messages = defineMessages({
  title: {
    id: 'verifyEmailChangePage.title',
    defaultMessage: 'E-mail Address Change Request',
  },
  verifyingEmailChangeSubtitle: {
    id: 'verifyEmailChangePage.verifyingEmailChangeSubtitle',
    defaultMessage: 'Verifying request...',
  },
  verifyEmailChangeSuccessSubtitle: {
    id: 'verifyEmailChangePage.verifyEmailChangeSucessSubtitle',
    defaultMessage: 'Your email address has been changed successfully',
  },
  verifyEmailChangeErrorSubtitle: {
    id: 'verifyEmailChangePage.verifyEmailChangeErrorSubtitle',
    defaultMessage:
      "Oops looks like that didn't work. The verification attempt probably " +
      'expired and needs to be resent',
  },
});

export default function VerifyEmailChangePage() {
  const { match, router } = useRouter();
  const { token } = match.params;

  const [mutate, { loading, error, data }] = useMutation<Mutation>(mutation, {
    variables: {
      input: {
        token,
      },
    },
    onCompleted: () => {
      setTimeout(() => router.replace(routes.root()), REDIRECT_ON_FINISH);
    },
  });

  useEffect(() => {
    mutate();
    // explicitly only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { title } = messages;
  let subtitle = messages.verifyingEmailChangeSubtitle;
  if (error) {
    subtitle = messages.verifyEmailChangeErrorSubtitle;
  } else if (data) {
    subtitle = messages.verifyEmailChangeSuccessSubtitle;
  }

  return (
    <BlankPage>
      <InfoPageCard title={title} subtitle={subtitle}>
        <BusyToDoneAnimation
          busy={loading}
          theme={error ? 'failure' : 'success'}
        />
      </InfoPageCard>
    </BlankPage>
  );
}
