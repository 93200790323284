import { FarceRouterOptions, Match } from 'found';
import createFarceRouter from 'found/createFarceRouter';
import createRender from 'found/createRender';
import { ScrollManager } from 'found-scroll';
import React, { useMemo } from 'react';
import { Environment, ReactRelayContext } from 'react-relay';

import { AuthContextValue } from 'components/AuthContext';
import UploadProvider from 'components/UploadProvider';

import Resolver from './Resolver';
import LaunchDarklyManager from './utils/LaunchDarklyManager';

interface Props {
  environment: Environment;
  auth: AuthContextValue;
  viewerLocalId: string | null;
  launchDarkly: LaunchDarklyManager;
}

export default function createAppRouter({
  renderError,
  store,
}: Partial<FarceRouterOptions>) {
  const render = createRender({ renderError });

  const ConnectedRouter = createFarceRouter({
    render: (renderArgs: Match) => (
      <ScrollManager renderArgs={renderArgs}>
        <UploadProvider>{render(renderArgs)}</UploadProvider>
      </ScrollManager>
    ),
    store,
  } as any);

  function Router({ environment, auth, viewerLocalId, launchDarkly }: Props) {
    // Use memoized resolver to avoid spuriously rerunning match resolution.
    const resolver = useMemo(
      () => new Resolver(environment, auth),
      [environment, auth],
    );

    const context = useMemo(
      () => ({
        environment,
        launchDarkly,
        viewerLocalId,
      }),
      [environment, launchDarkly, viewerLocalId],
    );

    return (
      <ReactRelayContext.Provider value={context}>
        <ConnectedRouter resolver={resolver} matchContext={context} />
      </ReactRelayContext.Provider>
    );
  }

  return Router;
}
