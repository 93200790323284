import HttpError from 'found/HttpError';

import { RelayRenderArgs } from 'components/Route';

type Props<T extends string> = {
  tenant: { id: string };
} & {
  [_K in T]: { tenant: { id: string } };
};

export function createCheckTenantItem<T extends string>(fieldName: T) {
  return ({ props }: RelayRenderArgs<Props<T>>) => {
    if (!props) return;
    const { [fieldName]: item, tenant } = props;

    if (!item || item.tenant.id !== tenant.id) throw new HttpError(404);
  };
}

export default createCheckTenantItem('item');
