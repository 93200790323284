/**
 * @generated SignedSource<<28c1b0a8c16d9c8a7a440fe0a8896c63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type firstProjectRedirect_tenant$data = {
  readonly projectConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly handle: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly slug: string | null;
  readonly " $fragmentType": "firstProjectRedirect_tenant";
};
export type firstProjectRedirect_tenant$key = {
  readonly " $data"?: firstProjectRedirect_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"firstProjectRedirect_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "firstProjectRedirect_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "ProjectConnection",
      "kind": "LinkedField",
      "name": "projectConnection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Project",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "handle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projectConnection(first:1)"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "3ae38affeffd0df62c63b976b6bf7c24";

export default node;
