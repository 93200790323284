import React from 'react';
import { FormattedMessage } from 'react-intl';

import AboutModal from './AboutModal';
import { DropdownMenuItem } from './Dropdown';

interface Props {
  dataTestId?: string;
}

const rootClass = 'AboutControl';

const AboutControl = React.forwardRef<any, Props>(
  ({ dataTestId = rootClass }, ref) => {
    return (
      <AboutModal
        trigger={
          <DropdownMenuItem
            data-testid={`${dataTestId}-about`}
            ref={ref}
            onSelect={(event) => {
              event.preventDefault();
            }}
          >
            <FormattedMessage id="aboutControl.title" defaultMessage="About" />
          </DropdownMenuItem>
        }
      />
    );
  },
);

export default AboutControl;
