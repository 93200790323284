import classNames from 'classnames';
import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';

import Button from 'components/Button';
import Section from 'components/Section';
import * as routes from 'utils/routes';

import type { EulaType } from './__generated__/EulaPageMutation.graphql';
import type { EulaPage_viewer$data as Viewer } from './__generated__/EulaPage_viewer.graphql';

import './EulaPage.scss';

interface Props {
  viewer: DeepNonNull<Viewer>;
  dataTestId?: string;
}

const rootClass = 'EulaPage';

function EulaPage({ viewer, dataTestId = rootClass }: Props) {
  const { match, router } = useRouter();
  const { user, latestCloudEula, latestDeviceEula } = viewer;
  const { hasAcceptedLatestCloudEula, hasAcceptedLatestDeviceEula } = user;
  const { eulaType } = match.params;

  const [mutate] = useMutation(graphql`
    mutation EulaPageMutation($input: UserAcceptEulaInput!) {
      userAcceptEulaOrError(input: $input) {
        ...mutationError_error @relay(mask: false)
        ... on UserAcceptEulaPayload {
          user {
            hasAcceptedLatestCloudEula
            hasAcceptedLatestDeviceEula
          }
        }
      }
    }
  `);

  const eula =
    eulaType === 'CLOUD'
      ? {
          latestEula: latestCloudEula,
          type: 'Cloud',
          hasAcceptedLatestEula: hasAcceptedLatestCloudEula,
        }
      : {
          latestEula: latestDeviceEula,
          type: 'Device',
          hasAcceptedLatestEula: hasAcceptedLatestDeviceEula,
        };

  return (
    <Section size="medium" data-testid={dataTestId} className={rootClass}>
      <h2>
        {eula.hasAcceptedLatestEula ? (
          <FormattedMessage
            defaultMessage="Latest {type} EULA"
            id="eula.titleAccepted"
            values={{
              type: eula.type,
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Please review and accept the latest {type} EULA"
            id="eula.titleAccept"
            values={{
              type: eula.type,
            }}
          />
        )}
      </h2>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: eula.latestEula.textHtml }}
        className={classNames(`${rootClass}__text`)}
      />
      {!eula.hasAcceptedLatestEula && (
        <div className={classNames(`${rootClass}__button-container`)}>
          <Button
            onClick={() => {
              mutate({
                variables: {
                  input: {
                    version: eula.latestEula.version,
                    userId: user.id,
                    eulaType: eulaType as EulaType,
                  },
                },
                onCompleted: () => {
                  router.replace(
                    match.location.state?.prevLocation || routes.root(),
                  );
                },
              });
            }}
          >
            <FormattedMessage defaultMessage="Accept" id="eula.accept" />
          </Button>
        </div>
      )}
    </Section>
  );
}

export default createFragmentContainer(EulaPage, {
  viewer: graphql`
    fragment EulaPage_viewer on Viewer {
      latestCloudEula {
        id
        version
        textHtml
      }
      latestDeviceEula {
        id
        version
        textHtml
      }
      user {
        id
        hasAcceptedLatestCloudEula
        hasAcceptedLatestDeviceEula
      }
    }
  `,
});
