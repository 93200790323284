/**
 * @generated SignedSource<<b8096c0909c2e4f2a7be023b57755015>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TenantSwitchControl_tenant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SwitchTenantModal_activeTenant">;
  readonly " $fragmentType": "TenantSwitchControl_tenant";
};
export type TenantSwitchControl_tenant$key = {
  readonly " $data"?: TenantSwitchControl_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TenantSwitchControl_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantSwitchControl_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SwitchTenantModal_activeTenant"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "9f82b5e31e26fb4af630dbcd738b3def";

export default node;
