import React from 'react';

import Breadcrumbs, { AppsBreadcrumb } from 'components/Breadcrumbs';
import Header from 'components/Header';

interface Props {
  children: React.ReactNode;
}

export default function AppsPage({ children }: Props) {
  return (
    <>
      <Header.PageHeader data-testid="AppsPage">
        <Breadcrumbs>
          <AppsBreadcrumb />
        </Breadcrumbs>
      </Header.PageHeader>

      {children}
    </>
  );
}
