import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { getInfiniteListVariables } from 'components/utils/createInfiniteList';

export default (
  <Route path="notifications">
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "notifications" */ './components/NotificationsPage'
        ).then((p) => p.default)
      }
      query={graphql`
        query NotificationsPage_pageQuery(
          $tenantSlug: String!
          $count: Int!
          $cursor: String
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NotificationsPage_tenant
          }
        }
      `}
      prepareVariables={getInfiniteListVariables}
    />
  </Route>
);
