import pick from 'lodash/pick';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { GraphQLTaggedNode, graphql } from 'react-relay';

import { Status as RunStatus } from 'config/runStatus';
import { analysisStatus } from 'messages/analyses';
import libraryMessages from 'messages/libraries';
import runMessages from 'messages/runs';

import AllSearchList from './components/AllSearchList';
import AnalysisSearchList, {
  analysisSearchListQuery,
} from './components/AnalysisSearchList';
import LibrarySearchList, {
  librarySearchListQuery,
} from './components/LibrarySearchList';
import RunSearchList, { runSearchListQuery } from './components/RunSearchList';
import SampleSearchList, {
  samplesSearchListQuery,
} from './components/SampleSearchList';
import type { ItemSearchProps } from './components/SearchBar';

export const ENTITIES = [
  'analyses',
  'libraries',
  'runs',
  'specimens',
] as const;
export const SEARCHABLE_ENTITIES = ['all', ...ENTITIES] as const;
// https://stackoverflow.com/a/45486495/522415
export type SEARCHABLE_ENTITY = (typeof SEARCHABLE_ENTITIES)[number];
export type ENTITY = (typeof ENTITIES)[number];

type MessageMap = Record<string, MessageDescriptor>;
type OptionMessageMap = Record<SEARCHABLE_ENTITY, MessageDescriptor>;
type SearchPages = Record<
  SEARCHABLE_ENTITY,
  {
    component: any;
    query: GraphQLTaggedNode;
  }
>;

type StatusItem = { value: SEARCHABLE_ENTITY; type: 'Status' };

const buildStaticStatusRenderFunction = (
  statuses: Partial<OptionMessageMap>,
): ItemSearchProps<StatusItem> => ({
  type: 'Status',
  groupName: 'Status',
  renderPill: ({ item, getTextField }) => (
    <FormattedMessage
      id="routes.status"
      defaultMessage="Status: {status}"
      values={{ status: getTextField(item) }}
    />
  ),
  getTextField: ({ formatMessage, item }) =>
    formatMessage((statuses as OptionMessageMap)[item.value]),
  getQuery: ({ value }) => ({ status: value }),
  getValues: () =>
    Object.keys(statuses).map((value: SEARCHABLE_ENTITY) => ({ value })),
  getParam: (_, match) => {
    const status = match?.location.query.status;
    return status && { value: status };
  },
});

const buildStatusMessages = ({
  keys,
  messages,
}: {
  keys: string[];
  messages: MessageMap;
}): Partial<MessageMap> => pick<MessageMap>(messages, keys);

export const renderFunctions = {
  analyses: [
    buildStaticStatusRenderFunction(
      buildStatusMessages({
        keys: Object.keys(analysisStatus),
        messages: analysisStatus,
      }),
    ),
  ],
  libraries: [
    buildStaticStatusRenderFunction(
      buildStatusMessages({
        keys: ['ABORTED', 'FAILED', 'IN_PROGRESS', 'PLANNED', 'READY'],
        messages: libraryMessages,
      }),
    ),
  ],
  runs: [
    buildStaticStatusRenderFunction(
      buildStatusMessages({
        keys: Object.values(RunStatus),
        messages: runMessages,
      }),
    ),
  ],
};

export const searchPages: SearchPages = {
  all: {
    component: AllSearchList,
    query: graphql`
      query searchPages_AllSearchListQuery(
        $projectHandles: [IdOrHandle!]
        $search: String
        $analysisSort: [AnalysisSorting!]
        $librarySort: [LibrarySorting!]
        $runSort: [RunSorting!]
        $sampleSort: [SampleSorting!]
        $tagHandles: [IdOrHandle!]
        $userProfileHandles: [IdOrHandle!]
        $tenantSlug: String!
      ) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          ...AllSearchList_tenant
        }
      }
    `,
  },
  analyses: {
    component: AnalysisSearchList,
    query: analysisSearchListQuery,
  },
  libraries: {
    component: LibrarySearchList,
    query: librarySearchListQuery,
  },
  runs: {
    component: RunSearchList,
    query: runSearchListQuery,
  },
  specimens: {
    component: SampleSearchList,
    query: samplesSearchListQuery,
  },
};
