import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { TextAccessor, dataValue } from 'react-widgets/Accessors';
import BaseDropdownList, { DropdownProps } from 'react-widgets/DropdownList';

const rootClass = 'DropdownList';
const EMPTY_ARRAY: any[] = [];

import './DropdownList.scss';
import Spinner from './Spinner';

export interface DropdownListProps<TDataItem>
  extends Omit<DropdownProps<TDataItem>, 'placeholder' | 'textField'> {
  /**
   * Allow values to be cleared by selecting the first item
   */
  allowEmpty?: boolean;
  /**
   * The token representing "empty", shouldn't need to be changed unless
   * `null` is a sematically meaningful value elsewhere in the dataset.
   */
  emptyValue?: any;
  /**
   * A react-intl message used as the text for the "empty" option.
   */
  emptyMessage?: MessageDescriptor;
  placeholder?: MessageDescriptor;
  textField?: TextAccessor | ((item: unknown) => MessageDescriptor);
  /**
   * Hides the checkbox next to the selected item in the dropdown.
   * This may be desirable when using the DropdownList as base for building a
   * more specific input, such as a search bar.
   * */
  hideSelectedOptionIndicator?: boolean;
}

function isMessage(msg: any) {
  return msg && msg.id && msg.defaultMessage;
}

export function useLocalizedTextAccessor({
  allowEmpty = false,
  emptyValue = null,
  emptyMessage,
  textField,
}: {
  allowEmpty?: boolean;
  emptyValue?: any;
  emptyMessage?: MessageDescriptor;
  textField?: TextAccessor | ((item: unknown) => MessageDescriptor);
} = {}) {
  const intl = useIntl();
  return useCallback(
    (item: unknown) => {
      if (allowEmpty && item === emptyValue) {
        return emptyMessage ? intl.formatMessage(emptyMessage) : '\u2003';
      }

      const text = dataValue(item, textField);

      return isMessage(text)
        ? intl.formatMessage(text as MessageDescriptor)
        : String(text);
    },
    [emptyMessage, emptyValue, intl, textField, allowEmpty],
  );
}

function DropdownList<TDataItem = unknown>({
  className,
  data = EMPTY_ARRAY,
  allowEmpty = false,
  placeholder,
  emptyValue = null,
  dataKey,
  textField,
  renderValue,
  emptyMessage,
  value,
  ...props
}: DropdownListProps<TDataItem>) {
  const intl = useIntl();
  const options = useMemo(() => {
    let opts = data;

    if (allowEmpty && (data.length || value)) {
      opts = [emptyValue, ...data];
    }

    return opts;
  }, [allowEmpty, data, emptyValue, value]);

  const textAccessor = useLocalizedTextAccessor({
    allowEmpty,
    emptyMessage,
    emptyValue,
    textField,
  });

  const renderItem = (args: any) =>
    renderValue ? renderValue(args) : args.text;
  const formattedPlaceholder = placeholder && intl.formatMessage(placeholder);

  return (
    <div className={classNames(rootClass, className)}>
      <BaseDropdownList
        {...props}
        value={value}
        data={options}
        dataKey={dataKey}
        textField={textAccessor}
        placeholder={formattedPlaceholder}
        busySpinner={<Spinner />}
        renderValue={renderItem}
        className={classNames(`${rootClass}__dropdown`, className)}
      />
    </div>
  );
}

export default DropdownList;
