import { useRouter } from 'found';
import Link from 'found/Link';
import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { InferType, Message, object, string } from 'yup';

import { AuthContextValue, withAuth } from 'components/AuthContext';
import FieldGroup from 'components/FieldGroup';
import Text from 'components/Text';
import login from 'utils/login';
import { AlphaNumericPasswordRegex } from 'utils/regex';
import * as routes from 'utils/routes';

import AuthForm from './AuthForm';
import BlankPage from './BlankPage';
import ConfirmLogoutDialog from './ConfirmLogoutDialog';
import TitleMessage from './TitleMessage';
import type { SignupPageMutation as Mutation } from './__generated__/SignupPageMutation.graphql';
import type { SignupPage_invitation$data as Invitation } from './__generated__/SignupPage_invitation.graphql';

interface Props {
  auth: AuthContextValue;
  invitation: Invitation;
}

const messages = defineMessages({
  email: {
    id: 'signupPage.email',
    defaultMessage: 'Email',
  },
  missingPassword: {
    id: 'signupPage.missingPassword',
    defaultMessage: 'Please enter a password',
  },
  createPassword: {
    id: 'signupPage.createPassword',
    defaultMessage: 'Create Password',
  },
  strength: {
    id: 'signupPage.strength',
    defaultMessage:
      'Password must be at least 8 characters long and contain one uppercase, one lowercase, one number and one special character',
  },
});

// This component is a weird hack to prevent autocomplete per
// https://stackoverflow.com/a/19174309/251162.
//
// we use sr-only to avoid a clear signal to the browser that this is not visible
// aria-hidden prevents SRs from reading it as if it was display: none;
const AutofillHackInput = () => {
  return (
    <input
      type="password"
      name="password"
      aria-hidden="true"
      className="sr-only"
    />
  );
};

const mutation = graphql`
  mutation SignupPageMutation($input: SignupWithInvitationInput!) {
    signupWithInvitationOrError(input: $input) {
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

const schema = object({
  email: string().email().required().default(''),
  password: string()
    .required(messages.missingPassword as any)
    .matches(AlphaNumericPasswordRegex, {
      message: messages.strength as Message,
    })
    .default(''),
});

function SignupPage({ auth, invitation }: Props) {
  const [value, setValue] = useState<InferType<typeof schema>>();
  const { router, match } = useRouter();
  const intl = useIntl();

  const onCompleted = async () => {
    // If signup is successful, try to log in.
    try {
      const { email, password } = value!;
      const authInfo = await login(email, password);
      auth.setAccessToken(authInfo);
    } catch {
      // The root redirect to user setup won't work, but we'll at least show
      // either the login page or the account created page.
    }

    router.replace(routes.root());
  };

  const getInput = (input: InferType<typeof schema>) => {
    const { invitationToken } = match.location.query;
    // XXX: save the input to use it in onComplete. this is a little dirty
    setValue(input);
    return {
      token: invitationToken,
      password: input.password,
    };
  };

  return (
    <BlankPage>
      <ConfirmLogoutDialog>
        <FormattedMessage
          id="signupPage.confirmLogout"
          defaultMessage="You are already logged in. Do you want to log out to accept this invitation?"
        />
      </ConfirmLogoutDialog>
      <TitleMessage>
        <FormattedMessage
          id="signupPage.title"
          defaultMessage="Complete Sign Up"
        />
      </TitleMessage>
      <AuthForm<Mutation, typeof schema>
        schema={schema}
        defaultValue={{
          email: invitation.email || '',
          password: '',
        }}
        mutation={mutation}
        getInput={getInput}
        onCompleted={onCompleted}
      >
        <AutofillHackInput />
        <FieldGroup
          readOnly
          name="email"
          type="email"
          label={<FormattedMessage {...messages.email} />}
        />

        <FieldGroup
          label={<FormattedMessage {...messages.createPassword} />}
          name="password"
          type="password"
          placeholder={intl.formatMessage({
            id: 'signupPage.createPassword',
            defaultMessage: 'Create Password',
          })}
        />

        <AuthForm.Submit>
          <FormattedMessage
            id="signupPage.submit"
            defaultMessage="Create an Account"
          />
        </AuthForm.Submit>
      </AuthForm>
      <Text color="draft">
        <FormattedMessage
          id="signupPage.alreadyHaveAccount"
          defaultMessage="Already have an account? {loginLink}"
          values={{
            loginLink: (
              <Link to="/">
                <FormattedMessage
                  id="signupPage.login"
                  defaultMessage="Log In"
                />
              </Link>
            ),
          }}
        />
      </Text>
    </BlankPage>
  );
}

export default createFragmentContainer(withAuth(SignupPage), {
  invitation: graphql`
    fragment SignupPage_invitation on InvitationInfo {
      email
      tenantInfo {
        name
        slug
      }
    }
  `,
});
