import type { Match } from 'found';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { getSessionStore } from 'utils/SessionStore';
import * as routes from 'utils/routes';

import type { tenantRedirectQuery as Query } from './__generated__/tenantRedirectQuery.graphql';
import firstProjectRedirect from './firstProjectRedirect';

function getQuery(match: Match) {
  const {
    params: { tenantSlug },
    location,
  } = match;

  const session = getSessionStore(match);
  const project = session.getLastVisitedProjectForTenant(tenantSlug);

  if (project) {
    // Redirect in the query aggregation phase. We don't need server data.
    throw new RedirectException({
      pathname: routes.project({
        project: { handle: project.handle },
        tenant: { slug: tenantSlug },
      }),
      state: {
        isTenantRedirect: true,
        prevLocation: location,
      },
    });
  }

  return graphql`
    query tenantRedirectQuery($tenantSlug: String!) {
      tenant: tenantBySlug(slug: $tenantSlug) {
        ...firstProjectRedirect_tenant @relay(mask: false)
      }
    }
  `;
}

function tenantRedirect({
  props,
}: {
  props?: DeepNonNull<Query['response']>;
}) {
  if (!props) {
    return;
  }

  firstProjectRedirect(props.tenant);
}

tenantRedirect.getQuery = getQuery;

export default tenantRedirect;
