/**
 * @generated SignedSource<<5785b12372ef83e66b75a75240307245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Search_SearchPageQuery$variables = {
  hasProject: boolean;
  hasTag: boolean;
  hasUserProfile: boolean;
  projectHandle: string;
  tagHandle: string;
  userProfileHandle: string;
};
export type Search_SearchPageQuery$data = {
  readonly project?: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchPage_project">;
  } | null;
  readonly tag?: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchPage_tag">;
  } | null;
  readonly userProfile?: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchPage_userProfile">;
  } | null;
};
export type Search_SearchPageQuery = {
  response: Search_SearchPageQuery$data;
  variables: Search_SearchPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasProject"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTag"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserProfile"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectHandle"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagHandle"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userProfileHandle"
},
v6 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "userProfileHandle"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "projectHandle"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "tagHandle"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Search_SearchPageQuery",
    "selections": [
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SearchPage_userProfile"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SearchPage_project"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasTag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SearchPage_tag"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Search_SearchPageQuery",
    "selections": [
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasTag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5163eb2ff81f774489b33ddedb103808",
    "id": null,
    "metadata": {},
    "name": "Search_SearchPageQuery",
    "operationKind": "query",
    "text": "query Search_SearchPageQuery(\n  $projectHandle: String!\n  $hasProject: Boolean!\n  $userProfileHandle: String!\n  $hasUserProfile: Boolean!\n  $tagHandle: String!\n  $hasTag: Boolean!\n) {\n  userProfile(handle: $userProfileHandle) @include(if: $hasUserProfile) {\n    ...SearchPage_userProfile\n    id\n  }\n  project(handle: $projectHandle) @include(if: $hasProject) {\n    ...SearchPage_project\n    id\n  }\n  tag(handle: $tagHandle) @include(if: $hasTag) {\n    ...SearchPage_tag\n    id\n  }\n}\n\nfragment SearchPage_project on Project {\n  name\n}\n\nfragment SearchPage_tag on Tag {\n  name\n}\n\nfragment SearchPage_userProfile on UserProfile {\n  displayName\n}\n"
  }
};
})();

(node as any).hash = "08acc58e33c5248634e97f10987819ed";

export default node;
