import classNames from 'classnames';
import { Link, useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import NoItemFound from 'components/NoItemFound';
import messages from 'messages/common';
import { getNodes } from 'utils/graphql';
import { useTenantRoutes } from 'utils/tenantRoutes';

import { entityMessages } from './SearchBar';
import type { ENTITY, SEARCHABLE_ENTITY } from '../searchPages';

import './SearchList.scss';

interface MoreLinkProps {
  entityType: SEARCHABLE_ENTITY;
}

const rootClass = 'SearchList';

function MoreLink({ entityType }: MoreLinkProps) {
  const tenantRoutes = useTenantRoutes();
  const { location } = useRouter().match;

  return (
    <Link
      className={classNames(`${rootClass}__more-link`)}
      to={{
        pathname: tenantRoutes.search(),
        query: { ...location.query, entityType },
      }}
    >
      <FormattedMessage {...messages.more} />
    </Link>
  );
}

interface Props {
  connection: GenericConnection<any>;
  entityType: ENTITY;
  Component: React.ElementType<{
    showProject?: boolean;
    footer?: React.ReactNode;
  }>;
  showMore?: boolean;
}

function SearchList({ connection, Component, showMore, entityType }: Props) {
  const nodes = getNodes(connection);
  const { location } = useRouter().match;
  const isSearchingAllProjects = !location.query.project;

  if (nodes.length === 0) {
    return (
      <NoItemFound
        itemType={<FormattedMessage {...entityMessages[entityType]} />}
      />
    );
  }

  // translate entity name since our internal vs. external naming convention differs ("samples" vs. "specimens")
  const type = entityType === 'specimens' ? 'samples' : entityType;

  return (
    <div className={rootClass}>
      <Component
        {...{
          [type]: nodes,
        }}
        showProject={isSearchingAllProjects}
      />
      {showMore && <MoreLink entityType={entityType} />}
    </div>
  );
}

export default SearchList;
