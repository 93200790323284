/**
 * @generated SignedSource<<cdb27d22106f9e434f63d52071d09bdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routes_settingsRootQuery$variables = {};
export type routes_settingsRootQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AppPage_viewer">;
  } | null;
};
export type routes_settingsRootQuery = {
  response: routes_settingsRootQuery$data;
  variables: routes_settingsRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_settingsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_settingsRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numTenants",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f87a85eaefe80315bea54f0481032eee",
    "id": null,
    "metadata": {},
    "name": "routes_settingsRootQuery",
    "operationKind": "query",
    "text": "query routes_settingsRootQuery {\n  viewer {\n    ...AppPage_viewer\n    id\n  }\n}\n\nfragment AppHeader_viewer on Viewer {\n  ...HeaderUserMenu_viewer\n}\n\nfragment AppPage_viewer on Viewer {\n  ...BaseAppPage_viewer\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment BaseAppPage_viewer on Viewer {\n  ...AppHeader_viewer\n}\n\nfragment HeaderUserMenu_viewer on Viewer {\n  user {\n    profile {\n      displayName\n      ...Avatar_userProfile\n      id\n    }\n    id\n  }\n  numTenants\n}\n"
  }
};
})();

(node as any).hash = "ddacaf7c07c5a8bc74227a1fca49b0a6";

export default node;
