/**
 * @generated SignedSource<<14ecbe82e0115bd0c8d5b89c95729974>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnalysisJobStatus = "ABORTED" | "COMPLETED" | "FAILED" | "QUEUED" | "RESTARTING" | "RUNNING" | "WAITING_FOR_INSTANCE";
export type AnalysisJobStepStatus = "ABORTED" | "COMPLETED" | "FAILED" | "PENDING" | "RESTARTING" | "RUNNING";
import { FragmentRefs } from "relay-runtime";
export type AnalysisProgress_analysisJob$data = {
  readonly id: string;
  readonly status: AnalysisJobStatus | null;
  readonly steps: ReadonlyArray<{
    readonly status: AnalysisJobStepStatus | null;
  } | null> | null;
  readonly " $fragmentType": "AnalysisProgress_analysisJob";
};
export type AnalysisProgress_analysisJob$key = {
  readonly " $data"?: AnalysisProgress_analysisJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnalysisProgress_analysisJob">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnalysisProgress_analysisJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalysisJobStep",
      "kind": "LinkedField",
      "name": "steps",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "AnalysisJob",
  "abstractKey": null
};
})();

(node as any).hash = "3f7b3b6fc0089cfef7a3fa45bc7c2455";

export default node;
