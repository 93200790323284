import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage, { Props } from './BaseAppPage';
import type { AppPage_viewer$data as Viewer } from './__generated__/AppPage_viewer.graphql';

function AppPage({
  header,
  viewer,
  children,
}: Omit<Props, 'viewer' | 'tenant'> & { viewer: Viewer | null }) {
  return (
    <BaseAppPage header={header} viewer={viewer} tenant={null}>
      {children}
    </BaseAppPage>
  );
}

const AppPageContainer = createFragmentContainer(AppPage, {
  viewer: graphql`
    fragment AppPage_viewer on Viewer {
      ...BaseAppPage_viewer
    }
  `,
});

export default AppPageContainer;
