import Layout from '@4c/layout';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import HelpMenu from 'components/HelpMenu';
import NotificationsMenu from 'components/NotificationsMenu';

import HeaderUserMenu from './HeaderUserMenu';
import Navbar from './Navbar';
import NavbarBrand from './NavbarBrand';
import UploadManager from './UploadManager';
import type { AppHeader_tenant$data as Tenant } from './__generated__/AppHeader_tenant.graphql';
import type { AppHeader_viewer$data as Viewer } from './__generated__/AppHeader_viewer.graphql';

import './AppHeader.scss';

type AppHeaderProps = {
  children?: React.ReactNode;
  viewer: Viewer | null;
  tenant: Tenant | null;
};

const rootClass = 'AppHeader';

function AppHeader({ children, viewer, tenant }: AppHeaderProps) {
  return (
    <Navbar className={rootClass}>
      <NavbarBrand />
      <Layout.Block flex>{children}</Layout.Block>
      <UploadManager />
      {viewer && (
        <div className={`${rootClass}__actions`}>
          <HelpMenu />
          {tenant && <NotificationsMenu tenant={tenant} />}
          <HeaderUserMenu viewer={viewer} tenant={tenant!} />
        </div>
      )}
    </Navbar>
  );
}

export default createFragmentContainer(AppHeader, {
  viewer: graphql`
    fragment AppHeader_viewer on Viewer {
      ...HeaderUserMenu_viewer
    }
  `,
  tenant: graphql`
    fragment AppHeader_tenant on Tenant {
      ...HeaderUserMenu_tenant
      ...NotificationsMenu_tenant
    }
  `,
});
