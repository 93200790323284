import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import ItemGrid, { TableColumns } from 'components/ItemGrid';
import { useTenantRoutes } from 'utils/tenantRoutes';

import CreatedByListItem from './CreatedByListItem';
import Date from './Date';
import NoItemFound from './NoItemFound';
import RunProgress from './RunProgress';
import type { RunListSubscription } from './__generated__/RunListSubscription.graphql';
import type { RunList_runs$data as Runs } from './__generated__/RunList_runs.graphql';

const subscription = graphql`
  subscription RunListSubscription($input: UpdateRunSubscriptionInput!) {
    updateRun(input: $input) {
      run {
        ...RunList_runs
      }
    }
  }
`;

function RunListSubscriber({ run }: { run: Runs[number] }) {
  useSubscription<RunListSubscription>({
    subscription,
    variables: {
      input: { runId: run.id },
    },
  });
  return null;
}

interface Props {
  runs: Runs;
  showProject?: boolean;
}

function RunList({ runs, showProject }: Props) {
  const routes = useTenantRoutes();

  const columns = React.useMemo<TableColumns<Runs[number]>>(
    () => [
      {
        Header: <FormattedMessage id="runList.name" defaultMessage="Run" />,
        accessor: 'name',
      },
      {
        Header: (
          <FormattedMessage id="runList.status" defaultMessage="Status" />
        ),
        accessor: 'status',
        Cell: ({ row }) => <RunProgress run={row.original} />,
      },
      {
        Header: (
          <FormattedMessage id="runList.project" defaultMessage="Project" />
        ),
        accessor: (row) => row.project?.name,
        id: 'project',
      },
      {
        Header: (
          <FormattedMessage id="runList.library" defaultMessage="Library" />
        ),
        accessor: (data) => {
          const output: string[] = [];
          data.libraryAssociations?.map((association) => {
            return output.push(association?.library?.name ?? '');
          });
          return output.join(', ');
        },
        id: 'library',
      },
      {
        Header: (
          <FormattedMessage id="runList.sample" defaultMessage="Sample" />
        ),
        accessor: (data) => {
          const output: string[] = [];
          data.libraryAssociations?.map((association) => {
            return output.push(association?.library?.sample?.name ?? '');
          });
          return output.join(', ');
        },
        id: 'sample',
      },
      {
        Header: (
          <FormattedMessage id="runList.created" defaultMessage="Created" />
        ),
        accessor: 'createdAt',
        sort: 'CREATED_AT',
        Cell: ({ row }) => (
          <CreatedByListItem
            userProfile={row.original.createdBy?.userProfile ?? null}
            createdAt={row.original.createdAt}
          />
        ),
      },
      {
        Header: (
          <FormattedMessage id="runList.started" defaultMessage="Started" />
        ),
        accessor: 'startedAt',
        sort: 'STARTED_AT',
        Cell: ({ value }) => <Date value={value} imprecise />,
      },
      {
        Header: (
          <FormattedMessage id="runList.ended" defaultMessage="Finished" />
        ),
        accessor: 'endedAt',
        sort: 'ENDED_AT',
        Cell: ({ value }) => <Date value={value} imprecise />,
      },
    ],
    [],
  );

  if (runs.length === 0) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage id="runList.itemType" defaultMessage="runs" />
        }
      />
    );
  }

  return (
    <ItemGrid
      data={runs}
      columns={columns}
      initialState={{
        hiddenColumns: [!showProject && 'project'].filter(Boolean) as string[],
      }}
      linkTo={(run: DeepNonNull<Runs[number]>) =>
        routes.run({ project: run.project, run })
      }
      subscriberComponent={(run) => <RunListSubscriber run={run} />}
      data-testid="RunList"
    />
  );
}

export default createFragmentContainer(RunList, {
  runs: graphql`
    fragment RunList_runs on Run @relay(plural: true) {
      id
      handle
      name
      startedAt
      endedAt
      status

      createdAt
      createdBy {
        userProfile {
          ...CreatedByListItem_userProfile
        }
      }
      libraryAssociations {
        library {
          name
          sample {
            name
          }
        }
      }
      project {
        handle
        name
      }
      ...RunProgress_run
    }
  `,
});
