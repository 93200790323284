import HttpError from 'found/HttpError';

import { RelayRenderArgs } from 'components/Route';

type Props<T extends string> = {
  [_K in T]: unknown;
};

export function createCheckItem<T extends string>(fieldName: T) {
  return ({ props }: RelayRenderArgs<Props<T>>) => {
    if (!props) return;
    const { [fieldName]: item } = props;

    if (!item) throw new HttpError(404);
  };
}

export default createCheckItem('item');
