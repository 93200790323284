/**
 * @generated SignedSource<<711547430848c8380f0d1251a26f3e25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Devices_DeviceLibraryLoadingsSectionQuery$variables = {
  count: number;
  cursor?: string | null;
  deviceHandle: ReadonlyArray<any>;
  tenantSlug: string;
};
export type Devices_DeviceLibraryLoadingsSectionQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"DeviceLibraryLoadingsSection_device">;
  } | null;
};
export type Devices_DeviceLibraryLoadingsSectionQuery = {
  response: Devices_DeviceLibraryLoadingsSectionQuery$data;
  variables: Devices_DeviceLibraryLoadingsSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceHandle"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "carbonDeviceId",
    "variableName": "deviceHandle"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_DeviceLibraryLoadingsSectionQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v4/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeviceLibraryLoadingsSection_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Devices_DeviceLibraryLoadingsSectionQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v4/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "LibraryLoadingConnection",
            "kind": "LinkedField",
            "name": "libraryLoadingConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LibraryLoadingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LibraryLoading",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Run",
                        "kind": "LinkedField",
                        "name": "run",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Project",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": [
              "carbonDeviceId"
            ],
            "handle": "connection",
            "key": "Device_libraryLoadingConnection",
            "kind": "LinkedHandle",
            "name": "libraryLoadingConnection"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9dc126e170c10e9e7ba9172a6005c6b5",
    "id": null,
    "metadata": {},
    "name": "Devices_DeviceLibraryLoadingsSectionQuery",
    "operationKind": "query",
    "text": "query Devices_DeviceLibraryLoadingsSectionQuery(\n  $tenantSlug: String!\n  $deviceHandle: [IdOrHandle!]!\n  $count: Int!\n  $cursor: String\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...DeviceLibraryLoadingsSection_device\n    id\n  }\n}\n\nfragment DeviceLibraryLoadingsSection_device on Tenant {\n  libraryLoadingConnection(first: $count, after: $cursor, carbonDeviceId: $deviceHandle) {\n    edges {\n      node {\n        ...LibraryLoadingList_libraryLoadings\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment LibraryLoadingListItem_libraryLoading on LibraryLoading {\n  startedAt\n  status\n  createdAt\n  run {\n    project {\n      handle\n      name\n      id\n    }\n    handle\n    name\n    id\n  }\n}\n\nfragment LibraryLoadingList_libraryLoadings on LibraryLoading {\n  id\n  ...LibraryLoadingListItem_libraryLoading\n}\n"
  }
};
})();

(node as any).hash = "1c114e63c06c9b491bac0cf4a1ae0d26";

export default node;
