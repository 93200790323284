import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import * as yup from 'yup';

import FieldGroup from 'components/FieldGroup';
import RelayForm from 'components/RelayForm';
import commonMessages from 'messages/common';
import * as routes from 'utils/routes';

import AuthForm from './AuthForm';
import BlankPage from './BlankPage';
import TitleMessage from './TitleMessage';
import type { UserSetupPageMutation as Mutation } from './__generated__/UserSetupPageMutation.graphql';
import type { UserSetupPage_viewer$data as Viewer } from './__generated__/UserSetupPage_viewer.graphql';

const messages = defineMessages({
  namePlaceholder: {
    id: 'userSetupPage.namePlaceholder',
    defaultMessage: 'Full Name',
  },
});

const schema = yup.object({
  name: yup.string().required().default('').trim(),
});

const defaultValue = schema.getDefault();

const mutation = graphql`
  mutation UserSetupPageMutation($input: UpdateUserInput!) {
    updateUserOrError(input: $input) {
      ... on UpdateUserPayload {
        user {
          profile {
            name
          }
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  viewer: DeepNonNull<Viewer>;
}

function UserSetupPage({ viewer }: Props) {
  const { router } = useRouter();
  const intl = useIntl();

  return (
    <BlankPage data-testid="UserSetupPage">
      <RelayForm<Mutation, typeof schema>
        schema={schema}
        defaultValue={defaultValue}
        mutation={mutation}
        getInput={(v) => ({
          userId: viewer.user.id,
          name: v.name,
        })}
        onCompleted={() => router.replace(routes.root())}
      >
        <TitleMessage>
          <FormattedMessage
            id="userSetupPage.title"
            defaultMessage="Please enter your name"
          />
        </TitleMessage>

        <FieldGroup
          label={<FormattedMessage {...messages.namePlaceholder} />}
          labelSrOnly
          name="name"
          placeholder={intl.formatMessage({
            id: 'userSetupPage.namePlaceholder',
            defaultMessage: 'Full Name',
          })}
          autoFocus
        />
        <AuthForm.Submit>
          <FormattedMessage {...commonMessages.save} />
        </AuthForm.Submit>
      </RelayForm>
    </BlankPage>
  );
}

export default createFragmentContainer(UserSetupPage, {
  viewer: graphql`
    fragment UserSetupPage_viewer on Viewer {
      user {
        id
      }
    }
  `,
});
