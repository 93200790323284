import { useRouter } from 'found';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { AuthContextValue, withAuth } from 'components/AuthContext';
import { useModal } from 'components/ModalProvider';
import * as routes from 'utils/routes';

interface Props {
  auth: AuthContextValue;
  children: React.ReactNode;
}

function ConfirmLogoutDialog({ auth, children }: Props) {
  const { router } = useRouter();
  const { openModal } = useModal();

  useEffect(() => {
    const openDialog = async () => {
      const logout = await openModal({
        content: children,
        open: true,
        title: (
          <FormattedMessage
            id="confirmLogout.dialog.title"
            defaultMessage="Already Logged In"
          />
        ),
        confirmLabel: (
          <FormattedMessage
            id="confirmLogout.dialog.confirm"
            defaultMessage="Log Out"
          />
        ),
      });

      if (logout) {
        auth.clearAccessToken();
      } else {
        router.replace(routes.root());
      }
    };

    if (auth.isAuthenticated()) {
      openDialog();
    }
  }, [auth, children, openModal, router]);

  return null;
}

export default withAuth(ConfirmLogoutDialog);
