/**
 * @generated SignedSource<<e5220c65b9e14f25a0eb27306199263f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateNotificationSubscriptionInput = {
  clientSubscriptionId?: string | null;
  tenantId: String;
  userId: String;
};
export type NotificationsMenuSubscription$variables = {
  input: CreateNotificationSubscriptionInput;
};
export type NotificationsMenuSubscription$data = {
  readonly createNotification: {
    readonly notificationEdge: {
      readonly node: {
        readonly id?: string;
        readonly " $fragmentSpreads": FragmentRefs<"useNotificationData_notification">;
      } | null;
    } | null;
  } | null;
};
export type NotificationsMenuSubscription = {
  response: NotificationsMenuSubscription$data;
  variables: NotificationsMenuSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  },
  (v2/*: any*/)
],
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsMenuSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "useNotificationData_notification"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsMenuSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateNotificationSubscriptionPayload",
        "kind": "LinkedField",
        "name": "createNotification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "notificationEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isNotification"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notificationType",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Analysis",
                        "kind": "LinkedField",
                        "name": "analysis",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "AnalysisNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Device",
                        "kind": "LinkedField",
                        "name": "device",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "DeviceAlarmNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Library",
                        "kind": "LinkedField",
                        "name": "library",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "LibraryNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Run",
                        "kind": "LinkedField",
                        "name": "run",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "RunNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sample",
                        "kind": "LinkedField",
                        "name": "sample",
                        "plural": false,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "SampleNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "ProjectNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Reference",
                        "kind": "LinkedField",
                        "name": "reference",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "ReferenceNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NewsItem",
                        "kind": "LinkedField",
                        "name": "newsItem",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "NewsNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkflowRelease",
                        "kind": "LinkedField",
                        "name": "workflowRelease",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "versionName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Workflow",
                            "kind": "LinkedField",
                            "name": "workflow",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "WorkflowReleaseNotification",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9acf73924fbb4ca890ff0a8bceb54e12",
    "id": null,
    "metadata": {},
    "name": "NotificationsMenuSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationsMenuSubscription(\n  $input: CreateNotificationSubscriptionInput!\n) {\n  createNotification(input: $input) {\n    notificationEdge {\n      node {\n        __typename\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n        ...useNotificationData_notification\n      }\n    }\n  }\n}\n\nfragment useNotificationData_notification on Notification {\n  __isNotification: __typename\n  notificationType\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  ... on AnalysisNotification {\n    analysis {\n      handle\n      name\n      project {\n        handle\n        id\n      }\n      id\n    }\n  }\n  ... on DeviceAlarmNotification {\n    device {\n      handle\n      name\n      id\n    }\n  }\n  ... on LibraryNotification {\n    library {\n      handle\n      name\n      project {\n        handle\n        id\n      }\n      id\n    }\n  }\n  ... on RunNotification {\n    run {\n      handle\n      name\n      project {\n        handle\n        id\n      }\n      id\n    }\n  }\n  ... on SampleNotification {\n    sample {\n      handle\n      name\n      project {\n        handle\n        id\n      }\n      id\n    }\n  }\n  ... on ProjectNotification {\n    project {\n      handle\n      name\n      id\n    }\n  }\n  ... on ReferenceNotification {\n    reference {\n      handle\n      name\n      id\n    }\n  }\n  ... on NewsNotification {\n    newsItem {\n      handle\n      title\n      id\n    }\n  }\n  ... on WorkflowReleaseNotification {\n    workflowRelease {\n      versionName\n      workflow {\n        name\n        handle\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fa965664da4310b1d47cb69699df606";

export default node;
