import Layout from '@4c/layout';
import classNames from 'classnames';
import { useRouter } from 'found';
import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import InfoPageCard from 'components/InfoPageCard';
import { REDIRECT_ON_FINISH } from 'config/delays';
import * as routes from 'utils/routes';

import BlankPage from './BlankPage';
import VerifyEmailControl from './VerifyEmailControl';
import type { VerifyEmailPageMutation as Mutation } from './__generated__/VerifyEmailPageMutation.graphql';
import type { VerifyEmailPage_viewer$data as Viewer } from './__generated__/VerifyEmailPage_viewer.graphql';
import BusyToDoneAnimation from './animation/BusyToDoneAnimation';
import logoUrl from '../../assets/qsi-logo.svg';
import './VerifyEmailPage.scss';

const mutation = graphql`
  mutation VerifyEmailPageMutation($input: AcceptEmailVerificationInput!) {
    acceptEmailVerificationOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const messages = defineMessages({
  activatingAccount: {
    id: 'verifyEmailPage.activatingAccount',
    defaultMessage: 'Activating your account…',
  },
  activatingAccountSubtitle: {
    id: 'verifyEmailPage.activatingAccountSubtitle',
    defaultMessage: 'Confirming your email address and activating the account',
  },
  verifyEmail: {
    id: 'verifyEmailPage.verifyEmail',
    defaultMessage: 'Verify your email address',
  },
  verifyEmailSubtitle: {
    id: 'verifyEmailPage.verifyEmailSubtitle',
    defaultMessage:
      'Check your email and click the link to activate your account.',
  },
  activateError: {
    id: 'verifyEmailPage.activateError',
    defaultMessage: 'Account activation failed',
  },
  activateErrorSubtitle: {
    id: 'verifyEmailPage.activateErrorSubtitle',
    defaultMessage:
      "Oops looks like that didn't work. The verification attempt probably " +
      'expired and needs to be resent',
  },
});

interface Props {
  viewer: DeepNonNull<Viewer>;
}

const rootClass = 'VerifyEmailPage';

function VerifyEmailPage({ viewer }: Props) {
  const { match, router } = useRouter();
  const { token } = match.params;

  const [mutate, { loading, error }] = useMutation<Mutation>(mutation, {
    variables: {
      input: {
        userId: viewer.user.id,
        token,
      },
    },
    onCompleted: () => {
      setTimeout(() => router.replace(routes.root()), REDIRECT_ON_FINISH);
    },
  });

  useEffect(() => {
    mutate();
    // explicitly only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let title = messages.verifyEmail;
  let subtitle = messages.verifyEmailSubtitle;
  if (loading) {
    title = messages.activatingAccount;
    subtitle = messages.activatingAccountSubtitle;
  } else if (error) {
    title = messages.activateError;
    subtitle = messages.activateErrorSubtitle;
  }
  return (
    <BlankPage>
      <InfoPageCard title={title} subtitle={subtitle} className={rootClass}>
        {!token ? (
          <Layout
            className={classNames(`${rootClass}__placeholder`)}
            justify="center"
          >
            <img alt="Quantum-Si" src={logoUrl} />
          </Layout>
        ) : (
          <BusyToDoneAnimation
            busy={loading}
            theme={error ? 'failure' : 'success'}
          />
        )}
        {!loading && (
          <Layout justify="center">
            {(!token || error) && <VerifyEmailControl viewer={viewer} />}
          </Layout>
        )}
      </InfoPageCard>
    </BlankPage>
  );
}

export default createFragmentContainer(VerifyEmailPage, {
  viewer: graphql`
    fragment VerifyEmailPage_viewer on Viewer {
      user {
        id
        emailVerified
      }
      ...VerifyEmailControl_viewer
    }
  `,
});
