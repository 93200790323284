import useRefWithInitialValueFactory from '@restart/hooks/useRefWithInitialValueFactory';
import React, { useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchQuery, graphql } from 'react-relay';
import {
  SocketIoSubscriptionClient,
  createFetch,
  createSubscribe,
  useAuthToken,
} from 'relay-network-layer';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';

import AuthContext, { AuthInfo } from 'components/AuthContext';
import type { AuthContextValue } from 'components/AuthContext';
import { useModal } from 'components/ModalProvider';
import Analytics from 'utils/Analytics';

import ImpersonationCallout from './ImpersonationCallout';
import type { AuthProvider_SessionInfoQuery as SessionInfoQuery } from './__generated__/AuthProvider_SessionInfoQuery.graphql';

interface Storage<TData> {
  load(): TData | null;
  save(value: TData): void;
  clear(): void;
}

// We're reading this from localStorage, so anything could be undefined.
type SessionInfo = {
  localId: string;
  email: string;
};

export type TokenState = AuthInfo & SessionInfo;

interface Props {
  tokenStorage: Storage<TokenState>;
  children: (childArgs: {
    environment: Environment;
    viewerLocalId: string | null;
    auth: AuthContextValue;
  }) => React.ReactNode;
}

const INIT = {
  headers: {
    'apollographql-client-name': 'web',
    'apollographql-client-version': process.env.VERSION!,
  },
};

function createEnvironmentInfo(token?: string, onRequest?: () => any) {
  const origin = window.qsiConfig.API_ORIGIN;

  const subscribeFn = createSubscribe({
    subscriptionClientClass: SocketIoSubscriptionClient,
    url: `${origin}/socket.io/graphql`,
    token,
  });

  const network = Network.create(
    createFetch({
      url: `${origin}/graphql`,
      authorization: token,
      init: () => {
        if (onRequest) onRequest();
        return INIT;
      },
    }),
    subscribeFn,
  );

  const store = new Store(new RecordSource());
  store.holdGC(); // Disable GC on the relayStore.

  const environment = new Environment({ network, store });

  return { environment, subscribeFn };
}

async function fetchSessionInfo(
  environment: Environment,
): Promise<Omit<SessionInfo, 'expiresAt'>> {
  const query = await fetchQuery<DeepNonNull<SessionInfoQuery>>(
    environment,
    graphql`
      query AuthProvider_SessionInfoQuery {
        viewer {
          user {
            email
            profile {
              displayName
              handle
            }
          }
        }
      }
    `,
    {},
  ).toPromise();
  const { viewer } = query!;

  return {
    localId: viewer.user.profile.handle,
    email: viewer.user.email,
  };
}

function useAuthState({
  onTokenExpired,
  tokenStorage,
}: {
  tokenStorage: Storage<TokenState>;
  onTokenExpired: () => any | Promise<any>;
}) {
  const [tokenState, setTokenState] = useAuthToken<TokenState>({
    onTokenExpired: async () => {
      await onTokenExpired();

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearAccessToken();
    },

    tokenStorage,
  });
  // we need to have a ref because the latest value needs to be passed
  // inside the onRequest closure
  const tokenStateRef = useRef<typeof tokenState>();
  tokenStateRef.current = tokenState;

  const onRequest = () => async () => {
    const ts = tokenStateRef.current;
    if (ts && ts.expiresAt - 5000 < Date.now()) {
      await onTokenExpired();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      clearAccessToken();
    }
  };

  const environmentInfoRef = useRefWithInitialValueFactory(() => {
    if (tokenState) {
      Analytics.identify(tokenState.localId, { email: tokenState.email });
    }

    // TODO: It would be best to reset analytics identity here if there is no
    //  tokenState, but this will generate a new anonymous ID on Segment, which
    //  is not what we want.

    return createEnvironmentInfo(tokenState?.accessToken, onRequest());
  });

  // These don't use useCallback because they're wrapped with a useMemo that
  //  accesses a ref's current value in <AuthProvider> below.
  const getNextEnvironmentInfo = (accessToken?: string) => {
    environmentInfoRef.current.subscribeFn.close();
    return createEnvironmentInfo(accessToken, onRequest());
  };

  const setAccessToken = async ({
    accessToken,
    expiresAt,
    impersonation,
  }: AuthInfo) => {
    if (accessToken === tokenState?.accessToken) {
      // No need to do anything here.
      return;
    }

    const nextEnvironmentInfo = getNextEnvironmentInfo(accessToken);

    // Before setting the tokenState, lookup the localId from the server
    // and add it to the state that'll be kept in local stroage.
    const sessionInfo = await fetchSessionInfo(
      nextEnvironmentInfo.environment,
    );

    Analytics.identify(sessionInfo.localId, { email: sessionInfo.email });

    environmentInfoRef.current = nextEnvironmentInfo;
    await setTokenState({
      accessToken,
      expiresAt,
      impersonation,
      ...sessionInfo,
    });
  };

  const clearAccessToken = async () => {
    // TODO: It would be ideal to reset analytics identity here, but we don't
    //  want to generate a new anonymous ID.

    environmentInfoRef.current = getNextEnvironmentInfo();
    await setTokenState(null);
  };

  return {
    tokenState,
    environment: environmentInfoRef.current.environment,
    setAccessToken,
    clearAccessToken,
  };
}

export default function AuthProvider({ children, tokenStorage }: Props) {
  const intl = useIntl();
  const { openModal } = useModal();

  const authState = useAuthState({
    tokenStorage,
    onTokenExpired: () =>
      openModal({
        content: (
          <FormattedMessage
            id="authProvider.authExpired"
            defaultMessage="Your session has expired. Please login again to continue."
          />
        ),
        open: true,
        title: intl.formatMessage({
          id: 'authProvider.expiredTitle',
          defaultMessage: 'Session Expired',
        }),
        hideCancel: true,
        confirmLabel: intl.formatMessage({
          id: 'authProvider.logIn',
          defaultMessage: 'Log In',
        }),
      }),
  });

  // We wrap the state in a Ref so that the context value doesn't
  // change even if auth does
  const authStateRef = useRef(authState);
  authStateRef.current = authState;

  const authContext: AuthContextValue = useMemo(
    () => ({
      setAccessToken: (tokenData: AuthInfo) =>
        authStateRef.current.setAccessToken(tokenData),
      clearAccessToken: () => authStateRef.current.clearAccessToken(),
      isAuthenticated: () => !!authStateRef.current.tokenState,
      viewerLocalId: authState.tokenState?.localId,
    }),
    [authState.tokenState?.localId],
  );

  return (
    <AuthContext.Provider value={authContext}>
      {children({
        environment: authState.environment,
        viewerLocalId: authState.tokenState?.localId ?? null,
        auth: authContext,
      })}
      {authState.tokenState?.impersonation && (
        <ImpersonationCallout tokenState={authState.tokenState} />
      )}
    </AuthContext.Provider>
  );
}
