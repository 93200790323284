/**
 * @generated SignedSource<<9fdb3bf54be2aa32aac94592e650b5ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apps_Query$variables = {
  count: number;
  cursor?: string | null;
  tenantSlug: string;
};
export type Apps_Query$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"AppsListSection_tenant">;
  } | null;
};
export type Apps_Query = {
  response: Apps_Query$data;
  variables: Apps_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Literal",
    "name": "archived",
    "value": false
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "tenantSlug",
  "variableName": "tenantSlug"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppsListSection_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Apps_Query",
    "selections": [
      {
        "alias": "tenant",
        "args": (v3/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "WorkflowConnection",
            "kind": "LinkedField",
            "name": "workflowConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Workflow",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "handle",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequenceType",
                        "storageKey": null
                      },
                      {
                        "alias": "latestInstalledRelease",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "installed",
                            "value": true
                          },
                          (v7/*: any*/)
                        ],
                        "concreteType": "WorkflowRelease",
                        "kind": "LinkedField",
                        "name": "latestRelease",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "public",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "latestPublicRelease",
                        "args": [
                          (v7/*: any*/)
                        ],
                        "concreteType": "WorkflowRelease",
                        "kind": "LinkedField",
                        "name": "latestRelease",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantProfile",
                        "kind": "LinkedField",
                        "name": "tenantProfile",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "logoUrl",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "archived"
            ],
            "handle": "connection",
            "key": "Tenant_workflowConnection",
            "kind": "LinkedHandle",
            "name": "workflowConnection"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "307843b060eb51c211a76ad2c83cf2c2",
    "id": null,
    "metadata": {},
    "name": "Apps_Query",
    "operationKind": "query",
    "text": "query Apps_Query(\n  $tenantSlug: String!\n  $count: Int!\n  $cursor: String\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...AppsListSection_tenant\n    id\n  }\n}\n\nfragment AppsListSection_tenant on Tenant {\n  workflowConnection(first: $count, after: $cursor, archived: false) {\n    edges {\n      node {\n        description\n        handle\n        id\n        name\n        sequenceType\n        latestInstalledRelease: latestRelease(tenantSlug: $tenantSlug, installed: true) {\n          id\n          versionName\n          public\n        }\n        latestPublicRelease: latestRelease(tenantSlug: $tenantSlug) {\n          id\n          createdAt\n          versionName\n        }\n        tenantProfile {\n          ...AppsLogo_tenantProfile\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AppsLogo_tenantProfile on TenantProfile {\n  logoUrl\n  name\n}\n"
  }
};
})();

(node as any).hash = "b2295bd809b5c3ec0fa09600708e0d7f";

export default node;
