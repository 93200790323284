import classNames from 'classnames';
import Link from 'found/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import logoUrl from '../../assets/qsi-full-logo.svg';

import './NavbarBrand.scss';

interface Props {
  dataTestId?: string;
}

const rootClass = 'NavbarBrand';

export default function NavbarBrand({ dataTestId = rootClass }: Props) {
  return (
    <Link to="/" className={classNames(rootClass)} data-testid={dataTestId}>
      <div className={classNames(`${rootClass}__logo-wrapper`)}>
        <img
          alt="Quantum-Si"
          src={logoUrl}
          className={classNames(`${rootClass}__logo`)}
        />
        <span className="sr-only">
          <FormattedMessage id="logo.homeAlt" defaultMessage="Home" />
        </span>
      </div>
    </Link>
  );
}
