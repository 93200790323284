import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { getDateString } from 'utils/date';

export default (
  <Route
    path="dashboard"
    getComponent={() =>
      import(
        /* webpackChunkName: "profile" */ './components/DashboardPage'
      ).then((p) => p.default)
    }
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "dashboard" */ './components/DashboardMainPage'
        ).then((p) => p.default)
      }
      prepareVariables={(params, { location }) => {
        const createdAtMin = new Date();
        createdAtMin.setDate(createdAtMin.getDate() - 30);
        return {
          ...params,
          createdAtMin: getDateString(createdAtMin),
          createdBy: location.query.createdBy && [location.query.createdBy],
        };
      }}
      query={graphql`
        query DashboardMainPage_PageQuery(
          $tenantSlug: String!
          $createdBy: [IdOrHandle!]
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...DashboardMainPage_tenant
          }
        }
      `}
    />
  </Route>
);
