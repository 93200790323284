/**
 * @generated SignedSource<<ccf199cfa357c9f3c256caea960b4b6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SearchPage_userProfile$data = {
  readonly displayName: string | null;
  readonly " $fragmentType": "SearchPage_userProfile";
};
export type SearchPage_userProfile$key = {
  readonly " $data"?: SearchPage_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchPage_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPage_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "48086351d8fe697e08e46df5ebb170c7";

export default node;
