import classNames from 'classnames';
import { Link } from 'found';
import React, { forwardRef } from 'react';
import { Column, UseTableOptions, useTable } from 'react-table';

import SortButton from './SortButton';

import './ItemGrid.scss';

const rootClass = 'ItemGrid';

const ItemGridRow = forwardRef<any, any>(
  ({ className, children, href, dataTestId = rootClass, ...props }, ref) => {
    const isLink = !!href;

    return (
      <tr
        ref={ref}
        {...(isLink && { as: 'a' })}
        {...props}
        className={classNames(`${rootClass}__row`, className)}
        data-testid={dataTestId}
      >
        {children}
      </tr>
    );
  },
);

export type TableColumns<T extends object> = (Column<T> & { sort?: string })[];

interface TableProps<T extends object> extends UseTableOptions<T> {
  columns: TableColumns<T>;
  linkTo?: (row: T) => string | false;
  subscriberComponent?: (row: T) => React.ReactNode;
  dataTestId?: string;
  className?: string;
}

const ItemGrid = <T extends object>({
  columns,
  data,
  initialState,
  linkTo,
  subscriberComponent,
  dataTestId = rootClass,
  className,
  ...rest
}: TableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({
      columns,
      data,
      initialState,
    });

  return (
    <table
      className={classNames(className, rootClass)}
      style={{
        gridTemplateColumns: `repeat(${headerGroups[0].headers.length}, auto)`,
      }}
      {...getTableProps()}
      role="table"
      {...rest}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                data-testid="ItemGridHeader"
                {...column.getHeaderProps()}
                className={`${rootClass}__header`}
              >
                <SortButton sort={(column as any).sort}>
                  {column.render('Header')}
                </SortButton>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          const to = linkTo && linkTo(row.original);
          return (
            <ItemGridRow
              {...row.getRowProps()}
              dataTestId={`${dataTestId}-row-${index}`}
            >
              {subscriberComponent && subscriberComponent(row.original)}
              {row.cells.map((cell) => {
                return (
                  <td data-testid="ItemGridCell" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                    {to && (
                      <Link
                        to={to}
                        className={classNames(`${rootClass}__row-link`)}
                      />
                    )}
                  </td>
                );
              })}
            </ItemGridRow>
          );
        })}
      </tbody>
    </table>
  );
};

export default ItemGrid;
