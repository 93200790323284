import React from 'react';
import { graphql } from 'react-relay';
import StaticContainer from 'react-static-container';

import Route from 'components/Route';
import { getInfiniteListVariables } from 'components/utils/createInfiniteList';

import HeaderSearchBar from './components/HeaderSearchBar';
import SearchPage from './components/SearchPage';
import { searchPages } from './searchPages';

export const searchHeaderRoutes = [
  <Route
    path="(projects|dashboard|tags|search)(.*)?"
    Component={HeaderSearchBar}
    userProfile={null}
    project={null}
    tag={null}
    query={graphql`
      query Search_HeaderSearchBarQuery(
        $projectHandle: String!
        $hasProject: Boolean!
        $userProfileHandle: String!
        $hasUserProfile: Boolean!
        $tagHandle: String!
        $hasTag: Boolean!
      ) {
        userProfile(handle: $userProfileHandle) @include(if: $hasUserProfile) {
          ...HeaderSearchBar_userProfile
        }
        project(handle: $projectHandle) @include(if: $hasProject) {
          ...HeaderSearchBar_project
        }
        tag(handle: $tagHandle) @include(if: $hasTag) {
          ...HeaderSearchBar_tag
        }
      }
    `}
    prepareVariables={(variables, { location: { query } }) => {
      const projectHandle = query.project || '';
      const userProfileHandle = query.createdBy || '';
      const tagHandle = query.tag || '';

      return {
        ...variables,
        projectHandle,
        hasProject: !!projectHandle,
        userProfileHandle,
        hasUserProfile: !!userProfileHandle,
        tagHandle,
        hasTag: !!tagHandle,
      };
    }}
    render={({ Component, props, match: { location } }) => (
      <StaticContainer shouldUpdate={!!props}>
        {props && Component && (
          <Component
            project={null}
            userProfile={null}
            tag={null}
            search={location.query.search || null}
            status={location.query.status || null}
            {...props}
          />
        )}
      </StaticContainer>
    )}
  />,
  <Route path="(.*)?" />,
];

export const searchResultsRoute = (
  <Route
    path="search"
    Component={SearchPage}
    prepareVariables={(variables, { location }) => {
      const { query } = location;
      const projectHandle = query.project || '';
      const userProfileHandle = query.createdBy || '';
      const tagHandle = query.tag || '';

      return {
        ...variables,
        projectHandle,
        hasProject: !!projectHandle,
        userProfileHandle,
        hasUserProfile: !!userProfileHandle,
        tagHandle,
        hasTag: !!tagHandle,
      };
    }}
    userProfile={null}
    project={null}
    tag={null}
    query={graphql`
      query Search_SearchPageQuery(
        $projectHandle: String!
        $hasProject: Boolean!
        $userProfileHandle: String!
        $hasUserProfile: Boolean!
        $tagHandle: String!
        $hasTag: Boolean!
      ) {
        userProfile(handle: $userProfileHandle) @include(if: $hasUserProfile) {
          ...SearchPage_userProfile
        }
        project(handle: $projectHandle) @include(if: $hasProject) {
          ...SearchPage_project
        }
        tag(handle: $tagHandle) @include(if: $hasTag) {
          ...SearchPage_tag
        }
      }
    `}
    renderFetched={({ Component, props, match: { location } }: any) => (
      <Component
        project={null}
        userProfile={null}
        tag={null}
        search={location.query.search || null}
        status={location.query.status || null}
        {...props}
      />
    )}
  >
    <Route
      prepareVariables={(variables, { location }) => {
        const { query } = location;

        return {
          ...variables,
          projectHandles: query.project || null,
          userProfileHandles: query.createdBy || null,
          tagHandles: query.tag || null,
          search: query.search || null,
          statuses: query.status || null,
          sort: query.sort || null,
          analysisSort: query.sort || null,
          librarySort: query.sort || null,
          runSort: query.sort || null,
          sampleSort: query.sort || null,
          ...getInfiniteListVariables(),
        };
      }}
      getComponent={({ location }) =>
        searchPages[location.query.entityType as keyof typeof searchPages]
          .component
      }
      getQuery={({ location }) =>
        searchPages[location.query.entityType as keyof typeof searchPages]
          .query
      }
    />
  </Route>
);
