import { Location, useRouter } from 'found';
import clamp from 'lodash/clamp';
import React, { useCallback, useContext, useMemo, useRef } from 'react';

import fetchWithProgress from 'utils/fetchWithProgress';

interface Props {
  children: React.ReactNode;
}

export type Upload = {
  id: number;
  status: number | 'completed' | 'failed';
  title: string;
};

type OnProgress = (progress: number, loadedBytes: number) => any | void;

export type UploadInfo = RequestInit & {
  method: string;
  url: string;
  postData: [string, string][];
  headers: [string, string][];
};

type GetPartUrl = (partNumber: number) => Promise<string>;

export type UploadInit = {
  file: File;
  title: string;
} & (
  | { uploadInfo: UploadInfo; type: 'simple' }
  | { getPartUrl: GetPartUrl; partSize: number; type: 'multipart' }
);
type Listener = (uploads: Upload[], type?: string) => void;

export interface UploaderContextValue {
  subscribe: (listener: Listener) => () => void;
  registerUpload: (init: UploadInit) => Promise<Response[]>;
  clearUpload: (upload: Upload) => void;
}

export const UploaderContext = React.createContext<UploaderContextValue>(
  null as any,
);

export function useUploader() {
  return useContext(UploaderContext);
}

function useCounter() {
  const idRef = useRef(0);

  const getId = useCallback(() => {
    idRef.current += 1;
    return idRef.current;
  }, []);

  return getId;
}

function UploadProvider({ children }: Props) {
  const { router } = useRouter();
  const uploadsRef = useRef<Upload[]>([]);
  const listenersRef = useRef<Listener[]>([]);

  const getId = useCounter();

  const dispatch = useCallback((type: string) => {
    uploadsRef.current = uploadsRef.current.slice();
    listenersRef.current.forEach((listener) => {
      listener(uploadsRef.current, type);
    });
  }, []);

  const onTransition = useCallback((location: Location | null) => {
    if (location) return null;
    return false;
  }, []);

  const request = useCallback(
    async (url: string, init: RequestInit, onProgress: OnProgress) => {
      const response = await fetchWithProgress(url, {
        ...init,
        onProgress: (event) => {
          if (!event.lengthComputable) return;
          const progress = clamp(event.loaded / event.total, 0, 1);
          onProgress(progress, event.loaded);
        },
      });

      if (!response.ok) {
        const text = await response.text();
        throw new Error(`${response.statusText}: ${text}`);
      }
      return response;
    },
    [],
  );

  const uploadFile = useCallback(
    (file: File, uploadInfo: UploadInfo, onProgress: OnProgress) => {
      const { url, postData, ...options } = uploadInfo;

      const body = new FormData();
      if (postData) {
        postData.forEach((dataPair) => body.append(dataPair[0], dataPair[1]));
      }

      body.append('file', file);

      return request(url, { body, ...options }, onProgress);
    },
    [request],
  );

  const uploadFileMultipart = useCallback(
    async (
      file: File,
      getPartUrl: (partNumber: number) => Promise<string>,
      partSize: number,
      onProgress: OnProgress,
    ) => {
      let start = 0;
      const parts: Blob[] = [];
      const responses: Response[] = [];

      while (start < file.size) {
        const end = Math.min(start + partSize, file.size);
        const part = file.slice(start, end);
        // this is to prevent push blob with 0Kb
        if (part.size > 0) parts.push(part);
        start = end;
      }

      // we intentionally decide to have await in a for loop to upload
      // the parts sequentially
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const partNumber = i + 1;
        // eslint-disable-next-line no-await-in-loop
        const uploadUrl = await getPartUrl(partNumber);
        // eslint-disable-next-line no-await-in-loop
        const response = await request(
          uploadUrl,
          { method: 'PUT', body: part },
          (_progress, loadedBytes) => {
            // this strictly assumes part uploads are in order
            const fullLoadedBytes = i * partSize + loadedBytes;
            onProgress(fullLoadedBytes / file.size, fullLoadedBytes);
          },
        );
        responses.push(response);
      }

      return responses;
    },
    [request],
  );

  const clearUpload = useCallback(
    (upload: Upload) => {
      uploadsRef.current = uploadsRef.current.filter((u) => u !== upload);
      dispatch('remove');
    },
    [dispatch],
  );

  const subscribe = useCallback((listener: Listener) => {
    listenersRef.current.push(listener);

    listener(uploadsRef.current.slice());

    return () => {
      listenersRef.current = listenersRef.current.filter(
        (l) => l !== listener,
      );
    };
  }, []);

  const registerUpload = useCallback(
    async ({ file, title, ...init }: UploadInit) => {
      const upload: Upload = {
        status: 0,
        title,
        id: getId(),
      };

      function onProgress(progress: number) {
        upload.status = progress;
        dispatch('progress');
      }

      let req: Promise<Response[]>;
      if (init.type === 'simple') {
        req = uploadFile(file, init.uploadInfo, onProgress).then((r) => [r]);
      } else {
        req = uploadFileMultipart(
          file,
          init.getPartUrl,
          init.partSize,
          onProgress,
        );
      }

      const removeNavigationListener = router.addNavigationListener(
        onTransition,
        { beforeUnload: true },
      );

      try {
        uploadsRef.current.push(upload);

        dispatch('insert');

        const responses = await req;
        upload.status = 'completed';
        return responses;
      } catch (err) {
        upload.status = 'failed';
        throw err;
      } finally {
        if (removeNavigationListener) removeNavigationListener();

        dispatch('done');
      }
    },
    [dispatch, getId, onTransition, router, uploadFile, uploadFileMultipart],
  );

  const context = useMemo(
    () => ({
      clearUpload,
      registerUpload,
      subscribe,
    }),
    [clearUpload, registerUpload, subscribe],
  );

  return (
    <UploaderContext.Provider value={context}>
      {children}
    </UploaderContext.Provider>
  );
}
export default UploadProvider;
