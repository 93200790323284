/**
 * @generated SignedSource<<91baf052eb8d83b4c99c447d61144cdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routes_verifyEmailQuery$variables = {};
export type routes_verifyEmailQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"VerifyEmailPage_viewer">;
  } | null;
};
export type routes_verifyEmailQuery = {
  response: routes_verifyEmailQuery$data;
  variables: routes_verifyEmailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_verifyEmailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VerifyEmailPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_verifyEmailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailVerified",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b6298f3d4bedf0f99b87eb22e1411a24",
    "id": null,
    "metadata": {},
    "name": "routes_verifyEmailQuery",
    "operationKind": "query",
    "text": "query routes_verifyEmailQuery {\n  viewer {\n    ...VerifyEmailPage_viewer\n    id\n  }\n}\n\nfragment VerifyEmailControl_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment VerifyEmailPage_viewer on Viewer {\n  user {\n    id\n    emailVerified\n  }\n  ...VerifyEmailControl_viewer\n}\n"
  }
};
})();

(node as any).hash = "775622a9f31e21200971d3da2615cfe9";

export default node;
