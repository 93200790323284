import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import Progress from 'components/Progress';
import { toTimeParts } from 'utils/duration';

import type { DeviceProgressSubscription } from './__generated__/DeviceProgressSubscription.graphql';
import type { DeviceProgress_device$data as Device } from './__generated__/DeviceProgress_device.graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment DeviceProgress_subscriptionPayload on Device {
    error {
      label
      details
    }

    state {
      code
      label
      progress
      timeRemainingSeconds
    }
  }
`;

const deviceProgressSubscription = graphql`
  subscription DeviceProgressSubscription(
    $input: UpdateDeviceSubscriptionInput!
  ) {
    updateDevice(input: $input) {
      device {
        ...DeviceProgress_subscriptionPayload @relay(mask: false)
      }
    }
  }
`;

function convertSecondsToTime(seconds: number) {
  const { minutes, hours } = toTimeParts(seconds);
  const parts = [hours, minutes].map((i) =>
    (i || 0).toString().padStart(2, '0'),
  );
  return parts.join(':');
}

interface Props {
  device: DeepNonNull<Device>;
  className?: string;
}

function DeviceProgress({ device, ...rest }: Props) {
  const { id: deviceId, state, error } = device;

  useSubscription<DeviceProgressSubscription>({
    subscription: deviceProgressSubscription,
    variables: {
      input: { deviceId },
    },
  });

  if (error) {
    return <Progress error={error} {...rest} />;
  }

  if (state) {
    const { label, timeRemainingSeconds, code } = state;

    if (code === 'UPDATING') {
      return (
        <Progress
          status={
            <FormattedMessage
              id="deviceProgress.updateAvailable"
              defaultMessage="Updating"
            />
          }
          {...rest}
        />
      );
    }

    return (
      <Progress
        status={
          <FormattedMessage
            id="messages.deviceProgress"
            defaultMessage="{label} {timeRemaining}"
            values={{
              label,
              timeRemaining:
                timeRemainingSeconds > 0 &&
                `(${convertSecondsToTime(timeRemainingSeconds)})`,
            }}
          />
        }
        progress={state.progress}
        {...rest}
      />
    );
  }

  return null;
}

export default createFragmentContainer(DeviceProgress, {
  device: graphql`
    fragment DeviceProgress_device on Device {
      id

      ...DeviceProgress_subscriptionPayload @relay(mask: false)
    }
  `,
});
