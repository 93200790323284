/**
 * @generated SignedSource<<87777adf3c5ae980a95f8ba154f487ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateDeviceSubscriptionInput = {
  clientSubscriptionId?: string | null;
  deviceId: String;
};
export type DeviceSoftwareVersionSubscription$variables = {
  input: UpdateDeviceSubscriptionInput;
};
export type DeviceSoftwareVersionSubscription$data = {
  readonly updateDevice: {
    readonly device: {
      readonly " $fragmentSpreads": FragmentRefs<"DeviceSoftwareVersion_device">;
    } | null;
  } | null;
};
export type DeviceSoftwareVersionSubscription = {
  response: DeviceSoftwareVersionSubscription$data;
  variables: DeviceSoftwareVersionSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceSoftwareVersionSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DeviceSoftwareVersion_device"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceSoftwareVersionSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "softwareVersion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DeviceState",
                "kind": "LinkedField",
                "name": "state",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progress",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "441a0ae00293910dd2b57cfff37957c4",
    "id": null,
    "metadata": {},
    "name": "DeviceSoftwareVersionSubscription",
    "operationKind": "subscription",
    "text": "subscription DeviceSoftwareVersionSubscription(\n  $input: UpdateDeviceSubscriptionInput!\n) {\n  updateDevice(input: $input) {\n    device {\n      ...DeviceSoftwareVersion_device\n      id\n    }\n  }\n}\n\nfragment DeviceSoftwareVersion_device on Device {\n  id\n  softwareVersion\n  state {\n    code\n    label\n    progress\n  }\n}\n"
  }
};
})();

(node as any).hash = "43cb864a27d8f0ee1a7f9380edd0174e";

export default node;
