/**
 * @generated SignedSource<<544d6f372ea3856f1a7ffe8b862a3471>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DevicePresence = "offline" | "online";
import { FragmentRefs } from "relay-runtime";
export type DeviceStatus_device$data = {
  readonly availableSoftwareUpgrades: ReadonlyArray<{
    readonly __typename: "SoftwareVersion";
  } | null> | null;
  readonly error: {
    readonly __typename: "DeviceError";
  } | null;
  readonly id: string;
  readonly presence: DevicePresence | null;
  readonly state: {
    readonly __typename: "DeviceState";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceProgress_device">;
  readonly " $fragmentType": "DeviceStatus_device";
};
export type DeviceStatus_device$key = {
  readonly " $data"?: DeviceStatus_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeviceStatus_device">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeviceStatus_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "presence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SoftwareVersion",
      "kind": "LinkedField",
      "name": "availableSoftwareUpgrades",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceError",
      "kind": "LinkedField",
      "name": "error",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeviceState",
      "kind": "LinkedField",
      "name": "state",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeviceProgress_device"
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "60b9f76e2011217bf80df2369f54128d";

export default node;
