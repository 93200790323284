import React from 'react';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import FormattedLabel from 'components/FormattedLabel';
import Progress from 'components/Progress';

import type { DeviceSoftwareVersionSubscription } from './__generated__/DeviceSoftwareVersionSubscription.graphql';
import type { DeviceSoftwareVersion_device$data as Device } from './__generated__/DeviceSoftwareVersion_device.graphql';

const subscription = graphql`
  subscription DeviceSoftwareVersionSubscription(
    $input: UpdateDeviceSubscriptionInput!
  ) {
    updateDevice(input: $input) {
      device {
        ...DeviceSoftwareVersion_device
      }
    }
  }
`;

interface Props {
  device: DeepNonNull<Device>;
  refresh?: () => void;
  className?: string;
}

function DeviceSoftwareVersion({ device, refresh, ...rest }: Props) {
  const { id: deviceId, state, softwareVersion } = device;

  const emDash = '\u2014';
  useSubscription<DeviceSoftwareVersionSubscription>({
    subscription,
    variables: {
      input: { deviceId },
    },
    onNext: () => {
      if (refresh) {
        refresh();
      }
    },
  });

  if (state?.code === 'UPDATING') {
    const { label, progress } = state;
    return <Progress status={label} progress={progress} {...rest} />;
  }

  return <FormattedLabel label={softwareVersion ?? emDash} />;
}

export default createFragmentContainer(DeviceSoftwareVersion, {
  device: graphql`
    fragment DeviceSoftwareVersion_device on Device {
      id
      softwareVersion

      state {
        code
        label
        progress
      }
    }
  `,
});
