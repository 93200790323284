/**
 * @generated SignedSource<<940a912614bac6451e491ef30e9d118c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Libraries_rootFragment$data = {
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"NewLibraryPage_project">;
  } | null;
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"NewLibraryPage_tenant">;
  } | null;
  readonly " $fragmentType": "Libraries_rootFragment";
};
export type Libraries_rootFragment$key = {
  readonly " $data"?: Libraries_rootFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Libraries_rootFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "projectHandle"
    },
    {
      "kind": "RootArgument",
      "name": "tenantSlug"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Libraries_rootFragment",
  "selections": [
    {
      "alias": "tenant",
      "args": [
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "tenantSlug"
        }
      ],
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenantBySlug",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewLibraryPage_tenant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "handle",
          "variableName": "projectHandle"
        }
      ],
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewLibraryPage_project"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "30e31107a86c10e9e5b7d2d8b15d6726";

export default node;
