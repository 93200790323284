/**
 * @generated SignedSource<<c8dc49286c6261adf919db06d0d0be9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RunStatus = "aborted" | "chip_check" | "failed" | "finished" | "finished_chip_check" | "planned" | "planned_chip_check" | "processing" | "running" | "uploading";
import { FragmentRefs } from "relay-runtime";
export type RunList_runs$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly userProfile: {
      readonly " $fragmentSpreads": FragmentRefs<"CreatedByListItem_userProfile">;
    } | null;
  } | null;
  readonly endedAt: string | null;
  readonly handle: string | null;
  readonly id: string;
  readonly libraryAssociations: ReadonlyArray<{
    readonly library: {
      readonly name: string | null;
      readonly sample: {
        readonly name: string | null;
      } | null;
    } | null;
  } | null> | null;
  readonly name: string | null;
  readonly project: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly startedAt: string | null;
  readonly status: RunStatus | null;
  readonly " $fragmentSpreads": FragmentRefs<"RunProgress_run">;
  readonly " $fragmentType": "RunList_runs";
}>;
export type RunList_runs$key = ReadonlyArray<{
  readonly " $data"?: RunList_runs$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunList_runs">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RunList_runs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantUser",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "userProfile",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreatedByListItem_userProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RunLibraryAssociation",
      "kind": "LinkedField",
      "name": "libraryAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Library",
          "kind": "LinkedField",
          "name": "library",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Sample",
              "kind": "LinkedField",
              "name": "sample",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RunProgress_run"
    }
  ],
  "type": "Run",
  "abstractKey": null
};
})();

(node as any).hash = "c1fc9607c8838ecf03ab465d29552271";

export default node;
