/**
 * @generated SignedSource<<2c61cb84d7aebb0edd4f9dfbddc0393a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkflowReleaseIdInput = {
  version: number;
  workflowHandle?: string | null;
  workflowId?: string | null;
};
export type Apps_EditReleasePage_Query$variables = {
  releaseId: WorkflowReleaseIdInput;
};
export type Apps_EditReleasePage_Query$data = {
  readonly release: {
    readonly " $fragmentSpreads": FragmentRefs<"EditReleasePage_release">;
  } | null;
};
export type Apps_EditReleasePage_Query = {
  response: Apps_EditReleasePage_Query$data;
  variables: Apps_EditReleasePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "releaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "releaseId",
    "variableName": "releaseId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_EditReleasePage_Query",
    "selections": [
      {
        "alias": "release",
        "args": (v1/*: any*/),
        "concreteType": "WorkflowRelease",
        "kind": "LinkedField",
        "name": "workflowRelease",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EditReleasePage_release"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Apps_EditReleasePage_Query",
    "selections": [
      {
        "alias": "release",
        "args": (v1/*: any*/),
        "concreteType": "WorkflowRelease",
        "kind": "LinkedField",
        "name": "workflowRelease",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "public",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "changelog",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Workflow",
            "kind": "LinkedField",
            "name": "workflow",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "handle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c205ca99f2dcd18f1f2747e531510aee",
    "id": null,
    "metadata": {},
    "name": "Apps_EditReleasePage_Query",
    "operationKind": "query",
    "text": "query Apps_EditReleasePage_Query(\n  $releaseId: WorkflowReleaseIdInput!\n) {\n  release: workflowRelease(releaseId: $releaseId) {\n    ...EditReleasePage_release\n    id\n  }\n}\n\nfragment EditReleasePage_release on WorkflowRelease {\n  id\n  public\n  changelog\n  version\n  workflow {\n    handle\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6dbcdd1870a1ee1f42e7177f0aeaf9bb";

export default node;
