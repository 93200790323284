/**
 * @generated SignedSource<<107cbece162782c4e5776dca1a49075d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SendUserEmailVerificationInput = {
  clientMutationId?: string | null;
  userId: String;
};
export type VerifyEmailControl_SendEmailVerificationMutation$variables = {
  input: SendUserEmailVerificationInput;
};
export type VerifyEmailControl_SendEmailVerificationMutation$data = {
  readonly sendUserEmailVerificationOrError: {
    readonly __typename?: string;
    readonly message?: string | null;
  } | null;
};
export type VerifyEmailControl_SendEmailVerificationMutation = {
  response: VerifyEmailControl_SendEmailVerificationMutation$data;
  variables: VerifyEmailControl_SendEmailVerificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyEmailControl_SendEmailVerificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendUserEmailVerificationOrError",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "SendUserEmailVerificationPayload",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyEmailControl_SendEmailVerificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "sendUserEmailVerificationOrError",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "ErrorInterface",
            "abstractKey": "__isErrorInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "530b3ae3cc90a85d5e9677fd1c969430",
    "id": null,
    "metadata": {},
    "name": "VerifyEmailControl_SendEmailVerificationMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyEmailControl_SendEmailVerificationMutation(\n  $input: SendUserEmailVerificationInput!\n) {\n  sendUserEmailVerificationOrError(input: $input) {\n    __typename\n    ... on SendUserEmailVerificationPayload {\n      __typename\n    }\n    ... on ErrorInterface {\n      __isErrorInterface: __typename\n      __typename\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da5f71e521b3572215b9edc11e45a7ff";

export default node;
