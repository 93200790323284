/**
 * @generated SignedSource<<6bf2a57e1649d4b3814e3543cdabb9bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Devices_DevicePlotsSectionQuery$variables = {
  deviceHandle: string;
};
export type Devices_DevicePlotsSectionQuery$data = {
  readonly device: {
    readonly " $fragmentSpreads": FragmentRefs<"DevicePlotsSection_device">;
  } | null;
};
export type Devices_DevicePlotsSectionQuery = {
  response: Devices_DevicePlotsSectionQuery$data;
  variables: Devices_DevicePlotsSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "deviceHandle"
  }
],
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 30
},
v3 = {
  "kind": "Literal",
  "name": "status",
  "value": [
    "finished"
  ]
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_DevicePlotsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DevicePlotsSection_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Devices_DevicePlotsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": "genomicRuns",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "runType",
                "value": [
                  "genomic"
                ]
              },
              (v3/*: any*/)
            ],
            "concreteType": "RunConnection",
            "kind": "LinkedField",
            "name": "runConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RunEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Run",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numBases",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numReads",
                                "storageKey": null
                              }
                            ],
                            "type": "GenomicRunData",
                            "abstractKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "runConnection(first:30,runType:[\"genomic\"],status:[\"finished\"])"
          },
          {
            "alias": "proteomicRuns",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "runType",
                "value": [
                  "proteomic"
                ]
              },
              (v3/*: any*/)
            ],
            "concreteType": "RunConnection",
            "kind": "LinkedField",
            "name": "runConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RunEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Run",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numAminoAcids",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "numPeptides",
                                "storageKey": null
                              }
                            ],
                            "type": "ProteomicRunData",
                            "abstractKey": null
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "runConnection(first:30,runType:[\"proteomic\"],status:[\"finished\"])"
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1b053c2ebbe1f446e7da59b5b2e5845",
    "id": null,
    "metadata": {},
    "name": "Devices_DevicePlotsSectionQuery",
    "operationKind": "query",
    "text": "query Devices_DevicePlotsSectionQuery(\n  $deviceHandle: String!\n) {\n  device(handle: $deviceHandle) {\n    ...DevicePlotsSection_device\n    id\n  }\n}\n\nfragment DeviceNumAminoAcidsWidget_runs on Run {\n  name\n  data {\n    __typename\n    ... on ProteomicRunData {\n      numAminoAcids\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DeviceNumBasesWidget_runs on Run {\n  name\n  data {\n    __typename\n    ... on GenomicRunData {\n      numBases\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DeviceNumPeptidesWidget_runs on Run {\n  name\n  data {\n    __typename\n    ... on ProteomicRunData {\n      numPeptides\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DeviceNumReadsWidget_runs on Run {\n  name\n  data {\n    __typename\n    ... on GenomicRunData {\n      numReads\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment DevicePlotsGenomic_runs on Run {\n  ...DeviceNumBasesWidget_runs\n  ...DeviceNumReadsWidget_runs\n}\n\nfragment DevicePlotsProteomic_runs on Run {\n  ...DeviceNumAminoAcidsWidget_runs\n  ...DeviceNumPeptidesWidget_runs\n}\n\nfragment DevicePlotsSection_device on Device {\n  ...DevicePlots_device\n}\n\nfragment DevicePlots_device on Device {\n  genomicRuns: runConnection(first: 30, status: [finished], runType: [genomic]) {\n    edges {\n      node {\n        type\n        ...DevicePlotsGenomic_runs\n        id\n      }\n    }\n  }\n  proteomicRuns: runConnection(first: 30, status: [finished], runType: [proteomic]) {\n    edges {\n      node {\n        type\n        ...DevicePlotsProteomic_runs\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2928836aa5ddc732a2088a02a219710";

export default node;
