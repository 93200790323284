/**
 * @generated SignedSource<<1879017abd16a44a7ba4c2cfbcc32f24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Avatar_userProfile$data = {
  readonly displayName: string | null;
  readonly pictureUrl: string | null;
  readonly " $fragmentType": "Avatar_userProfile";
};
export type Avatar_userProfile$key = {
  readonly " $data"?: Avatar_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Avatar_userProfile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pictureUrl",
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "3d33b9d2753f7f8e6ebeb61c2106f782";

export default node;
