import classNames from 'classnames';
import React from 'react';

import './InputGrid.scss';

const rootClass = 'InputGrid';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const InputGrid = ({ children, className, ...props }: Props) => {
  return (
    <div className={classNames(rootClass, className)} {...props}>
      {children}
    </div>
  );
};

export default InputGrid;
