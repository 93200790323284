import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import './Modal.scss';

interface Props {
  children: React.ReactNode;
  trigger?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: React.ReactNode;
  dataTestId?: string;
}

interface FooterProps {
  children: React.ReactNode;
}

interface BodyProps {
  children: React.ReactNode;
}

const rootClass = 'Modal';

function Modal({
  trigger,
  title,
  open,
  onOpenChange,
  dataTestId = rootClass,
  children,
}: Props) {
  return (
    <Dialog.Root modal open={open} onOpenChange={onOpenChange}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className={rootClass}>
          <Dialog.Content
            className={`${rootClass}__content`}
            data-testid={dataTestId}
          >
            {title && (
              <Dialog.Title className={`${rootClass}__header`}>
                {title}
              </Dialog.Title>
            )}
            <div>{children}</div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export function Footer({ children }: FooterProps) {
  return <div className={`${rootClass}__footer`}>{children}</div>;
}

export function Body({ children }: BodyProps) {
  return <div className={`${rootClass}__body`}>{children}</div>;
}

export default Object.assign(Modal, {
  Body,
  Footer,
});
