import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import React from 'react';

import './Dropdown.scss';

interface Props {
  trigger: React.ReactNode;
  children: React.ReactNode;
  align?: 'start' | 'center' | 'end';
  className?: string;
  dataTestId?: string;
  fixedWidth?: boolean;
}

const rootClass = 'Dropdown';

function Dropdown({
  align = 'end',
  trigger,
  className,
  dataTestId = rootClass,
  children,
  fixedWidth,
}: Props) {
  return (
    <div className={classNames(rootClass, className)} data-testid={dataTestId}>
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
        <DropdownMenu.Content
          align={align}
          data-testid={`${dataTestId}-content`}
          className={classNames(`${rootClass}MenuContent`, {
            [`${rootClass}MenuContent--fixedwidth`]: fixedWidth,
          })}
        >
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}

export function DropdownMenuItem({
  children,
  className,
  nohover,
  onClick,
  onSelect,
  variant,
  ref,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  nohover?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  onSelect?: (event: Event) => void;
  variant?: 'danger';
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <DropdownMenu.Item
      onClick={onClick}
      className={classNames(className, {
        [`${rootClass}MenuItem--nohover`]: nohover,
        [`${rootClass}MenuItem--${variant}`]: variant,
      })}
      ref={ref}
      onSelect={onSelect}
      {...props}
    >
      {children}
    </DropdownMenu.Item>
  );
}

const DropdownMenuSeparator = DropdownMenu.Separator;

const DropdownMenuLabel = (props: DropdownMenu.DropdownMenuLabelProps) => {
  return (
    <DropdownMenu.Label
      {...props}
      className={classNames(`${rootClass}__dd-label`, props.className)}
    />
  );
};

export { DropdownMenuSeparator, DropdownMenuLabel };

export default Dropdown;
