import classNames from 'classnames';
import React from 'react';

import './Spinner.scss';

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'auto';
  centered?: boolean;
  className?: string;
  dataTestId?: string;
  allowInTests?: boolean;
}

const rootClass = 'Spinner';

const defaultProps: Props = {
  size: 'auto',
  dataTestId: rootClass,
};

const Spinner = ({
  size,
  centered,
  className,
  dataTestId,
  allowInTests,
}: Props) => {
  return (
    <span
      className={classNames(
        rootClass,
        className,
        centered && `${rootClass}--centered`,
      )}
    >
      <svg
        viewBox="0 0 34 34"
        className={classNames(`${rootClass}__icon--${size}`)}
        data-bni-id="Spinner"
        data-testid={dataTestId}
        data-allow-in-tests={allowInTests ? true : undefined}
      >
        <g fill="none">
          <g transform="translate(-3, 0)">
            <g transform="translate(2, 2)">
              <g className="Spinner--outer-circle--XGbgt">
                <path d="M30,15 C30,6.71572875 23.2842712,0 15,0 C6.71572875,0 2.55795385e-13,6.71572875 2.55795385e-13,15" />
              </g>
              <g className="Spinner--inner-circle--2rpkw">
                <path d="M12,0 C5.372583,0 -1.77635684e-15,5.372583 -1.77635684e-15,12" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

Spinner.defaultProps = defaultProps;

export default Spinner;
