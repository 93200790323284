import React from 'react';
import { FormattedMessage } from 'react-intl';

import { supportEmail } from 'config/about';

import { DropdownMenuItem } from './Dropdown';

interface Props {
  dataTestId?: string;
}

const rootClass = 'SupportControl';

const SupportControl = ({ dataTestId = rootClass }: Props) => {
  return (
    <a href={`mailto:${supportEmail}`} aria-label="Contact Support">
      <DropdownMenuItem data-testid={`${dataTestId}-supportCenter`}>
        <FormattedMessage
          id="helpMenu.contactSupport"
          defaultMessage="Contact Support"
        />
      </DropdownMenuItem>
    </a>
  );
};

export default SupportControl;
