import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'messages/common';

import Button from './Button';
import Modal from './Modal';

type ModalProps = {
  title: ReactNode | string;
  content: ReactNode | string;
  open: boolean;
  hideCancel?: boolean;
  confirmLabel?: ReactNode | string;
  onCancel?: () => void;
  onSubmit?: () => void;
};

const ModalContext = React.createContext({
  openModal: (_modalProps: ModalProps): Promise<boolean> => {
    return new Promise((resolve) => {
      resolve(true);
    });
  },
  closeModal: () => {
    return true;
  },
});

const ModalProvider = (props: any) => {
  const [modal, setModal] = useState<ModalProps>();
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setModal(undefined);
  };
  const openModal = (modalProps: ModalProps): Promise<boolean> => {
    return new Promise((resolve) => {
      setModal({
        ...modalProps,
        open,
        onCancel: () => {
          closeModal();
          resolve(false);
          return true;
        },
        onSubmit: () => {
          closeModal();
          resolve(true);
        },
      });
      setOpen(true);
      return true;
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider {...props} value={{ openModal, closeModal }}>
      {props?.children}
      {modal && (
        <Modal title={modal.title} open={open}>
          <Modal.Body>{modal.content}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={modal.onSubmit}>
              {modal.confirmLabel ? (
                modal.confirmLabel
              ) : (
                <FormattedMessage {...commonMessages.save} />
              )}
            </Button>
            {!modal.hideCancel && (
              <Button variant="secondary" onClick={modal.onCancel}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('context undefined');
  }

  return context;
};

export { ModalProvider, useModal };
