/**
 * @generated SignedSource<<61afca6377ad29c7567af8d6960fae31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderSearchBar_tag$data = {
  readonly color: string | null;
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly " $fragmentType": "HeaderSearchBar_tag";
};
export type HeaderSearchBar_tag$key = {
  readonly " $data"?: HeaderSearchBar_tag$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_tag">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderSearchBar_tag",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Tag",
  "abstractKey": null
};

(node as any).hash = "77583c9b1c91f18f1ab914a345f311d0";

export default node;
