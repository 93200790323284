import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useSubscription,
} from 'react-relay';

import Progress from 'components/Progress';
import { analysisJobStatus, analysisStatus } from 'messages/analyses';

import type { AnalysisProgressSubscription } from './__generated__/AnalysisProgressSubscription.graphql';
import type { AnalysisProgress_analysis$data as Analysis } from './__generated__/AnalysisProgress_analysis.graphql';
import useAnalysisProgressInfo from './useAnalysisProgressInfo';

const subscription = graphql`
  subscription AnalysisProgressSubscription($input: AnalysisJobUpdatedInput!) {
    analysisJobUpdated(input: $input) {
      analysisJob {
        ...AnalysisProgress_analysisJob
      }
    }
  }
`;

interface Props {
  analysis: DeepNonNull<Analysis>;
  className?: string;
}

function AnalysisProgress({ analysis, className }: Props) {
  useSubscription<AnalysisProgressSubscription>({
    subscription,
    variables: {
      input: { analysisJobId: analysis.job?.id },
    },
  });

  const { job, status, numPendingRuns, numPendingUpstreamAnalyses } = analysis;

  const { numCompleted, numPending, numRunning } = useAnalysisProgressInfo(
    job?.steps ?? [],
  );

  const statusMessage = useMemo(() => {
    if (numPendingRuns)
      return (
        <FormattedMessage
          id="analysisProgress.waitingForRuns"
          defaultMessage="Waiting for runs to finish"
        />
      );

    if (numPendingUpstreamAnalyses)
      return (
        <FormattedMessage
          id="analysisProgress.waitingForUpstreamAnalyses"
          defaultMessage="Waiting for input analyses to finish"
        />
      );

    if (!job?.status) return analysisStatus[status];

    if (status === 'running' && job.status === 'RUNNING') {
      return (
        <FormattedMessage
          id="analysisSummarySection.stepsStatus"
          defaultMessage="{numCompleted} Completed | {numRunning} Running | {numPending} Pending"
          values={{
            numCompleted,
            numRunning,
            numPending,
          }}
        />
      );
    }

    return analysisJobStatus[job.status];
  }, [
    job,
    numCompleted,
    numPending,
    numPendingRuns,
    numPendingUpstreamAnalyses,
    numRunning,
    status,
  ]);

  const indeterminate = !job;

  const progress = useMemo(() => {
    if (indeterminate || status !== 'running') return undefined;

    return numCompleted / (numCompleted + numPending + numRunning);
  }, [indeterminate, numCompleted, numPending, numRunning, status]);

  return (
    <Progress
      className={className}
      status={statusMessage}
      progress={progress}
      indeterminate={indeterminate}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment AnalysisProgress_analysisJob on AnalysisJob {
    id
    status
    steps {
      status
    }
  }
`;

export default createFragmentContainer(AnalysisProgress, {
  analysis: graphql`
    fragment AnalysisProgress_analysis on Analysis {
      numPendingRuns
      numPendingUpstreamAnalyses
      status
      job {
        ...AnalysisProgress_analysisJob @relay(mask: false)
      }
    }
  `,
});
