/**
 * @generated SignedSource<<2f2bb3df3f3d5f3381e2b4dbf5214396>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Devices_DevicePageQuery$variables = {
  deviceHandle: string;
  tenantSlug: string;
};
export type Devices_DevicePageQuery$data = {
  readonly device: {
    readonly " $fragmentSpreads": FragmentRefs<"DevicePage_device">;
  } | null;
};
export type Devices_DevicePageQuery = {
  response: Devices_DevicePageQuery$data;
  variables: Devices_DevicePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deviceHandle"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenantSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "deviceHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Devices_DevicePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DevicePage_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Devices_DevicePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Device",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SoftwareVersion",
            "kind": "LinkedField",
            "name": "availableSoftwareUpgrades",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "versionNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "busyStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "softwareVersion",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceCommand",
            "kind": "LinkedField",
            "name": "command",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followingStatus",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "family",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numLibraries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "tenantSlug",
                "variableName": "tenantSlug"
              }
            ],
            "kind": "ScalarField",
            "name": "numRuns",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d238b884ae0eafca1341b573effea2c",
    "id": null,
    "metadata": {},
    "name": "Devices_DevicePageQuery",
    "operationKind": "query",
    "text": "query Devices_DevicePageQuery(\n  $deviceHandle: String!\n  $tenantSlug: String!\n) {\n  device(handle: $deviceHandle) {\n    ...DevicePage_device\n    id\n  }\n}\n\nfragment DevicePageHeader_device on Device {\n  name\n  handle\n  availableSoftwareUpgrades {\n    id\n    __typename\n  }\n  busyStatus\n  softwareVersion\n  command {\n    __typename\n  }\n  ...FollowDeviceControl_device\n  ...EditDeviceControl_device\n  ...UpgradeDeviceControl_device\n}\n\nfragment DevicePageTabs_device on Device {\n  handle\n  family\n  numLibraries\n  numRuns(tenantSlug: $tenantSlug)\n}\n\nfragment DevicePage_device on Device {\n  ...DevicePageHeader_device\n  ...DevicePageTabs_device\n}\n\nfragment EditDeviceControl_device on Device {\n  handle\n}\n\nfragment FollowDeviceControl_device on Device {\n  followingStatus\n  id\n}\n\nfragment UpgradeDeviceControl_device on Device {\n  busyStatus\n  softwareVersion\n  command {\n    __typename\n  }\n  availableSoftwareUpgrades {\n    __typename\n    id\n  }\n  ...UpgradeDeviceModal_device\n}\n\nfragment UpgradeDeviceModal_device on Device {\n  id\n  softwareVersion\n  availableSoftwareUpgrades {\n    id\n    versionNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "4bd99eef8842bf7165a0781955099205";

export default node;
