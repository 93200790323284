import classNames from 'classnames';
import React from 'react';

import Text from 'components/Text';

import './SectionHeader.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const rootClass = 'SectionHeader';

const SectionHeader = ({ children, className }: Props) => {
  return (
    <Text
      size="lg"
      variant="bold"
      as="div"
      className={classNames(rootClass, className)}
    >
      {children}
    </Text>
  );
};

export default SectionHeader;
