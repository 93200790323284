export const UppercaseRegex = /^(?=.*[A-Z])/;

export const LowercaseRegex = /^(?=.*[a-z])/;

export const NumericRegex = /^(?=.*[0-9])/;

//  [!-/:-@[-`{-~] = validates four different ranges of characters covering all possible special characters.
export const SpecialCharacterRegex = /^(?=.*[!-/:-@[-`{-~])/;

// Regex that confirms length of 8 that has one uppercase, one lowercase, one special character and one number
export const AlphaNumericPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~])(?=.{8,255})/;
