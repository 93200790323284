import { defineMessages } from 'react-intl';

export default defineMessages({
  specimens: {
    id: 'tabs.specimens',
    defaultMessage: 'Samples',
  },
  libraries: {
    id: 'tabs.libraries',
    defaultMessage: 'Libraries',
  },
  runs: {
    id: 'tabs.runs',
    defaultMessage: 'Runs',
  },
  analyses: {
    id: 'tabs.analyses',
    defaultMessage: 'Analyses',
  },
  projects: {
    id: 'tabs.projects',
    defaultMessage: 'Projects',
  },
  count: {
    id: 'tabs.count',
    defaultMessage: '{entity} ({count})',
  },
  notes: {
    id: 'tabs.notes',
    defaultMessage: 'Notes',
  },
});
