import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <Route
    path="settings"
    getComponent={() =>
      import(/* webpackChunkName: "tenants" */ './components/TenantPage').then(
        (p) => p.default,
      )
    }
    query={graphql`
      query Tenant_TenantPageQuery($tenantSlug: String!) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          ...TenantPage_tenant
        }
      }
    `}
  />
);
