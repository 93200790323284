/**
 * @generated SignedSource<<2ec7e7e172d81082c66c54c10ee2d94a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LibrarySearchList_tenant$data = {
  readonly libraryConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"LibraryList_libraries">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "LibrarySearchList_tenant";
};
export type LibrarySearchList_tenant$key = {
  readonly " $data"?: LibrarySearchList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"LibrarySearchList_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "projectHandles"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "statuses"
    },
    {
      "kind": "RootArgument",
      "name": "tagHandles"
    },
    {
      "kind": "RootArgument",
      "name": "userProfileHandles"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "libraryConnection"
        ]
      }
    ]
  },
  "name": "LibrarySearchList_tenant",
  "selections": [
    {
      "alias": "libraryConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "createdBy",
          "variableName": "userProfileHandles"
        },
        {
          "kind": "Variable",
          "name": "project",
          "variableName": "projectHandles"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "statuses"
        },
        {
          "kind": "Variable",
          "name": "tag",
          "variableName": "tagHandles"
        }
      ],
      "concreteType": "LibraryConnection",
      "kind": "LinkedField",
      "name": "__Tenant_libraryConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LibraryEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Library",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LibraryList_libraries"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "37d103cc8ed6403ea7a62762841db28d";

export default node;
