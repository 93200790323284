/**
 * @generated SignedSource<<68ebba3671c12145f8649e1f1caf66b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Protocols_protocolQuery$variables = {
  protocolHandle: string;
};
export type Protocols_protocolQuery$data = {
  readonly protocol: {
    readonly " $fragmentSpreads": FragmentRefs<"ProtocolPage_protocol">;
  } | null;
};
export type Protocols_protocolQuery = {
  response: Protocols_protocolQuery$data;
  variables: Protocols_protocolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "protocolHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "protocolHandle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Protocols_protocolQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "protocol",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProtocolPage_protocol"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Protocols_protocolQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "protocol",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isProtocol"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoUrl",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "type": "Node",
            "abstractKey": "__isNode"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3324f992688e759cb163c44d98243a55",
    "id": null,
    "metadata": {},
    "name": "Protocols_protocolQuery",
    "operationKind": "query",
    "text": "query Protocols_protocolQuery(\n  $protocolHandle: String!\n) {\n  protocol(handle: $protocolHandle) {\n    __typename\n    ...ProtocolPage_protocol\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ProtocolPage_protocol on Protocol {\n  __isProtocol: __typename\n  lastUpdatedAt\n  description\n  handle\n  name\n  type\n  fileUrl\n  videoUrl\n}\n"
  }
};
})();

(node as any).hash = "9ac6aaad9f4bad6d036677c6abe22df9";

export default node;
