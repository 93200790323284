import type { Match } from 'found';

export default function getReferenceSearchVariables(
  vars?: Obj,
  match?: Match,
) {
  const query = match?.location?.query;
  const search = query?.search || null;
  const proteomeFileStatus = query?.proteomeFileStatus || null;
  const selectedUserProfileHandle = query?.createdBy || null;
  const userProfileIdSearchFilters = selectedUserProfileHandle;
  const includeGlobalPublic = query?.includeGlobalPublic === 'true';

  return {
    ...vars,
    search,
    proteomeFileStatus,
    userProfileIdSearchFilters,
    selectedUserProfileHandle,
    hasUserProfile: !!selectedUserProfileHandle,
    includeGlobalPublic,
  };
}
