/**
 * @generated SignedSource<<6f8a90bc1583233e4117ac7576e1434d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnalysisJobStatus = "ABORTED" | "COMPLETED" | "FAILED" | "QUEUED" | "RESTARTING" | "RUNNING" | "WAITING_FOR_INSTANCE";
export type AnalysisJobStepStatus = "ABORTED" | "COMPLETED" | "FAILED" | "PENDING" | "RESTARTING" | "RUNNING";
export type AnalysisStatus = "aborted" | "completed" | "failed" | "pending" | "restarting" | "running";
import { FragmentRefs } from "relay-runtime";
export type analysesMessages_analysis$data = {
  readonly job: {
    readonly status: AnalysisJobStatus | null;
    readonly steps: ReadonlyArray<{
      readonly status: AnalysisJobStepStatus | null;
    } | null> | null;
  } | null;
  readonly status: AnalysisStatus | null;
  readonly " $fragmentType": "analysesMessages_analysis";
};
export type analysesMessages_analysis$key = {
  readonly " $data"?: analysesMessages_analysis$data;
  readonly " $fragmentSpreads": FragmentRefs<"analysesMessages_analysis">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "analysesMessages_analysis",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalysisJob",
      "kind": "LinkedField",
      "name": "job",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalysisJobStep",
          "kind": "LinkedField",
          "name": "steps",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Analysis",
  "abstractKey": null
};
})();

(node as any).hash = "bb8015119fe7f90aab40372e0a709496";

export default node;
