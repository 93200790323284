/**
 * @generated SignedSource<<be263f1c44bcaccec327a0af14c0da4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatedByListItem_userProfile$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserProfile_userProfile">;
  readonly " $fragmentType": "CreatedByListItem_userProfile";
};
export type CreatedByListItem_userProfile$key = {
  readonly " $data"?: CreatedByListItem_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreatedByListItem_userProfile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreatedByListItem_userProfile",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfile_userProfile"
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};

(node as any).hash = "5e8b90266299702bd5aefc4492403345";

export default node;
