/**
 * @generated SignedSource<<e511ced422d777cf83a4d2e7a94e6572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderSearchBar_project$data = {
  readonly handle: string | null;
  readonly name: string | null;
  readonly " $fragmentType": "HeaderSearchBar_project";
};
export type HeaderSearchBar_project$key = {
  readonly " $data"?: HeaderSearchBar_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderSearchBar_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "handle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "b9305101032f6f3e6ba99608055a57b1";

export default node;
