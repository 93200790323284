/**
 * @generated SignedSource<<ffeac35da8cc3cb68f49af41f9334a75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Protocols_manualQuery$variables = {
  manualHandle: string;
};
export type Protocols_manualQuery$data = {
  readonly manual: {
    readonly " $fragmentSpreads": FragmentRefs<"ManualPage_manual">;
  } | null;
};
export type Protocols_manualQuery = {
  response: Protocols_manualQuery$data;
  variables: Protocols_manualQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "manualHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "manualHandle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Protocols_manualQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Manual",
        "kind": "LinkedField",
        "name": "manual",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ManualPage_manual"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Protocols_manualQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Manual",
        "kind": "LinkedField",
        "name": "manual",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "497b349ac92bbf8590f7d4885f9466ca",
    "id": null,
    "metadata": {},
    "name": "Protocols_manualQuery",
    "operationKind": "query",
    "text": "query Protocols_manualQuery(\n  $manualHandle: String!\n) {\n  manual(handle: $manualHandle) {\n    ...ManualPage_manual\n    id\n  }\n}\n\nfragment ManualPage_manual on Manual {\n  handle\n  createdAt\n  lastUpdatedAt\n  name\n  fileUrl\n  description\n}\n"
  }
};
})();

(node as any).hash = "4606c2d56adff54d74817f00e3a3ba9c";

export default node;
