import React, { ReactElement } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export declare type LabelValue =
  | ReactElement
  | MessageDescriptor
  | string
  | number
  | boolean;

export type Props = {
  label?: any;
  children?: any;
};

const FormattedLabel = ({ label, children }: Props) => {
  const value = children || label;

  if (
    React.isValidElement(value) ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return <>{value}</>;
  }

  return <FormattedMessage {...value} />;
};

export default FormattedLabel;
