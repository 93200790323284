/**
 * @generated SignedSource<<3582b93cdee6e36825f3c6bf6e53e0e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AnalysisStatus = "aborted" | "completed" | "failed" | "pending" | "restarting" | "running";
import { FragmentRefs } from "relay-runtime";
export type AnalysisList_analyses$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly userProfile: {
      readonly " $fragmentSpreads": FragmentRefs<"CreatedByListItem_userProfile">;
    } | null;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly project: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly status: AnalysisStatus | null;
  readonly workflow: {
    readonly name: string | null;
  } | null;
  readonly workflowRelease: {
    readonly versionName: string | null;
  } | null;
  readonly workflowVersion: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"AnalysisProgress_analysis">;
  readonly " $fragmentType": "AnalysisList_analyses";
}>;
export type AnalysisList_analyses$key = ReadonlyArray<{
  readonly " $data"?: AnalysisList_analyses$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnalysisList_analyses">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AnalysisList_analyses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Workflow",
      "kind": "LinkedField",
      "name": "workflow",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowRelease",
      "kind": "LinkedField",
      "name": "workflowRelease",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "versionName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workflowVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantUser",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "userProfile",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreatedByListItem_userProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AnalysisProgress_analysis"
    }
  ],
  "type": "Analysis",
  "abstractKey": null
};
})();

(node as any).hash = "b5009e24656627a5b97948d268b1bd48";

export default node;
