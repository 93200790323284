import type { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';
import checkTenantItem from 'utils/checkTenantItem';

import DevicesListSection from './components/DevicesListSection';
import DevicesPage from './components/DevicesPage';

export default (
  <Route path="devices">
    <Route Component={DevicesPage}>
      <Route
        Component={DevicesListSection}
        query={graphql`
          query Devices_devicesQuery(
            $tenantSlug: String!
            $count: Int!
            $cursor: String
          ) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...DevicesListSection_tenant
            }
          }
        `}
        prepareVariables={getInfiniteListVariables}
      />
    </Route>

    <Route
      path=":deviceHandle"
      query={graphql`
        query Devices_deviceRootQuery(
          $tenantSlug: String!
          $deviceHandle: String!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: device(handle: $deviceHandle) {
            tenant {
              id
            }
          }
        }
      `}
      prerender={checkTenantItem}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "devices" */ './components/DevicePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Devices_DevicePageQuery(
            $deviceHandle: String!
            $tenantSlug: String!
          ) {
            device(handle: $deviceHandle) {
              ...DevicePage_device
            }
          }
        `}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "devices" */ './components/DeviceSummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Devices_DeviceSummarySectionQuery($deviceHandle: String!) {
              device(handle: $deviceHandle) {
                ...DeviceSummarySection_device
              }
            }
          `}
        />
        <Route
          path="plots"
          getComponent={() =>
            import(
              /* webpackChunkName: "devices" */ './components/DevicePlotsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Devices_DevicePlotsSectionQuery($deviceHandle: String!) {
              device(handle: $deviceHandle) {
                ...DevicePlotsSection_device
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
        <Route
          path="runs"
          getComponent={() =>
            import(
              /* webpackChunkName: "devices" */ './components/DeviceRunsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Devices_DeviceRunsSectionQuery(
              $tenantSlug: String!
              $deviceHandle: [IdOrHandle!]!
              $count: Int!
              $cursor: String
              $sort: [RunSorting!]
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...DeviceRunsSection_device
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="libraries"
          getComponent={() =>
            import(
              /* webpackChunkName: "devices" */ './components/DeviceLibrariesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Devices_DeviceLibrariesSectionQuery(
              $tenantSlug: String!
              $deviceHandle: [IdOrHandle!]!
              $count: Int!
              $cursor: String
              $sort: [LibrarySorting!]
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...DeviceLibrariesSection_device
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="loading"
          getComponent={() =>
            import(
              /* webpackChunkName: "devices" */ './components/DeviceLibraryLoadingsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Devices_DeviceLibraryLoadingsSectionQuery(
              $tenantSlug: String!
              $deviceHandle: [IdOrHandle!]!
              $count: Int!
              $cursor: String
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...DeviceLibraryLoadingsSection_device
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "devices" */ './components/EditDevicePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Devices_EditDevicePageQuery($deviceHandle: String!) {
            device(handle: $deviceHandle) {
              ...EditDevicePage_device
            }
          }
        `}
      />
    </Route>
  </Route>
);
