/**
 * @generated SignedSource<<acab60fa37acb8252f8f1ba4323f8a18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Search_HeaderSearchBarQuery$variables = {
  hasProject: boolean;
  hasTag: boolean;
  hasUserProfile: boolean;
  projectHandle: string;
  tagHandle: string;
  userProfileHandle: string;
};
export type Search_HeaderSearchBarQuery$data = {
  readonly project?: {
    readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_project">;
  } | null;
  readonly tag?: {
    readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_tag">;
  } | null;
  readonly userProfile?: {
    readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_userProfile">;
  } | null;
};
export type Search_HeaderSearchBarQuery = {
  response: Search_HeaderSearchBarQuery$data;
  variables: Search_HeaderSearchBarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasProject"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTag"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserProfile"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectHandle"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagHandle"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userProfileHandle"
},
v6 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "userProfileHandle"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "projectHandle"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "tagHandle"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Search_HeaderSearchBarQuery",
    "selections": [
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HeaderSearchBar_userProfile"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HeaderSearchBar_project"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasTag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HeaderSearchBar_tag"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Search_HeaderSearchBarQuery",
    "selections": [
      {
        "condition": "hasUserProfile",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pictureUrl",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Project",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "hasTag",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "f4a71af5ff61566369c2df373351bba7",
    "id": null,
    "metadata": {},
    "name": "Search_HeaderSearchBarQuery",
    "operationKind": "query",
    "text": "query Search_HeaderSearchBarQuery(\n  $projectHandle: String!\n  $hasProject: Boolean!\n  $userProfileHandle: String!\n  $hasUserProfile: Boolean!\n  $tagHandle: String!\n  $hasTag: Boolean!\n) {\n  userProfile(handle: $userProfileHandle) @include(if: $hasUserProfile) {\n    ...HeaderSearchBar_userProfile\n    id\n  }\n  project(handle: $projectHandle) @include(if: $hasProject) {\n    ...HeaderSearchBar_project\n    id\n  }\n  tag(handle: $tagHandle) @include(if: $hasTag) {\n    ...HeaderSearchBar_tag\n    id\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment HeaderSearchBar_project on Project {\n  handle\n  name\n}\n\nfragment HeaderSearchBar_tag on Tag {\n  color\n  handle\n  id\n  name\n}\n\nfragment HeaderSearchBar_userProfile on UserProfile {\n  handle\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "8452d628a5921b9735ed80b08a6d78b6";

export default node;
