/**
 * @generated SignedSource<<58e6deaf814b9b620b52e9085a6a6a0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SwitchTenantModal_activeTenant$data = {
  readonly id: string;
  readonly " $fragmentType": "SwitchTenantModal_activeTenant";
};
export type SwitchTenantModal_activeTenant$key = {
  readonly " $data"?: SwitchTenantModal_activeTenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"SwitchTenantModal_activeTenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SwitchTenantModal_activeTenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "4dc2e68a0b1fef6e3b5a24f343154bb7";

export default node;
