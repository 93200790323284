import classNames from 'classnames';
import React from 'react';

import './Text.scss';

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: React.ElementType | string;
  variant?: 'bold' | 'italic';
  size?: 'xs' | 'sm' | 'lg' | 'xl';
  color?:
    | 'inherit'
    | 'headline'
    | 'subtitle'
    | 'draft'
    | 'primary'
    | 'success'
    | 'danger'
    | 'expired';
  transform?: 'uppercase';
  truncate?: boolean;
  headline?: boolean;
  className?: string;
  children?: React.ReactNode;
  dataTestId?: string;
};

const rootClass = 'Text';

const Text = ({
  as: Tag = 'span',
  dataTestId = rootClass,
  className,
  variant,
  color,
  size,
  transform,
  headline,
  truncate,
  children,
  ...rest
}: Props) => {
  return (
    <Tag
      data-testid={dataTestId}
      className={classNames(
        rootClass,
        className,
        variant && `${rootClass}__variant-${variant}`,
        transform && `${rootClass}__transform-${transform}`,
        truncate && `${rootClass}__truncate`,
        headline && `${rootClass}__headline`,
        size && `${rootClass}__size-${size}`,
        color && `${rootClass}__color-${color}`,
      )}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default Text;
