import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Button from 'components/Button';
import DeviceSoftwareVersion from 'components/DeviceSoftwareVersion';
import DeviceStatus from 'components/DeviceStatus';
import ItemGrid, { TableColumns } from 'components/ItemGrid';
import NoItemFound from 'components/NoItemFound';
import { deviceFamily } from 'messages/devices';
import { useTenantRoutes } from 'utils/tenantRoutes';
import useTenant from 'utils/useTenant';

import type { DeviceList_devices$data as Devices } from './__generated__/DeviceList_devices.graphql';
import RegisterDeviceModal from './controls/RegisterDeviceModal';

interface Props {
  devices: Devices;
  dataTestId?: string;
}

const rootClass = 'DeviceList';

function DeviceList({ devices, dataTestId = rootClass }: Props) {
  const routes = useTenantRoutes();
  const { isAdmin: canRegisterDevices } = useTenant();

  const columns = React.useMemo<TableColumns<Devices[number]>>(
    () => [
      {
        Header: (
          <FormattedMessage id="deviceList.name" defaultMessage="Name" />
        ),
        accessor: 'name',
      },
      {
        Header: (
          <FormattedMessage id="deviceList.type" defaultMessage="Type" />
        ),
        accessor: 'family',
        Cell: ({ row }) => (
          <FormattedMessage {...deviceFamily[row.original.family!]} />
        ),
      },
      {
        Header: (
          <FormattedMessage
            id="deviceList.softwareVersion"
            defaultMessage="Software Version"
          />
        ),
        accessor: 'softwareVersion',
        Cell: ({ row }) => <DeviceSoftwareVersion device={row.original} />,
      },
      {
        Header: (
          <FormattedMessage id="deviceList.status" defaultMessage="Status" />
        ),
        accessor: 'registered',
        Cell: ({ row }) => {
          if (row.original.registered)
            return <DeviceStatus device={row.original} />;
          if (canRegisterDevices)
            return (
              <RegisterDeviceModal
                device={row.original}
                trigger={
                  <Button
                    data-testid="AssignedDeviceRegister"
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      e.stopPropagation();
                    }}
                  >
                    <FormattedMessage
                      id="deviceList.register"
                      defaultMessage="Register"
                    />
                  </Button>
                }
              />
            );

          return (
            <FormattedMessage
              id="deviceList.unregistered"
              defaultMessage="Unregistered"
            />
          );
        },
      },
    ],
    [canRegisterDevices],
  );

  if (devices.length === 0) {
    return (
      <NoItemFound
        itemType={
          <FormattedMessage
            id="deviceList.itemType"
            defaultMessage="instruments"
          />
        }
      />
    );
  }

  return (
    <ItemGrid
      data={devices}
      columns={columns}
      dataTestId={rootClass}
      linkTo={(device: DeepNonNull<Devices[number]>) => {
        if (!device.registered) return false;
        return routes.device({ device });
      }}
      data-testid={dataTestId}
    />
  );
}

export default createFragmentContainer(DeviceList, {
  devices: graphql`
    fragment DeviceList_devices on Device @relay(plural: true) {
      id
      error {
        label
      }
      handle
      name
      registered
      family
      softwareVersion
      ...DeviceStatus_device
      ...RegisterDeviceModal_device
      ...DeviceSoftwareVersion_device
    }
  `,
});
