import { GraphQLTaggedNode, fetchQuery } from 'react-relay';
import {
  AnySchema,
  ValidationError,
  addMethod,
  array,
  mixed,
  object,
  setLocale,
  string,
} from 'yup';

import messages from 'messages/validation';

setLocale(messages as any);

type TestResult =
  | boolean
  | ValidationError
  | Promise<boolean | ValidationError>;
type OptionalTestResult = TestResult | undefined;
type LocalValidateResult = OptionalTestResult;

interface RemoteArgs {
  query: GraphQLTaggedNode;
  getArgs: (value: unknown, self: any, parent: any, context: unknown) => Obj;
  getResult: (data: any, context: unknown, testContext: unknown) => TestResult;
  localValidate: (
    value: unknown,
    self: any,
    parent: any,
    context: unknown,
    previousResult: OptionalTestResult,
    previousValue: unknown,
  ) => LocalValidateResult;
  message: any;
}

function remote(
  this: AnySchema,
  { query, getArgs, getResult, localValidate, message }: RemoteArgs,
) {
  let previousResult: OptionalTestResult;
  let previousValue: unknown;

  return this.test({
    message,
    name: 'remote',
    exclusive: false,

    async test(value: unknown, testContext) {
      const { context } = this.options;
      if (localValidate) {
        const localValidateResult = localValidate(
          value,
          this,
          this.parent,
          context,
          previousResult,
          previousValue,
        );
        if (localValidateResult !== undefined) {
          return localValidateResult;
        }
      }

      const data = await fetchQuery(
        context!.relay.environment,
        query,
        getArgs(value, this, this.parent, context),
      ).toPromise();

      const result = getResult(data!, context, testContext);
      previousResult = result;
      previousValue = value;
      return result;
    },
  });
}

// Adapted from: https://github.com/jquense/yup/issues/851#issuecomment-1213339673
function sequence(
  this: AnySchema,
  getValidators: () => Array<(schema: AnySchema) => AnySchema>,
) {
  const funcList = getValidators();
  const sequenceSchemas = funcList.map((fn) => fn(this.clone()));
  return this.test(async (value, context) => {
    try {
      for (const schema of sequenceSchemas) {
        // eslint-disable-next-line no-await-in-loop
        await schema.validate(value, context.options);
      }
    } catch (error: any) {
      return context.createError({ message: error.message, type: error.type });
    }
    return true;
  });
}

addMethod(mixed, 'remote', remote);
addMethod(string, 'remote', remote);
addMethod(array, 'remote', remote);
addMethod(object, 'remote', remote);
addMethod(mixed, 'sequence', sequence);
addMethod(string, 'sequence', sequence);
addMethod(array, 'sequence', sequence);
addMethod(object, 'sequence', sequence);
