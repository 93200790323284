/**
 * @generated SignedSource<<5c2c115b1585404cef069244261cdde1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SampleList_samples$data = ReadonlyArray<{
  readonly createdAt: string | null;
  readonly createdBy: {
    readonly userProfile: {
      readonly " $fragmentSpreads": FragmentRefs<"CreatedByListItem_userProfile">;
    } | null;
  } | null;
  readonly handle: string | null;
  readonly id: string;
  readonly name: string | null;
  readonly project: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly " $fragmentType": "SampleList_samples";
}>;
export type SampleList_samples$key = ReadonlyArray<{
  readonly " $data"?: SampleList_samples$data;
  readonly " $fragmentSpreads": FragmentRefs<"SampleList_samples">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SampleList_samples",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantUser",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfile",
          "kind": "LinkedField",
          "name": "userProfile",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreatedByListItem_userProfile"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Sample",
  "abstractKey": null
};
})();

(node as any).hash = "394f081702fcebf4f0005ad2bc4e7fc4";

export default node;
