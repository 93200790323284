import classNames from 'classnames';
import { Link } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import Text from 'components/Text';
import tabs from 'messages/tabs';
import { useProjectRoutes } from 'utils/projectRoutes';
import { useTenantRoutes } from 'utils/tenantRoutes';

import './Breadcrumbs.scss';

interface Props {
  children: React.ReactNode;
}

interface Entity {
  handle: string;
  name: string;
}

interface ProjectEntity extends Entity {
  project: Entity;
}

interface NewsEntity {
  handle: string;
  title: string;
}

interface DisableLink {
  disableLink?: boolean;
}

const rootClass = 'Breadcrumbs';

const LinkWrapper = ({
  children,
  to,
  disableLink,
}: {
  children: React.ReactNode;
  to?: string;
} & DisableLink) => {
  if (disableLink || !to) {
    return (
      <Text variant="bold" color="headline">
        {children}
      </Text>
    );
  }

  return (
    <Link to={to} className={classNames(`${rootClass}__link`)}>
      {children}
    </Link>
  );
};

const Breadcrumb = ({ children }: Props) => {
  const items = React.Children.toArray(children);

  return (
    <>
      {items.map((node, i) => {
        const isLast = i === items.length - 1;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            {node}
            {!isLast && <Text color="subtitle">{' > '}</Text>}
          </React.Fragment>
        );
      })}
    </>
  );
};

function Breadcrumbs({ children }: Props) {
  const items = React.Children.toArray(children) as React.ReactElement[];

  return (
    <h2 className={rootClass}>
      <Breadcrumb>
        {items.map((node, i) =>
          React.cloneElement(node, {
            disableLink: i === items.length - 1,
          }),
        )}
      </Breadcrumb>
    </h2>
  );
}

export const AppsBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.workflows()} disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.workflows"
        defaultMessage="Workflows"
      />
    </LinkWrapper>
  );
};

export const AppBreadcrumb = ({
  app,
  disableLink,
}: {
  app: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <AppsBreadcrumb />
      <LinkWrapper disableLink={disableLink} to={routes.app({ app })}>
        {app.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const DeveloperWorkflowsBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.developers()} disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.developerWorkflows"
        defaultMessage="Developer Portal"
      />
    </LinkWrapper>
  );
};

export const DeveloperWorkflowBreadcrumb = ({
  workflow,
  disableLink,
}: {
  workflow: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <DeveloperWorkflowsBreadcrumb />
      <LinkWrapper
        disableLink={disableLink}
        to={routes.developersWorkflow({ workflow })}
      >
        {workflow.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const DevicesBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.devices()} disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.instruments"
        defaultMessage="Instruments"
      />
    </LinkWrapper>
  );
};

export const DeviceBreadcrumb = ({
  device,
  disableLink,
}: {
  device: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <DevicesBreadcrumb />
      <LinkWrapper disableLink={disableLink} to={routes.device({ device })}>
        {device.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const NewsBreadcrumb = ({ disableLink }: DisableLink) => {
  return (
    <LinkWrapper to="/-/shared/news" disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.news"
        defaultMessage="Quantum-Si News"
      />
    </LinkWrapper>
  );
};

export const NewsArticleBreadcrumb = ({
  news,
  disableLink,
}: {
  news: NewsEntity;
} & DisableLink) => {
  return (
    <Breadcrumb>
      <NewsBreadcrumb />
      <LinkWrapper
        disableLink={disableLink}
        to={`/-/shared/news/${news.handle}`}
      >
        {news.title}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ManualsAndProtocolsBreadcrumb = ({
  disableLink,
}: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.protocols()} disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.manualsAndProtocols"
        defaultMessage="Manuals & Protocols"
      />
    </LinkWrapper>
  );
};

export const ProtocolBreadcrumb = ({
  protocol,
  disableLink,
}: {
  protocol: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <ManualsAndProtocolsBreadcrumb />
      <LinkWrapper
        disableLink={disableLink}
        to={routes.protocol({ protocol })}
      >
        {protocol.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ManualBreadcrumb = ({
  manual,
  disableLink,
}: {
  manual: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <ManualsAndProtocolsBreadcrumb />
      <LinkWrapper disableLink={disableLink} to={routes.manual({ manual })}>
        {manual.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ReferencesBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.references()} disableLink={disableLink}>
      <FormattedMessage
        id="breadcrumbs.references"
        defaultMessage="References"
      />
    </LinkWrapper>
  );
};

export const ReferenceBreadcrumb = ({
  reference,
  disableLink,
}: {
  reference: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <ReferencesBreadcrumb />
      <LinkWrapper
        disableLink={disableLink}
        to={routes.reference({ reference })}
      >
        {reference.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ReferenceAnnotationsBreadcrumb = ({
  reference,
  disableLink,
}: { reference: Entity } & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <ReferenceBreadcrumb reference={reference} />
      <LinkWrapper
        to={routes.referenceAnnotations({ reference })}
        disableLink={disableLink}
      >
        <FormattedMessage
          id="breadcrumbs.referenceAnnotations"
          defaultMessage="Reference Annotations"
        />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ReferenceAnnotationBreadcrumb = ({
  referenceAnnotation,
  reference,
  disableLink,
}: { reference: Entity; referenceAnnotation: Entity } & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <ReferenceAnnotationsBreadcrumb reference={reference} />
      <LinkWrapper
        to={routes.referenceAnnotation({ referenceAnnotation, reference })}
        disableLink={disableLink}
      >
        {referenceAnnotation.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const TagsBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <LinkWrapper to={routes.tags()} disableLink={disableLink}>
        <FormattedMessage id="breadcrumbs.tags" defaultMessage="Tags" />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const TagBreadcrumb = ({
  tag,
  disableLink,
}: {
  tag: Entity;
} & DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <Breadcrumb>
      <TagsBreadcrumb />
      <LinkWrapper disableLink={disableLink} to={routes.tag({ tag })}>
        {tag.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const ProjectsBreadcrumb = ({ disableLink }: DisableLink) => {
  const routes = useTenantRoutes();
  return (
    <LinkWrapper to={routes.projects()} disableLink={disableLink}>
      <FormattedMessage {...tabs.projects} />
    </LinkWrapper>
  );
};

export const ProjectBreadcrumb = ({
  project,
  disableLink,
}: {
  project: Entity;
} & DisableLink) => {
  const routes = useProjectRoutes();
  return (
    <LinkWrapper to={routes.project()} disableLink={disableLink}>
      {project.name}
    </LinkWrapper>
  );
};

export const AnalysesBreadcrumb = ({ project }: { project: Entity }) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <ProjectBreadcrumb project={project} />
      <LinkWrapper to={routes.analyses()}>
        <FormattedMessage {...tabs.analyses} />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const AnalysisBreadcrumb = ({
  analysis,
  disableLink,
}: {
  analysis: ProjectEntity;
} & DisableLink) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <AnalysesBreadcrumb project={analysis.project} />
      <LinkWrapper
        disableLink={disableLink}
        to={routes.analysis({ analysis })}
      >
        {analysis.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const RunsBreadcrumb = ({ project }: { project: Entity }) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <ProjectBreadcrumb project={project} />
      <LinkWrapper to={routes.runs()}>
        <FormattedMessage {...tabs.runs} />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const RunBreadcrumb = ({
  run,
  disableLink,
}: {
  run: ProjectEntity;
} & DisableLink) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <RunsBreadcrumb project={run.project} />
      <LinkWrapper disableLink={disableLink} to={routes.run({ run })}>
        {run.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const LibrariesBreadcrumb = ({ project }: { project: Entity }) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <ProjectBreadcrumb project={project} />
      <LinkWrapper to={routes.libraries()}>
        <FormattedMessage {...tabs.libraries} />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const LibraryBreadcrumb = ({
  library,
  disableLink,
}: {
  library: ProjectEntity;
} & DisableLink) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <LibrariesBreadcrumb project={library.project} />
      <LinkWrapper disableLink={disableLink} to={routes.library({ library })}>
        {library.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const SamplesBreadcrumb = ({ project }: { project: Entity }) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <ProjectBreadcrumb project={project} />
      <LinkWrapper to={routes.specimens()}>
        <FormattedMessage {...tabs.specimens} />
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const SampleBreadcrumb = ({
  sample,
  disableLink,
}: {
  sample: ProjectEntity;
} & DisableLink) => {
  const routes = useProjectRoutes();

  return (
    <Breadcrumb>
      <SamplesBreadcrumb project={sample.project} />
      <LinkWrapper disableLink={disableLink} to={routes.specimen({ sample })}>
        {sample.name}
      </LinkWrapper>
    </Breadcrumb>
  );
};

export const EditBreadcrumb = () => (
  <LinkWrapper disableLink>
    <FormattedMessage id="breadcrumbs.edit" defaultMessage="Edit" />
  </LinkWrapper>
);

export const NewBreadcrumb = () => (
  <LinkWrapper disableLink>
    <FormattedMessage id="breadcrumbs.new" defaultMessage="New" />
  </LinkWrapper>
);

export function PageTitle({ children }: Props) {
  return (
    <Breadcrumb>
      <LinkWrapper disableLink>{children}</LinkWrapper>
    </Breadcrumb>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment Breadcrumbs_entity on Entity {
    name

    ... on Project {
      handle
    }

    ... on Analysis {
      handle
      project {
        handle
        name
      }
    }

    ... on Library {
      handle
      project {
        handle
        name
      }
    }

    ... on Run {
      handle
      project {
        handle
        name
      }
    }

    ... on Sample {
      handle
      project {
        handle
        name
      }
    }
  }
`;

export default Breadcrumbs;
