/**
 * @generated SignedSource<<6bb51dbeb952cb0cd2067cc2df951af3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunSearchList_tenant$data = {
  readonly runConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"RunList_runs">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "RunSearchList_tenant";
};
export type RunSearchList_tenant$key = {
  readonly " $data"?: RunSearchList_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunSearchList_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "projectHandles"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "statuses"
    },
    {
      "kind": "RootArgument",
      "name": "tagHandles"
    },
    {
      "kind": "RootArgument",
      "name": "userProfileHandles"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "runConnection"
        ]
      }
    ]
  },
  "name": "RunSearchList_tenant",
  "selections": [
    {
      "alias": "runConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "createdBy",
          "variableName": "userProfileHandles"
        },
        {
          "kind": "Variable",
          "name": "project",
          "variableName": "projectHandles"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "statuses"
        },
        {
          "kind": "Variable",
          "name": "tag",
          "variableName": "tagHandles"
        }
      ],
      "concreteType": "RunConnection",
      "kind": "LinkedField",
      "name": "__Tenant_runConnection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RunEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Run",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RunList_runs"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};

(node as any).hash = "9869484bdd15e73b01aeb9a1a6086cac";

export default node;
