import classNames from 'classnames';
import React from 'react';

import './ErrorMessage.scss';

const rootClass = 'ErrorMessage';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ErrorMessage = ({ className, children }: Props) => {
  return <div className={classNames(rootClass, className)}>{children}</div>;
};

export default ErrorMessage;
