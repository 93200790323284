import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import { createCheckItem } from 'utils/checkItem';

import { commonRoutes } from '.';

export default (
  <Route
    /* eslint-disable relay/must-colocate-fragment-spreads */ // XXX: misbehaves
    query={graphql`
      query sharedRoutes_analysisQuery($analysisHandle: String!) {
        analysis(handle: $analysisHandle) {
          ...SharedAnalysisPage_analysis
        }
      }
    `}
    path="analyses/:analysisHandle"
    prerender={createCheckItem('analysis')}
    getComponent={() =>
      import(
        /* webpackChunkName: "analyses" */ './components/SharedAnalysisPage'
      ).then((p) => p.default)
    }
  >
    {commonRoutes}
  </Route>
);
