/**
 * @generated SignedSource<<23d5b145f72d03616600fc107068a4df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnalysisSorting = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "ENDED_AT_ASC" | "ENDED_AT_DESC" | "NAME_ASC" | "NAME_DESC" | "NAME_LENGTH_ASC" | "NAME_LENGTH_DESC";
export type AnalysisStatus = "aborted" | "completed" | "failed" | "pending" | "restarting" | "running";
export type AnalysisSearchListQuery$variables = {
  count: number;
  cursor?: string | null;
  projectHandles?: ReadonlyArray<any> | null;
  search?: string | null;
  sort?: ReadonlyArray<AnalysisSorting> | null;
  statuses?: ReadonlyArray<AnalysisStatus> | null;
  tagHandles?: ReadonlyArray<any> | null;
  tenantSlug: string;
  userProfileHandles?: ReadonlyArray<any> | null;
};
export type AnalysisSearchListQuery$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisSearchList_tenant">;
  } | null;
};
export type AnalysisSearchListQuery = {
  response: AnalysisSearchListQuery$data;
  variables: AnalysisSearchListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectHandles"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "statuses"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tagHandles"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userProfileHandles"
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "tenantSlug"
  }
],
v10 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "createdBy",
    "variableName": "userProfileHandles"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "project",
    "variableName": "projectHandles"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "statuses"
  },
  {
    "kind": "Variable",
    "name": "tag",
    "variableName": "tagHandles"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnalysisSearchListQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisSearchList_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AnalysisSearchListQuery",
    "selections": [
      {
        "alias": "tenant",
        "args": (v9/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenantBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "AnalysisConnection",
            "kind": "LinkedField",
            "name": "analysisConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Analysis",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Workflow",
                        "kind": "LinkedField",
                        "name": "workflow",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkflowRelease",
                        "kind": "LinkedField",
                        "name": "workflowRelease",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "versionName",
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workflowVersion",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserProfile",
                            "kind": "LinkedField",
                            "name": "userProfile",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pictureUrl",
                                "storageKey": null
                              },
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numPendingRuns",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "numPendingUpstreamAnalyses",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnalysisJob",
                        "kind": "LinkedField",
                        "name": "job",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AnalysisJobStep",
                            "kind": "LinkedField",
                            "name": "steps",
                            "plural": true,
                            "selections": [
                              (v14/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "filters": [
              "status",
              "search",
              "sort",
              "createdBy",
              "project",
              "tag"
            ],
            "handle": "connection",
            "key": "Tenant_analysisConnection",
            "kind": "LinkedHandle",
            "name": "analysisConnection"
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "784f530e1037a9ffa903f1e8fb6efe6d",
    "id": null,
    "metadata": {},
    "name": "AnalysisSearchListQuery",
    "operationKind": "query",
    "text": "query AnalysisSearchListQuery(\n  $tenantSlug: String!\n  $count: Int!\n  $cursor: String\n  $statuses: [AnalysisStatus!]\n  $search: String\n  $sort: [AnalysisSorting!]\n  $userProfileHandles: [IdOrHandle!]\n  $tagHandles: [IdOrHandle!]\n  $projectHandles: [IdOrHandle!]\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    ...AnalysisSearchList_tenant\n    id\n  }\n}\n\nfragment AnalysisList_analyses on Analysis {\n  id\n  handle\n  name\n  project {\n    handle\n    name\n    id\n  }\n  workflow {\n    name\n    id\n  }\n  workflowRelease {\n    versionName\n    id\n  }\n  workflowVersion\n  status\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n  ...AnalysisProgress_analysis\n}\n\nfragment AnalysisProgress_analysis on Analysis {\n  numPendingRuns\n  numPendingUpstreamAnalyses\n  status\n  job {\n    id\n    status\n    steps {\n      status\n      id\n    }\n  }\n}\n\nfragment AnalysisSearchList_tenant on Tenant {\n  analysisConnection(status: $statuses, search: $search, sort: $sort, createdBy: $userProfileHandles, project: $projectHandles, tag: $tagHandles, first: $count, after: $cursor) {\n    edges {\n      node {\n        ...AnalysisList_analyses\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "81869ae716ab7aabc5380bf6c4e3c9cc";

export default node;
