import classNames from 'classnames';
import { Link } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import type { AppNotifications_viewer$data as Viewer } from './__generated__/AppNotifications_viewer.graphql';

import './AppNotifications.scss';

type Props = {
  viewer: Viewer | null;
};

const rootClass = 'AppNotifications';

function AppNotifications({ viewer }: Props) {
  if (
    !viewer ||
    !viewer.user ||
    (viewer?.user?.passwordExpiresIn && viewer.user.passwordExpiresIn > 7)
  ) {
    return null;
  }

  return (
    <div className={classNames(rootClass)}>
      <FormattedMessage
        id="AppNotifications.passwordExpiring"
        defaultMessage="Password expires In {expireDays} days. Click {linkReset} to change your password"
        values={{
          expireDays: viewer.user.passwordExpiresIn,
          linkReset: (
            <Link to="/-/profile" data-testid={`${rootClass}-changePassword`}>
              <FormattedMessage
                id="AppNotifications.resetPasswored"
                defaultMessage=" Here "
              />
            </Link>
          ),
        }}
      />
    </div>
  );
}

export default createFragmentContainer(AppNotifications, {
  viewer: graphql`
    fragment AppNotifications_viewer on Viewer {
      user {
        passwordExpiresIn
      }
    }
  `,
});
