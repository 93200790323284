import { LDClient, initialize } from 'launchdarkly-js-client-sdk';
import type { Environment } from 'relay-runtime';

const launchDarklyClientKey =
  window.qsiConfig.QSI_LAUNCHDARKLY_CLIENT_SIDE_ID!;

interface LaunchDarklyConfig {
  user: Obj;
  hash: string;
  state: Obj;
}

export default class LaunchDarklyManager {
  // used by the Resolved
  waitingForClient = false;

  lastEnvironment: Environment | null = null;

  lastTenantId: string | null = null;

  client: LDClient | null = null;

  clear() {
    if (!this.client) return;

    this.client.close();
    this.client = null;
  }

  init(
    environment: Environment,
    tenantId: string,
    { user, hash, state }: LaunchDarklyConfig,
  ): LDClient | null {
    if (
      this.client &&
      this.lastEnvironment === environment &&
      this.lastTenantId === tenantId
    ) {
      return this.client;
    }

    this.clear();
    this.lastEnvironment = environment;
    this.lastTenantId = tenantId;

    this.client = initialize(launchDarklyClientKey, user, {
      hash,
      bootstrap: state,
    });

    return this.client;
  }
}
