import classNames from 'classnames';
import { useRouter } from 'found';
import Link from 'found/Link';
import React, { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { object, string } from 'yup';

import { AuthContextValue, withAuth } from 'components/AuthContext';
import FieldGroup from 'components/FieldGroup';
import MultiTextInputGroup from 'components/MultiTextInputGroup';
import login, { LoginError } from 'utils/login';

import AuthForm from './AuthForm';
import './LoginForm.scss';

const messages = defineMessages({
  email: {
    id: 'loginForm.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'loginForm.password',
    defaultMessage: 'Password',
  },
  missingEmail: {
    id: 'loginForm.missingEmail',
    defaultMessage: 'Please enter your email',
  },
  missingPassword: {
    id: 'loginForm.missingPassword',
    defaultMessage: 'Please enter your password',
  },
});

const loginSchema = object({
  email: string()
    .email()
    .required(messages.missingEmail)
    .default('' as string),
  password: string().required(messages.missingPassword).default(''),
});

interface Props {
  email?: string;
  auth: AuthContextValue;
}

const rootClass = 'LoginForm';

function LoginForm({ auth, email }: Props) {
  const { match } = useRouter();
  const [errors, setErrors] = useState<Obj>();
  const intl = useIntl();

  const submit = async (input: { email: string; password: string }) => {
    try {
      const authInfo = await login(input.email, input.password);
      auth.setAccessToken(authInfo);
    } catch (e) {
      if (e instanceof LoginError) {
        setErrors({
          '': e.detail || 'Unknown Error',
        });
        // we only throw when there are unexpected results (ie no detail)
        if (!e.detail) {
          throw e;
        }
      }
    }
  };

  const defaultValue = loginSchema.getDefault();

  // TODO: Remove support for getting email from query.
  const defaultEmail = email || match.location.query.user_email;
  if (defaultEmail) {
    defaultValue.email = defaultEmail;
  }

  return (
    <>
      <AuthForm
        schema={loginSchema}
        submitForm={submit}
        defaultValue={defaultValue}
        errors={errors}
        onFormError={setErrors}
        className={rootClass}
      >
        <MultiTextInputGroup className={classNames(`${rootClass}__input`)}>
          <FieldGroup
            name="email"
            type="email"
            placeholder={intl.formatMessage({
              id: 'loginForm.email',
              defaultMessage: 'Email',
            })}
            autoCapitalize="off"
            data-testid="LoginForm-email"
            labelSrOnly
            hideError
            label={<FormattedMessage {...messages.email} />}
          />
          <FieldGroup
            name="password"
            type="password"
            hideError
            data-testid="LoginForm-password"
            labelSrOnly
            label={<FormattedMessage {...messages.password} />}
            placeholder={intl.formatMessage({
              id: 'loginForm.password',
              defaultMessage: 'Password',
            })}
          />
        </MultiTextInputGroup>
        <AuthForm.Submit data-testid="Login">
          <FormattedMessage id="loginForm.submit" defaultMessage="LOG IN" />
        </AuthForm.Submit>
      </AuthForm>
      <Link to="/-/forgot-password">
        <FormattedMessage
          id="loginForm.forgotPassword"
          defaultMessage="Forgot your password?"
        />
      </Link>
    </>
  );
}

export default withAuth(LoginForm) as React.ComponentType<Omit<Props, 'auth'>>;
