import { useRouter } from 'found';
import React, { useCallback } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

import './SortButton.scss';

const DEFAULT_SORT = 'CREATED_AT_DESC';

export interface SortButtonProps {
  children: React.ReactNode;
  sort?: string;
}

const rootClass = 'SortButton';

const SortButton = ({ children, sort }: SortButtonProps) => {
  const { match, router } = useRouter();
  const { location } = match;
  const currentSort = location.query.sort || DEFAULT_SORT;
  const isDescending = currentSort === `${sort}_DESC`;

  const handleToggleSort = useCallback(() => {
    router.replace({
      ...location,
      query: {
        ...location.query,
        sort: isDescending ? `${sort}_ASC` : `${sort}_DESC`,
      },
    });
  }, [router, location, sort, isDescending]);

  if (!sort) return <>{children}</>;

  return (
    <button type="button" className={rootClass} onClick={handleToggleSort}>
      <span>{children}</span>
      {currentSort.startsWith(sort) &&
        (isDescending ? <RiArrowDownSFill /> : <RiArrowUpSFill />)}
    </button>
  );
};

export default SortButton;
