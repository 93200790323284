/**
 * @generated SignedSource<<4674b75b8be4ed306600f52c0e83db74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TenantUserSettingType = "SHOW_HIDDEN_ANALYSIS_PARAMS";
import { FragmentRefs } from "relay-runtime";
export type useTenantUserSettings_settings$data = {
  readonly settings: ReadonlyArray<{
    readonly data?: {
      readonly enabled: boolean | null;
    } | null;
    readonly settingType: TenantUserSettingType | null;
  } | null> | null;
  readonly " $fragmentType": "useTenantUserSettings_settings";
};
export type useTenantUserSettings_settings$key = {
  readonly " $data"?: useTenantUserSettings_settings$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTenantUserSettings_settings">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTenantUserSettings_settings",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "settings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settingType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BooleanSetting",
              "kind": "LinkedField",
              "name": "data",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "enabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ShowHiddenAnalysisParamsSetting",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantUser",
  "abstractKey": null
};

(node as any).hash = "038d34ac78066af05b926a5e46f33699";

export default node;
