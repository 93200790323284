/**
 * @generated SignedSource<<3edf471b345f110b9d1c169ac98f21cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type UpdateDeviceSubscriptionInput = {
  clientSubscriptionId?: string | null;
  deviceId: String;
};
export type DeviceProgressSubscription$variables = {
  input: UpdateDeviceSubscriptionInput;
};
export type DeviceProgressSubscription$data = {
  readonly updateDevice: {
    readonly device: {
      readonly error: {
        readonly details: string | null;
        readonly label: string | null;
      } | null;
      readonly state: {
        readonly code: string | null;
        readonly label: string | null;
        readonly progress: number | null;
        readonly timeRemainingSeconds: number | null;
      } | null;
    } | null;
  } | null;
};
export type DeviceProgressSubscription = {
  response: DeviceProgressSubscription$data;
  variables: DeviceProgressSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceError",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "details",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "DeviceState",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeRemainingSeconds",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceProgressSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceProgressSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateDeviceSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateDevice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "203eafb7ec0a49be6f375682b75b52be",
    "id": null,
    "metadata": {},
    "name": "DeviceProgressSubscription",
    "operationKind": "subscription",
    "text": "subscription DeviceProgressSubscription(\n  $input: UpdateDeviceSubscriptionInput!\n) {\n  updateDevice(input: $input) {\n    device {\n      error {\n        label\n        details\n      }\n      state {\n        code\n        label\n        progress\n        timeRemainingSeconds\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a0b076435660b9ca3df1e48887e57f6";

export default node;
