import classNames from 'classnames';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import Tooltip from './Tooltip';
import type { Avatar_userProfile$data as UserProfile } from './__generated__/Avatar_userProfile.graphql';
import defaultProfilePicture from '../../../assets/profile-picture.png';

import './Avatar.scss';

interface Props {
  userProfile: DeepNonNull<UserProfile>;
  width: number;
  className?: string;
}

const rootClass = 'Avatar';

function Avatar({ className, userProfile, width }: Props) {
  const { displayName, pictureUrl } = userProfile;

  return (
    <Tooltip
      tooltipSide="top"
      tooltipMessage={displayName}
      tooltipTrigger={
        <img
          alt={displayName}
          className={classNames(className, rootClass)}
          src={pictureUrl ?? defaultProfilePicture}
          style={{ width, height: width }}
        />
      }
    />
  );
}

export default createFragmentContainer(Avatar, {
  userProfile: graphql`
    fragment Avatar_userProfile on UserProfile {
      displayName
      pictureUrl
    }
  `,
});
