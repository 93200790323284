import React from 'react';
import { FormattedMessage } from 'react-intl';

import RegisterDeviceModal from './RegisterDeviceModal';

interface Props {
  as: React.ElementType;
  dataTestId?: string;
}

const rootClass = 'RegisterDeviceControl';

function RegisterDeviceControl({
  as: Component,
  dataTestId = rootClass,
}: Props) {
  return (
    <RegisterDeviceModal
      device={null}
      trigger={
        <Component data-testid={dataTestId}>
          <FormattedMessage
            id="registerDeviceControl.register"
            defaultMessage="Register"
          />
        </Component>
      }
    />
  );
}

export default RegisterDeviceControl;
