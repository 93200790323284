/**
 * @generated SignedSource<<4b0c9c8ae548d3e997ca38558c19863c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateRunSubscriptionInput = {
  clientSubscriptionId?: string | null;
  runId: String;
};
export type RunListSubscription$variables = {
  input: UpdateRunSubscriptionInput;
};
export type RunListSubscription$data = {
  readonly updateRun: {
    readonly run: {
      readonly " $fragmentSpreads": FragmentRefs<"RunList_runs">;
    } | null;
  } | null;
};
export type RunListSubscription = {
  response: RunListSubscription$data;
  variables: RunListSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRunSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateRun",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Run",
            "kind": "LinkedField",
            "name": "run",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RunList_runs"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRunSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateRun",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Run",
            "kind": "LinkedField",
            "name": "run",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUser",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "userProfile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pictureUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RunLibraryAssociation",
                "kind": "LinkedField",
                "name": "libraryAssociations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Library",
                    "kind": "LinkedField",
                    "name": "library",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sample",
                        "kind": "LinkedField",
                        "name": "sample",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "progress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeRemainingSeconds",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeviceError",
                    "kind": "LinkedField",
                    "name": "error",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "details",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c2f055d602189936e40c7343ce8abd9",
    "id": null,
    "metadata": {},
    "name": "RunListSubscription",
    "operationKind": "subscription",
    "text": "subscription RunListSubscription(\n  $input: UpdateRunSubscriptionInput!\n) {\n  updateRun(input: $input) {\n    run {\n      ...RunList_runs\n      id\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment DeviceProgress_device on Device {\n  id\n  error {\n    label\n    details\n  }\n  state {\n    code\n    label\n    progress\n    timeRemainingSeconds\n  }\n}\n\nfragment RunList_runs on Run {\n  id\n  handle\n  name\n  startedAt\n  endedAt\n  status\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n  libraryAssociations {\n    library {\n      name\n      sample {\n        name\n        id\n      }\n      id\n    }\n  }\n  project {\n    handle\n    name\n    id\n  }\n  ...RunProgress_run\n}\n\nfragment RunProgress_run on Run {\n  status\n  device {\n    state {\n      label\n    }\n    ...DeviceProgress_device\n    id\n  }\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "8fa3af85a6debc4dd56596f644e41956";

export default node;
