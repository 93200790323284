import classNames from 'classnames';
import { useRouter } from 'found';
import React from 'react';
import { RiArrowRightLine as ArrowRightIcon } from 'react-icons/ri';

import Button from 'components/Button';
import { getSessionStore } from 'utils/SessionStore';

import './MinimizedSidePanel.scss';

interface Props {
  dataTestId?: string;
}

const rootClass = 'MinimizedSidePanel';

function MinimizedSidePanel({ dataTestId = rootClass }: Props) {
  const { match, router } = useRouter();
  const toggleSidePanel = () => {
    const session = getSessionStore(match);
    session.setMinimized(false);
    router.replace(match.location); // force re-render
  };

  return (
    <div className={classNames(rootClass, 'scrollable')}>
      <Button
        onClick={toggleSidePanel}
        className={classNames(`${rootClass}__button`)}
        data-testid={`${dataTestId}-button`}
      >
        <ArrowRightIcon width={13} />
      </Button>
    </div>
  );
}

export default MinimizedSidePanel;
