import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFragmentContainer,
  graphql,
  useLazyLoadQuery,
} from 'react-relay';

import Modal from 'components/Modal';
import Spinner from 'components/Spinner';
import { PAGE_SIZE } from 'components/utils/createInfiniteList';

import SwitchTenantList from './SwitchTenantList';
import type { SwitchTenantModalQuery as Query } from './__generated__/SwitchTenantModalQuery.graphql';
import type { SwitchTenantModal_activeTenant$data as ActiveTenant } from './__generated__/SwitchTenantModal_activeTenant.graphql';

const query = graphql`
  query SwitchTenantModalQuery($count: Int!, $cursor: String) {
    viewer {
      ...SwitchTenantList_viewer
    }
  }
`;

interface Props {
  trigger: React.ReactNode;
  activeTenant: ActiveTenant | null;
}

function SwitchTenantModal({ activeTenant, trigger }: Props) {
  const data = useLazyLoadQuery<Query>(query, { count: PAGE_SIZE });

  return (
    <Modal
      title={
        <FormattedMessage
          id="switchTenantModal.title"
          defaultMessage="Switch Organization"
        />
      }
      trigger={trigger}
    >
      <Modal.Body>
        {data?.viewer ? (
          <SwitchTenantList
            viewer={data.viewer}
            activeTenant={activeTenant?.id}
          />
        ) : (
          <Spinner size="lg" centered />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default createFragmentContainer(SwitchTenantModal, {
  activeTenant: graphql`
    fragment SwitchTenantModal_activeTenant on Tenant {
      id
    }
  `,
});
