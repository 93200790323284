import { Link } from 'found';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { marketingWebsiteUrl, qsiBuildVersion } from 'config/about';

import Modal from './Modal';

const rootClass = 'AboutModal';

interface Props {
  trigger: React.ReactNode;
  dataTestId?: string;
}

function AboutModal({ trigger, dataTestId = rootClass }: Props) {
  const { formatMessage } = useIntl();

  return (
    <Modal
      data-testid={dataTestId}
      title={
        <FormattedMessage
          id="aboutModal.title"
          defaultMessage="About Quantum-Si Cloud"
        />
      }
      trigger={trigger}
    >
      <Modal.Body>
        <p>
          <FormattedMessage
            id="aboutModal.versionParagraph"
            defaultMessage="Quantum-Si Cloud {qsiBuildVersion}"
            values={{ qsiBuildVersion }}
          />
        </p>
        <p>
          <FormattedMessage
            id="aboutModal.address"
            defaultMessage="Quantum-Si, Inc., 29 Business Park Dr., Suite C, Branford, CT 06405"
          />
        </p>
        <p>
          <a
            href={marketingWebsiteUrl}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            data-testid={`${dataTestId}-websiteUrl`}
            rel="noreferrer"
          >
            {marketingWebsiteUrl}
          </a>
        </p>
        <p>
          <Link
            to="/-/eulas/latest/CLOUD"
            data-testid={`${dataTestId}-cloudEULA`}
          >
            <FormattedMessage
              id="aboutModal.eula"
              defaultMessage="End-User Cloud License Agreement (EULA)"
            />
          </Link>
        </p>
        <p>
          <Link
            to="/-/eulas/latest/DEVICE"
            data-testid={`${dataTestId}-deviceEULA`}
          >
            <FormattedMessage
              id="aboutModal.eula"
              defaultMessage="End-User Device License Agreement (EULA)"
            />
          </Link>
        </p>
        <p>
          <a
            href={`${marketingWebsiteUrl}/privacy-policy`}
            aria-label={formatMessage({
              id: 'aboutModal.privacyPolicyA11y',
              defaultMessage: 'Privacy Policy',
            })}
            target="_blank"
            data-testid={`${dataTestId}-privacyPolicy`}
            rel="noreferrer"
          >
            <FormattedMessage
              id="aboutModal.privacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </a>
        </p>
        <p>
          <a
            href={`${marketingWebsiteUrl}/cookie-policy`}
            aria-label={formatMessage({
              id: 'aboutModal.cookieNoticeA11y',
              defaultMessage: 'Cookie Notice',
            })}
            target="_blank"
            data-testid={`${dataTestId}-cookiePolicy`}
            rel="noreferrer"
          >
            <FormattedMessage
              id="aboutModal.cookieNotice"
              defaultMessage="Cookie Notice"
            />
          </a>
        </p>
        <p>
          <a
            href={`${marketingWebsiteUrl}/legal-disclaimer`}
            aria-label={formatMessage({
              id: 'aboutModal.legalDisclaimerA11y',
              defaultMessage: 'Legal Disclaimer',
            })}
            target="_blank"
            data-testid={`${dataTestId}-termsOfUse`}
            rel="noreferrer"
          >
            <FormattedMessage
              id="aboutModal.termsOfUse"
              defaultMessage="Terms of Use"
            />
          </a>
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default AboutModal;
