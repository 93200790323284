import React from 'react';
import { graphql } from 'react-relay';

import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route from 'components/Route';
import { getInfiniteListVariables } from 'components/utils/createInfiniteList';
import { checkIsAdmin } from 'utils/checkIsAdmin';

import AppsPage from './components/AppsPage';

export default (
  <>
    <Route path="apps">
      <Route Component={AppsPage}>
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "apps" */ './components/AppsListSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_Query(
              $tenantSlug: String!
              $count: Int!
              $cursor: String
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...AppsListSection_tenant
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path=":workflowHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "apps" */ './components/AppDetailPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Apps_DetailPageQuery(
            $tenantSlug: String!
            $workflowHandle: String!
            $count: Int!
            $cursor: String
          ) {
            workflow(handle: $workflowHandle) {
              ...AppDetailPage_workflow
            }
          }
        `}
        prepareVariables={getInfiniteListVariables}
      />
      <Route
        path="-/installations"
        getComponent={() =>
          import(
            /* webpackChunkName: "apps" */ './components/InstallationsPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Apps_InstallationsPage_Query(
            $tenantSlug: String!
            $count: Int!
            $cursor: String
          ) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...InstallationsPage_tenant
            }
          }
        `}
        prepareVariables={getInfiniteListVariables}
      />
    </Route>

    <LaunchDarklyRoute
      path="developer"
      getVariation={(ldClient) =>
        ldClient.variation('workflow-developer', false) && {
          query: graphql`
            query Apps_Developer_prerenderQuery($tenantSlug: String!) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                currentUser {
                  role
                }
              }
            }
          `,
        }
      }
      prerender={checkIsAdmin()}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "developer" */ './components/DeveloperPage'
          ).then((p) => p.default)
        }
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/DeveloperWorkflowListSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_DeveloperActivePage_Query(
              $tenantSlug: String!
              $count: Int!
              $cursor: String
              $archived: Boolean
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...DeveloperWorkflowListSection_tenant
              }
            }
          `}
          prepareVariables={(vars) => ({
            archived: false,
            ...getInfiniteListVariables(vars),
          })}
        />
        <Route
          path="archived"
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/DeveloperWorkflowListSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_DeveloperArchivedPage_Query(
              $tenantSlug: String!
              $count: Int!
              $cursor: String
              $archived: Boolean
            ) {
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...DeveloperWorkflowListSection_tenant
              }
            }
          `}
          prepareVariables={(vars) => ({
            archived: true,
            ...getInfiniteListVariables(vars),
          })}
        />
      </Route>
      <Route
        path="-/sandbox"
        getComponent={() =>
          import(
            /* webpackChunkName: "developer" */ './components/DeveloperSandboxPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Apps_DeveloperSandboxPage_Query($tenantSlug: String!) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...DeveloperSandboxPage_tenant
            }
          }
        `}
      />
      <Route
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "developer" */ './components/NewWorkflowPage'
          ).then((p) => p.default)
        }
      />
      <Route path=":workflowHandle">
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/DeveloperWorkflowDetailPage'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_DeveloperWorkflowDetailPage_Query(
              $tenantSlug: String!
              $workflowHandle: String!
              $count: Int!
              $cursor: String
            ) {
              workflow(handle: $workflowHandle) {
                ...DeveloperWorkflowDetailPage_workflow
              }
            }
          `}
          prepareVariables={getInfiniteListVariables}
        />
        <Route
          path="edit"
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/EditWorkflowPage'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_Developer_EditWorkflowPageQuery(
              $workflowHandle: String!
            ) {
              workflow(handle: $workflowHandle) {
                ...EditWorkflowPage_workflow
              }
            }
          `}
        />
        <Route
          path="new-release"
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/NewReleasePage'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_NewReleasePage_Query($workflowHandle: String!) {
              workflow(handle: $workflowHandle) {
                ...NewReleasePage_workflow
              }
            }
          `}
        />
        <Route
          path=":version/edit"
          prepareVariables={(params) => ({
            releaseId: {
              workflowHandle: params.workflowHandle,
              version: Number(params.version),
            },
          })}
          getComponent={() =>
            import(
              /* webpackChunkName: "developer" */ './components/EditReleasePage'
            ).then((p) => p.default)
          }
          query={graphql`
            query Apps_EditReleasePage_Query(
              $releaseId: WorkflowReleaseIdInput!
            ) {
              release: workflowRelease(releaseId: $releaseId) {
                ...EditReleasePage_release
              }
            }
          `}
        />
      </Route>
    </LaunchDarklyRoute>
  </>
);
