/**
 * @generated SignedSource<<4f2f6888f7fa20adfdb29c68abb22c07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnalysisJobStepIdInput = {
  analysisHandle?: string | null;
  analysisId?: string | null;
  index: string;
};
export type Analyses_stepLogSectionQuery$variables = {
  analysisJobStepId: AnalysisJobStepIdInput;
};
export type Analyses_stepLogSectionQuery$data = {
  readonly analysisJobStep: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisStepLogSection_analysisJobStep">;
  } | null;
};
export type Analyses_stepLogSectionQuery = {
  response: Analyses_stepLogSectionQuery$data;
  variables: Analyses_stepLogSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "analysisJobStepId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "analysisJobStepId",
    "variableName": "analysisJobStepId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "log",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_stepLogSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisJobStep",
        "kind": "LinkedField",
        "name": "analysisJobStep",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisStepLogSection_analysisJobStep"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_stepLogSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisJobStep",
        "kind": "LinkedField",
        "name": "analysisJobStep",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "index",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": "numLogErrors",
            "args": [
              {
                "kind": "Literal",
                "name": "level",
                "value": [
                  "error"
                ]
              }
            ],
            "kind": "ScalarField",
            "name": "numLogLines",
            "storageKey": "numLogLines(level:[\"error\"])"
          },
          {
            "alias": "numLogWarnings",
            "args": [
              {
                "kind": "Literal",
                "name": "level",
                "value": [
                  "warning"
                ]
              }
            ],
            "kind": "ScalarField",
            "name": "numLogLines",
            "storageKey": "numLogLines(level:[\"warning\"])"
          },
          {
            "alias": "logAnnotations",
            "args": [
              {
                "kind": "Literal",
                "name": "level",
                "value": [
                  "warning",
                  "error"
                ]
              }
            ],
            "concreteType": "LogLine",
            "kind": "LinkedField",
            "name": "logLines",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": "logLines(level:[\"warning\",\"error\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee4a7d659cf4cbd09fefd33efa961bb2",
    "id": null,
    "metadata": {},
    "name": "Analyses_stepLogSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_stepLogSectionQuery(\n  $analysisJobStepId: AnalysisJobStepIdInput!\n) {\n  analysisJobStep(analysisJobStepId: $analysisJobStepId) {\n    ...AnalysisStepLogSection_analysisJobStep\n    id\n  }\n}\n\nfragment AnalysisStepLogSection_analysisJobStep on AnalysisJobStep {\n  index\n  log\n  numLogErrors: numLogLines(level: [error])\n  numLogWarnings: numLogLines(level: [warning])\n  logAnnotations: logLines(level: [warning, error]) {\n    level\n    log\n    jobId\n    time\n    id\n  }\n  endedAt\n  startedAt\n  status\n}\n"
  }
};
})();

(node as any).hash = "e1ea87a371aa5ff1b2d0f0b40b9a20d1";

export default node;
