import classNames from 'classnames';
import React, { Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Button from 'components/Button';
import Spinner from 'components/Spinner';

import SwitchTenantModal from './SwitchTenantModal';
import type { TenantSwitchControl_tenant$data as Tenant } from './__generated__/TenantSwitchControl_tenant.graphql';

interface Props {
  tenant?: DeepNonNull<Tenant> | null;
  dataTestId?: string;
}

const rootClass = 'TenantSwitchControl';

function TenantSwitchControl({ tenant, dataTestId = rootClass }: Props) {
  return (
    <Suspense fallback={<Spinner size="lg" centered />}>
      <SwitchTenantModal
        activeTenant={tenant ?? null}
        trigger={
          <Button
            className={classNames(`${rootClass}__tenant-switch`)}
            dataTestId={dataTestId}
            variant="text-secondary"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
            }}
          >
            {tenant ? (
              <FormattedMessage
                id="headerMenu.switch"
                defaultMessage="Switch"
              />
            ) : (
              <FormattedMessage
                id="headerMenu.selectTenant"
                defaultMessage="Select Organization"
              />
            )}
          </Button>
        }
      />
    </Suspense>
  );
}

export default createFragmentContainer(TenantSwitchControl, {
  tenant: graphql`
    fragment TenantSwitchControl_tenant on Tenant {
      ...SwitchTenantModal_activeTenant
    }
  `,
});
