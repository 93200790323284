import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import './InfoPageCard.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  title: MessageDescriptor;
  subtitle?: MessageDescriptor;
}

const rootClass = 'InfoPageCard';

function InfoPageCard({
  className,
  children,
  title,
  subtitle,
  ...props
}: Props) {
  return (
    <div className={classNames(className, rootClass)} {...props}>
      <div className={classNames(`${rootClass}__header`)}>
        <div className={classNames(`${rootClass}__title`)}>
          <FormattedMessage {...title} />
        </div>
        {subtitle && (
          <div className={classNames(`${rootClass}__subtitle`)}>
            <FormattedMessage {...subtitle} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default InfoPageCard;
