import classNames from 'classnames';
import React from 'react';

import './DoneAnimation.scss';

interface Props {
  theme?: 'success' | 'failure';
}

const rootClass = 'DoneAnimation';

function DoneAnimation({ theme = 'success' }: Props) {
  return (
    <svg
      className={classNames([`${rootClass}`, `${rootClass}__${theme}`])}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
    >
      <circle
        className={classNames(`${rootClass}__circle`)}
        cx="26"
        cy="26"
        r="25"
        fill="none"
      />
      {theme === 'success' && (
        <path
          className={classNames(`${rootClass}__check`)}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      )}
      {theme === 'failure' && (
        <>
          <path
            className={classNames(`${rootClass}__crossLeft`)}
            fill="none"
            d="M16,36 l20,-20"
          />
          <path
            className={classNames(`${rootClass}__crossRight`)}
            fill="none"
            d="M16,16 l20,20"
          />
        </>
      )}
    </svg>
  );
}

export default DoneAnimation;
