import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useFormSubmit } from 'react-formal';
import { RiArrowDownSFill } from 'react-icons/ri';

import Icon from './Icon';
import Spinner from './Spinner';

import './Button.scss';

export type Props = React.ComponentProps<React.ElementType> & {
  as?: React.ElementType;
  busy?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'text-primary'
    | 'text-secondary'
    | 'text-danger'
    | 'navbar'
    | 'header-user';
  href?: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
  rel?: string;
  disabled?: boolean;
  active?: boolean;
  focus?: boolean;
  className?: string;
  children?: React.ReactNode;
  dataTestId?: string;
  type?: 'button' | 'submit';
  triggers?: string[];
  withCaret?: boolean;
  noPadding?: boolean;
  minimalPadding?: boolean;
};

const rootClass = 'Button';

const defaultProps: Props = {
  variant: 'primary',
  dataTestId: rootClass,
  type: 'button',
};

const Button = React.forwardRef<any, Props>(
  (
    {
      as: Component = 'button',
      dataTestId,
      className,
      variant,
      disabled,
      busy,
      children,
      type,
      triggers,
      withCaret,
      noPadding,
      minimalPadding,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const [submit, { submitting }] = useFormSubmit({
      triggers,
    });

    const handleClick = useCallback<
      React.ChangeEventHandler<HTMLInputElement>
    >(
      (e) => {
        submit();
        if (onClick) {
          onClick(e);
        }
      },
      [submit, onClick],
    );

    return (
      <Component
        data-testid={dataTestId}
        type={type === 'button' ? 'button' : 'submit'}
        onClick={triggers ? handleClick : onClick || null}
        disabled={disabled || submitting}
        className={classNames(
          rootClass,
          className,
          `${rootClass}--${variant}`,
          disabled && `${rootClass}--disabled`,
          busy && `${rootClass}--busy`,
          withCaret && `${rootClass}--withcaret`,
          noPadding && `${rootClass}--no-padding`,
          minimalPadding && `${rootClass}--min-padding`,
        )}
        ref={ref}
        {...rest}
      >
        {busy && <Spinner className={`${rootClass}__spinner`} />}
        <span className={`${rootClass}__label`}>{children}</span>
        {withCaret && (
          <Icon>
            <RiArrowDownSFill />
          </Icon>
        )}
      </Component>
    );
  },
);

Button.defaultProps = defaultProps;

export default Button;
