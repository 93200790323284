export type ObjStub<T extends string> = { [_idx in T]: { handle: string } };

export function join(prefix: () => string, suffix: string): () => string;
export function join<T, U>(
  prefix: (params: T) => string,
  suffix: ((params: U) => string) | string,
): (params: T & U) => string;
export function join<T, U>(
  prefix: (params: T) => string,
  suffix: ((params: U) => string) | string,
) {
  return (params: T & U) => {
    const realSuffix = typeof suffix === 'string' ? suffix : suffix(params);
    return `${prefix(params)}/${realSuffix}`;
  };
}

export function idRoute<T extends string>(t: T) {
  return (p: ObjStub<typeof t>) => encodeURIComponent(p[t].handle);
}
