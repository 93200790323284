/**
 * @generated SignedSource<<104d4d8f7392888c9fc0530858df1e07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type References_ReferenceAnnotationsListSectionQuery$variables = {
  count: number;
  cursor?: string | null;
  referenceHandle: string;
};
export type References_ReferenceAnnotationsListSectionQuery$data = {
  readonly reference: {
    readonly " $fragmentSpreads": FragmentRefs<"ReferenceAnnotationsListSection_reference">;
  } | null;
};
export type References_ReferenceAnnotationsListSectionQuery = {
  response: References_ReferenceAnnotationsListSectionQuery$data;
  variables: References_ReferenceAnnotationsListSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "referenceHandle"
},
v3 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "referenceHandle"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "References_ReferenceAnnotationsListSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReferenceAnnotationsListSection_reference"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "References_ReferenceAnnotationsListSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Reference",
        "kind": "LinkedField",
        "name": "reference",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "ReferenceAnnotationConnection",
            "kind": "LinkedField",
            "name": "annotationConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ReferenceAnnotationEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReferenceAnnotation",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Reference",
                        "kind": "LinkedField",
                        "name": "reference",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequenceType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "annotationType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Reference_annotationConnection",
            "kind": "LinkedHandle",
            "name": "annotationConnection"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fda7a6a832aac179038f3e3ee4e8336",
    "id": null,
    "metadata": {},
    "name": "References_ReferenceAnnotationsListSectionQuery",
    "operationKind": "query",
    "text": "query References_ReferenceAnnotationsListSectionQuery(\n  $referenceHandle: String!\n  $count: Int!\n  $cursor: String\n) {\n  reference(handle: $referenceHandle) {\n    ...ReferenceAnnotationsListSection_reference\n    id\n  }\n}\n\nfragment AnnotationListActionBar_reference on Reference {\n  handle\n  tenant {\n    id\n  }\n}\n\nfragment AnnotationList_annotations on ReferenceAnnotation {\n  id\n  handle\n  reference {\n    handle\n    id\n  }\n  name\n  status\n  sequenceType\n  annotationType\n}\n\nfragment ReferenceAnnotationsListSection_reference on Reference {\n  ...AnnotationListActionBar_reference\n  annotationConnection(first: $count, after: $cursor) {\n    edges {\n      node {\n        ...AnnotationList_annotations\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "72c8559dca4a80f8e89bb48dfd4e3880";

export default node;
