import HttpError from 'found/HttpError';

import { RelayRenderArgs } from 'components/Route';

import { isAdmin } from './useTenant';

type Props = {
  tenant?: { currentUser?: { role: number } };
};

export function checkIsAdmin() {
  return ({ props }: RelayRenderArgs<Props>) => {
    if (!props) return;
    const currentUser = props.tenant?.currentUser;

    if (!currentUser || !isAdmin(currentUser)) throw new HttpError(404);
  };
}
