import { HttpError } from 'found';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import { RelayRenderArgs } from 'components/Route';
import * as routes from 'utils/routes';

import type {
  runRedirectQuery$data as Props,
  runRedirectQuery$variables as RunResponse,
} from './__generated__/runRedirectQuery.graphql';
import type { tenantRedirectQuery$variables as RootResponse } from './__generated__/tenantRedirectQuery.graphql';

type Variables = RootResponse & RunResponse;

function runRedirect({
  props,
  variables,
}: RelayRenderArgs<DeepNonNull<Props>>) {
  if (!props) return;

  const { run } = props;
  const { tenantSlug } = variables as Variables;
  if (!run) throw new HttpError(404);

  const { project } = props.run;
  throw new RedirectException({
    pathname: routes.run({ project, run, tenant: { slug: tenantSlug } }),
  });
}

const query = graphql`
  query runRedirectQuery($runHandle: String!) {
    run(handle: $runHandle) {
      handle
      project {
        handle
      }
    }
  }
`;

runRedirect.query = query;

export default runRedirect;
