import React from 'react';

import Text from 'components/Text';

export type Props = {
  text: string;
  search: string;
};

const Highlighter = ({ text, search }: Props) => {
  const start = text.toLowerCase().indexOf(search.toLowerCase());
  if (start === -1) return <>{text}</>;

  return (
    <span>
      {text.slice(0, start)}
      <Text variant="bold" color="inherit">
        {text.slice(start, start + search.length)}
      </Text>
      {text.slice(start + search.length)}
    </span>
  );
};

export default Highlighter;
