import { LDClient } from 'launchdarkly-js-client-sdk';
import React, { useContext } from 'react';

import type FeatureFlags from '../config/FeatureFlags';

const LaunchDarklyContext = React.createContext<LDClient | null>(null);
export default LaunchDarklyContext;

export function useVariation<TKey extends keyof FeatureFlags>(
  key: TKey,
  defaultValue: FeatureFlags[TKey],
): FeatureFlags[TKey] {
  const ldclient = useContext(LaunchDarklyContext);
  return ldclient ? ldclient.variation(key, defaultValue) : defaultValue;
}
