import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';

export default (
  <>
    <Route path="protocols">
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "protocols" */ './components/ManualsAndProtocolsPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Protocols_protocolsQuery($tenantSlug: String!) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              ...ManualsAndProtocolsPage_tenant
            }
            query {
              ...ManualsAndProtocolsPage_query
            }
          }
        `}
      />
      <Route
        path=":protocolHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "protocols" */ './components/ProtocolPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Protocols_protocolQuery($protocolHandle: String!) {
            protocol(handle: $protocolHandle) {
              ...ProtocolPage_protocol
            }
          }
        `}
      />
    </Route>
    <Route path="manuals">
      <Route
        path=":manualHandle"
        getComponent={() =>
          import(
            /* webpackChunkName: "protocols" */ './components/ManualPage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Protocols_manualQuery($manualHandle: String!) {
            manual(handle: $manualHandle) {
              ...ManualPage_manual
            }
          }
        `}
      />
    </Route>
  </>
);
