/**
 * @generated SignedSource<<3ff3ad56d1e0018b9230f7034edd727e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Apps_DetailPageQuery$variables = {
  count: number;
  cursor?: string | null;
  tenantSlug: string;
  workflowHandle: string;
};
export type Apps_DetailPageQuery$data = {
  readonly workflow: {
    readonly " $fragmentSpreads": FragmentRefs<"AppDetailPage_workflow">;
  } | null;
};
export type Apps_DetailPageQuery = {
  response: Apps_DetailPageQuery$data;
  variables: Apps_DetailPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workflowHandle"
},
v4 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "workflowHandle"
  }
],
v5 = {
  "kind": "Variable",
  "name": "tenantSlug",
  "variableName": "tenantSlug"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Apps_DetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppDetailPage_workflow"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Apps_DetailPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Workflow",
        "kind": "LinkedField",
        "name": "workflow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "kind": "ScalarField",
            "name": "isOwned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "WorkflowReleaseConnection",
            "kind": "LinkedField",
            "name": "releaseConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowReleaseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowRelease",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": (v6/*: any*/),
                        "concreteType": "WorkflowReleaseInstall",
                        "kind": "LinkedField",
                        "name": "installation",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Workflow",
                        "kind": "LinkedField",
                        "name": "workflow",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "versionName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "changelog",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "filters": [
              "tenantSlug"
            ],
            "handle": "connection",
            "key": "Workflow_releaseConnection",
            "kind": "LinkedHandle",
            "name": "releaseConnection"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequenceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowXlargeResources",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantProfile",
            "kind": "LinkedField",
            "name": "tenantProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoUrl",
                "storageKey": null
              },
              (v7/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a36a867e86b3f96f47dd0afd10953c55",
    "id": null,
    "metadata": {},
    "name": "Apps_DetailPageQuery",
    "operationKind": "query",
    "text": "query Apps_DetailPageQuery(\n  $tenantSlug: String!\n  $workflowHandle: String!\n  $count: Int!\n  $cursor: String\n) {\n  workflow(handle: $workflowHandle) {\n    ...AppDetailPage_workflow\n    id\n  }\n}\n\nfragment ActionItem_release on WorkflowRelease {\n  id\n  installation(tenantSlug: $tenantSlug) {\n    version\n  }\n  workflow {\n    id\n  }\n}\n\nfragment AppDetailPage_workflow on Workflow {\n  isOwned(tenantSlug: $tenantSlug)\n  handle\n  name\n  releaseConnection(tenantSlug: $tenantSlug, first: $count, after: $cursor) {\n    edges {\n      node {\n        version\n        ...ActionItem_release\n        ...WorkflowReleaseListItem_release\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...WorkflowKeyValues_workflow\n}\n\nfragment Logo_tenantProfile on TenantProfile {\n  logoUrl\n  name\n}\n\nfragment WorkflowKeyValues_workflow on Workflow {\n  category\n  description\n  name\n  sequenceType\n  allowXlargeResources\n  tenantProfile {\n    ...Logo_tenantProfile\n    id\n  }\n}\n\nfragment WorkflowReleaseListItem_release on WorkflowRelease {\n  id\n  createdAt\n  versionName\n  changelog\n  workflow {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd40fd21dc0608b0e721dd6aea22faa3";

export default node;
