/**
 * @generated SignedSource<<9c3582e6325a9e52d2ed82f54c454b86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Project_projectPageQuery$variables = {
  projectHandle: string;
};
export type Project_projectPageQuery$data = {
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectPage_project">;
  } | null;
};
export type Project_projectPageQuery = {
  response: Project_projectPageQuery$data;
  variables: Project_projectPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "projectHandle"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Project_projectPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectPage_project"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Project_projectPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "followingStatus",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Project",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "Analysis",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "Library",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "Run",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v5/*: any*/),
                "type": "Sample",
                "abstractKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numLibraries",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numRuns",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numSamples",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6b715dd182f4b09cc09bdf99e78aa5d",
    "id": null,
    "metadata": {},
    "name": "Project_projectPageQuery",
    "operationKind": "query",
    "text": "query Project_projectPageQuery(\n  $projectHandle: String!\n) {\n  project(handle: $projectHandle) {\n    ...ProjectPage_project\n    id\n  }\n}\n\nfragment DeleteProjectControl_project on Project {\n  id\n}\n\nfragment FollowProjectControl_project on Project {\n  followingStatus\n  id\n}\n\nfragment ProjectPageHeader_project on Project {\n  ...FollowProjectControl_project\n  ...DeleteProjectControl_project\n  ... on Entity {\n    __isEntity: __typename\n    name\n    ... on Project {\n      handle\n    }\n    ... on Analysis {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Library {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Run {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n    ... on Sample {\n      handle\n      project {\n        handle\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment ProjectPageTabs_project on Project {\n  numAnalyses\n  numLibraries\n  numRuns\n  numSamples\n  numNotes\n}\n\nfragment ProjectPage_project on Project {\n  ...ProjectPageHeader_project\n  ...ProjectPageTabs_project\n}\n"
  }
};
})();

(node as any).hash = "5c426e3c33665387adfa573995d9163e";

export default node;
