import Layout from '@4c/layout';
import classNames from 'classnames';
import React from 'react';
import { createFragmentContainer, fetchQuery, graphql } from 'react-relay';
import type { Environment } from 'relay-runtime';

import Avatar from 'components/Avatar';
import { getNodes } from 'utils/graphql';
import { useTenantRoutes } from 'utils/tenantRoutes';

import SearchBar, {
  AVATAR_WIDTH,
  ItemSearchProps,
  entityMessages,
  freeTextSearchFn,
} from './SearchBar';
import {
  SEARCHABLE_ENTITIES,
  SEARCHABLE_ENTITY,
  renderFunctions,
} from '../searchPages';
import type { HeaderSearchBar_SearchBarQuery as Query } from './__generated__/HeaderSearchBar_SearchBarQuery.graphql';
import type { HeaderSearchBar_project$data as Project } from './__generated__/HeaderSearchBar_project.graphql';
import type { HeaderSearchBar_tag$data as Tag } from './__generated__/HeaderSearchBar_tag.graphql';
import type { HeaderSearchBar_userProfile$data as UserProfile } from './__generated__/HeaderSearchBar_userProfile.graphql';

import './HeaderSearchBar.scss';

const rootClass = 'HeaderSearchBar';

const getSearchPillTag = (item: { color: string }) => (
  <span
    style={{
      width: AVATAR_WIDTH,
      height: AVATAR_WIDTH,
      background: item.color,
    }}
    className={classNames(`${rootClass}__pill-circle`)}
  />
);

export interface Props {
  project: DeepNonNull<Project> | null;
  tag: DeepNonNull<Tag> | null;
  userProfile: DeepNonNull<UserProfile> | null;
  search: string | null;
  dataTestId?: string;
}

async function fetchSearchInfo(
  environment: Environment,
  tenantSlug: string,
  searchTerm: string,
) {
  const response = await fetchQuery<DeepNonNull<Query>>(
    environment,
    graphql`
      query HeaderSearchBar_SearchBarQuery(
        $tenantSlug: String!
        $searchTerm: String!
      ) {
        tenant: tenantBySlug(slug: $tenantSlug) {
          projectConnection(search: $searchTerm, first: 5) {
            edges {
              node {
                ...HeaderSearchBar_project @relay(mask: false)
              }
            }
          }
          tagConnection(search: $searchTerm, first: 5) {
            edges {
              node {
                ...HeaderSearchBar_tag @relay(mask: false)
              }
            }
          }
          userAssociationConnection(search: $searchTerm, first: 5) {
            edges {
              node {
                userProfile {
                  ...HeaderSearchBar_userProfile @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    { tenantSlug, searchTerm },
  ).toPromise();
  return response!;
}

export const userProfileEntityItemFn: ItemSearchProps<any> = {
  type: 'UserProfile',
  groupName: 'Created By',
  renderItem: ({ renderHighlighted, item, searchTerm }) => (
    <Layout align="center">
      <Avatar
        className={classNames(`${rootClass}__pill-circle`)}
        userProfile={item}
        width={AVATAR_WIDTH}
      />
      {renderHighlighted(item, searchTerm)}
    </Layout>
  ),
  renderPill: ({ item }) => (
    <>
      <Avatar
        className={classNames(`${rootClass}__pill-circle`)}
        userProfile={item}
        width={AVATAR_WIDTH}
      />
      <span>{item.displayName}</span>
    </>
  ),
  getTextField: ({ item }) => item.displayName,
  getQuery: (item) => ({ createdBy: item.handle }),
  getValues: (query) =>
    getNodes(query!.userAssociationConnection).map(
      (node: any) => node.userProfile,
    ),
  getParam: (props) => props.userProfile,
};

const projectEntityItemFn: ItemSearchProps<ProjectItem, FetchedTenant> = {
  type: 'Project',
  groupName: 'Projects',
  displayIndex: Number.MAX_SAFE_INTEGER,
  getQuery: (item) => ({ project: item.handle }),
  getValues: (query) => getNodes(query!.projectConnection),
  getParam: (props) => props.project,
};

export const tagEntityItemFn: ItemSearchProps<any> = {
  type: 'Tag',
  groupName: 'Tags',
  renderItem: ({ renderHighlighted, item, searchTerm }) => (
    <Layout align="center">
      {getSearchPillTag(item)}
      {renderHighlighted(item, searchTerm)}
    </Layout>
  ),
  renderPill: ({ item }) => (
    <>
      {getSearchPillTag(item)}
      <span>{item.name}</span>
    </>
  ),
  getQuery: (item) => ({ tag: item.handle }),
  getValues: (query) => getNodes(query!.tagConnection),
  getParam: (props) => props.tag,
};

const entityItemFns: ItemSearchProps<SearchFilterItem, FetchedTenant>[] = [
  userProfileEntityItemFn,
  projectEntityItemFn,
  tagEntityItemFn,
  freeTextSearchFn,
];

type FetchedTenant = DeepNonNull<Query['response']>['tenant'];

type UserProfileItem = Omit<
  RelayConnectionType<
    FetchedTenant['userAssociationConnection']
  >['userProfile'],
  'type'
> & {
  type: 'UserProfile';
};

type ProjectItem = Omit<
  RelayConnectionType<FetchedTenant['projectConnection']>,
  'type'
> & {
  type: 'Project';
};

export type TagItem = Omit<
  RelayConnectionType<FetchedTenant['tagConnection']>,
  'type'
> & {
  type: 'Tag';
};

type SearchFilterItem =
  | UserProfileItem
  | ProjectItem
  | TagItem
  | { type: 'FREE_TEXT_SEARCH' }
  // for "status" filtering
  | { type: string };

function HeaderSearchBar(props: Props) {
  const { dataTestId = rootClass } = props;
  const routes = useTenantRoutes();

  const dropdownProps = {
    searchableTypes: [...SEARCHABLE_ENTITIES],
    searchableTypeMessages: entityMessages,
  };

  return (
    <SearchBar<FetchedTenant, Props, SearchFilterItem, SEARCHABLE_ENTITY>
      dataTestId={dataTestId}
      searchRoute={routes.search()}
      searchQueryArg="search"
      searchTypeQueryArg="entityType"
      fetchSearchInfo={fetchSearchInfo}
      itemFunctions={entityItemFns}
      className={classNames(rootClass)}
      defaultSearchType="all"
      renderFunctions={renderFunctions}
      dropdownProps={dropdownProps}
      {...props}
    />
  );
}

export default createFragmentContainer(HeaderSearchBar, {
  project: graphql`
    fragment HeaderSearchBar_project on Project {
      handle
      name
    }
  `,
  tag: graphql`
    fragment HeaderSearchBar_tag on Tag {
      ...Tag_tagComponent @relay(mask: false)
    }
  `,
  userProfile: graphql`
    fragment HeaderSearchBar_userProfile on UserProfile {
      handle
      displayName
      ...Avatar_userProfile
    }
  `,
});
