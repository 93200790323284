import classNames from 'classnames';
import React from 'react';

import './Icon.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const rootClass = 'icon';

const Icon = ({ className, children }: Props) => {
  return <div className={classNames(rootClass, className)}>{children}</div>;
};

export default Icon;
