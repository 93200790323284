/**
 * @generated SignedSource<<82a11a84bef9f933a104f99bc7e95d78>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Analyses_analysisQuery$variables = {
  analysisHandle: string;
  tenantSlug: string;
};
export type Analyses_analysisQuery$data = {
  readonly item: {
    readonly tenant: {
      readonly id: string;
    } | null;
  } | null;
  readonly tenant: {
    readonly id: string;
  } | null;
};
export type Analyses_analysisQuery = {
  response: Analyses_analysisQuery$data;
  variables: Analyses_analysisQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analysisHandle"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenantSlug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": "tenant",
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "tenantSlug"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenantBySlug",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "analysisHandle"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_analysisQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": "item",
        "args": (v5/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Analyses_analysisQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": "item",
        "args": (v5/*: any*/),
        "concreteType": "Analysis",
        "kind": "LinkedField",
        "name": "analysis",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bec66fd687100139102cc0989880daee",
    "id": null,
    "metadata": {},
    "name": "Analyses_analysisQuery",
    "operationKind": "query",
    "text": "query Analyses_analysisQuery(\n  $tenantSlug: String!\n  $analysisHandle: String!\n) {\n  tenant: tenantBySlug(slug: $tenantSlug) {\n    id\n  }\n  item: analysis(handle: $analysisHandle) {\n    tenant {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "18786c084d6f68b637e9ca1c6658a860";

export default node;
