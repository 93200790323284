import useIsomorphicEffect from '@restart/hooks/useIsomorphicEffect';

import './BodyStyle.scss';

interface Props {
  className: string;
}

export function useBodyStyle(className: string) {
  useIsomorphicEffect(() => {
    if (!className) return undefined;
    const { body } = document;
    const classes = className.split(/\s+/);
    classes.forEach((cls) => {
      body.classList.add(cls);
    });
    return () => {
      classes.forEach((cls) => {
        body.classList.remove(cls);
      });
    };
  }, [className]);
}

const BodyStyle = ({ className }: Props) => {
  useBodyStyle(className);
  return null;
};

export default BodyStyle;
