/**
 * @generated SignedSource<<3ae98bdd5d2c03714412df1b3d28f3e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateAnalysisSubscriptionInput = {
  analysisId: String;
  clientSubscriptionId?: string | null;
};
export type AnalysisListSubscription$variables = {
  input: UpdateAnalysisSubscriptionInput;
};
export type AnalysisListSubscription$data = {
  readonly updateAnalysis: {
    readonly analysis: {
      readonly " $fragmentSpreads": FragmentRefs<"AnalysisList_analyses">;
    } | null;
  } | null;
};
export type AnalysisListSubscription = {
  response: AnalysisListSubscription$data;
  variables: AnalysisListSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AnalysisListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAnalysisSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateAnalysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Analysis",
            "kind": "LinkedField",
            "name": "analysis",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AnalysisList_analyses"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AnalysisListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAnalysisSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateAnalysis",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Analysis",
            "kind": "LinkedField",
            "name": "analysis",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Workflow",
                "kind": "LinkedField",
                "name": "workflow",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowRelease",
                "kind": "LinkedField",
                "name": "workflowRelease",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "versionName",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workflowVersion",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUser",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "userProfile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pictureUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numPendingRuns",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numPendingUpstreamAnalyses",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalysisJob",
                "kind": "LinkedField",
                "name": "job",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnalysisJobStep",
                    "kind": "LinkedField",
                    "name": "steps",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4bb15d0e07736a8b3be30abc388e3c68",
    "id": null,
    "metadata": {},
    "name": "AnalysisListSubscription",
    "operationKind": "subscription",
    "text": "subscription AnalysisListSubscription(\n  $input: UpdateAnalysisSubscriptionInput!\n) {\n  updateAnalysis(input: $input) {\n    analysis {\n      ...AnalysisList_analyses\n      id\n    }\n  }\n}\n\nfragment AnalysisList_analyses on Analysis {\n  id\n  handle\n  name\n  project {\n    handle\n    name\n    id\n  }\n  workflow {\n    name\n    id\n  }\n  workflowRelease {\n    versionName\n    id\n  }\n  workflowVersion\n  status\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n  ...AnalysisProgress_analysis\n}\n\nfragment AnalysisProgress_analysis on Analysis {\n  numPendingRuns\n  numPendingUpstreamAnalyses\n  status\n  job {\n    id\n    status\n    steps {\n      status\n      id\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "bddbf2f2408f8caebe6c1ea5d478c0d9";

export default node;
