import type { Match } from 'found';
import React from 'react';
import { graphql } from 'react-relay';

import Route from 'components/Route';
import {
  getInfiniteListVariables,
  getSortVariables,
} from 'components/utils/createInfiniteList';
import applyFunctions from 'utils/applyFunctions';
import checkTenantItem from 'utils/checkTenantItem';

export default (
  <Route path="specimens">
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "samples" */ './components/NewSamplePage'
        ).then((p) => p.default)
      }
      prepareVariables={(params, { location }) => ({
        ...params,
        sampleHandle: location.query.specimen || '',
        hasSample: !!location.query.specimen,
      })}
      patternSample={null}
      query={graphql`
        query Samples_newSampleQuery(
          $tenantSlug: String!
          $projectHandle: String!
          $sampleHandle: String!
          $hasSample: Boolean!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            ...NewSamplePage_tenant
          }
          project: project(handle: $projectHandle) {
            ...NewSamplePage_project
          }
          patternSample: sample(handle: $sampleHandle)
            @include(if: $hasSample) {
            ...NewSamplePage_patternSample
          }
        }
      `}
    />
    <Route
      query={graphql`
        query Samples_sampleQuery(
          $tenantSlug: String!
          $sampleHandle: String!
        ) {
          tenant: tenantBySlug(slug: $tenantSlug) {
            id
          }
          item: sample(handle: $sampleHandle) {
            tenant {
              id
            }
          }
        }
      `}
      path=":sampleHandle"
      prerender={checkTenantItem}
    >
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "samples" */ './components/SamplePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Samples_samplePageQuery($sampleHandle: String!) {
            sample(handle: $sampleHandle) {
              ...SamplePage_sample
            }
          }
        `}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "samples" */ './components/SampleSummarySection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Samples_sampleSummarySectionQuery($sampleHandle: String!) {
              sample(handle: $sampleHandle) {
                ...SampleSummarySection_sample
              }
            }
          `}
        />
        <Route
          path="libraries"
          getComponent={() =>
            import(
              /* webpackChunkName: "samples" */ './components/SampleLibrariesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Samples_librariesSectionQuery(
              $sampleHandle: String!
              $count: Int!
              $cursor: String
              $sort: [LibrarySorting!]
            ) {
              sample(handle: $sampleHandle) {
                ...SampleLibrariesSection_sample
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="runs"
          getComponent={() =>
            import(
              /* webpackChunkName: "samples" */ './components/SampleRunsSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Samples_runsSectionQuery(
              $sampleHandle: String!
              $count: Int!
              $cursor: String
              $sort: [RunSorting!]
            ) {
              sample(handle: $sampleHandle) {
                ...SampleRunsSection_sample
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          path="analyses"
          getComponent={() =>
            import(
              /* webpackChunkName: "samples" */ './components/SampleAnalysesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Samples_analysesSectionQuery(
              $sampleHandle: String!
              $count: Int!
              $cursor: String
              $sort: [AnalysisSorting!]
            ) {
              sample(handle: $sampleHandle) {
                ...SampleAnalysesSection_sample
              }
            }
          `}
          prepareVariables={applyFunctions<[Obj, Match]>(
            getInfiniteListVariables,
            getSortVariables,
          )}
        />
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "samples" */ './components/SampleNotesSection'
            ).then((p) => p.default)
          }
          query={graphql`
            query Samples_notesSectionQuery(
              $sampleHandle: String!
              $count: Int!
              $cursor: String
            ) {
              sample(handle: $sampleHandle) {
                ...SampleNotesSection_sample
              }
            }
          `}
          path="notes"
          prepareVariables={getInfiniteListVariables}
        />
      </Route>
      <Route
        path="edit"
        getComponent={() =>
          import(
            /* webpackChunkName: "samples" */ './components/EditSamplePage'
          ).then((p) => p.default)
        }
        query={graphql`
          query Samples_editSamplePageQuery($sampleHandle: String!) {
            sample(handle: $sampleHandle) {
              ...EditSamplePage_sample
            }
          }
        `}
      />
    </Route>
  </Route>
);
