/**
 * @generated SignedSource<<2f527f5683d255cb7b051e71721111fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Analyses_fileHtmlSectionQuery$variables = {
  fileHandle: string;
};
export type Analyses_fileHtmlSectionQuery$data = {
  readonly analysisFile: {
    readonly " $fragmentSpreads": FragmentRefs<"AnalysisFileHtmlSection_analysisFile">;
  } | null;
};
export type Analyses_fileHtmlSectionQuery = {
  response: Analyses_fileHtmlSectionQuery$data;
  variables: Analyses_fileHtmlSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "fileHandle"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Analyses_fileHtmlSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisFile",
        "kind": "LinkedField",
        "name": "analysisFile",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AnalysisFileHtmlSection_analysisFile"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Analyses_fileHtmlSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AnalysisFile",
        "kind": "LinkedField",
        "name": "analysisFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6dec6d2e2c5838652b257032cb7c599",
    "id": null,
    "metadata": {},
    "name": "Analyses_fileHtmlSectionQuery",
    "operationKind": "query",
    "text": "query Analyses_fileHtmlSectionQuery(\n  $fileHandle: String!\n) {\n  analysisFile(handle: $fileHandle) {\n    ...AnalysisFileHtmlSection_analysisFile\n    id\n  }\n}\n\nfragment AnalysisFileHtmlSection_analysisFile on AnalysisFile {\n  title\n  url\n}\n"
  }
};
})();

(node as any).hash = "86c183545669ffa9e8717880b7aa1483";

export default node;
