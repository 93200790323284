import React from 'react';

import './SrOnly.scss';

interface Props {
  children: React.ReactNode;
}

const rootClass = 'SrOnly';

function SrOnly({ children }: Props) {
  return <span className={rootClass}>{children}</span>;
}

export default SrOnly;
