import Layout from '@4c/layout';
import classNames from 'classnames';
import { HttpError, Link } from 'found';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import Text from 'components/Text';

import logoUrl from '../../../assets/qsi-logo.svg';
import BlankPage from '../../components/BlankPage';

import './ErrorPage.scss';

const errorCodes = [404 as const, 500 as const];
export const ERROR_CODES = new Set<number>(errorCodes);
type ErrorCode = (typeof errorCodes)[0];

const explanations = defineMessages({
  404: {
    id: 'errorPage.404',
    defaultMessage: "We can't find the page you're looking for. 😢",
  },
  500: {
    id: 'errorPage.500',
    defaultMessage: 'Something went wrong. 😢',
  },
});

interface Props {
  code: ErrorCode;
  data: HttpError['data'];
  dataTestId?: string;
}

const rootClass = 'ErrorPage';

function ErrorPage({ code, data, dataTestId = rootClass }: Props) {
  const explanation = explanations[code];

  return (
    <BlankPage size="large" data-testid={dataTestId} className={rootClass}>
      <div className={classNames(`${rootClass}__wrapper`)}>
        <Layout justify="space-between">
          <Layout direction="column" align="flex-start">
            <h1 className={classNames(`${rootClass}__title`)}>
              <FormattedMessage id="errorPage.title" defaultMessage="Oops!" />
            </h1>

            <h2>
              <FormattedMessage {...explanation} />
            </h2>

            <Layout.Spacer />

            <Text
              variant="bold"
              color="headline"
              as="h4"
              className={classNames(`${rootClass}__error-code`)}
            >
              <FormattedMessage
                id="errorPage.code"
                defaultMessage="Error code: {code}"
                values={{ code }}
              />
              {data && (
                <FormattedMessage
                  id="errorPage.message"
                  defaultMessage="Message: {data}"
                  values={{ data }}
                />
              )}
            </Text>

            <Layout.Spacer />

            <Layout direction="column">
              <Link to="/">
                <FormattedMessage id="errorPage.home" defaultMessage="Home" />
              </Link>
              <Link to="/">
                <FormattedMessage id="errorPage.help" defaultMessage="Help" />
              </Link>
            </Layout>

            <Layout.Spacer />
          </Layout>

          <img
            alt="Quantum-Si"
            src={logoUrl}
            className={classNames(`${rootClass}__logo`)}
          />
        </Layout>
      </div>
    </BlankPage>
  );
}

export default ErrorPage;
