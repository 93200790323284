import React from 'react';
import { RiQuestionFill } from 'react-icons/ri';
import { FormattedMessage } from 'react-intl';

import AboutControl from 'components/AboutControl';
import SrOnly from 'components/SrOnly';

import Button from './Button';
import Dropdown from './Dropdown';
import Icon from './Icon';
import SupportControl from './SupportControl';

interface Props {
  dataTestId?: string;
}

const rootClass = 'HelpMenu';

export default function HelpMenu({ dataTestId = rootClass }: Props) {
  return (
    <Dropdown
      dataTestId={dataTestId}
      trigger={
        <Button variant="navbar">
          <Icon>
            <RiQuestionFill />
          </Icon>
          <SrOnly>
            <FormattedMessage
              id="helpMenu.accessibilityTitle"
              defaultMessage="Help"
            />
          </SrOnly>
        </Button>
      }
    >
      <AboutControl dataTestId={rootClass} />
      <SupportControl />
    </Dropdown>
  );
}
