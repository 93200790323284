/**
 * @generated SignedSource<<0b7550e730720914680caec66054a6f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routes_SignupQuery$variables = {
  token: string;
};
export type routes_SignupQuery$data = {
  readonly invitation: {
    readonly " $fragmentSpreads": FragmentRefs<"SignupPage_invitation">;
  } | null;
};
export type routes_SignupQuery = {
  response: routes_SignupQuery$data;
  variables: routes_SignupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_SignupQuery",
    "selections": [
      {
        "alias": "invitation",
        "args": (v1/*: any*/),
        "concreteType": "InvitationInfo",
        "kind": "LinkedField",
        "name": "invitationByToken",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SignupPage_invitation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_SignupQuery",
    "selections": [
      {
        "alias": "invitation",
        "args": (v1/*: any*/),
        "concreteType": "InvitationInfo",
        "kind": "LinkedField",
        "name": "invitationByToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TenantInfo",
            "kind": "LinkedField",
            "name": "tenantInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "250b96bd0a9085f1104d5365b1b98291",
    "id": null,
    "metadata": {},
    "name": "routes_SignupQuery",
    "operationKind": "query",
    "text": "query routes_SignupQuery(\n  $token: String!\n) {\n  invitation: invitationByToken(token: $token) {\n    ...SignupPage_invitation\n  }\n}\n\nfragment SignupPage_invitation on InvitationInfo {\n  email\n  tenantInfo {\n    name\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1316875be7fb791283b570ef1a58a2c";

export default node;
