import * as routes from './routes';
import useTenant from './useTenant';

type Routes = typeof routes;

type Route<T> = (params: T) => string;

type OmitTenantFromRoute<T extends Route<any>> = (
  params: Omit<Parameters<T>[0], 'tenant'>,
) => string;

// this is a rather complicated type. in short:
// if the route has tenant param and other params, remove the tenant param from the object (eg `:tenantSlug/projects/:projectId`)
// if the route has ONLY tenant param, then remove the parm object completely (eg `:tenantSlug/references`)
// if the route has no tenant param, then keep it as is (eg -/profile)
export type TenantRoutes = {
  [idx in keyof Routes]: Parameters<Routes[idx]>[0] extends {
    tenant: any;
  }
    ? keyof Omit<Parameters<Routes[idx]>[0], 'tenant'> extends never
      ? () => string
      : OmitTenantFromRoute<Routes[idx]>
    : Routes[idx];
};

export function useTenantRoutes() {
  const tenant = useTenant();

  const tenantRoutes: TenantRoutes = {} as any;

  Object.entries(routes).forEach(([k, v]) => {
    (tenantRoutes as any)[k] = (params: any) => v({ tenant, ...params });
  });

  return tenantRoutes;
}
