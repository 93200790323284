import { MessageDescriptor, defineMessages } from 'react-intl';
import { graphql } from 'react-relay';

import type {
  AnalysisJobStatus,
  AnalysisJobStepStatus,
  AnalysisStatus,
} from './__generated__/analysesMessages_analysis.graphql';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment analysesMessages_analysis on Analysis {
    status
    job {
      status
      steps {
        status
      }
    }
  }
`;

export default defineMessages({
  analyses: {
    id: 'analyses.analyses',
    defaultMessage: 'Analyses',
  },

  newAnalysis: {
    id: 'analyses.newAnalysis',
    defaultMessage: 'New analysis',
  },
  startAnalysis: {
    id: 'analyses.startAnalysis',
    defaultMessage: 'Start analysis',
  },
  shareAnalysis: {
    id: 'analyses.shareAnalysis',
    defaultMessage: 'Share analysis',
  },
  editAnalysis: {
    id: 'analyses.editAnalysis',
    defaultMessage: 'Edit analysis',
  },
  emptyAnalysesHeading: {
    id: 'analyses.emptyAnalysesHeading',
    defaultMessage: 'There are no analyses',
  },
});

const STATUSES = defineMessages({
  ABORTED: {
    id: 'analyses.analysisAborted',
    defaultMessage: 'Aborted',
  },
  COMPLETED: {
    id: 'analyses.analysisCompleted',
    defaultMessage: 'Completed',
  },
  FAILED: {
    id: 'analyses.analysisFailed',
    defaultMessage: 'Failed',
  },
  PENDING: {
    id: 'analyses.analysisPending',
    defaultMessage: 'Pending',
  },
  PREPARING: {
    id: 'analyses.analysisPreparing',
    defaultMessage: 'Preparing Working Directory',
  },
  QUEUED: {
    id: 'analyses.analysisQueued',
    defaultMessage: 'Queued',
  },
  RESTARTING: {
    id: 'analyses.analysisRestarting',
    defaultMessage: 'Restarting',
  },
  RUNNING: {
    id: 'analyses.analysisRunning',
    defaultMessage: 'Running',
  },
  UPLOADING: {
    id: 'analyses.analysisUploading',
    defaultMessage: 'Uploading',
  },
  WAITING_FOR_INSTANCE: {
    id: 'analyses.analysisWaiting',
    defaultMessage: 'Provisioning machine',
  },
});

export const analysisStatus: Record<AnalysisStatus, MessageDescriptor> = {
  aborted: STATUSES.ABORTED,
  completed: STATUSES.COMPLETED,
  failed: STATUSES.FAILED,
  pending: STATUSES.PENDING,
  restarting: STATUSES.RESTARTING,
  running: STATUSES.RUNNING,
};

export const analysisJobStatus: Record<AnalysisJobStatus, MessageDescriptor> =
  {
    ABORTED: STATUSES.ABORTED,
    COMPLETED: STATUSES.COMPLETED,
    FAILED: STATUSES.FAILED,
    QUEUED: STATUSES.QUEUED,
    RESTARTING: STATUSES.RESTARTING,
    RUNNING: STATUSES.RUNNING,
    WAITING_FOR_INSTANCE: STATUSES.WAITING_FOR_INSTANCE,
  };

export const analysisJobStepStatus: Record<
  AnalysisJobStepStatus,
  MessageDescriptor
> = {
  ABORTED: STATUSES.ABORTED,
  COMPLETED: STATUSES.COMPLETED,
  FAILED: STATUSES.FAILED,
  PENDING: STATUSES.PENDING,
  RESTARTING: STATUSES.RESTARTING,
  RUNNING: STATUSES.RUNNING,
};
