/**
 * @generated SignedSource<<f8b5878b3c35deb567d135f65a2fb081>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSampleSubscriptionInput = {
  clientSubscriptionId?: string | null;
  sampleId: String;
};
export type SampleListSubscription$variables = {
  input: UpdateSampleSubscriptionInput;
};
export type SampleListSubscription$data = {
  readonly updateSample: {
    readonly sample: {
      readonly " $fragmentSpreads": FragmentRefs<"SampleList_samples">;
    } | null;
  } | null;
};
export type SampleListSubscription = {
  response: SampleListSubscription$data;
  variables: SampleListSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SampleListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSampleSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateSample",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sample",
            "kind": "LinkedField",
            "name": "sample",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SampleList_samples"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SampleListSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateSampleSubscriptionPayload",
        "kind": "LinkedField",
        "name": "updateSample",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Sample",
            "kind": "LinkedField",
            "name": "sample",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantUser",
                "kind": "LinkedField",
                "name": "createdBy",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserProfile",
                    "kind": "LinkedField",
                    "name": "userProfile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pictureUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f641781ef97a0180d79772638e63fb58",
    "id": null,
    "metadata": {},
    "name": "SampleListSubscription",
    "operationKind": "subscription",
    "text": "subscription SampleListSubscription(\n  $input: UpdateSampleSubscriptionInput!\n) {\n  updateSample(input: $input) {\n    sample {\n      ...SampleList_samples\n      id\n    }\n  }\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment CreatedByListItem_userProfile on UserProfile {\n  ...UserProfile_userProfile\n}\n\nfragment SampleList_samples on Sample {\n  id\n  handle\n  name\n  createdAt\n  createdBy {\n    userProfile {\n      ...CreatedByListItem_userProfile\n      id\n    }\n    id\n  }\n  project {\n    handle\n    name\n    id\n  }\n}\n\nfragment UserProfile_userProfile on UserProfile {\n  displayName\n  ...Avatar_userProfile\n}\n"
  }
};
})();

(node as any).hash = "ddd4168db59a4f236b06b696993389dd";

export default node;
