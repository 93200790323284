import { useRouter } from 'found';
import React, { useEffect } from 'react';

import { useAuth } from 'components/AuthContext';
import * as routes from 'utils/routes';

export default function ImpersonationPage() {
  const { match, router } = useRouter();
  const { access_token, expires_at } = match.location.query;
  const auth = useAuth();

  router.push(routes.root());

  useEffect(() => {
    auth.setAccessToken({
      accessToken: access_token,
      expiresAt: Number(new Date(expires_at)),
      impersonation: true,
    });
    // explicitly only execute once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
}
