/**
 * @generated SignedSource<<f3d3d31c8632f1640402887094147d5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationType = "ANALYSIS_ABORTED" | "ANALYSIS_COMPLETED" | "ANALYSIS_CREATED" | "ANALYSIS_FAILED" | "DEVICE_ALARM" | "LIBRARY_ABORTED" | "LIBRARY_CREATED" | "LIBRARY_FAILED" | "LIBRARY_READY" | "NEWS_CREATED" | "NOTE_CREATED" | "PROJECT_CREATED" | "REFERENCE_GENOME_INDEXING_FAILED" | "REFERENCE_GENOME_READY" | "REFERENCE_PROTEOME_READY" | "REFERENCE_PROTEOME_VALIDATING_FAILED" | "RUN_ABORTED" | "RUN_CREATED" | "RUN_FAILED" | "RUN_FINISHED" | "SAMPLE_CREATED" | "WORKFLOW_RELEASE_CREATED" | "WORKFLOW_RELEASE_UPDATED";
import { FragmentRefs } from "relay-runtime";
export type useNotificationData_notification$data = {
  readonly analysis?: {
    readonly handle: string | null;
    readonly name: string | null;
    readonly project: {
      readonly handle: string | null;
    } | null;
  } | null;
  readonly device?: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly id?: string;
  readonly library?: {
    readonly handle: string | null;
    readonly name: string | null;
    readonly project: {
      readonly handle: string | null;
    } | null;
  } | null;
  readonly newsItem?: {
    readonly handle: string | null;
    readonly title: string | null;
  } | null;
  readonly notificationType: NotificationType | null;
  readonly project?: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly reference?: {
    readonly handle: string | null;
    readonly name: string | null;
  } | null;
  readonly run?: {
    readonly handle: string | null;
    readonly name: string | null;
    readonly project: {
      readonly handle: string | null;
    } | null;
  } | null;
  readonly sample?: {
    readonly handle: string | null;
    readonly name: string | null;
    readonly project: {
      readonly handle: string | null;
    } | null;
  } | null;
  readonly workflowRelease?: {
    readonly versionName: string | null;
    readonly workflow: {
      readonly handle: string | null;
      readonly name: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "useNotificationData_notification";
};
export type useNotificationData_notification$key = {
  readonly " $data"?: useNotificationData_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"useNotificationData_notification">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "project",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useNotificationData_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Analysis",
          "kind": "LinkedField",
          "name": "analysis",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AnalysisNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "device",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "DeviceAlarmNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Library",
          "kind": "LinkedField",
          "name": "library",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "LibraryNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Run",
          "kind": "LinkedField",
          "name": "run",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "RunNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Sample",
          "kind": "LinkedField",
          "name": "sample",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "SampleNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Project",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ProjectNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Reference",
          "kind": "LinkedField",
          "name": "reference",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ReferenceNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NewsItem",
          "kind": "LinkedField",
          "name": "newsItem",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "NewsNotification",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "WorkflowRelease",
          "kind": "LinkedField",
          "name": "workflowRelease",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "versionName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Workflow",
              "kind": "LinkedField",
              "name": "workflow",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "WorkflowReleaseNotification",
      "abstractKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": "__isNotification"
};
})();

(node as any).hash = "fe0024a15ca45a73d5ee26542b4c938b";

export default node;
