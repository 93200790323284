import React from 'react';
import { graphql } from 'react-relay';

import LibraryList from 'components/LibraryList';
import createInfiniteList from 'components/utils/createInfiniteList';

import SearchList from './SearchList';
import type { LibrarySearchList_tenant$data as Tenant } from './__generated__/LibrarySearchList_tenant.graphql';

interface Props {
  tenant: DeepNonNull<Tenant>;
}

export const librarySearchListQuery = graphql`
  query LibrarySearchListQuery(
    $tenantSlug: String!
    $count: Int!
    $cursor: String
    $statuses: [LibraryStatus!]
    $sort: [LibrarySorting!]
    $search: String
    $userProfileHandles: [IdOrHandle!]
    $tagHandles: [IdOrHandle!]
    $projectHandles: [IdOrHandle!]
  ) {
    tenant: tenantBySlug(slug: $tenantSlug) {
      ...LibrarySearchList_tenant
    }
  }
`;

export default createInfiniteList(
  ({ tenant }: Props) => (
    <SearchList
      connection={tenant.libraryConnection}
      entityType="libraries"
      Component={LibraryList}
    />
  ),
  {
    tenant: graphql`
      fragment LibrarySearchList_tenant on Tenant {
        libraryConnection(
          status: $statuses
          sort: $sort
          search: $search
          createdBy: $userProfileHandles
          project: $projectHandles
          tag: $tagHandles
          first: $count
          after: $cursor
        ) @connection(key: "Tenant_libraryConnection") {
          edges {
            node {
              ...LibraryList_libraries
            }
          }
        }
      }
    `,
  },
  {
    query: librarySearchListQuery,
  },
);
