import { useRouter } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql, useMutation } from 'react-relay';

import Button from 'components/Button';
import Page from 'components/Page';
import PageContainer from 'components/PageContainer';
import Section from 'components/Section';
import Text from 'components/Text';

import ConfirmLogoutDialog from './ConfirmLogoutDialog';
import type { AcceptInvitationPage_invitation$data as Invitation } from './__generated__/AcceptInvitationPage_invitation.graphql';
import type { AcceptInvitationPage_viewer$data as Viewer } from './__generated__/AcceptInvitationPage_viewer.graphql';

interface Props {
  viewer: DeepNonNull<Viewer>;
  invitation: DeepNonNull<Invitation>;
}

interface AcceptButtonProps {
  onAccept: () => void;
  token: string;
}

function AcceptButton({ token, onAccept }: AcceptButtonProps) {
  const [mutate] = useMutation(graphql`
    mutation AcceptInvitationPageMutation($input: AcceptInvitationInput!) {
      acceptInvitationOrError(input: $input) {
        ...mutationError_error @relay(mask: false)
      }
    }
  `);

  return (
    <Button
      onClick={() => {
        mutate({
          variables: {
            input: {
              token,
            },
          },
          onCompleted: onAccept,
        });
      }}
    >
      <FormattedMessage
        id="acceptInvite.confirmAcceptButton"
        defaultMessage="Accept Invitation"
      />
    </Button>
  );
}

function AcceptInvitationPage({ viewer, invitation }: Props) {
  const { match, router } = useRouter();
  const handleAcceptInvite = () => {
    router.replace(`/${invitation.tenantInfo.slug}`);
  };
  const isWrongUser =
    viewer.user.email.toLowerCase() !== invitation.email.toLowerCase();

  return (
    <Page>
      <PageContainer center>
        {isWrongUser ? (
          <ConfirmLogoutDialog>
            <FormattedMessage
              id="acceptInvite.confirmLogoutAndLogin"
              defaultMessage="This invitation is for another email address. Please log in as {email} to accept the invitation."
              values={{ email: invitation.email }}
            />
          </ConfirmLogoutDialog>
        ) : (
          <Section size="medium" centerText>
            <div>
              <Text variant="bold" size="xl">
                <FormattedMessage
                  id="acceptInvite.confirmAcceptTitle"
                  defaultMessage="Invitation to Join {tenant}"
                  values={{ tenant: invitation.tenantInfo.name }}
                />
              </Text>
              <p>
                <FormattedMessage
                  id="acceptInvite.confirmAcceptBody"
                  defaultMessage="You have been invited to join a new Organization: {tenant}"
                  values={{ tenant: invitation.tenantInfo.name }}
                />
              </p>
              <AcceptButton
                token={match.params.token}
                onAccept={handleAcceptInvite}
              />
            </div>
          </Section>
        )}
      </PageContainer>
    </Page>
  );
}

export default createFragmentContainer(AcceptInvitationPage, {
  invitation: graphql`
    fragment AcceptInvitationPage_invitation on InvitationInfo {
      email
      tenantInfo {
        name
        slug
      }
    }
  `,
  viewer: graphql`
    fragment AcceptInvitationPage_viewer on Viewer {
      user {
        email
      }
    }
  `,
});
