/**
 * @generated SignedSource<<4b1259d1f3da5c4c561fc1bc5db2c332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RelayForm_error$data = {
  readonly __typename: string;
  readonly fields?: ReadonlyArray<{
    readonly message: string;
    readonly path: ReadonlyArray<string>;
  }>;
  readonly message: string | null;
  readonly " $fragmentType": "RelayForm_error";
};
export type RelayForm_error$key = {
  readonly " $data"?: RelayForm_error$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelayForm_error">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelayForm_error",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvalidInputErrorField",
          "kind": "LinkedField",
          "name": "fields",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "InvalidInputError",
      "abstractKey": null
    }
  ],
  "type": "ErrorInterface",
  "abstractKey": "__isErrorInterface"
};
})();

(node as any).hash = "a53a29fb3f5e3d521969f52e7b4bff51";

export default node;
