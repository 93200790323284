/**
 * @generated SignedSource<<aeaeb956f76faea4d135c8b5310a928d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sharedRoutes_runQuery$variables = {
  runHandle: string;
};
export type sharedRoutes_runQuery$data = {
  readonly run: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedRunPage_run">;
  } | null;
};
export type sharedRoutes_runQuery = {
  response: sharedRoutes_runQuery$data;
  variables: sharedRoutes_runQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "runHandle"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "handle",
    "variableName": "runHandle"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 0
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedRoutes_runQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Run",
        "kind": "LinkedField",
        "name": "run",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SharedRunPage_run"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sharedRoutes_runQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Run",
        "kind": "LinkedField",
        "name": "run",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numAnalyses",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numNotes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "LibraryLoadingConnection",
            "kind": "LinkedField",
            "name": "libraryLoadingConnection",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "libraryLoadingConnection(first:0)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fileUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "RunDataPlotConnection",
                "kind": "LinkedField",
                "name": "plotConnection",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": "plotConnection(first:0)"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "RunConnection",
            "kind": "LinkedField",
            "name": "splitRunsConnection",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": "splitRunsConnection(first:0)"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c49b00fc2113306665e62607a31488c",
    "id": null,
    "metadata": {},
    "name": "sharedRoutes_runQuery",
    "operationKind": "query",
    "text": "query sharedRoutes_runQuery(\n  $runHandle: String!\n) {\n  run(handle: $runHandle) {\n    ...SharedRunPage_run\n    id\n  }\n}\n\nfragment RunPageTabs_run on Run {\n  handle\n  numAnalyses\n  numNotes\n  status\n  libraryLoadingConnection(first: 0) {\n    pageInfo {\n      hasNextPage\n    }\n  }\n  data {\n    __typename\n    fileUrl\n    plotConnection(first: 0) {\n      pageInfo {\n        hasNextPage\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  splitRunsConnection(first: 0) {\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n\nfragment SharedRunPage_run on Run {\n  status\n  ...RunPageTabs_run\n}\n"
  }
};
})();

(node as any).hash = "e901897739ea720487b4c9319345c1e3";

export default node;
