import type { Match, RouteRenderArgs } from 'found';
import HttpError from 'found/HttpError';
import RedirectException from 'found/RedirectException';
import makeRouteConfig from 'found/makeRouteConfig';
import uniq from 'lodash/uniq';
import React from 'react';
import { Variables, graphql } from 'react-relay';
import StaticContainer from 'react-static-container';
// import TenantUserSettingsProvider from 'shared/components/TenantUserSettingsProvider';

import LaunchDarklyContext from 'components/LaunchDarklyContext';
import Route, { RelayRenderArgs } from 'components/Route';
import {
  TenantUserSettingProvider,
  useTenantUserSettingsQuery,
} from 'components/useTenantUserSettings';
import Analytics from 'utils/Analytics';
import { getSessionStore } from 'utils/SessionStore';
import { TenantContext, useTenantQuery } from 'utils/useTenant';

import type { routes_sidePanelQuery$data as SidePanelData } from './__generated__/routes_sidePanelQuery.graphql';
import analysisRedirect from './analysisRedirect';
import AcceptInvitationPage from './components/AcceptInvitationPage';
import AppPage from './components/AppPage';
import AppPageWithSidePanel from './components/AppPageWithSidePanel';
import EulaPage from './components/EulaPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ImpersonationPage from './components/ImpersonationPage';
import ResetPasswordPage from './components/ResetPasswordPage';
import SignupPage from './components/SignupPage';
import TenantSidePanelItem from './components/TenantSidePanelItem';
import UserSetupPage from './components/UserSetupPage';
import VerifyEmailChangePage from './components/VerifyEmailChangePage';
import VerifyEmailPage from './components/VerifyEmailPage';
import protocolRedirect from './protocolRedirect';
import rootRedirect from './rootRedirect';
import appsRoute from './routes/Apps';
import dashboardRoute from './routes/Dashboard';
import projectRoute from './routes/Data/routes/Project';
import devicesRoute from './routes/Devices';
import notificationsRoute from './routes/Notifications';
import profileRoute from './routes/Profile';
import referencesRoute from './routes/References';
import { searchHeaderRoutes, searchResultsRoute } from './routes/Search';
import settingsRoute from './routes/Settings';
import sqopeRoute from './routes/Sqope';
import runRedirect from './runRedirect';
import sharedRoutes from './sharedRoutes';
import tenantRedirect from './tenantRedirect';

function checkUserHygiene({ props, match }: RelayRenderArgs) {
  const viewer = props?.viewer;
  if (!viewer) return;

  if (!viewer.user.profile || !viewer.user.profile.name) {
    throw new RedirectException({
      pathname: '/-/user-setup',
    });
  }
  if (!viewer.user.emailVerified) {
    throw new RedirectException({
      pathname: '/-/verify-email',
    });
  }
  if (!viewer.user.hasAcceptedLatestCloudEula) {
    throw new RedirectException({
      pathname: '/-/eulas/latest/CLOUD',
      state: { nextUrl: match.location.pathname },
    });
  }
  if (!viewer.user.hasAcceptedLatestDeviceEula) {
    throw new RedirectException({
      pathname: '/-/eulas/latest/DEVICE',
      state: { nextUrl: match.location.pathname },
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment routes_checkUserHygiene_viewer on Viewer {
    user {
      hasAcceptedLatestCloudEula
      hasAcceptedLatestDeviceEula
      emailVerified
      passwordExpiresIn

      profile {
        name
      }
    }
  }
`;

const routes = [
  <Route
    path="/"
    getQuery={rootRedirect.getQuery}
    prerender={rootRedirect as any}
  />,

  <Route
    path="protocols"
    getQuery={protocolRedirect.getQuery}
    prerender={protocolRedirect as any}
  />,

  sharedRoutes,

  <Route path="-">
    <Route
      Component={AppPage}
      query={graphql`
        query routes_settingsRootQuery {
          viewer {
            ...AppPage_viewer
          }
        }
      `}
    >
      <Route
        path="eulas/latest/:eulaType"
        Component={EulaPage}
        query={graphql`
          query routes_eulaQuery {
            viewer {
              ...EulaPage_viewer
            }
          }
        `}
      />
      {profileRoute}
      <Route
        path="verify-email/:token?"
        Component={VerifyEmailPage}
        query={graphql`
          query routes_verifyEmailQuery {
            viewer {
              ...VerifyEmailPage_viewer
            }
          }
        `}
      />
    </Route>
    <Route
      path="user-setup"
      Component={UserSetupPage}
      query={graphql`
        query routes_userSetupPageQuery {
          viewer {
            user {
              profile {
                name
              }
            }
            ...UserSetupPage_viewer
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return;
        if (props.viewer.user?.profile.name) throw new HttpError(404);
      }}
    />
    <Route
      path="forgot-password"
      unauthenticated
      Component={ForgotPasswordPage}
    />
    <Route
      path="impersonation"
      unauthenticated
      Component={ImpersonationPage}
    />
    <Route
      unauthenticated
      path="reset-password/:token?"
      Component={ResetPasswordPage}
      query={graphql`
        query routes_resetPasswordQuery($token: String!) {
          passwordResetValid(token: $token) {
            valid
          }
        }
      `}
      prerender={({ props }) => {
        if (!props) return false;
        if (!props.passwordResetValid.valid) throw new HttpError(404);
        return true;
      }}
    />
    <Route
      unauthenticated
      path="verify-email-change/:token?"
      Component={VerifyEmailChangePage}
      prerender={({ props }) => {
        if (!props) return false;
        return true;
      }}
    />
    <Route
      path="/invitations/:token"
      Component={AcceptInvitationPage}
      unauthenticated
      query={graphql`
        query routes_AcceptInvitationQuery($token: String!) {
          invitation: invitationByToken(token: $token) {
            ...AcceptInvitationPage_invitation
            email
            userExists
          }
          viewer {
            ...AcceptInvitationPage_viewer
          }
        }
      `}
      prerender={({ props, match }) => {
        if (!props) return;

        const { invitation, viewer } = props;
        if (!invitation) throw new HttpError(404);

        if (!viewer) {
          // Not logged-in
          if (!invitation.userExists) {
            // No QSI account
            throw new RedirectException({
              pathname: '/-/signup',
              query: { invitationToken: match.params.token },
            });
          } else {
            // Has a QSI account, not logged-in
            throw new HttpError(401, {
              // Prepopulate the email for sign-in.
              email: invitation.email,
            });
          }
        }
      }}
    />
    <Route
      unauthenticated
      path="signup"
      Component={SignupPage}
      query={graphql`
        query routes_SignupQuery($token: String!) {
          invitation: invitationByToken(token: $token) {
            ...SignupPage_invitation
          }
        }
      `}
      prepareVariables={(params, { location }) => {
        const { query } = location;
        return {
          ...params,
          token: query.invitationToken,
        };
      }}
      prerender={({ props }) => {
        if (!props) return;
        if (!props.invitation) throw new HttpError(404);
      }}
    />
  </Route>,
  <Route
    path=":tenantSlug"
    query={useTenantQuery}
    renderFetched={({ props }: any) => {
      const { tenant } = props;

      if (!tenant) return null;

      const { handle, name, slug } = tenant;
      Analytics.group(handle, { name, slug });

      return <TenantContext.Provider value={tenant} />;
    }}
  >
    <Route
      query={useTenantUserSettingsQuery}
      renderFetched={({ props }: any) => {
        const { tenant } = props;
        return <TenantUserSettingProvider tenant={tenant} />;
      }}
    >
      <Route
        query={graphql`
          query routes_launchDarklyQuery($tenantSlug: String!) {
            tenant: tenantBySlug(slug: $tenantSlug) {
              handle
              viewerLaunchDarklyConfig {
                user
                hash
                state
              }
            }
          }
        `}
        renderFetched={({ props, match }: any) => {
          const { tenant } = props;
          const { launchDarkly, environment } = match.context;

          if (!tenant?.viewerLaunchDarklyConfig) {
            return null;
          }

          const ldclient = launchDarkly.init(
            environment,
            tenant.handle,
            tenant.viewerLaunchDarklyConfig,
          );

          // This will get cloned with the correct children.
          return <LaunchDarklyContext.Provider value={ldclient} />;
        }}
      >
        <Route
          Component={AppPageWithSidePanel}
          query={graphql`
            query routes_tenantSlugQuery($tenantSlug: String!) {
              viewer {
                ...AppPageWithSidePanel_viewer
                ...routes_checkUserHygiene_viewer @relay(mask: false)
              }
              tenant: tenantBySlug(slug: $tenantSlug) {
                ...AppPageWithSidePanel_tenant
              }
            }
          `}
          prerender={(args) => {
            if (!args.props) return;

            checkUserHygiene(args);
            if (!args.props.tenant) {
              // Root redirects don't check for valid targets to avoid an extra
              // round trip. If it turns out we redirected to somewhere invalid,
              // clear the cache and actually ask the server where to go.
              const { state } = args.match.location;
              if (state?.isRootRedirect) {
                const session = getSessionStore(args.match);
                session.removeTenantFromSession(args.match.params.tenantSlug);
                throw new RedirectException(state.prevLocation);
              }

              throw new HttpError(404);
            }
          }}
        >
          {{
            sidePanel: (
              <Route
                path="(.*)?"
                query={graphql`
                  query routes_sidePanelQuery(
                    $tenantSlug: String!
                    $p1: String!
                    $hasP1: Boolean!
                    $p2: String!
                    $hasP2: Boolean!
                    $p3: String!
                    $hasP3: Boolean!
                    $p4: String!
                    $hasP4: Boolean!
                    $p5: String!
                    $hasP5: Boolean!
                  ) {
                    tenant: tenantBySlug(slug: $tenantSlug) {
                      ...TenantSidePanelItem_tenant
                    }
                    p1: project(handle: $p1) @include(if: $hasP1) {
                      ...TenantSidePanelItem_recentProjects
                    }
                    p2: project(handle: $p2) @include(if: $hasP2) {
                      ...TenantSidePanelItem_recentProjects
                    }
                    p3: project(handle: $p3) @include(if: $hasP3) {
                      ...TenantSidePanelItem_recentProjects
                    }
                    p4: project(handle: $p4) @include(if: $hasP4) {
                      ...TenantSidePanelItem_recentProjects
                    }
                    p5: project(handle: $p5) @include(if: $hasP5) {
                      ...TenantSidePanelItem_recentProjects
                    }
                  }
                `}
                render={({
                  props,
                  variables,
                }: RouteRenderArgs & {
                  props?: DeepNonNull<SidePanelData> & { match: Match };
                  variables: Variables;
                }) => {
                  let content: React.ReactNode = null;

                  if (props) {
                    const { p1, p2, p3, p4, p5, match, ...data } = props;
                    const session = getSessionStore(match);
                    const { tenantSlug } = match.params;
                    const projects = { p1, p2, p3, p4, p5 };
                    const recentProjects: (typeof p1)[] = [];

                    Object.entries(projects).forEach(([key, value]) => {
                      if (value) {
                        recentProjects.push(value);
                      } else {
                        session.removeProjectFromSession(
                          tenantSlug,
                          variables[key],
                        );
                      }
                    });

                    content = (
                      <TenantSidePanelItem
                        {...data}
                        recentProjects={recentProjects}
                      />
                    );
                  }

                  return (
                    <StaticContainer shouldUpdate={!!props}>
                      {content}
                    </StaticContainer>
                  );
                }}
                prepareVariables={(variables, match) => {
                  const session = getSessionStore(match);
                  const { tenantSlug, projectHandle } = match.params;
                  const tenant = session.getTenantSession(tenantSlug);
                  const recentProjects = tenant.lastVisitedProjects.map(
                    (p) => p.handle,
                  );

                  // we add the projectHandle at the beginning, because when
                  // navigating to a project, that project might not necessarily be
                  // in the recent projects
                  const [p1, p2, p3, p4, p5] = uniq([
                    projectHandle,
                    ...recentProjects,
                  ]).filter(Boolean);

                  return {
                    ...variables,
                    p1: p1 || '',
                    hasP1: !!p1,
                    p2: p2 || '',
                    hasP2: !!p2,
                    p3: p3 || '',
                    hasP3: !!p3,
                    p4: p4 || '',
                    hasP4: !!p4,
                    p5: p5 || '',
                    hasP5: !!p5,
                  };
                }}
              />
            ),
            header: searchHeaderRoutes,
            children: [
              <Route
                path="/"
                getQuery={tenantRedirect.getQuery}
                prerender={tenantRedirect as any}
              />,
              <Route
                path="/analyses/:analysisHandle"
                query={analysisRedirect.query}
                prerender={analysisRedirect}
              />,
              <Route
                path="/runs/:runHandle"
                query={runRedirect.query}
                prerender={runRedirect}
              />,
              sqopeRoute,
              dashboardRoute,
              projectRoute,
              devicesRoute,
              settingsRoute,
              searchResultsRoute,
              referencesRoute,
              appsRoute,
              notificationsRoute,
            ],
          }}
        </Route>
      </Route>
    </Route>
  </Route>,
];

export default makeRouteConfig(routes);
